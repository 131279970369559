import PropTypes from 'prop-types';
import React from 'react';

import { DocumentIcon } from '@heroicons/react/20/solid';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import getFileType from 'utils/file/getFileType';
import isImageFile from 'utils/file/isImageFile';

const CompanyDownloadPreview = ({ download }) => {
  const isImage = isImageFile(download?.file);
  const fileType = getFileType(download);

  return (
    <div className="flex w-full h-32 justify-center items-center rounded-t-lg relative">
      {isImage ? (
        <>
          <UniversalImage
            image={download.file}
            alt={`File preview of ${download.title}`}
          />
          <div className="absolute inset-0 bg-gray-500 opacity-20" />
        </>
      ) : (
        <>
          <div className="absolute inset-0 bg-teal-500 opacity-50" />
          <div className="relative">
            <DocumentIcon className="w-24 h-24 text-white" />
            <span
              className="text-xs absolute inset-0 mt-1 flex justify-center items-center text-gray-500 font-black"
              data-test-id={`company-download-${download.title}-type`}
            >
              {fileType}
            </span>
          </div>
        </>
      )}
      <p
        className="absolute bottom-4 right-4 text-xs text-gray-100 rounded-lg bg-black bg-opacity-20 px-2 py-1"
        data-test-id={`company-download-${download.title}-size`}
      >
        {Math.floor(download.file?.size ? download.file.size / 1024 : 0)}kB
      </p>
    </div>
  );
};

CompanyDownloadPreview.propTypes = {
  download: PropTypes.object,
};

export default CompanyDownloadPreview;
