import { useEffect } from 'react';

import useHandleLogout from 'hooks/useHandleLogout';

const LogoutPage = () => {
  const logout = useHandleLogout('requires-recent-login=true');

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default LogoutPage;
