import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import Modal from 'components/Modal';
import UniversalImage from 'components/UniversalImage/UniversalImage';
import MediaOpportunitiesExplanation from 'static/media-opportunities-explanation.png';

const OpportunitiesMetaHowToModal = ({ open = false, onClose = noop }) => (
  <Modal open={open} widthClass="sm:max-w-4xl">
    <Modal.Close srMessage="Close helper modal" onClose={onClose} />
    <Modal.Description>
      <UniversalImage
        image={{ path: MediaOpportunitiesExplanation }}
        alt="Opportunities explained"
      />
      <div className="text-left text-md mt-4 text-gray-800 leading-relaxed">
        <h3 className="text-xl font-semibold">What is an Opportunity?</h3>
        <p className="mt-1.5">
          An Opportunity is your segway into a conversation with a journalist on
          a topic you know about.
          <br /> Let us walk you through a few of the elements that might not be
          obvious at first glance:
        </p>
        <h4 className="mt-3 text-lg font-semibold">Tags</h4>
        <p className="mt-1.5 text-md">
          Tags help you understand what type of opportunity you&apos;re dealing
          with:
        </p>
        <ul className="mt-2 ml-6 list-disc space-y-1.5">
          <li>
            Blue tags (like the one above) stand for <b>custom</b> opportunities
            created by the MVPR team with a high response rate
          </li>
          <li>
            All other tags are keywords in a <b>regular</b> opportunity matching
            your search terms
          </li>
        </ul>
        <p className="mt-2">
          For more on keywords and how to respond to each opportunity type, see
          our article on{' '}
          <a
            className="text-teal-500 underline hover:no-underline"
            target="_blank"
            href="https://help.mvpr.io/en/articles/8679849-how-to-use-media-opportunities"
            rel="noreferrer"
          >
            How to use Opportunities
          </a>
          .
        </p>
        <h4 className="mt-3 text-lg font-semibold">Domain Authority</h4>
        <p className="mt-2">
          The Domain Authority is a rankings of publications independently
          provided by{' '}
          <a
            className="text-teal-500 underline hover:no-underline"
            href="https://moz.com/"
            target="_blank"
            rel="noreferrer"
          >
            moz.com
          </a>{' '}
          - it measures how high they rank on Google and how much traffic they
          attract.
        </p>
        <p className="mt-2">
          For more info, see our{' '}
          <a
            className="text-teal-500 underline hover:no-underline"
            target="_blank"
            href="https://help.mvpr.io/en/articles/8837206-guide-to-pitching-journalists"
            rel="noreferrer"
          >
            Guide to pitching journalists
          </a>
        </p>

        <h4 className="mt-3 text-lg font-semibold">Deadline</h4>
        <p className="mt-2">There are two possibilities here:</p>
        <ul className="mt-2 ml-6 list-disc space-y-1.5">
          <li>
            <b>Ongoing</b>: the opportunity doesn&apos;t expire and you can
            respond to it anytime
          </li>
          <li>
            <b>An expiry date</b> (e.g. &quot;4 days left&quot;): the
            opportunity has a fixed deadline and won&apos;t accept any more
            responses after that
          </li>
        </ul>
      </div>
    </Modal.Description>
    <Modal.Content />
  </Modal>
);

OpportunitiesMetaHowToModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default OpportunitiesMetaHowToModal;
