import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { CREATE_NEW_COMPANY, ROOT_PATH } from 'constants/paths';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useQuery from 'hooks/useQuery';
import getPageTitle from 'utils/getPageTitle';

import OnboardingSignupModal from './OnboardingSignupModal/OnboardingSignupModal';

const OnboardingPage = () => {
  const navigate = useNavigate();

  const query = useQuery();

  const { isAnonymous, uid } = useUserData();

  const onComplete = () => {
    navigate(`${CREATE_NEW_COMPANY}?${query.toString()}`);
  };

  const isLoggedIn = !isAnonymous && uid;

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROOT_PATH);
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className="relative bg-gray-50 min-h-screen">
      <Helmet>
        <title>{getPageTitle('Sign up')}</title>
      </Helmet>
      <OnboardingSignupModal open onSignupComplete={onComplete} />
    </div>
  );
};

export default OnboardingPage;
