import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useTracking } from 'modules/segment/TrackingProvider';

import ContentCalendarTab from './ContentCalendar/ContentCalendarTab';
import TasksTab from './Tasks/TasksTab';

const TAB_COMPONENTS = {
  'content-calendar': ContentCalendarTab,
  tasks: TasksTab,
};

const Planning = () => {
  const trackingService = useTracking();
  const { tab } = useParams();
  const TabComponent = TAB_COMPONENTS[tab];

  useEffect(() => {
    trackingService.page('Planning');
  }, [trackingService]);

  if (!TabComponent) {
    return null;
  }

  return <TabComponent />;
};

export default Planning;
