import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import useAPIRequest from './useAPIRequest';

const useSaveJournalistResponse = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const companyUid = searchParams.get('companyUid');
  const threadId = searchParams.get('threadId');
  const journalistResponse = searchParams.get('journalistResponse');
  const { fetchData: saveJournalistResponse } = useAPIRequest({
    service: 'MOD',
    method: 'PUT',
  });

  useEffect(() => {
    if (journalistResponse && threadId) {
      saveJournalistResponse({
        endpoint: `/threads/${threadId}/journalistResponse`,
        body: { response: journalistResponse },
      });
    }
  }, [journalistResponse, threadId, saveJournalistResponse]);

  return {
    threadId,
    journalistResponse,
    companyUid,
    resetSearchParams: () =>
      setSearchParams({ thread: null, journalistResponse: null }),
  };
};

export default useSaveJournalistResponse;
