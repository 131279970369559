import PropTypes from 'prop-types';
import React from 'react';

import DocumentTag from 'routes/ContentManagement/ContentManagementDocuments/DocumentTag';
import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';

const MvprDeeplinkDocument = ({
  data: {
    title = '',
    type = '',
    draft: { plainText = '', lastEdited } = {},
  } = {},
}) => (
  <div className="flex flex-col gap-2 text-gray-700">
    <div className="items-center text-xs text-gray-500 flex gap-2 justify-between">
      <DocumentTag type={type} />
      {lastEdited ? (
        <p>Last edited {getDateFromTimestamp(lastEdited)}</p>
      ) : null}
    </div>
    <h2 className="font-bold">{title}</h2>
    <p className="line-clamp-3 text-sm">{plainText}</p>
  </div>
);

MvprDeeplinkDocument.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    journalist: PropTypes.object,
  }),
};

export default MvprDeeplinkDocument;
