import PropTypes from 'prop-types';
import React from 'react';

import { Controller } from 'react-hook-form';

import OnboardingInputsContainer from 'components/OnboardingInputsContainer';
import SeparatorTextLine from 'components/form/SeparatorTextLine';
import InputFieldCheckboxesCards from 'components/form/inputFields/InputFieldCheckboxesCards/InputFieldCheckboxesCards';
import InputFieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import { PAGE_TITLE } from 'constants/meta';

import TitleSubtitle from './TitleSubtitle';

const optionsPrGoals = [
  { emoji: '📰', label: 'Get regular press coverage' },
  { emoji: '🗣', label: 'Share news about a current or upcoming announcement' },
  { emoji: '🛠', label: "Build up your company's brand name" },
  { emoji: '🎙', label: 'Grow your own voice with the industry' },
  { emoji: '🏆', label: 'Convince investors of your credibility' },
];

const StepOne = ({ form }) => (
  <OnboardingInputsContainer>
    <TitleSubtitle
      title="What would you like to achieve by doing PR?"
      subtitle="Select as many as are relevant to you."
    />

    <Controller
      control={form.control}
      name="company.prGoals"
      defaultValue={[]}
      render={({ field: { value, onChange } }) => (
        <>
          <InputFieldCheckboxesCards
            options={optionsPrGoals}
            value={value}
            onChange={onChange}
            testId="onboarding-prGoals"
          />

          <SeparatorTextLine text="OR" classNames="mt-0.5" />

          <InputFieldTextArea
            name="company.prGoalsOther"
            placeholder={`Let us know what you’d like to achieve using ${PAGE_TITLE}`}
            data-test-id="onboarding-prGoals-other"
          />
        </>
      )}
    />
  </OnboardingInputsContainer>
);

StepOne.propTypes = {
  form: PropTypes.object.isRequired,
};

export default StepOne;
