/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import noop from 'lodash/noop';
import { useStorage } from 'reactfire';
import { v4 as uuidv4 } from 'uuid';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';
import usePhotoUrl from 'hooks/usePhotoUrl';
import useUserName from 'hooks/useUserName';

const InputFieldTextDocumentEditor = ({
  onChange = noop,
  initialValue = '',
  id = '',
  height = '75vh',
  testId = '',
  editorRef = {},
  aiContext = {},
}) => {
  const userPhotoUrl = usePhotoUrl();
  const userName = useUserName();
  const storage = useStorage();
  const { showError } = useNotifications();
  const { companyData } = useCompanyData();
  const { fetchData: fetchOpenApiData } = useAPIRequest({
    method: 'POST',
    service: 'MOD',
  });
  const uploadFolder = `inboxes/${companyData?.uid}`;

  const getBaseLanguage = (locale) => locale.split('-')[0] || 'en';
  const spellCheckerLanguage = getBaseLanguage(
    companyData?.defaultLanguage || 'en-US'
  );

  const uploadFile = async (file) => {
    const uploadRef = ref(storage, `${uploadFolder}/${uuidv4()}-${file.name}`);
    await uploadBytes(uploadRef, file, {
      contentDisposition: `attachment; filename="${file.name}"`,
      customMetadata: {
        companyId: companyData?.uid,
      },
    });
    const url = await getDownloadURL(uploadRef);
    return url;
  };

  const handleAIRequest = useCallback(
    async (request) => {
      try {
        const response = await fetchOpenApiData({
          endpoint: `/editor/${companyData?.uid}/completions`,
          body: {
            ...request,
            ...aiContext,
          },
        });

        return response?.choices[0]?.message?.content?.trim() || '';
      } catch (error) {
        showError({
          message: `There was an error communicating with the AI`,
        });
      }
      return 'Something went wrong.';
    },
    [fetchOpenApiData, showError, aiContext, companyData?.uid]
  );

  const EDITOR_SETTINGS = {
    branding: false,
    elementpath: false,
    height,
    menubar: false,
    convert_urls: false,
    plugins: [
      'ai',
      'advlist',
      'autolink',
      'lists',
      'link',
      'image',
      'charmap',
      'anchor',
      'media',
      'wordcount',
      'tinymcespellchecker',
      'code',
      'tinycomments',
    ],
    tinycomments_mode: 'embedded',
    tinycomments_author: userName || 'Unknwon',
    tinycomments_author_avatar: userPhotoUrl,
    tinycomments_can_resolve(req, done) {
      const allowed = req.comments.length > 0;
      done({
        canResolve: allowed,
      });
    },
    toolbar:
      'aidialog | blocks | image |' +
      'addcomment showcomments |' +
      'bold italic forecolor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | ' +
      'removeformat' +
      'spellchecker language spellcheckdialog',
    spellchecker_language: spellCheckerLanguage,
    spellchecker_active: true,
    ai_request: (request, respondWith) => {
      respondWith.string(() => handleAIRequest(request));
    },
    extended_valid_elements: 'span[class]',
    content_style: `
  body {
    max-width: 90ch;
    margin: 20px auto;
    padding: 0 20px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 1.5;
  }

  a {
    color: #38b2ac;
  }

  .highlight {
    cursor: pointer;
    background-color: #fdf6b2;
    display: inline-block;
    color: #633112;
  }


  .journalistPlaceholder {
    color: #B91C1C;
    display: inline-block;
    background-color: #FECACA;
    cursor: not-allowed;
  }
  `,
    file_picker_callback: (cb) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      input.addEventListener('change', async (e) => {
        const file = e.target.files[0];
        const url = await uploadFile(file);
        cb(url);
      });

      input.click();
    },
    setup(editor) {
      editor.on('SkinLoaded', () => {
        editor.execCommand('showcomments');
      });
    },
  };

  return (
    <div data-test-id={testId} className="h-full">
      <Editor
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        onEditorChange={(v, editor) => {
          onChange(v, editor.getContent({ format: 'text' }));
        }}
        onInit={(_, editor) => {
          editorRef.current = editor;
        }}
        initialValue={initialValue}
        init={EDITOR_SETTINGS}
        id={id}
      />
    </div>
  );
};

InputFieldTextDocumentEditor.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string,
  initialValue: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  testId: PropTypes.string,
  editorRef: PropTypes.object,
  aiContext: PropTypes.object,
};

export default InputFieldTextDocumentEditor;
