import React, { useEffect } from 'react';

import { captureException } from '@sentry/react';
import { useRouteError } from 'react-router-dom';

import ErrorPage from './ErrorPage';

const SentryRouteErrorFallback = () => {
  const routeError = useRouteError();

  useEffect(() => {
    captureException(routeError, { level: 'fatal' });
  }, [routeError]);

  return <ErrorPage instructions={<p>${routeError.toString()}</p>} />;
};

export default SentryRouteErrorFallback;
