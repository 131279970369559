const tasksColumns = [
  {
    id: 'BACKLOG',
    title: 'Backlog',
  },
  {
    id: 'IN_PROGRESS',
    title: 'In Progress',
  },
  {
    id: 'READY_FOR_REVIEW',
    title: 'Ready for Review',
  },
  {
    id: 'IN_REVIEW',
    title: 'In Review',
  },
  {
    id: 'COMPLETED',
    title: 'Completed',
  },
  {
    id: 'WITH_3RD_PARTY',
    title: 'With 3rd Party',
  },
];

export const activityTypes = [
  {
    id: 'EVENTS_AND_AWARDS',
    title: 'Events and Awards',
  },
  {
    id: 'PLANNING',
    title: 'Planning',
  },
  {
    id: 'PODCASTS',
    title: 'Podcasts',
  },
  {
    id: 'PROACTIVE_PITCHING',
    title: 'Proactive Pitching',
  },
  {
    id: 'REACTIVE_COMMENTARY',
    title: 'Reactive Commentary',
  },
  {
    id: 'THOUGHT_LEADERSHIP',
    title: 'Thought Leadership',
  },
  {
    id: 'PRESS_RELEASE',
    title: 'Press Release',
  },
  {
    id: 'SOCIAL_MEDIA',
    title: 'Social Media',
  },
];

export default tasksColumns;
