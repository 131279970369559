import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';
import { useTracking } from 'modules/segment/TrackingProvider';

import TargetHint from '../CompanyTargetForm/TargetHint';

const CompanyInviteUserForm = ({ onSuccess = noop }) => {
  const { companyData } = useCompanyData();
  const trackingService = useTracking();
  const { showSuccess, showError } = useNotifications();
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const { fetchData: addNewMember } = useAPIRequest({
    method: 'PUT',
    endpoint: `/company/${companyData.uid}/invites`,
    service: 'UCD',
  });

  const onSubmit = async (data) => {
    if (!data.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setError('email', {
        type: 'manual',
        message: 'Invalid email address format',
      });
      return;
    }
    clearErrors('email');

    try {
      await addNewMember({ body: { email: data.email }, shouldThrow: true });

      trackingService.track('Invite Sent', {
        companyId: companyData.uid,
      });

      showSuccess({
        message:
          'Your invitation has been successfully sent to the provided email address.',
      });
      setValue('email', '');
      onSuccess();
    } catch (err) {
      if (err.message.startsWith('A user with this email already exists')) {
        showError({
          message: <>We&apos;re sorry! This email has already been used.</>,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div className="grid grid-cols-12 gap-6 items-center">
        <div className="col-span-12 md:col-span-6">
          <label
            htmlFor="add-team-members"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Add new team members to your company
          </label>
          <div className="mt-2 flex">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: 'Please provide an email address',
              }}
              render={({ field }) => (
                <InputFieldText
                  {...field}
                  name="email"
                  placeholder="Email address"
                  errors={errors}
                />
              )}
            />
            <span className="ml-3">
              <button
                type="submit"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Add
              </button>
            </span>
          </div>
        </div>
        <TargetHint
          text="We'll send an email which allows new users to join your
              company. They'll have access to all its resources, except the
              subscription settings and adding new members."
        />
      </div>
    </form>
  );
};

CompanyInviteUserForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default CompanyInviteUserForm;
