import PropTypes from 'prop-types';
import React from 'react';

const StorySummary = ({ summary }) => (
  <p className="mt-2 text-sm text-gray-700 overflow-hidden line-clamp-3">
    {summary}
  </p>
);

StorySummary.propTypes = {
  summary: PropTypes.string,
};

export default StorySummary;
