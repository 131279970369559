export const tabNames = {
  CALENDAR: 'content-calendar',
  TASKS: 'tasks',
};

export const tabs = [
  { title: 'Tasks', field: tabNames.TASKS },
  { title: 'Content Calendar', field: tabNames.CALENDAR },
];

export default tabs;
