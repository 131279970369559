import PropTypes from 'prop-types';
import React from 'react';

import { noop } from 'lodash';

import AdminCoveragesTableRow from './AdminCoveragesTableRow/AdminCoveragesTableRow';
import AdminCoveragesTableSortableHeader from './AdminCoveragesTableSortableHeader';

const AdminCoveragesTable = ({
  coverages = [],
  sort = {
    by: 'createdAt',
    direction: 'asc',
  },
  setSort = noop,
  selected = [],
  setSelected = noop,
}) => (
  <div className="max-h-full h-auto shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
    <table className="min-w-full divide-y divide-gray-200 text-gray-500">
      <thead className="z-20 sticky top-0 bg-gray-50 text-xs font-medium uppercase">
        <tr className="text-left tracking-wider">
          <th scope="col" className="sticky left-0 bg-gray-50 px-4 py-2 ">
            <input
              checked={selected.length === coverages.length}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelected(coverages);
                } else {
                  setSelected([]);
                }
              }}
              type="checkbox"
              className="h-4 w-4 mr-2 text-teal-600 focus:ring-teal-500 border-gray-300 rounded cursor-pointer"
            />
          </th>
          <th scope="col" className="px-4 py-2 max-w-sm">
            Company
          </th>
          <th scope="col" className="px-4 py-2 max-w-sm">
            Publication
          </th>
          <th scope="col" className="px-4 py-2 max-w-sm">
            Coverage title
          </th>
          <th scope="col" className="px-4 py-2 max-w-sm">
            Intent
          </th>
          <th scope="col" className="px-4 py-2 text-center max-w-sm">
            Status
          </th>
          <AdminCoveragesTableSortableHeader
            by="created_at"
            sort={sort}
            setSort={setSort}
          >
            Date created
          </AdminCoveragesTableSortableHeader>
          <AdminCoveragesTableSortableHeader
            by="published_at"
            sort={sort}
            setSort={setSort}
          >
            Date published
          </AdminCoveragesTableSortableHeader>
          <th scope="col" className="px-4 py-2 max-w-sm">
            Date invoiced
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 text-sm">
        {coverages?.map((coverage) => (
          <AdminCoveragesTableRow
            key={coverage.id}
            coverage={coverage}
            isSelected={selected.find((c) => c.id === coverage.id)}
            handleSelected={() => {
              if (selected.find((c) => c.id === coverage.id)) {
                setSelected(selected.filter((c) => c.id !== coverage.id));
              } else {
                setSelected([...selected, coverage]);
              }
            }}
          />
        ))}
      </tbody>
    </table>
  </div>
);

AdminCoveragesTable.propTypes = {
  sort: PropTypes.shape({
    by: PropTypes.string,
    direction: PropTypes.string,
  }),
  setSort: PropTypes.func,
  coverages: PropTypes.array,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

export default AdminCoveragesTable;
