import PropTypes from 'prop-types';
import React from 'react';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import JournalistTeaser from 'components/JournalistTeaser';

import StoryTags from './StoryTags';

const StoryHeading = ({ journalist, topics }) => (
  <div className="flex items-start mb-2 flex-col">
    <div className="flex flex-row justify-start mb-2 flex-wrap">
      <DomainAuthorityPill
        className="mb-2 mr-2"
        domainAuthority={journalist?.publication?.domainAuthority}
      />
      {topics.length > 0 && (
        <StoryTags className_="-mt-[3px]" topics={topics.slice(0, 5)} />
      )}
    </div>
    <div className="flex items-start space-x-4">
      <JournalistTeaser journalist={journalist} />
    </div>
  </div>
);

StoryHeading.propTypes = {
  journalist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    publication: PropTypes.object,
  }).isRequired,
  topics: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StoryHeading;
