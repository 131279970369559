import PropTypes from 'prop-types';
import React from 'react';

import { Menu } from '@headlessui/react';
import {
  ChevronDownIcon,
  BarsArrowUpIcon,
  BarsArrowDownIcon,
} from '@heroicons/react/20/solid';
import classNames from 'classnames';
import noop from 'lodash/noop';

import { sortOptions } from 'constants/opportunitiesOptions';

import OpportunitiesSortOption from './OpportunitiesSortOption';

// Opportunities sort dropdown: allows sorting opportunities by whatever you pass in
const OpportunitiesSort = ({
  sort = null,
  setSort = noop,
  sortDir = 1,
  setSortDir = noop,
  wrapperClassName = 'hidden lg:flex',
  buttonClassName = 'w-44',
}) => (
  <div className={wrapperClassName}>
    <div
      className="relative"
      data-test-id="opportunities-sortBy"
      data-intercom-id="opportunities-sort"
    >
      <Menu>
        <Menu.Button
          className={classNames(
            'h-full flex justify-between text-teal-500 px-3 py-2 border border-r-0 border-gray-300 rounded-l-md text-sm cursor-pointer focus:outline-none',
            buttonClassName
          )}
        >
          <div className="flex items-center">
            {sort === null && <span className="text-gray-700">Sort by</span>}
            <span className="">{sort !== null && sort.label}</span>
          </div>
          <ChevronDownIcon className="w-4 ml-2" />
        </Menu.Button>

        <Menu.Items
          className="origin-top-right absolute top-11 left-0 z-20 rounded-md shadow-lg py-1 bg-white focus:outline-none ring-1 ring-gray-200"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          {sortOptions.map((option) => (
            <OpportunitiesSortOption
              key={option.name}
              option={option}
              onClick={() => setSort(option.name === 'none' ? null : option)}
            />
          ))}
        </Menu.Items>
      </Menu>
    </div>
    <div className="relative">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setSortDir(sortDir === 1 ? -1 : 1);
        }}
        className="h-full flex text-teal-500 px-3 py-2 border border-gray-300 rounded-r-lg text-sm font-medium hover:bg-gray-100 cursor-pointer focus:outline-none"
      >
        {sortDir === -1 ? (
          <BarsArrowUpIcon className="w-4 h-4 my-0.5" />
        ) : (
          <BarsArrowDownIcon className="w-4 h-4 my-0.5" />
        )}
      </a>
    </div>
  </div>
);

OpportunitiesSort.propTypes = {
  sort: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
  }),
  sortDir: PropTypes.number,
  setSort: PropTypes.func.isRequired,
  setSortDir: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
};

export default OpportunitiesSort;
