import React from 'react';

import SweetheartListHeader from './SweetheartListHeader/SweetheartListHeader';
import SweetheartListJournalistLists from './SweetheartListJournalistLists';
import SweetheartListJournalistListsList from './SweetheartListJournalistListsList';
import SweetheartListJournalistsSearch from './SweetheartListJournalistsSearch';
import SweetheartListNews from './SweetheartListNews/SweetheartListNews';

const SweetheartList = () => (
  <div className="h-full flex relative grow flex-col self-stretch w-full">
    <SweetheartListHeader />
    <SweetheartListNews />
    <SweetheartListJournalistLists />
    <SweetheartListJournalistListsList />
    <SweetheartListJournalistsSearch />
  </div>
);

export default SweetheartList;
