import PropTypes from 'prop-types';
import React from 'react';

import { Doughnut } from 'react-chartjs-2';

import capitalizeKeys from './utils/capitalizeKeys';
import { adaptColorsToDataLength } from './utils/colors';
import { legend, title, tooltip } from './utils/options';
import sortData from './utils/sortData';

const getLabel = (context) => context.parsed || 0;

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend,
    tooltip: tooltip(getLabel),
    title,
  },
};

const JournalistDetailsModalTopics = ({ topics = {} }) => {
  const capitalizedTopics = capitalizeKeys(topics);
  const sortedTopics = sortData(capitalizedTopics);

  return (
    <Doughnut
      className="text-"
      options={options}
      data={{
        labels: sortedTopics.map(([key]) => key),
        datasets: [
          {
            data: sortedTopics.map(([, value]) => value),
            backgroundColor: adaptColorsToDataLength(sortedTopics),
          },
        ],
      }}
    />
  );
};

JournalistDetailsModalTopics.propTypes = {
  topics: PropTypes.objectOf(PropTypes.number),
};

export default JournalistDetailsModalTopics;
