import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { debounce, noop } from 'lodash';
import { v4 as uuid } from 'uuid';

import Label from 'components/form/Label';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import useAudienceSearchSuggestions from 'routes/Company/CompanyMeta/hook/useAudienceSearchSuggestions';

const ContentManagementResponseFormAudiences = ({
  audiences = [],
  onChange = noop,
}) => {
  const [searchString, setSearchString] = useState('');
  const [suggestions] = useAudienceSearchSuggestions(searchString);

  const debouncedOnChange = useMemo(
    () => debounce(setSearchString, 250),
    [setSearchString]
  );

  return (
    <div>
      <Label htmlFor="audiences">Audiences</Label>
      <small className="mt-1 text-gray-400 text-xs">
        {` We'll make sure the Generated Content is targeting this specific set of audiences.`}
      </small>
      <div className="flex max-w-md mt-1 space-x-2 md:max-w-2xl">
        <InputFieldTagsAutocomplete
          placeholder="Add audiences"
          customFieldName="audience"
          suggestions={suggestions}
          onInput={debouncedOnChange}
          tags={
            audiences.map((audience) => ({
              id: uuid(),
              name: audience,
            })) || []
          }
          onAddition={(tag) => {
            if (audiences.find((t) => t === tag.name)) {
              return;
            }
            onChange([...audiences, tag.name]);
          }}
          onDelete={(tag) => {
            onChange(audiences.filter((t) => tag.name !== t));
          }}
        />
      </div>
    </div>
  );
};

ContentManagementResponseFormAudiences.propTypes = {
  audiences: PropTypes.array,
  onChange: PropTypes.func,
};

export default ContentManagementResponseFormAudiences;
