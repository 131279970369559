import React from 'react';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import EmptyState from 'components/EmptyState';
import useUserData from 'contexts/UserContext/hooks/useUserData';

const AgencyViewStatesError = () => {
  const user = useUserData();

  return (
    <EmptyState
      helmetTitle="AgencyCompany List"
      testId="AgencyCompanyList-ListError"
      title="There's been an error fetching the AgencyCompany list."
      subtitle="Please get in touch with tom@mvpr.io to resolve this issue."
      Icon={ExclamationTriangleIcon}
      instructions={
        !user.claimsAdmin ? null : (
          <div className="pt-4">
            <h3 className="text-md font-semibold mx-8 text-center">
              There has been an error loading your dashboard.
            </h3>
          </div>
        )
      }
    />
  );
};

AgencyViewStatesError.propTypes = {};

export default AgencyViewStatesError;
