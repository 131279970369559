import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { useNotifications } from 'components/Notifications/NotificationsProvider';

import useCurrentDocumentContext from './useCurrentDocumentContext';

const useSendContentDocument = () => {
  const { updateDocument } = useCurrentDocumentContext();
  const { showSuccess, showError } = useNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });
  const messageError = errors;

  const createSendContentDocument = () => async () => {
    setIsLoading(true);
    const { document: formDocument } = getValues();
    const { title, type, author, audiences, threadData, draft } = formDocument;
    const dateCreated = new Date().toISOString();
    const documentDoc = {
      dateCreated,
      title,
      type,
      author,
      threadData,
      audiences,
      draft: {
        ...draft,
        lastEdited: new Date(),
      },
    };

    try {
      await updateDocument(documentDoc);

      showSuccess({
        message: `Your document was saved.`,
      });
    } catch {
      showError({
        title: 'Whoops! Something went wrong',
        message: "We couldn't save your document, please try again",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleSubmit,
    createSendContentDocument,
    setValue,
    register,
    messageError,
    reset,
    control,
    isLoading,
  };
};

export default useSendContentDocument;
