import PropTypes from 'prop-types';
import React from 'react';

import { EyeIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

const RemovePhotoModal = ({
  open = false,
  onClose = noop,
  onSubmit = noop,
}) => (
  <Modal open={open}>
    <Modal.Icon Icon={EyeIcon} />
    <Modal.Title>Remove your profile photo</Modal.Title>
    <Modal.Description>
      Are you sure you want to remove your profile photo?
    </Modal.Description>
    <Modal.RightButtons>
      <Button type="secondary" onClick={() => onClose()}>
        Cancel
      </Button>

      <Button onClick={onSubmit}>Remove photo</Button>
    </Modal.RightButtons>
  </Modal>
);

RemovePhotoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RemovePhotoModal;
