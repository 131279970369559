/* eslint-disable max-params */
import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const fetchCompanyCoverages = async ({ uid, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/companies/${uid}/coverages`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch coverage: ${error.message}`);
  }
};

const useCompanyCoverages = (uid = '') => {
  const auth = useAuth();

  return useQuery(
    ['coverages'],
    async () =>
      fetchCompanyCoverages({
        uid,
        auth,
      }),
    {
      enabled: !!uid,
      keepPreviousData: true,
      initialData: {
        items: [],
        meta: {},
      },
    }
  );
};

export default useCompanyCoverages;
