import PropTypes from 'prop-types';
import React from 'react';

const ReportTitle = ({ title = '', subtitle = '', wrapperClassName = '' }) => (
  <div className={wrapperClassName}>
    <p className="font-bold text-xl text-gray-700 text-left">{title}</p>
    <p className="text-xs text-gray-500">{subtitle}</p>
  </div>
);

ReportTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default ReportTitle;
