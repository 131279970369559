import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const fetchJournalistListsStories = async ({ uid, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/companies/${uid}/newsfeed`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch journalist lists: ${error.message}`);
  }
};

const useJournalistListsStories = (uid) => {
  const auth = useAuth();
  const { data, error, isFetching, refetch } = useQuery(
    ['journalistListsStories', uid],
    () => fetchJournalistListsStories({ uid, auth }),
    {
      enabled: !!uid,
      initialData: [],
    }
  );

  return {
    getJournalistsStories: refetch,
    journalistsStories: data,
    isLoadingJournalistsStories: isFetching,
    journalistListsStoriesError: error,
  };
};

export default useJournalistListsStories;
