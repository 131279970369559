import React from 'react';

import { useParams } from 'react-router-dom';

import { tabNames } from 'constants/settingsTabs';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import BillingSettingsDangerZone from './BillingSettingsDangerZone/BillingSettingsDangerZone';
import BillingSettingsInvoices from './BillingSettingsInvoices/BillingSettingsInvoices';
import BillingSettingsPayment from './BillingSettingsPayment/BillingSettingsPayment';
import BillingSettingsSubscription from './BillingSettingsSubscription/BillingSettingsSubscription';
import BillingSettingsSuccessFees from './BillingSettingsSuccessFees/BillingSettingsSuccessFees';
import useCompanySubscription from './hooks/useCompanySubscription';

const BillingSettings = () => {
  const { companyData } = useCompanyData();
  const { data: subscriptionData } = useCompanySubscription(companyData?.uid);
  const { tab } = useParams();

  if (tab !== tabNames.BILLING) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6">
      {subscriptionData?.subscription && (
        <div className="flex flex-col gap-6 lg:flex-row">
          <BillingSettingsSubscription subscriptionData={subscriptionData} />
          <BillingSettingsPayment />
        </div>
      )}
      <BillingSettingsInvoices />
      <BillingSettingsSuccessFees />
      {subscriptionData?.subscription &&
        !subscriptionData?.subscription?.cancel_at_period_end && (
          <BillingSettingsDangerZone subscriptionData={subscriptionData} />
        )}
    </div>
  );
};

export default BillingSettings;
