import React, { useState } from 'react';

import Button from 'components/buttons/Button';

import AgencyDashboardPublicationsEditModal from './AgencyDashboardPublicationsEditModal';

const AgencyDashboardPublicationsEditModalButton = () => {
  const [isAddPublicationModalOpen, setIsAddPublicationModalOpen] =
    useState(false);

  return (
    <>
      <Button
        onClick={() => setIsAddPublicationModalOpen(true)}
        className="w-fit self-end"
      >
        Add Publication
      </Button>
      {isAddPublicationModalOpen ? (
        <AgencyDashboardPublicationsEditModal
          onClose={() => setIsAddPublicationModalOpen(false)}
        />
      ) : null}
    </>
  );
};

export default AgencyDashboardPublicationsEditModalButton;
