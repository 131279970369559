import PropTypes from 'prop-types';
import React, { useState } from 'react';

import JournalistDetailsModal from 'components/JournalistDetailsModal/JournalistDetailsModal';
import JournalistTeaser from 'components/JournalistTeaser';
import useJournalistsByIds from 'hooks/useJournalistsByIds';

import JournalistPlaceholder from './JournalistPlaceholder';

const JournalistsConversationItem = ({ toolParams }) => {
  const [selectedJournalist, setSelectedJournalist] = useState(null);
  const { ids } = toolParams;
  const { data, isLoading } = useJournalistsByIds(ids);
  const journalists = data?.items || [];

  if (isLoading) {
    return (
      <>
        {(ids || []).map((id) => (
          <div className="my-1 mx-5 bg-white rounded p-3 min-h-[70px]" key={id}>
            <JournalistPlaceholder />
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      {(journalists || []).map((journalist) => (
        <div
          className="my-1 mr-5 bg-white rounded p-3 hover:bg-gray-50 cursor-pointer"
          key={journalist.name}
          onClick={() => setSelectedJournalist(journalist)}
        >
          <JournalistTeaser journalist={journalist} />
        </div>
      ))}
      {selectedJournalist && (
        <JournalistDetailsModal
          journalist={selectedJournalist}
          onClose={() => setSelectedJournalist(null)}
        />
      )}
    </>
  );
};

JournalistsConversationItem.propTypes = {
  toolParams: PropTypes.object,
};

export default JournalistsConversationItem;
