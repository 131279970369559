import PropTypes from 'prop-types';
import React from 'react';

import Tag from 'components/tags/Tag';
import getTagColors from 'utils/color/getTagColors';

const StoryTags = ({ topics = [] }) => (
  <>
    {topics.map((topic, index) => {
      const [bgColor, textColor] = getTagColors(index, topics?.length);
      return (
        <Tag
          key={`topic-${topic}`}
          bgColor={bgColor}
          textColor={textColor}
          className="mt-0 mb-2"
        >
          {topic}
        </Tag>
      );
    })}
  </>
);

StoryTags.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.string),
};

export default StoryTags;
