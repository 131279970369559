import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import {
  funnelBackgroundColors,
  funnelNames,
  funnelStatus,
  funnelTextColors,
} from 'constants/announcementFunnelStatus';

const funnelOrder = [
  '',
  funnelStatus.DRAFT,
  funnelStatus.PITCHED,
  funnelStatus.OPENED,
  funnelStatus.PRESS_PAGE_VISIT,
  funnelStatus.RESPONDED,
  funnelStatus.COVERED,
];

const AnnouncementJournalistListFunnelItem = ({
  order = 0,
  zIndex = 0,
  first = false,
  last = false,
  journalists = [],
  status = funnelStatus.DRAFT,
}) => {
  const count = journalists.filter((j) => j.thread?.status === status).length;
  return (
    <>
      <div
        className={classNames('relative flex items-center h-8', {
          'rounded-l-full': first,
          'rounded-r-full': last,
        })}
        style={{ zIndex }}
      >
        <div
          className={classNames(
            'z-10 text-sm h-full flex items-center justify-center',
            {
              'pl-5 -ml-6': !first,
              'pl-4 rounded-l-full': first,
              'pr-4 rounded-r-full': last,
            }
          )}
          style={{
            backgroundColor: funnelBackgroundColors[status],
            color: funnelTextColors[status],
          }}
        >
          <span className="-mr-1">{count}</span>
        </div>
        {!last ? (
          <div
            style={{
              backgroundColor: funnelBackgroundColors[status],
            }}
            className="-translate-x-[50%] h-6 w-6 rotate-45"
          />
        ) : null}
      </div>
      <div
        className="z-10 absolute w-1/6 h-full"
        style={{ left: `${((order * 1) / funnelOrder.length) * 100}%` }}
        data-tooltip-id="funnel-tooltip"
        data-tooltip-content={funnelNames[status]}
      />
    </>
  );
};

AnnouncementJournalistListFunnelItem.propTypes = {
  order: PropTypes.number,
  zIndex: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool,
  journalists: PropTypes.array,
  status: PropTypes.string,
};

const AnnouncementJournalistListFunnel = ({ journalists = [] }) => (
  <div className="flex relative overflow-hidden min-w-max">
    {funnelOrder.map((status, index) => (
      <AnnouncementJournalistListFunnelItem
        key={status}
        first={index === 0}
        last={index === funnelOrder.length - 1}
        zIndex={funnelOrder.length - index}
        order={index}
        journalists={journalists}
        status={status}
      />
    ))}
  </div>
);

AnnouncementJournalistListFunnel.propTypes = {
  journalists: PropTypes.array,
};

export default AnnouncementJournalistListFunnel;
