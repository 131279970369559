import PropTypes from 'prop-types';
import React from 'react';

const HtmlContainer = ({
  htmlString = '',
  testId = '',
  className = 'prose max-w-none prose-gray prose-p:my-4 prose-li:my-0 prose-a:text-teal-500 prose-pre:bg-gray-200 prose-pre:text-gray-800 prose-table:bg-inherit prose-table:m-0',
}) => (
  <div
    data-test-id={testId}
    className={className}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: htmlString }}
  />
);

HtmlContainer.propTypes = {
  htmlString: PropTypes.string,
  testId: PropTypes.string,
  className: PropTypes.string,
};

export default HtmlContainer;
