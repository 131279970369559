import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { noop } from 'lodash';
import { Controller, useWatch } from 'react-hook-form';

import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldToggle from 'components/form/inputFields/InputFieldToggle';
import useDateTimeSelector from 'hooks/useDateTimeSelector';
import { todaysDateObj } from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

const TasksAddEditModalDeadline = ({ control = {}, setValue = noop }) => {
  const isDeadlineEnabled = useWatch({
    control,
    name: 'isDeadlineEnabled',
  });
  const deadline = useWatch({
    control,
    name: 'deadline',
  });
  const deadlineObject = useWatch({
    control,
    name: 'deadlineObject',
  });

  const validOptionRanges = useDateTimeSelector({
    config: {
      type: 'future',
    },
    selection: {
      selectedHour: deadlineObject?.hour,
      selectedDay: deadlineObject?.day,
      selectedMonth: deadlineObject?.month,
      selectedYear: deadlineObject?.year,
    },
    updateSelections: ({ day, month, year, hour }) => {
      setValue('deadlineObject.day', hour);
      setValue('deadlineObject.day', day);
      setValue('deadlineObject.month', month);
      setValue('deadlineObject.year', year);
    },
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between gap-2">
        <Label htmlFor="name">Deadline</Label>
        <InputFieldToggle
          value={isDeadlineEnabled}
          onChange={(enabled) => {
            setValue('isDeadlineEnabled', enabled);
            if (enabled && deadline) {
              setValue('deadlineObject', getDateObjFromTimestamp(deadline));
            } else if (enabled && !deadline) {
              setValue('deadlineObject', todaysDateObj);
            } else {
              setValue('deadlineObject', null);
            }
          }}
          text="Enable"
        />
      </div>
      <div
        className={classNames('flex w-full gap-2', {
          'pointer-events-none, opacity-20': !isDeadlineEnabled,
        })}
      >
        <Controller
          name="deadlineObject.day"
          control={control}
          defaultValue={todaysDateObj.day}
          render={({ field: { onChange, value } }) => (
            <InputFieldDropdown
              options={validOptionRanges.days}
              onChange={onChange}
              value={value}
              buttonClassName="w-24"
            />
          )}
        />
        <Controller
          name="deadlineObject.month"
          control={control}
          defaultValue={todaysDateObj.month}
          render={({ field: { onChange, value } }) => (
            <InputFieldDropdown
              options={validOptionRanges.months}
              onChange={onChange}
              value={value}
              buttonClassName="w-36"
            />
          )}
        />
        <Controller
          name="deadlineObject.year"
          control={control}
          defaultValue={todaysDateObj.year}
          render={({ field: { onChange, value } }) => (
            <InputFieldDropdown
              options={validOptionRanges.years}
              onChange={onChange}
              value={value}
              buttonClassName="w-28"
            />
          )}
        />
        <Controller
          name="deadlineObject.hour"
          control={control}
          defaultValue={todaysDateObj.hour}
          render={({ field: { onChange, value } }) => (
            <InputFieldDropdown
              options={validOptionRanges.hours}
              onChange={onChange}
              value={value}
              buttonClassName="w-[calc(5ch+4rem)]"
            />
          )}
        />
      </div>
    </div>
  );
};

TasksAddEditModalDeadline.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func,
};

export default TasksAddEditModalDeadline;
