import PropTypes from 'prop-types';
import React, { useState, useEffect, cloneElement } from 'react';

import { PaperClipIcon, PlusIcon } from '@heroicons/react/20/solid';
import get from 'lodash/get';
import { Controller } from 'react-hook-form';

import ErrorMessage from 'components/form/ErrorMessage';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';
import useCompoundComponents from 'hooks/useCompoundComponents';
import useCurrentResourceContext from 'routes/ContentManagement/hooks/useCurrentResourceContext';

import inputFieldUploadCompoundComponents from './inputFieldUploadCompoundComponents';

/**
 * @typedef InputFieldUploadResourceProps
 * @property {keyof uploadPresets} presetName
 *
 * @param {InputFieldUploadResourceProps} props
 *
 * @returns
 */
const InputFieldUploadResource = ({
  id = '',
  name = '',
  form = {},
  children = null,
  testId = '',
  overridePreview = false,
  showError = true,
  rules = {},
}) => {
  const { companyData } = useCompanyData();
  const { areCustomResourcesLoading } = useCurrentResourceContext();
  const { Label } = useCompoundComponents(
    children,
    inputFieldUploadCompoundComponents
  );

  const {
    watch,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = form;
  const formFile = watch(name);
  const error = get(errors, name, false);
  const inputId = id || name;

  const { fetchData: getUploadUrl } = useAPIRequest({
    service: 'MOD',
    endpoint: `/resources/${companyData.uid}/resource/uploadUrl`,
    method: 'POST',
  });

  const uploadFile = async (file) => {
    const {
      url,
      file: attachment,
      headers,
    } = await getUploadUrl({
      body: {
        name: file.name,
        type: file.type,
      },
    });

    await fetch(url, {
      method: 'PUT',
      body: file,
      headers,
    });

    return attachment;
  };

  const [localFile, setLocalFile] = useState(formFile);
  const file = localFile || formFile;

  useEffect(() => {
    if (overridePreview) {
      setValue(name, overridePreview, { shouldValidate: true });
      setLocalFile(overridePreview);
    }
  }, [overridePreview, name, setValue, setLocalFile]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={formFile || null}
      rules={rules}
      render={() => (
        <div className="w-full flex flex-col gap-2">
          {Label ? cloneElement(Label, { htmlFor: inputId }) : null}

          <div>
            <label
              htmlFor="content-resources-addAttachment"
              className="relative py-2 px-3 w-fit flex items-center truncate rounded-md border border-gray-200 bg-white cursor-pointer"
            >
              <input
                id="content-resources-addAttachment"
                type="file"
                multiple="multiple"
                accept="application/pdf"
                onChange={async (e) => {
                  const fileBody = e.target.files[0];
                  const attachment = await uploadFile(fileBody);
                  setValue(name, { attachment, fileBody });
                  clearErrors(name);
                  e.target.value = '';
                }}
                className="hidden"
              />
              <div className="mr-1 text-teal-500 ">
                <PlusIcon className="w-6 h-6 -my-1" />
              </div>
              <div className="w-fit truncate text-sm">
                <span className="font-medium text-gray-500">
                  {!file ? 'Add file...' : 'Change file...'}
                </span>
              </div>
            </label>
          </div>

          {!areCustomResourcesLoading && file ? (
            <div className="relative py-2 px-3 w-fit flex items-center truncate rounded-md border border-gray-200 bg-white cursor-pointer">
              <div className="mr-1 text-teal-500">
                <PaperClipIcon className="w-6 h-6 -my-1" />
              </div>
              <div className="w-fit truncate text-sm">
                <span className="font-medium text-gray-500">
                  {file?.attachment.fileName}
                </span>
              </div>
            </div>
          ) : null}

          {error && showError ? (
            <ErrorMessage testId={`${testId}-error`}>
              {error.message}
            </ErrorMessage>
          ) : null}
        </div>
      )}
    />
  );
};

InputFieldUploadResource.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  form: PropTypes.object,
  children: PropTypes.node,
  testId: PropTypes.string,
  overridePreview: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  rules: PropTypes.object,
  showError: PropTypes.bool,
};

export default InputFieldUploadResource;
