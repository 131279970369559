import React from 'react';

import Card from 'components/Card';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import useCompanyBillingInfo from '../hooks/useCompanyBillingInfo';

import BillingSettingsPaymentBillingInformation from './BillingSettingsPaymentBillingInformation';
import BillingSettingsPaymentDetails from './BillingSettingsPaymentDetails';

const BillingSettingsPayment = () => {
  const { companyData } = useCompanyData();

  const { data: billingInfo } = useCompanyBillingInfo(companyData?.uid);

  if (!billingInfo) {
    return null;
  }

  return (
    <Card title="Payment" className="lg:min-w-[40%] xl:min-w-[30%]">
      <div className="w-full max-w-5xl">
        <dl className="flex flex-col sm:flex-row lg:flex-col gap-8">
          <div className="grow flex flex-col">
            <dt className="text-sm font-medium text-gray-500">
              Your payment details
            </dt>
            <dd className="mt-1 text-sm text-gray-900 h-full">
              <BillingSettingsPaymentDetails {...billingInfo.card} />
            </dd>
          </div>
          <div className="grow">
            <dt className="text-sm font-medium text-gray-500">
              Billing information
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <BillingSettingsPaymentBillingInformation
                {...billingInfo.address}
                name={billingInfo.name}
                email={billingInfo.email}
              />
            </dd>
          </div>
        </dl>
      </div>
    </Card>
  );
};

export default BillingSettingsPayment;
