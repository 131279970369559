import React, { useEffect, useState } from 'react';

import { noop } from 'lodash';
import { focusManager } from 'react-query';
import { useParams } from 'react-router-dom';

import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';
import { useTracking } from 'modules/segment/TrackingProvider';

import { useResponseFormEditorContext } from './InboxMessageResponseFormEditorProvider';

const InboxMessageResponseFormEditorResponse = () => {
  const [inFrame, setInFrame] = useState(false);
  const { draft, saveDraft } = useResponseFormEditorContext();
  const { threadId } = useParams();
  const trackingService = useTracking();

  useEffect(() => {
    if (inFrame) {
      focusManager.setEventListener(noop);
    } else {
      focusManager.setEventListener((handleFocus) => {
        // Listen to visibilitychange and focus
        if (typeof window !== 'undefined' && window.addEventListener) {
          window.addEventListener('visibilitychange', handleFocus, false);
          window.addEventListener('focus', handleFocus, false);
        }

        return () => {
          // Be sure to unsubscribe if a new handler is set
          window.removeEventListener('visibilitychange', handleFocus);
          window.removeEventListener('focus', handleFocus);
        };
      });
    }
  }, [inFrame]);

  return (
    <InputFieldTextEditor
      value={draft.html}
      onChange={(html, text) => {
        trackingService.track('inbox_interaction', {
          action: 'response_type',
          inbox_threadId: threadId,
          inbox_numberOfCharacters: text.length,
        });
        saveDraft({ html, text });
      }}
      onFocus={() => {
        setInFrame(true);
      }}
      onBlur={() => {
        setInFrame(false);
      }}
      testId="inboxMessage-responseFormTextInput"
      id="inboxMessage-responseFormTextInput"
      aiContext={{
        threadId,
      }}
    />
  );
};

export default InboxMessageResponseFormEditorResponse;
