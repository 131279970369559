import { generatePath, redirect } from 'react-router-dom';

import { PLANNING_PATH } from 'constants/paths';
import { tabNames, tabs } from 'constants/planningTabs';

import PlanningPage from './PlanningPage';

const PlanningRoute = {
  path: PLANNING_PATH,
  authRequired: true,
  component: PlanningPage,
  loader: ({ params }) => {
    const { id, tab } = params;

    if (!tab || !tabs.find((t) => t.field === tab)) {
      return redirect(
        generatePath(PLANNING_PATH, {
          id,
          tab: tabNames.TASKS,
        })
      );
    }

    return null;
  },
};

export default PlanningRoute;
