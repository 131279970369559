import React, { Suspense, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import ContentManagementNavbar from '../ContentManagementNavbar';
import ContentManagementResourcesTemplateContent from '../ContentManagementResources/ContentManagementResourcesTemplateContent';
import useCurrentResourceContext from '../hooks/useCurrentResourceContext';

import ContentManagementResourcesList from './ContentManagementQnAList';

const ContentManagementQnATab = () => {
  const { companyData } = useCompanyData();
  const { documentId: selectedResourceTemplate, id } = useParams();
  const { resources: resourcesList, getResources } =
    useCurrentResourceContext();

  useEffect(() => {
    getResources();
  }, [getResources]);

  return (
    <>
      {!selectedResourceTemplate && <ContentManagementNavbar />}
      <div>
        {!selectedResourceTemplate ? (
          <aside>
            <Suspense>
              <ContentManagementResourcesList
                templates={resourcesList}
                slug={id}
              />
            </Suspense>
          </aside>
        ) : null}

        {selectedResourceTemplate ? (
          <section className="flex-auto min-w-0">
            <Suspense>
              <div className="rounded-t-lg">
                <ContentManagementResourcesTemplateContent
                  companyData={companyData}
                />
              </div>
            </Suspense>
          </section>
        ) : null}
      </div>
    </>
  );
};

export default ContentManagementQnATab;
