import PropTypes from 'prop-types';
import React from 'react';

import { Menu } from '@headlessui/react';
import { ArrowUpIcon, CheckIcon, BoltIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

import getDomainAuthorityClassNames from 'utils/color/getDomainAuthorityClassNames';

// Opportunities filter option displaying the filter option (tier) alongside a checkmark if it's selected
const OpportunitiesFilterOption = ({
  option = {},
  isSelected = false,
  onClick = noop,
}) => (
  <Menu.Item
    key={option.index}
    onClick={onClick}
    data-test-id={`opportunities-filterBy-${option.label}`}
  >
    <div className="flex items-center space-x-2 px-2 py-2 text-sm text-gray-900 focus:outline-none hover:bg-gray-50 cursor-pointer mr-6">
      <div className="flex items-center space-x-2">
        <div className="w-4">{isSelected && <CheckIcon className="w-4" />}</div>

        <span className="whitespace-nowrap w-10">{option.label}</span>
        <div
          className={`inline-flex items-center px-2 py-1 space-x-1 rounded-lg ml-3 text-xs ${getDomainAuthorityClassNames(
            option.min
          )}`}
        >
          <BoltIcon className="w-3 h-3" />
          <span>{option.min}</span>
          <ArrowUpIcon className="w-3" />
        </div>
      </div>
    </div>
  </Menu.Item>
);

OpportunitiesFilterOption.propTypes = {
  option: PropTypes.shape({
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default OpportunitiesFilterOption;
