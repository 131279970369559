export const tabNames = {
  DOCUMENTS: 'documents',
  RESOURCES: 'resources',
  QnA: 'qna',
};

export const tabs = [
  { title: 'Documents', field: tabNames.DOCUMENTS },
  { title: 'Resources', field: tabNames.RESOURCES },
  { title: 'Q&A', field: tabNames.QnA },
];

export default tabs;
