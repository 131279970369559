import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import noop from 'lodash/noop';
import { useNavigate, useParams, generatePath } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useImpersonation from 'contexts/ImpersonationContext/hooks/useImpersonation';
import { useTracking } from 'modules/segment/TrackingProvider';

import useResourceCRUD from './useResourceCRUD';

const defaultContext = {
  currentResource: null,
  resources: [],
  customResources: [],
  areResourcesLoading: false,
  getResources: noop,
  getResource: noop,
  updateResource: noop,
  createResource: noop,
};

export const CurrentResourceContext = createContext(defaultContext);

const { Provider } = CurrentResourceContext;

const CurrentResourceProvider = ({ children }) => {
  const { documentId: selectedResourceId } = useParams();
  const { companyData } = useCompanyData();

  const [resources, setResources] = useState([]);
  const [customResources, setCustomResources] = useState([]);
  const [resource, setResource] = useState(null);
  const { impersonatedCompanySlug } = useImpersonation();

  const trackingService = useTracking();
  const navigate = useNavigate();

  const { showError, showSuccess } = useNotifications();
  const [isCreatingResource, setIsCreatingResource] = useState(false);

  const {
    getResourceApi,

    getResourcesApi,
    isGetResourcesApiError,
    isGetResourcesApiLoading,

    getCustomResourcesApi,
    isGetCustomResourcesApiError,
    isGetCustomResourcesApiLoading,

    updateResourceApi,

    removeCustomResourceApi,

    addCustomResourceApi,
    isAddCustomResourceApiLoading,

    submitResourceApi,
  } = useResourceCRUD();

  useEffect(() => {
    if (selectedResourceId && companyData.uid) {
      const prepare = async () => {
        try {
          const response = await getResourceApi({
            resourceId: selectedResourceId,
            companyUid: companyData.uid,
          });

          setResource(response);
        } catch (error) {
          showError({
            title: "We're sorry! Something went wrong.",
            message: `The document ${selectedResourceId} couldn't be loaded.`,
          });

          navigate(
            generatePath(CONTENT_MANAGEMENT_PATH, {
              id: companyData.slug,
              tab: 'resources',
            })
          );
        }
      };

      prepare();
    } else {
      setResource(null);
    }
  }, [
    companyData.slug,
    companyData.uid,
    getResourceApi,
    impersonatedCompanySlug,
    navigate,
    selectedResourceId,
    showError,
  ]);

  const getResources = useCallback(async () => {
    if (!selectedResourceId) {
      setResource(null);
    }

    const resourcesResponse = await getResourcesApi({
      companyUid: companyData.uid,
    });

    setResources(resourcesResponse);
  }, [selectedResourceId, getResourcesApi, companyData.uid]);

  const getCustomResources = useCallback(async () => {
    const customResourcesResponse = await getCustomResourcesApi({
      companyUid: companyData.uid,
    });

    setCustomResources(customResourcesResponse);
  }, [companyData.uid, getCustomResourcesApi]);

  const updateResource = useCallback(
    async (body) => {
      const response = await updateResourceApi({
        companyUid: companyData.uid,
        resourceId: selectedResourceId,
        body,
      });

      setResource(response);
    },
    [companyData.uid, selectedResourceId, updateResourceApi]
  );

  const removeCustomResource = useCallback(
    async (body) => {
      const response = await removeCustomResourceApi({
        companyUid: companyData.uid,
        body,
      });

      setCustomResources(response);
    },
    [companyData.uid, removeCustomResourceApi]
  );

  const addCustomResource = useCallback(
    async (body) => {
      const response = await addCustomResourceApi({
        companyUid: companyData.uid,
        body,
      });

      setCustomResources(response);
    },
    [addCustomResourceApi, companyData.uid]
  );

  const submitResource = useCallback(
    async (body) => {
      try {
        const response = await submitResourceApi({
          companyUid: companyData.uid,
          resourceId: selectedResourceId,
          body,
        });

        trackingService.track('Resource Submitted Successfully', {
          resourceId: response.id,
          type: response.type || 'blank',
          companyId: companyData.uid,
        });
        showSuccess({ message: 'Resource submitted successfully.' });
        setResource(response);

        navigate(
          generatePath(CONTENT_MANAGEMENT_PATH, {
            id: companyData.slug ?? impersonatedCompanySlug,
            tab: 'qna',
            documentId: response.id,
          })
        );
      } catch (error) {
        showError({
          title: "We're sorry! Something went wrong.",
          message: "The document couldn't be created.",
        });
      } finally {
        setIsCreatingResource(false);
      }
    },
    [
      submitResourceApi,
      companyData.uid,
      companyData.slug,
      trackingService,
      showSuccess,
      navigate,
      impersonatedCompanySlug,
      selectedResourceId,
      showError,
    ]
  );

  const providerValue = useMemo(
    () => ({
      currentResource: resource,

      resources,
      areResourcesLoading: isGetResourcesApiLoading,
      fetchResourcesError: isGetResourcesApiError,

      customResources,
      areCustomResourcesLoading: isGetCustomResourcesApiLoading,
      fetchCustomResourcesError: isGetCustomResourcesApiError,

      isAddCustomResourceApiLoading,

      isCreateResourceLoading: isCreatingResource,

      getResources,
      getCustomResources,
      updateResource,
      removeCustomResource,
      addCustomResource,
      submitResource,
    }),
    [
      resource,
      resources,
      isGetResourcesApiLoading,
      isGetResourcesApiError,
      customResources,
      isGetCustomResourcesApiLoading,
      isGetCustomResourcesApiError,
      isAddCustomResourceApiLoading,
      isCreatingResource,
      getResources,
      getCustomResources,
      updateResource,
      removeCustomResource,
      addCustomResource,
      submitResource,
    ]
  );

  return <Provider value={providerValue}>{children}</Provider>;
};

CurrentResourceProvider.propTypes = {
  children: PropTypes.node,
};

export default CurrentResourceProvider;
