import React, { useCallback, useEffect } from 'react';

import noop from 'lodash/noop';
import { useNavigate } from 'react-router-dom';

import EmptyState from 'components/EmptyState';
import PageWrapper from 'components/PageWrapper';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import { NYLAS_CALLBACK_PAGE_PATH } from 'constants/paths';
import useCreateCompanyEmailGrant from 'hooks/useCreateCompanyEmailGrant';
import useQuery from 'hooks/useQuery';

const NylasCallbackPage = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const [companyId, companySlug] = (query.get('state') || '').split(',');
  const code = query.get('code');
  const errorDescription = query.get('error_description');

  const { mutateAsync, isLoading } = useCreateCompanyEmailGrant();

  const goToSettings = () => navigate(`/settings/${companySlug}/company`);

  const createGrant = useCallback(
    async (data) => {
      await mutateAsync(data);
      navigate(`/settings/${companySlug}/company`);
    },
    [mutateAsync, companySlug, navigate]
  );

  useEffect(() => {
    if (code && companyId && !isLoading) {
      const redirectUri = `${window.location.origin}${NYLAS_CALLBACK_PAGE_PATH}`;
      createGrant({ code, companyId, redirectUri });
    }

    return noop;
  }, [code, companyId, companySlug, createGrant, isLoading]);

  return (
    <PageWrapper>
      {errorDescription && (
        <EmptyState
          helmetTitle="Error"
          title="An error occured"
          subtitle={(errorDescription || '').replace(/\+/g, ' ')}
          instructions={<Button onClick={goToSettings}>Try again</Button>}
        />
      )}

      {!errorDescription && <Spinner />}
    </PageWrapper>
  );
};

export default NylasCallbackPage;
