import React from 'react';

import { generatePath } from 'react-router-dom';

import { OPPORTUNITIES_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useUserRoles from 'contexts/UserContext/hooks/useUserRoles';

import NavbarDesktop from './NavbarDesktop/NavbarDesktop';
import NavbarMobile from './NavbarMobile/NavbarMobile';

const Navbar = () => {
  const { claimsJournalist } = useUserData();
  const { claimsAdmin, claimsAgencyId } = useUserRoles();
  const { companyData, hasBasicCompanyAccess, ownCompanySlug } =
    useCompanyData();
  const slug = companyData?.slug || ownCompanySlug;

  if (!hasBasicCompanyAccess || !slug) {
    return null;
  }
  const homeRoute = generatePath(OPPORTUNITIES_PATH, {
    id: slug,
  });
  const isPrivilegedUser = hasBasicCompanyAccess || claimsJournalist;

  return (
    <div data-test-id="navbar">
      <NavbarMobile
        companySlug={slug}
        claimsAdmin={claimsAdmin}
        claimsAgencyId={!!claimsAgencyId}
      />
      <NavbarDesktop
        homeRoute={isPrivilegedUser && homeRoute}
        companySlug={slug}
        claimsAdmin={claimsAdmin}
        claimsAgencyId={!!claimsAgencyId}
      />
    </div>
  );
};

export default Navbar;
