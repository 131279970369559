import PropTypes from 'prop-types';
import React from 'react';

import mean from 'lodash/mean';

import useFeatureToggles from 'hooks/useFeatureToggles';

import ReportsCard from '../ReportsCard';
import ReportsTitle from '../ReportsTitle';

import AddCoverageButton from './AddCoverageButton';
import ReportsCoverageStories from './ReportsCoverageStories';

const ReportsCoverage = ({
  title = 'Media Coverage Report',
  coverages = [],
  companyData = {},
}) => {
  const featureToggles = useFeatureToggles();
  const domainAuthorities = coverages
    .filter((coverage) => !!coverage.journalist?.publication?.domainAuthority)
    .map((coverage) =>
      Number(coverage.journalist?.publication?.domainAuthority)
    );
  const averageDomainAuthority = mean(domainAuthorities);

  return (
    <div className="w-full bg-white p-6 shadow ring-1 ring-gray-900/5 rounded-lg">
      <div className="flex justify-between gap-2">
        <ReportsTitle title={title} />
        {featureToggles.coverages ? <AddCoverageButton /> : null}
      </div>

      <div className="flex flex-col md:flex-row md:space-x-6">
        <div className="mt-4 border-b pb-4 mb-4 md:mb-0 md:border-b-0 md:pb-0 md:w-1/4 md:pr-4 md:border-r">
          <div className="-mt-4">
            <ReportsCard
              title="Total coverage"
              subTitle={`This is the total amount of times ${companyData.name} has been covered in the media`}
              value={coverages.length ?? 0}
            />

            <ReportsCard
              title="Average Domain Authority"
              subTitle="Based on the Domain Authority ranking provided by moz.com"
              value={(averageDomainAuthority || 0).toFixed(1)}
            />
          </div>
        </div>

        <ReportsCoverageStories lastCoverages={coverages} />
      </div>
    </div>
  );
};

ReportsCoverage.propTypes = {
  title: PropTypes.string,
  coverages: PropTypes.array,
  companyData: PropTypes.object.isRequired,
};

export default ReportsCoverage;
