import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const fetchInviteByToken = async (token, auth) => {
  const BASE_URL = process.env.REACT_APP_UCD_URL;
  const endpoint = `/invites/${token}`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch agency journalists: ${error.message}`);
  }
};

const useInviteByToken = (token) => {
  const auth = useAuth();
  const { data: invite, isFetching } = useQuery(
    ['invite', token],
    () => fetchInviteByToken(token, auth),
    {
      enabled: !!token,
      initialData: {},
    }
  );

  return { loading: isFetching, invite };
};

export default useInviteByToken;
