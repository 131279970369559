import { useCallback } from 'react';

import { useParams } from 'react-router-dom';

import useAPIRequest from 'hooks/useAPIRequest';

const useSaveDraft = () => {
  const { threadId } = useParams();
  const { fetchData } = useAPIRequest({
    endpoint: `/threads/${threadId}/draft`,
    method: 'PUT',
    service: 'MOD',
  });

  return useCallback(
    async (data = {}) => {
      await fetchData({
        body: data,
      });
    },
    [fetchData]
  );
};

export default useSaveDraft;
