import PropTypes from 'prop-types';
import React from 'react';

import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Stepper from 'components/Stepper';
import CopyToClipboardButton from 'components/buttons/CopyToClipboardButton';
import Founder from 'components/company/Founder';
import useWithAnalytics from 'hooks/useWithAnalytics';
import getCurrentObjectAndIndex from 'utils/getCurrentObjectAndIndex';

import CompanyQuoteTags from './CompanyQuote/CompanyQuoteTags';

const CompanyQuoteViewModal = ({
  open = false,
  quotes = [],
  founders = [],
  currentQuoteId = null,
  setCurrentQuoteId = noop,
  onClose = noop,
}) => {
  const [quote, currentIndex] = getCurrentObjectAndIndex(
    quotes,
    currentQuoteId
  );
  const onPrevious = useWithAnalytics({
    eventType: 'pressPage_view',
    eventProperties: {
      action: 'quote_view',
      quote_title: quotes[currentIndex - 1]?.title,
      quote_body: quotes[currentIndex - 1]?.body,
    },
  })(() => setCurrentQuoteId(quotes[currentIndex - 1]?.uid));

  const onNext = useWithAnalytics({
    eventType: 'pressPage_view',
    eventProperties: {
      action: 'quote_view',
      quote_title: quotes[currentIndex + 1]?.title,
      quote_body: quotes[currentIndex + 1]?.body,
    },
  })(() => setCurrentQuoteId(quotes[currentIndex + 1]?.uid));

  if (!quote) {
    return null;
  }

  return (
    <Modal open={open}>
      <Modal.Close
        onClose={onClose}
        srMessage="Close quote view modal"
        testId="company-quote-viewModal-close"
      />
      <Modal.Icon Icon={ChatBubbleBottomCenterTextIcon} />

      <Modal.Content>
        <div className="mt-3 sm:mt-5">
          <CompanyQuoteTags
            tags={quote.tags}
            testId={`company-quote-${quote.title}-tags`}
          />
        </div>

        <h3
          className="text-xl font-medium leading-normal line-clamp-2"
          data-test-id={`company-quote-${quote.title}-title`}
        >
          {quote.title}
        </h3>

        <p
          className="flex-1 mt-2 text-sm text-gray-400 leading-normal"
          data-test-id={`company-quote-${quote.title}-body`}
        >
          {quote.body}
        </p>

        <div className="w-full flex flex-row justify-between items-center mt-4">
          <Founder
            founder={founders.find((f) => f.name === quote.founder?.name)}
            widthClassName="w-3/4 flex-shrink"
            testId={`company-quote-${quote.title}-founder`}
          />
          <CopyToClipboardButton
            copyText={quote.body}
            moduleName="quote"
            eventType="pressPage_view"
            eventProperties={{
              action: 'quote_view_copyToClipboard',
              quoteCopied_title: quote?.title,
              quoteCopied_body: quote?.body,
            }}
          />
        </div>
      </Modal.Content>

      <Modal.TopBar>
        <Stepper
          currentStep={currentIndex}
          totalSteps={quotes.length}
          stepLabel="Quote"
          stepsLabel="Quotes"
          testId="company-quote-viewModal"
          onPrevious={onPrevious}
          onNext={onNext}
        />
      </Modal.TopBar>
    </Modal>
  );
};

CompanyQuoteViewModal.propTypes = {
  open: PropTypes.bool,
  quotes: PropTypes.array,
  founders: PropTypes.array,
  currentQuoteId: PropTypes.string,
  setCurrentQuoteId: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanyQuoteViewModal;
