const milestoneTypes = [
  {
    id: 'planning',
    name: 'Planning',
    color: '#FFF9C4',
    bgColor: '#FFF9C4',
    textColor: '#F57F17',
  },
  {
    id: 'key-dates',
    name: 'Key Dates',
    color: '#C8E6C9',
    bgColor: '#C8E6C9',
    textColor: '#388E3C',
  },
  {
    id: 'events',
    name: 'Events',
    color: '#BBDEFB',
    bgColor: '#BBDEFB',
    textColor: '#1976D2',
  },
  {
    id: 'awards',
    name: 'Awards',
    color: '#FFD54F',
    bgColor: '#FFD54F',
    textColor: '#FBC02D',
  },
  {
    id: 'announcements',
    name: 'Announcements',
    color: '#B2DFDB',
    bgColor: '#B2DFDB',
    textColor: '#00796B',
  },
  {
    id: 'opinion-articles',
    name: 'Opinion Articles',
    color: '#F8BBD0',
    bgColor: '#F8BBD0',
    textColor: '#C2185B',
  },
  {
    id: 'interviews',
    name: 'Interviews',
    color: '#E0E0E0',
    bgColor: '#E0E0E0',
    textColor: '#616161',
  },
  {
    id: 'social-media',
    name: 'Social Media',
    color: '#FFE0B2',
    bgColor: '#FFE0B2',
    textColor: '#FF9800',
  },
];

export default milestoneTypes;
