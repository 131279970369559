import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const getTextClasses = ({
  isReadByUser,
  isFromJournalist,
  isSelectedThread,
}) => {
  if (isSelectedThread) {
    if (isFromJournalist && !isReadByUser) {
      return 'text-white font-medium';
    }
    return 'text-white font-light';
  }

  if (isFromJournalist && !isReadByUser) {
    return 'text-gray-700 font-medium';
  }

  return 'text-gray-500 font-light';
};

const getDateFromTimestamp = (timestamp = '', options = {}) => {
  const date = new Date(timestamp);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (today.toDateString() === date.toDateString()) {
    return date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  if (today.getFullYear() === date.getFullYear()) {
    return date.toLocaleDateString('en-GB', options);
  }

  return date.toLocaleDateString('en-GB', { ...options, year: 'numeric' });
};

const InboxMessageHeaderDate = ({
  isFromJournalist = false,
  dateCreated = '',
  isReadByUser = false,
  isSelectedThread = false,
  tooltipId = '',
}) => (
  <div
    className={classNames(
      'ml-auto flex flex-col gap-1 items-end text-sm',
      getTextClasses({ isReadByUser, isFromJournalist, isSelectedThread })
    )}
  >
    <p
      data-test-id="inboxMessage-date"
      data-tooltip-id={tooltipId}
      data-tooltip-content={getDateFromTimestamp(dateCreated, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}
    >
      {getDateFromTimestamp(dateCreated, {
        month: 'short',
        day: 'numeric',
      })}
    </p>
  </div>
);

InboxMessageHeaderDate.propTypes = {
  isFromJournalist: PropTypes.bool,
  dateCreated: PropTypes.string,
  isReadByUser: PropTypes.bool,
  tooltipId: PropTypes.string,
  isSelectedThread: PropTypes.bool,
};

export default InboxMessageHeaderDate;
