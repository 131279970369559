import PropTypes from 'prop-types';
import React from 'react';

import UserAvatar from 'components/UserAvatar';

const CompanyMember = ({ member = {} }) => (
  <div className="flex min-w-0 gap-x-4">
    <UserAvatar
      showUserPhoto={false}
      image={{ path: member.photoUrl }}
      className="w-10 h-10"
    />
    <div className="min-w-0 flex-auto">
      <p className="text-sm font-semibold leading-5 text-gray-900">
        {member.firstName} {member.lastName}
      </p>
      <p className="text-sm leading-4 text-gray-500">{member.email}</p>
    </div>
  </div>
);

CompanyMember.propTypes = {
  member: PropTypes.object,
};

export default CompanyMember;
