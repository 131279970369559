import PropTypes from 'prop-types';
import React from 'react';

import { BoltIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

import getDomainAuthorityClassNames from 'utils/color/getDomainAuthorityClassNames';

// Displays the domain authority number next to a lightning bolt icon, or "n/a" if none exists
const DomainAuthorityPill = ({ domainAuthority = null, className = '' }) => (
  <span
    className={classNames(
      'inline-flex items-center px-2 py-1 rounded-md text-xs min-w-max',
      getDomainAuthorityClassNames(domainAuthority),
      className
    )}
  >
    <BoltIcon className="w-3 h-3 mr-1" />
    {domainAuthority ? `${domainAuthority} / 100` : 'n/a'}
  </span>
);

DomainAuthorityPill.propTypes = {
  domainAuthority: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

export default DomainAuthorityPill;
