import { useCallback } from 'react';

import useAPIRequest from 'hooks/useAPIRequest';

const useDocumentCRUD = () => {
  const {
    fetchData: _getDocumentsApi,
    loading: isGetDocumentsApiLoading,
    error: isGetDocumentsApiError,
  } = useAPIRequest({
    service: 'MOD',
    method: 'GET',
    autoFetch: false,
  });

  const { fetchData: _getDocumentApi, loading: isGetDocumentApiLoading } =
    useAPIRequest({
      service: 'MOD',
      method: 'GET',
      autoFetch: false,
    });

  const { fetchData: _updateDocumentApi, loading: isUpdateDocumentApiLoading } =
    useAPIRequest({
      service: 'MOD',
      method: 'PUT',
    });

  const { fetchData: _createDocumentApi, loading: isCreateDocumentApiLoading } =
    useAPIRequest({
      service: 'MOD',
      method: 'PUT',
    });

  const { fetchData: _updateDocumentThreadDataApi } = useAPIRequest({
    service: 'MOD',
    method: 'POST',
  });

  const { fetchData: _cloneDocumentApi } = useAPIRequest({
    service: 'MOD',
    method: 'POST',
  });

  const { fetchData: _createArticleFromIdea } = useAPIRequest({
    service: 'MOD',
    method: 'POST',
  });

  const getDocumentApi = useCallback(
    ({ companyUid, docId }) =>
      _getDocumentApi({
        endpoint: `/documents/${companyUid}/${docId}`,
        shouldThrow: true,
      }),
    [_getDocumentApi]
  );

  const getDocumentsApi = useCallback(
    ({ companyUid }) =>
      _getDocumentsApi({
        endpoint: `/documents/${companyUid}`,
        shouldThrow: true,
      }),
    [_getDocumentsApi]
  );

  const updateDocumentApi = useCallback(
    ({ companyUid, body }) =>
      _updateDocumentApi({
        endpoint: `/documents/${companyUid}`,
        body,
      }),
    [_updateDocumentApi]
  );

  const createDocumentApi = useCallback(
    ({ companyUid, body }) =>
      _createDocumentApi({
        endpoint: `/documents/${companyUid}`,
        body,
      }),
    [_createDocumentApi]
  );

  const updateDocumentThreadDataApi = useCallback(
    ({ companyUid, body }) =>
      _updateDocumentThreadDataApi({
        endpoint: `/documents/${companyUid}/selectThread`,
        body,
      }),
    [_updateDocumentThreadDataApi]
  );

  const cloneDocumentApi = useCallback(
    ({ companyUid, docId }) =>
      _cloneDocumentApi({
        endpoint: `/documents/${companyUid}/${docId}/clone`,
      }),
    [_cloneDocumentApi]
  );

  const createArticleFromArticleIdeaApi = useCallback(
    ({ companyUid, docId, body }) =>
      _createArticleFromIdea({
        endpoint: `/documents/${companyUid}/${docId}/createArticleFromIdea`,
        body,
      }),
    [_createArticleFromIdea]
  );

  return {
    getDocumentApi,
    isGetDocumentApiLoading,
    isGetDocumentsApiError,

    getDocumentsApi,
    isGetDocumentsApiLoading,

    updateDocumentApi,
    isUpdateDocumentApiLoading,

    createDocumentApi,
    isCreateDocumentApiLoading,

    updateDocumentThreadDataApi,

    cloneDocumentApi,

    createArticleFromArticleIdeaApi,
  };
};

export default useDocumentCRUD;
