import {
  contentManagementTypes,
  resourceStatus,
} from 'constants/contentManagementTypes';

const useDocumentState = ({ contentIdeation, newDocument }) => {
  const isContentIdeationCompleted =
    contentIdeation?.status === resourceStatus.COMPLETED;
  const aiGenerationTypes = new Set([
    contentManagementTypes.ARTICLEIDEA,
    contentManagementTypes.ARTICLE,
  ]);
  const isAIGenerationAvailable = aiGenerationTypes.has(newDocument.type);

  return { isContentIdeationCompleted, isAIGenerationAvailable };
};

export default useDocumentState;
