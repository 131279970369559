import React, { useState } from 'react';

import { UserPlusIcon } from '@heroicons/react/20/solid';
import { useParams, Link, generatePath } from 'react-router-dom';

import EmptyState from 'components/EmptyState';
import Spinner from 'components/Spinner';
import { tabNames } from 'constants/journalistsTabs';
import { SWEETHEART_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

import JournalistsBulkActions from './JournalistsBulkActions';
import JournalistsTable from './JournalistsTable';
import SweetheartListJournalistListsListBulkConversationsModal from './SweetheartListJournalistListsListBulkConversationsModal';
import SweetheartListJournalistListsListHeader from './SweetheartListJournalistListsListHeader';
import SweetheartListJournalistListError from './SweetheartListViewStates/SweetheartListJournalistListError';

const SweetheartListJournalistListsList = () => {
  const [selected, setSelected] = useState([]);
  const [isBulkConversationsModalOpen, setIsBulkConversationsModalOpen] =
    useState(false);
  const { listId, tab } = useParams();
  const { companyData } = useCompanyData();
  const { slug } = companyData;

  const {
    journalistList: { name, journalists = [] },
    isLoadingJournalistList,
    journalistListError,
  } = useSweetheartListContext();

  if (!listId || tab !== tabNames.LISTS) {
    return null;
  }

  return (
    <div className="flex flex-col h-full grow overflow-hidden p-0.5 relative">
      <Link
        to={generatePath(SWEETHEART_PATH, {
          id: slug,
          tab: tabNames.LISTS,
        })}
        className="text-teal-500 text-sm block w-fit mb-2 hover:underline underline-offset-2"
      >
        ← Journalist Lists
      </Link>

      {isLoadingJournalistList ? <Spinner /> : null}

      {!isLoadingJournalistList && journalistListError ? (
        <SweetheartListJournalistListError />
      ) : null}

      <SweetheartListJournalistListsListHeader />

      {journalists.length > 0 && (
        <JournalistsTable
          journalists={journalists}
          isLoading={isLoadingJournalistList}
          hideColumns={['add']}
          selected={selected}
          setSelected={setSelected}
        />
      )}

      {selected.length ? (
        <JournalistsBulkActions
          selected={selected}
          createDrafts={() => setIsBulkConversationsModalOpen(true)}
        />
      ) : null}

      {isBulkConversationsModalOpen && (
        <SweetheartListJournalistListsListBulkConversationsModal
          onCancel={() => setIsBulkConversationsModalOpen(false)}
          selected={selected}
        />
      )}

      {!isLoadingJournalistList &&
        !journalistListError &&
        !journalists.length && (
          <EmptyState
            testId="sweetheartList-ListError"
            helmetTitle="Journalist List - No journalists"
            Icon={UserPlusIcon}
            instructions={
              <p>
                Please go to{' '}
                <Link
                  to={generatePath(SWEETHEART_PATH, {
                    id: slug,
                    tab: tabNames.SEARCH,
                  })}
                  className="text-teal-500 underaline underline-offset-2"
                >
                  Journalist Search
                </Link>{' '}
                to add journalists to this list.
              </p>
            }
            title={`No journalists in "${name}"`}
          />
        )}
    </div>
  );
};

export default SweetheartListJournalistListsList;
