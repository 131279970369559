import { signOut } from 'firebase/auth';
import { useStateMachine } from 'little-state-machine';
import { useAuth } from 'reactfire';

import { defaultUser, defaultCompany } from 'constants/defaultData';
import { LOGIN_PATH } from 'constants/paths';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import { useTracking } from 'modules/segment/TrackingProvider';

// Clear state machine
const clearState = (state = {}) => ({
  ...state,
  user: defaultUser,
  company: defaultCompany,
  onboardingRef: null,
});

// Logs out a user and redirects to the login path
const useHandleLogout = (logoutPageQueryParams = '') => {
  const auth = useAuth();
  const userContext = useUserData();
  const { actions } = useStateMachine({ clearState });
  const trackingService = useTracking();

  return (e) => {
    if (e) {
      e.preventDefault();
    }

    // Clear state
    actions.clearState({});

    // Stop any user context updates from occurring
    if (userContext.setIsAuthenticating) {
      userContext.setIsAuthenticating(true);
    }

    // log event before logging out, so it doesn't get intercepted by the page refresh
    trackingService.track('logout');

    signOut(auth).then(() => {
      const path = logoutPageQueryParams
        ? `${LOGIN_PATH}?${logoutPageQueryParams}`
        : LOGIN_PATH;
      window.location = path;
    });
  };
};

export default useHandleLogout;
