import PropTypes from 'prop-types';
import React from 'react';

import { generatePath } from 'react-router-dom';

import BackButton from 'components/buttons/BackButton';
import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';
import redirectPaths from 'constants/redirectPaths';

import ContentManagementDocumentsResponseForm from './ContentManagementDocumentsResponseForm';

const ContentManagementDocumentContent = ({ companyData = {} }) => {
  const defaultPath = generatePath(CONTENT_MANAGEMENT_PATH, {
    id: companyData.slug,
    tab: 'documents',
  });

  return (
    <div className="space-y-6">
      <p className="text-xs text-gray-500 flex justify-between">
        <BackButton
          companySlug={companyData.slug}
          redirectPaths={redirectPaths}
          defaultPath={defaultPath}
        />
      </p>

      <div className="rounded-md border shadow-sm bg-white flex flex-col min-h-[50px]">
        <ContentManagementDocumentsResponseForm />
      </div>
    </div>
  );
};

ContentManagementDocumentContent.propTypes = {
  companyData: PropTypes.object,
};

export default ContentManagementDocumentContent;
