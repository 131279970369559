import PropTypes from 'prop-types';
import React from 'react';

import { BellAlertIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import AvatarList from 'components/AvatarList/AvatarList';
import Button from 'components/buttons/Button';
import CompanyImage from 'components/company/CompanyImage';
import { ReactComponent as Logo } from 'static/mvpr-logo.svg';

const getReferenceProps = ({ type, reference, externalReference }) => {
  if (reference) {
    return {
      link: reference,
      buttonType: 'secondary',
      buttonText: `Go to ${type.toLowerCase()}`,
    };
  }

  if (externalReference) {
    return {
      link: externalReference,
      buttonType: 'secondary',
      buttonText: 'Go to external link',
    };
  }

  return null;
};

const TasksCard = (
  {
    title,
    description,
    collaborators,
    externalCollaborators,
    type,
    reference,
    externalReference,
    withAgency,
    companyLogo,
    companyName,
    onClick,
    deadline,
  },
  { dragging }
) => {
  const isExpired = deadline
    ? new Date(deadline).getTime() < new Date().getTime()
    : false;
  const linkButtonProps = getReferenceProps({
    type,
    reference,
    externalReference,
  });
  const flattenedCollaborators = collaborators.map((collaborator) => ({
    ...collaborator,
    ...(collaborator?.data || {}),
  }));
  const allCollaborators = [
    ...flattenedCollaborators,
    ...externalCollaborators,
  ];

  return (
    <div
      onClick={onClick}
      className={classNames(
        'mb-2 w-80 flex flex-col gap-2 bg-white p-4 rounded-md shadow-sm hover:shadow-md',
        {
          'shadow-lg -rotate-6 transition-transform': dragging,
        }
      )}
    >
      <div className="mb-1 flex items-end justify-between gap-2">
        <div className="flex gap-2 items-center">
          {isExpired ? (
            <BellAlertIcon className="h-5 w-5 text-red-500" />
          ) : null}
          <h3 className="text-base h-fit text-gray-700 font-semibold">
            {title}
          </h3>
        </div>
        {!withAgency ? (
          <div className="flex items-center justify-center p-1 h-7 w-7 bg-teal-500 rounded-sm shrink-0 self-start">
            <Logo className="text-white" />
          </div>
        ) : (
          <CompanyImage
            image={companyLogo}
            className="h-7 w-7"
            alt={`${companyName} logo`}
          />
        )}
      </div>
      {description && (
        <p className="text-gray-500 text-sm line-clamp-3">{description}</p>
      )}
      {(allCollaborators?.length > 0 || linkButtonProps) && (
        <div className="flex items-center justify-between gap-2">
          {allCollaborators?.length ? (
            <AvatarList users={allCollaborators} />
          ) : null}
          {linkButtonProps && (
            <Button
              className={classNames({
                'ml-auto': !collaborators?.length,
              })}
              type={linkButtonProps.buttonType}
              onClick={(e) => {
                e.stopPropagation();
                window.open(linkButtonProps.link, '_blank');
              }}
            >
              {linkButtonProps.buttonText}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

TasksCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  reference: PropTypes.string,
  externalReference: PropTypes.string,
  collaborators: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      photoUrl: PropTypes.string,
    })
  ),
  externalCollaborators: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  companyLogo: PropTypes.string,
  companyName: PropTypes.string,
  withAgency: PropTypes.bool,
  deadline: PropTypes.string,
};

export default TasksCard;
