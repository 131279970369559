import { ADMIN_COVERAGES_PATH as path } from 'constants/paths';

import AdminCoveragesPage from './AdminCoveragesPage';

const AdminCoveragesRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: AdminCoveragesPage,
};

export default AdminCoveragesRoute;
