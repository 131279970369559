import PropTypes from 'prop-types';
import React from 'react';

import { PlusIcon } from '@heroicons/react/20/solid';

const TasksColumnHeader = ({ title, onClick }) => (
  <>
    <p className="mb-4 font-bold text-gray-700">{title}</p>
    <div className="mb-2 w-80 hover:bg-gray-100 rounded-lg border-dashed border-2">
      <button
        type="button"
        data-test-id="journalistLists-createButton"
        onClick={onClick}
        className="text-gray-500 gap-2 flex w-full h-full focus-visible:outline-teal-500 rounded-md justify-center items-center p-2"
      >
        <PlusIcon className="h-5 w-5" aria-hidden="true" />
        Add task
      </button>
    </div>
  </>
);

TasksColumnHeader.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default TasksColumnHeader;
