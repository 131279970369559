import { useSearchParams } from 'react-router-dom';

import sortOptions from 'constants/agenciesOptions';

const useAgencySearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    sort: sortOptions[1].name,
    sortDir: 1,
    query: '',
  });
  const sortName = searchParams.get('sort');
  const sort =
    sortOptions.find((option) => option.name === sortName) || sortOptions[0];
  const sortDirParsed = Number.parseInt(searchParams.get('sortDir'), 10);
  const sortDir = Number.isNaN(sortDirParsed) ? 1 : sortDirParsed;
  const query = searchParams.get('query');

  return {
    sort,
    sortDir,
    query,
    setSearchParams: (key, value) =>
      setSearchParams((prev) => {
        prev.set(key, value);
        return prev;
      }),
  };
};

export default useAgencySearchParams;
