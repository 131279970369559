import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import { tabNames } from 'constants/journalistsTabs';
import { SWEETHEART_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

const SweetheartListJournalistListsAddEditModal = ({
  onCancel = noop,
  initialValues = {},
  showDelete = false,
}) => {
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useNotifications();
  const { companyData } = useCompanyData();
  const navigate = useNavigate();
  const {
    upsertJournalistList,
    isUpsertingJournalistList,
    isDeletingJournalistList,
    deleteJournalistList,
  } = useSweetheartListContext();

  const { register, formState, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      description: '',
      ...initialValues,
    },
  });
  const { errors } = formState;

  return (
    <Modal open widthClass="max-w-xl lg:max-w-2xl xl:max-w-3xl">
      <Modal.Close
        srMessage="Close Opportunity Response modal"
        testId="opportunity-responseModal-close"
        onClose={onCancel}
      />
      <Modal.Title>
        {initialValues.id ? 'Update' : 'Create a'} Journalist List
      </Modal.Title>
      <Modal.Content>
        <div className="flex flex-col gap-2">
          <InputFieldText
            name="name"
            errors={errors}
            {...register('name', {
              required: 'Please put in a name',
            })}
          >
            <InputFieldText.Label>Name*</InputFieldText.Label>
          </InputFieldText>

          <div>
            <Label>Description*</Label>
            <InputfieldTextArea
              name="description"
              {...register('description', {
                required: 'Please put a description',
              })}
            />
            {errors.description?.message ? (
              <ErrorMessage>{errors.description.message}</ErrorMessage>
            ) : null}
          </div>
        </div>
      </Modal.Content>

      <Modal.LeftButtons>
        {showDelete && (
          <Button
            type="danger"
            className="relative"
            onClick={async () => {
              if (!initialValues.id || !companyData.uid) {
                return;
              }

              try {
                await deleteJournalistList(initialValues.id);
                navigate(
                  generatePath(SWEETHEART_PATH, {
                    id: companyData.slug,
                    tab: tabNames.LISTS,
                  })
                );
                showSuccess({
                  title: 'Journalist list deleted',
                });
                await queryClient.invalidateQueries('journalistLists');
              } catch (error) {
                showError({
                  title: 'Failed to delete journalist list',
                  message: error.message,
                });
              }
            }}
          >
            <span
              className={classNames({
                'animate-pulse': isDeletingJournalistList,
              })}
            >
              Delete
            </span>
            {isDeletingJournalistList ? <Spinner color="text-white" /> : null}
          </Button>
        )}
      </Modal.LeftButtons>
      <Modal.RightButtons>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          onClick={handleSubmit(async (values) => {
            await upsertJournalistList(values);
            onCancel();
          })}
          type="primary"
          className="relative"
        >
          <span
            className={classNames({
              'animate-pulse': isUpsertingJournalistList,
            })}
          >
            {initialValues.id ? 'Update' : 'Create'} Journalist List
          </span>
          {isUpsertingJournalistList ? <Spinner color="text-white" /> : null}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

SweetheartListJournalistListsAddEditModal.propTypes = {
  onCancel: PropTypes.func,
  initialValues: PropTypes.object,
  showDelete: PropTypes.bool,
};

export default SweetheartListJournalistListsAddEditModal;
