import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';
import { noop } from 'lodash';

import Avatar from 'components/Avatar';

const AvatarListAvatar = ({
  index = 0,
  size = '',
  containerRef = {},
  user = {},
  setAvatarsInView = noop,
  shouldObserve = false,
  hiddenUsersCount = 0,
  shouldShowPlaceholder = false,
  shouldHide = false,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!shouldObserve) {
      setAvatarsInView((prev) => {
        const newAvatarsInView = [...prev];
        newAvatarsInView[index] = true;
        return newAvatarsInView;
      });
      return noop;
    }

    const currentLastAvatar = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAvatarsInView((prev) => {
          const newAvatarsInView = [...prev];
          newAvatarsInView[index] = entry.isIntersecting;
          return newAvatarsInView;
        });
      },
      { threshold: 0.9, root: containerRef.current }
    );

    if (currentLastAvatar) {
      observer.observe(currentLastAvatar);
    }

    return () => {
      if (currentLastAvatar) {
        observer.unobserve(currentLastAvatar);
      }
    };
  }, [containerRef, index, setAvatarsInView, shouldObserve]);

  useEffect(
    () => () => {
      setAvatarsInView((prev) => prev.filter((_, i) => i !== index));
    },
    [setAvatarsInView, index]
  );
  return (
    <div ref={ref} className="flex items-center">
      {shouldShowPlaceholder ? (
        <div
          className={classNames(
            'h-7 w-7 rounded-full bg-teal-500 text-xs flex items-center justify-center text-white'
          )}
        >
          +{hiddenUsersCount}
        </div>
      ) : (
        <Avatar
          alt={user.name}
          image={{ path: user.photoUrl }}
          className={classNames(
            'inline-block rounded-full ring-2 ring-white',
            size,
            {
              'opacity-0': shouldHide,
            }
          )}
        />
      )}
    </div>
  );
};

AvatarListAvatar.propTypes = {
  containerRef: PropTypes.object,
  hiddenUsersCount: PropTypes.number,
  index: PropTypes.number,
  setAvatarsInView: PropTypes.func,
  shouldHide: PropTypes.bool,
  shouldObserve: PropTypes.bool,
  shouldShowPlaceholder: PropTypes.bool,
  size: PropTypes.string,
  user: PropTypes.object,
};

export default AvatarListAvatar;
