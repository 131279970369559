import React, { useMemo } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import Spinner from 'components/Spinner';
import { DEFAULT_ONBOARDING_PATH } from 'constants/paths';
import getPageTitle from 'utils/getPageTitle';

import AdminAgencyCompanyCard from './AgencyCompanyCard';
import AgencySearch from './AgencySearch';
import AgencySectionPlaceholder from './AgencySectionPlaceholder';
import AgencySort from './AgencySort';
import AdminAgencyViewStatesError from './AgencyViewStates/AgencyViewStatesError';
import useAgencyCompanies from './hooks/useAgencyCompanies';
import useAgencySearchParams from './useAgencySearchParams';

const NO_COMPANIES_DESCRIPTION =
  'Add your first company to collaborate and showcase their assets for journalists';
const COMPANIES_DESCRIPTION =
  'Add more companies to collaborate and showcase their assets for journalists';

const AgencyDashboardCompanies = () => {
  const { agencyId } = useParams();
  const { query, sort, sortDir } = useAgencySearchParams();
  const { data, isLoading, error } = useAgencyCompanies(agencyId);

  const baseURL = window.location.origin;
  const textToCopy = `${baseURL}${DEFAULT_ONBOARDING_PATH}?agency=${agencyId}`;

  const isEmpty = data?.items?.length < 1;
  const sortedCompanies = useMemo(() => {
    const filteredCompanies = (data?.items ?? []).filter((company) =>
      company.name.toLowerCase().includes(query.toLowerCase())
    );

    if (!sort) return filteredCompanies;

    return [...filteredCompanies].sort((a, b) => {
      let compareA;
      let compareB;
      switch (sort.name) {
        case 'name':
          compareA = a.name.toLowerCase();
          compareB = b.name.toLowerCase();
          if (sortDir === -1) {
            return compareA > compareB ? -1 : 1;
          }
          return compareA < compareB ? -1 : 1;

        case 'unapprovedMessages': {
          const difference = b.numUnapprovedMessages - a.numUnapprovedMessages;
          return sortDir === -1 ? -difference : difference;
        }

        default:
          return 0;
      }
    });
  }, [sort, sortDir, data, query]);

  if (error) {
    return <AdminAgencyViewStatesError />;
  }

  return (
    <>
      <Helmet>
        <title>{getPageTitle('Agency - Dashboard')}</title>
      </Helmet>

      <div className="flex py-2 w-full space-x-3 items-start justify-between mb-4">
        <AgencySearch />
        <AgencySort />
      </div>

      <ul className="grid grid-cols-1 gap-y-8 xl:grid-cols-2 gap-x-8">
        <AgencySectionPlaceholder
          title="Add a company"
          description={
            isEmpty ? NO_COMPANIES_DESCRIPTION : COMPANIES_DESCRIPTION
          }
          paddingClassName="p-6"
          copyText={textToCopy}
        />
        {isLoading && <Spinner />}
        {sortedCompanies.map((company) => (
          <AdminAgencyCompanyCard key={company.id} company={company} />
        ))}
      </ul>
    </>
  );
};

export default AgencyDashboardCompanies;
