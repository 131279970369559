import { FORBIDDEN_PAGE_PATH } from 'constants/paths';

import ForbiddenPage from './ForbiddenPage';

const ForbiddenRoute = {
  path: FORBIDDEN_PAGE_PATH,
  authRequired: true,
  component: ForbiddenPage,
};

export default ForbiddenRoute;
