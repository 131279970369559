import React, { useMemo, useState } from 'react';

import { Helmet } from 'react-helmet';

import Spinner from 'components/Spinner';
import useAdminOpportunities from 'hooks/useAdminOpportunities';
import getPageTitle from 'utils/getPageTitle';

import AdminWrapper from '../AdminWrapper';

import AdminOpportunitiesRow from './AdminOpportunitiesRow';

const AdminOpportunitiesPage = () => {
  const [hideDeleted, setHideDeleted] = useState(true);
  const {
    data: allOpportunities,
    isLoading,
    isError,
  } = useAdminOpportunities();

  const opportunities = useMemo(() => {
    if (hideDeleted) {
      return (allOpportunities || []).filter((opp) => !opp.deleted);
    }
    return allOpportunities || [];
  }, [allOpportunities, hideDeleted]);

  return (
    <AdminWrapper title="Opportunities">
      <Helmet>
        <title>{getPageTitle('Admin - Opportunities')}</title>
      </Helmet>
      {isLoading && <Spinner />}
      {isError && <p>Something went wrong! Please try again later</p>}
      {!isError && !isLoading && (
        <div className="flex flex-col mt-4">
          <div className="flex flex-row justify-end mb-2">
            <button
              type="button"
              className="text-sm text-teal-500"
              onClick={() => setHideDeleted((prev) => !prev)}
            >
              {hideDeleted ? 'Show deleted' : 'Hide deleted'}
            </button>
          </div>
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 text-xs font-medium text-gray-500 uppercase">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        Title / Description
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        Companies
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {opportunities.map((opportunity) => (
                      <AdminOpportunitiesRow
                        key={opportunity.id}
                        opportunity={opportunity}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </AdminWrapper>
  );
};

export default AdminOpportunitiesPage;
