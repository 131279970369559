import { useMatch } from 'react-router-dom';

import { ADMIN_PATH } from 'constants/paths';

const useAdminRoute = () => {
  const routeMatchAdmin = useMatch({ path: ADMIN_PATH, end: false });
  return routeMatchAdmin;
};

export default useAdminRoute;
