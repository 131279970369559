import React, { useState } from 'react';

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Helmet } from 'react-helmet';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import PaginationNav from 'components/PaginationNav';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import useJournalistsBySearch from 'hooks/useJournalistsBySearch';
import useUpdateJournalist from 'hooks/useUpdateJournalist';
import getPageTitle from 'utils/getPageTitle';

import AdminWrapper from '../AdminWrapper';

import AdminJournalistsEditJournalistModal from './AdminJournalistsEditJournalistModal';
import AdminJournalistsTable from './AdminJournalistsTable';

const AdminJournalistsPage = () => {
  const [query, setQuery] = useState('');
  const { showSuccess } = useNotifications();

  const { loading, numPages, page, journalists, setPage, reload } =
    useJournalistsBySearch(query);

  const updateJournalist = useUpdateJournalist();

  const [currentJournalist, setCurrentJournalist] = useState(false);
  const [isAddJournalistModalOpen, setIsAddJournalistModalOpen] =
    useState(false);

  const onActivate = async (id) => {
    const journalist = journalists.find((j) => j.id === id);
    await updateJournalist({
      name: journalist.name,
      publicationName: journalist?.publication?.name,
      isDeactivated: false,
    });
    showSuccess({ message: 'Journalist reactivated' });
    setTimeout(reload, 1000);
  };

  const onDeactivate = async (id) => {
    const journalist = journalists.find((j) => j.id === id);
    await updateJournalist({
      name: journalist.name,
      publicationName: journalist?.publication?.name,
      isDeactivated: true,
    });
    showSuccess({ message: 'Journalist deactivated' });
    setTimeout(reload, 1000);
  };

  return (
    <>
      <AdminWrapper title="Journalists">
        <Helmet>
          <title>{getPageTitle('Admin - Journalists')}</title>
        </Helmet>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <InputFieldText
              type="text"
              autoComplete="company"
              containerClassName="w-full mr-2"
              name="search"
              placeholder="Search by journalist, publication or email"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            >
              <InputFieldText.Prefix>
                <MagnifyingGlassIcon className="w-4 h-4" />
              </InputFieldText.Prefix>
            </InputFieldText>
            <Button
              onClick={() => setIsAddJournalistModalOpen(true)}
              className="w-fit self-end"
            >
              Add Journalist
            </Button>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <AdminJournalistsTable
            journalists={journalists}
            onEdit={(id) => {
              setCurrentJournalist(journalists.find((j) => j.id === id));
            }}
            onActivate={onActivate}
            onDeactivate={onDeactivate}
          />
        )}
        <PaginationNav
          currentPage={page}
          setPage={setPage}
          maxPages={numPages}
          loading={loading}
        />
      </AdminWrapper>

      {isAddJournalistModalOpen && (
        <AdminJournalistsEditJournalistModal
          onClose={() => setIsAddJournalistModalOpen(false)}
          open
          onSubmit={async (data) => {
            updateJournalist(data);
            setIsAddJournalistModalOpen(false);
            showSuccess({ message: 'Journalist added.' });
            // Give the search index some time and refresh
            setTimeout(reload, 2000);
          }}
        />
      )}

      {currentJournalist && (
        <AdminJournalistsEditJournalistModal
          currentJournalist={currentJournalist}
          open
          onClose={() => setCurrentJournalist(null)}
          onSubmit={async (data) => {
            updateJournalist(data);
            setCurrentJournalist(null);
            showSuccess({ message: 'Journalist updated.' });
            // Give the search index some time and refresh
            setTimeout(reload, 2000);
          }}
        />
      )}
    </>
  );
};

export default AdminJournalistsPage;
