import noop from 'lodash/noop';

const defaultValues = {
  threads: {
    loading: true,
    data: {
      threads: null,
      statusCounts: {},
      redirectTo: null,
    },
  },
  threadDetails: {
    loading: true,
    data: {
      messages: [],
      opportunity: {},
      isInitialDraft: true,
    },
  },
  draft: {
    loading: true,
    data: {},
  },
  templates: {
    loading: true,
    data: [],
  },
  currentThread: null,
  sendMessageErrors: null,
  pendingAttachments: [],

  archiveThread: noop,
  createTemplate: noop,
  saveDraft: noop,
  addAttachments: noop,
  removeAttachment: noop,
  sendMessage: noop,
  readMessage: noop,
  scheduleMessage: noop,
};

export default defaultValues;
