import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Tag from 'components/tags/Tag';
import getTagColors from 'utils/color/getTagColors';

// Child tag inside of a list of tags, can be selected
const InputFieldTagsListTag = ({
  tag = {},
  index = 0,
  isSelected = false,
  onChange = noop,
  testId = '',
}) => {
  const [bgColor, textColor, borderColor] = getTagColors(
    index,
    tag.name?.length,
    tag
  );
  return (
    <>
      <Tag
        as="button"
        type="button"
        onClick={() => onChange(tag)}
        data-test-id={`${testId}-${tag.name}`}
        data-tooltip-content={tag.tip}
        data-tooltip-id={`tooltip-tag-${tag.name}`}
        className={isSelected ? 'opacity-100' : 'opacity-40'}
        bgColor={bgColor}
        textColor={textColor}
        borderColor={borderColor}
      >
        {tag.name}
      </Tag>
      {tag.tip ? (
        <ReactTooltip
          id={`tooltip-tag-${tag.name}`}
          className="max-w-xs text-sm"
        />
      ) : null}
    </>
  );
};

InputFieldTagsListTag.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

export default InputFieldTagsListTag;
