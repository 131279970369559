import PropTypes from 'prop-types';
import React from 'react';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUpdateCompanySettings from 'hooks/useUpdateCompanySettings';

import CompanyMember from './CompanyMembers/CompanyMember';

// TODO: remove highlight of success fees without thread or company when migration is done
const CompanyNotificationsTableRow = ({ notifications = {}, member = {} }) => {
  const { companyData } = useCompanyData();
  const { mutate } = useUpdateCompanySettings(companyData?.uid);
  const memberNotifications = Object.keys(notifications).map((key) => ({
    type: key,
    enabled: notifications[key].includes(member.id),
  }));

  return (
    <tr>
      <td className="px-4 py-2 whitespace-nowrap">
        <CompanyMember member={member} />
      </td>
      {memberNotifications.map((notification) => (
        <td
          key={notification.type}
          className="px-4 py-2 text-center whitespace-nowrap"
        >
          <input
            checked={notification.enabled}
            onChange={() => {
              const newNotifications = {
                ...notifications,
                [notification.type]: notification.enabled
                  ? notifications[notification.type].filter(
                      (id) => id !== member.id
                    )
                  : [...notifications[notification.type], member.id],
              };
              mutate({ notifications: newNotifications });
            }}
            type="checkbox"
            className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded cursor-pointer"
          />
        </td>
      ))}
    </tr>
  );
};

CompanyNotificationsTableRow.propTypes = {
  notifications: PropTypes.object,
  member: PropTypes.object,
};

export default CompanyNotificationsTableRow;
