import { LOGOUT_PATH as path } from 'constants/paths';

import LogoutPage from './LogoutPage';

const LogoutRoute = {
  path,
  component: LogoutPage,
};

export default LogoutRoute;
