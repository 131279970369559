/* eslint-disable max-params */
import { useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from 'reactfire';

const fetchCompanyThreads = async ({ uid, threadId, search, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/companies/${uid}/threads?${search}${
    threadId ? `&threadId=${threadId}` : ''
  }`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch journalist lists: ${error.message}`);
  }
};

const useCompanyThreads = (uid = '', threadId = '', search = '') => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const [, setSearchParams] = useSearchParams();
  const queryKey = ['companyThreads', uid, search];
  const { error, refetch, data, isFetching, isLoading } = useQuery(
    queryKey,
    async () => {
      const currentQueryData = queryClient.getQueryData(queryKey);

      if (currentQueryData?.wasRedirected) {
        await queryClient.cancelQueries(queryKey);
        return {
          ...currentQueryData,
          wasRedirected: undefined,
          redirectTo: null,
        };
      }

      const companyThreads = await fetchCompanyThreads({
        uid,
        threadId,
        search,
        auth,
      });

      if (companyThreads.redirectTo) {
        const redirectSearchParams = new URLSearchParams(search);
        redirectSearchParams.set('status', companyThreads.redirectTo);
        queryClient.setQueryData(
          ['companyThreads', uid, redirectSearchParams.toString()],
          { ...companyThreads, wasRedirected: true }
        );
        setSearchParams(redirectSearchParams);
        return queryClient.cancelQueries(queryKey);
      }

      return companyThreads;
    },
    {
      enabled: !!uid,
      keepPreviousData: true,
    }
  );

  return {
    error,
    data,
    loading: isFetching,
    isInitialLoading: isLoading,
    fetchData: refetch,
  };
};

export default useCompanyThreads;
