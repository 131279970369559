import { useCallback, useEffect, useRef, useState } from 'react';

import { useAuth } from 'reactfire';

const UCD_URL = process.env.REACT_APP_UCD_URL;
const JPD_URL = process.env.REACT_APP_JPD_URL;
const MOD_URL = process.env.REACT_APP_MOD_URL;

const getBaseURL = (service = '') => {
  switch (service) {
    case 'MOD':
      return MOD_URL;
    case 'JPD':
      return JPD_URL;
    case 'UCD':
      return UCD_URL;
    default:
      throw new Error("Service must be one of 'MOD', 'JPD', or 'UCD'");
  }
};

/**
 * @param {Object} params
 * @param {("GET"|"POST"|"PUT"|"DELETE")} params.method
 * @param {Object} params.body
 * @param {string} params.endpoint
 * @param {("MOD"|"JPD"|"UCD")} params.service
 * @param {boolean} params.autoFetch
 * @param {any} params.initialData
 */
const useAPIRequest = (params = {}) => {
  const {
    method: initialMethod = 'GET',
    body: initialBody,
    endpoint: initialEndpoint,
    autoFetch = true,
    initialData = {},
    service,
  } = params;
  const conditionalAutoFetch = Boolean(autoFetch && initialMethod === 'GET');

  const BASE_URL = getBaseURL(service);

  const auth = useAuth();
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(conditionalAutoFetch);
  const abortRef = useRef();

  const fetchData = useCallback(
    async (fetchParams = {}) => {
      const {
        endpoint = initialEndpoint,
        body = initialBody,
        method = initialMethod,
        shouldThrow,
      } = fetchParams;

      setLoading(true);

      const jwt = await auth.currentUser?.getIdToken();
      abortRef.current = new AbortController();

      try {
        const response = await fetch(`${BASE_URL}${endpoint}`, {
          method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: body ? JSON.stringify(body) : null,
          signal: abortRef.current.signal,
        });

        const responseData = await response.json();

        if (!response?.ok) {
          setError({
            status: response.status,
            message: responseData?.msg || 'API call failed',
          });
          if (shouldThrow) {
            throw new Error(responseData?.msg || 'API call failed');
          }

          return responseData;
        }

        setError(null);
        setData(responseData);

        return responseData;
      } finally {
        setLoading(false);
      }
    },
    [initialEndpoint, initialBody, initialMethod, auth.currentUser, BASE_URL]
  );

  useEffect(() => {
    (async () => {
      if (conditionalAutoFetch) {
        try {
          await fetchData();
        } catch (_) {
          // swallow it and expect the components to use the error state
        }
      }
    })();
  }, [conditionalAutoFetch, fetchData]);

  return {
    loading,
    error,
    data,
    fetchData,
    abort: useCallback(() => abortRef?.current?.abort(), []),
  };
};

export default useAPIRequest;
