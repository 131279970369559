import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const MOD_URL = process.env.REACT_APP_MOD_URL;

const useDocument = (companyId, documentId) => {
  const auth = useAuth();
  return useQuery(['documents', documentId], async () => {
    const jwt = await auth.currentUser?.getIdToken();
    const response = await fetch(
      `${MOD_URL}/documents/${companyId}/${documentId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    if (!response?.ok) {
      throw new Error('Failed fetching conversation items');
    }
    return response.json();
  });
};

export default useDocument;
