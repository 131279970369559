import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const HTMLTextContent = ({ html = '', as = 'span', className = '' }) => {
  const Component = as;
  const plainText = useMemo(() => {
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.textContent || div.innerText || '';

    div.remove();

    return text;
  }, [html]);

  return <Component className={className}>{plainText}</Component>;
};

HTMLTextContent.propTypes = {
  html: PropTypes.string,
  as: PropTypes.string,
  className: PropTypes.string,
};

export default HTMLTextContent;
