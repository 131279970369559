import PropTypes from 'prop-types';
import React from 'react';

import { noop } from 'lodash';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';

const ContentManagementDocumentResponseFormEditIdeaModal = ({
  open = false,
  idea = {},
  onSubmit = noop,
  onClose = noop,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      headline: idea?.headline,
      description: idea?.description,
    },
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Close srMessage="Close edit idea modal" onClose={onClose} />
      <Modal.Title>
        {Object.keys(idea) === 0 ? `Edit article idea` : `Create article idea`}
      </Modal.Title>
      <Modal.Content>
        <form id="article-idea-edit" className="p-4">
          <div className="mb-4">
            <div>
              <Label htmlFor="idea-headline">Title</Label>

              <InputFieldText
                id="contentManagement-articleIdeaForm-ideaTitle"
                name="headline"
                {...register('headline', {
                  required: 'Headline is required',
                })}
                errors={errors}
                placeholder="The headline of the article"
              />
            </div>
          </div>
          <div className="mb-4">
            <Label htmlFor="idea-description">Content</Label>

            <InputfieldTextArea
              name="description"
              {...register('description')}
              rows="6"
              data-test-id="article-inputDescription"
            />
          </div>
          <div className="flex justify-end">
            <Button onClick={handleSubmit(onSubmit)}>Save Changes</Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};
ContentManagementDocumentResponseFormEditIdeaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  idea: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ContentManagementDocumentResponseFormEditIdeaModal;
