import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'reactfire';

const upsertTask = async ({ data, uid, auth }) => {
  const BASE_URL = process.env.REACT_APP_UCD_URL;
  const endpoint = `/companies/${uid}/tasks`;
  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to update task: ${error.message}`);
  }
};

const useUpsertTask = (uid = '', key = 'companyTasks') => {
  const auth = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) =>
      upsertTask({ data, uid: uid || data.companyId, auth }),
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },
  });
};

export default useUpsertTask;
