import PropTypes from 'prop-types';
import React from 'react';

import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { Controller, useForm } from 'react-hook-form';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import TextCounter from 'components/form/TextCounter';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { tagsDownloads } from 'constants/tags';

const CompanyDownloadsEditModal = ({
  download = null,
  open,
  onClose,
  onSubmit,
  uploadFolder,
}) => {
  const defaultValues = download || {};
  const form = useForm({ mode: 'onChange', defaultValues });
  const formName = 'company-downloads-editModal';
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, errors },
    watch,
  } = form;

  const title = watch('title');

  return (
    <Modal open={open}>
      <Modal.Close srMessage="Close downloads edit modal" onClose={onClose} />
      <Modal.Icon Icon={ArrowDownTrayIcon} />
      <Modal.Title>{download ? 'Edit' : 'Add a'} download</Modal.Title>
      {!download && (
        <Modal.Description>
          Journalists are more likely to cover your company if they can also
          publish images or other assets that accompany your content.
        </Modal.Description>
      )}
      <Modal.Content>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={formName}
          className="space-y-3"
        >
          <InputFieldText
            defaultValue={defaultValues.title}
            {...register('title', {
              required: 'Please put in a title',
              maxLength: {
                value: 140,
                message: 'Please keep the title below 140 characters',
              },
            })}
            errors={errors}
            placeholder="Type in what this download should be called..."
            testId="company-downloads-editModal-titleInput"
          >
            <InputFieldText.Label className="flex justify-between items-baseline mt-5">
              Title*
              <TextCounter str={title || defaultValues.title} />
            </InputFieldText.Label>
          </InputFieldText>

          <InputFieldUpload
            form={form}
            name="file"
            folder={uploadFolder}
            rules={{
              required: 'Please upload a file',
            }}
            presetName="file"
            testId="company-downloads-editModal-fileInput"
          >
            <InputFieldUpload.Label>Downloadble file</InputFieldUpload.Label>
          </InputFieldUpload>

          <div className="flex">
            <Label htmlFor="tags" className="flex items-center">
              Tags
              <Tooltip
                id="tooltip-downloads-tags"
                copy="Add tags to your downloadable file so it can appear in a Journalist's search in the future. i.e. logo, headshot, team, product etc."
              />
            </Label>
          </div>

          <div
            className="mt-2"
            data-test-id="company-downloads-editModal-tagsInput"
          >
            <Controller
              name="tags"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <InputFieldTagsAutocomplete
                  placeholder="Add a tag"
                  customFieldName="tag"
                  suggestions={tagsDownloads}
                  tags={value}
                  onAddition={(tag) => {
                    if (value.find((t) => t.id === tag.id)) {
                      return;
                    }
                    onChange([...value, tag]);
                  }}
                  onDelete={(tag) => {
                    onChange(value.filter((t) => tag.id !== t.id));
                  }}
                />
              )}
            />
          </div>
        </form>
        <ReactTooltip
          id="tooltip-downloads-tags"
          className="max-w-xs text-sm"
        />
      </Modal.Content>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-downloads-editModal-cancel"
        >
          Cancel
        </Button>
        <Button
          submit
          form={formName}
          disabled={!isValid}
          eventType="pressPage_update"
          eventProperties={{ action: 'download_add' }}
          data-test-id="company-downloads-editModal-submit"
        >
          {download ? 'Update' : 'Add'} download
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyDownloadsEditModal.propTypes = {
  download: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadFolder: PropTypes.string.isRequired,
};

export default CompanyDownloadsEditModal;
