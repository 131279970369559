import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Swiper } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import pressPageSections from 'constants/pressPageSections';
import usePrevious from 'hooks/usePrevious';

import { COMPANY_NAVBAR_OFFSET } from '../companyNavbarOffset';

import CompanyNavbarLink from './CompanyNavbarLink';
import companyNavbarBreakpoints from './companyNavbarBreakpoints';

const CompanyNavbar = ({
  currentLocation = {},
  sectionRefs = {},
  currentIndex = 0,
}) => {
  const swiperRef = useRef(null);
  const timeoutRef = useRef(null);
  const previousIndex = usePrevious(currentIndex);

  let initialIndex = 0;

  if (currentLocation?.hash) {
    initialIndex = pressPageSections.findIndex(
      (section) => section.hash === currentLocation.hash
    );
  }

  const [lockedIndex, setLockedIndex] = useState(initialIndex);
  const [locked, setLocked] = useState(true);

  const sections = pressPageSections.filter(
    (section) => sectionRefs[section.name]
  );

  // When clicking on the navbar, lock in the clicked section and scroll there
  // Release the lock after 1s so that scrolling will pick up new sections again
  const onScroll = useCallback((el, index) => {
    clearTimeout(timeoutRef.current);

    // lock for 1s
    setLocked(true);
    timeoutRef.current = setTimeout(() => {
      setLocked(false);
    }, 1000);

    // lock until previousIndex !== currentIndex
    setLockedIndex(index);

    // scroll to section smoothly. Offset has to be the same as the one used on
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: yCoordinate + COMPANY_NAVBAR_OFFSET,
      behavior: 'smooth',
    });
  }, []);

  // Block scroll for the first second after page, so that slider doesn't walk over items and shows them as active
  useEffect(() => {
    setLocked(true);
    setTimeout(() => setLocked(false), 1500);
  }, []);

  useEffect(() => {
    // When section index changes, unlock
    if (!locked && previousIndex !== currentIndex) {
      setLockedIndex(null);
    }

    // Slide to current anchor, to make nav item visible in case it's hidden
    sections.forEach((_, idx) => {
      if (idx === currentIndex) {
        swiperRef.current.slideTo(
          lockedIndex !== null ? lockedIndex - 1 : idx - 1
        );
      }
    });
  }, [sections, currentIndex, lockedIndex, previousIndex, locked]);

  if (sections.length === 0) {
    return null;
  }

  return (
    <div
      data-intercom-id="pressPage-navbar"
      className="sticky z-10 mt-0 md:mt-0 top-0 md:border-l md:border-gray-200"
    >
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        resizeObserver
        spaceBetween={16}
        breakpoints={companyNavbarBreakpoints}
        className="w-full bg-white shadow md:shadow-none text-gray-300 border-b border-gray-200 pt-5"
      >
        {sections.map((section, index) => (
          <CompanyNavbarLink
            key={section.name}
            to={{ ...currentLocation, hash: section.hash }}
            section={section}
            isActive={
              index === (lockedIndex !== null ? lockedIndex : currentIndex)
            }
            onScroll={(el) => onScroll(el, index)}
          />
        ))}
      </Swiper>
    </div>
  );
};

CompanyNavbar.propTypes = {
  currentLocation: PropTypes.object,
  sectionRefs: PropTypes.object,
  currentIndex: PropTypes.number,
};

export default React.memo(CompanyNavbar);
