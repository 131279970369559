import React from 'react';

import Card from 'components/Card';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import useCompanySuccessFees from '../hooks/useCompanySuccessFees';

import BillingSettingsSuccessFeesTable from './BillingSettingsSuccessFeesTable';

const BillingSettingsSuccessFees = () => {
  const { companyData } = useCompanyData();
  const { data: successFees } = useCompanySuccessFees(companyData?.uid);

  if (!successFees) {
    return null;
  }

  return (
    <Card title="Success Fees">
      <div className="w-full items-center gap-5">
        {successFees && successFees.length > 0 ? (
          <BillingSettingsSuccessFeesTable successFees={successFees} />
        ) : (
          <p className="text-center text-sm text-gray-900 mx-auto max-w-prose leading-relaxed">
            There are no success fees yet.
          </p>
        )}
      </div>
    </Card>
  );
};

export default BillingSettingsSuccessFees;
