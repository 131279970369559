import PropTypes from 'prop-types';
import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useParams } from 'react-router-dom';

import Callout from 'components/Callout/Callout';
import { messageStatus } from 'constants/messageStatus';
import { PAGE_TITLE } from 'constants/meta';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';
import useTrackIsExpanded from 'routes/Inbox2/hooks/useTrackIsExpanded';

import InboxMessageContent from './InboxMessageContent';
import InboxMessageHeader from './InboxMessageHeader/InboxMessageHeader';

const UNAPPROVED_MESSAGE_CALLOUT = `Your message will be reviewed and may be edited by the ${PAGE_TITLE} team to optimise journalist engagement.`;
const APPROVED_MESSAGE_CALLOUT =
  'Your response has been sent and is now with the journalist. We will send you a notification when you get a response.';

const InboxMessage = ({ message = {}, showCallout = false, grant }) => {
  const { currentThread } = useInboxContext();
  const { messageId } = useParams();
  const { claimsAdmin: isAdmin } = useUserData();

  const isExpanded = messageId === message.id;

  const isMessageApprovedOrDelivered =
    message.status === messageStatus.DELIVERED ||
    message.status === messageStatus.APPROVED;
  const isMessageApprovedAndScheduled =
    message.status === messageStatus.APPROVED && message.sendAfter;

  const calloutText = isMessageApprovedOrDelivered
    ? APPROVED_MESSAGE_CALLOUT
    : UNAPPROVED_MESSAGE_CALLOUT;

  useTrackIsExpanded({ message, isExpanded, currentThread });

  return (
    <div
      className={classNames('w-full', {
        'bg-gray-50': message.isFromUser,
        'opacity-50': message.pending,
      })}
    >
      <InboxMessageHeader
        message={message}
        thread={currentThread}
        isExpanded={isExpanded}
        isAdmin={isAdmin}
        grant={grant}
      />

      <InboxMessageContent
        text={message?.text}
        html={message?.html}
        isExpanded={isExpanded}
        attachments={message?.attachments || []}
      />

      <AnimatePresence>
        {!(isMessageApprovedAndScheduled && isAdmin) &&
        showCallout &&
        isExpanded ? (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: 'auto',
              opacity: 1,
              transition: { delay: 0.3 },
            }}
            exit={{ height: 0, opacity: 0 }}
            className="ml-20 mr-4 mb-4 w-[calc(100%-6rem)]"
          >
            <Callout
              type="info"
              Icon={InformationCircleIcon}
              wrapperClassName="!m-0"
            >
              {calloutText}
            </Callout>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

InboxMessage.propTypes = {
  message: PropTypes.object,
  showCallout: PropTypes.bool,
  grant: PropTypes.object,
};
export default InboxMessage;
