import React, { useState } from 'react';

import { doc } from 'firebase/firestore';
import { Controller, useForm } from 'react-hook-form';
import { useFirestore } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import CompanyImage from 'components/company/CompanyImage';
import UpdateLogoModal from 'components/company/UpdateLogoModal';
import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import defaultLanguages from 'constants/defaultLanguages';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';
import numberOfEmployees from 'constants/numberOfEmployees';
import { REGEX_URL_NO_PROTOCOL } from 'constants/regex';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import { useTracking } from 'modules/segment/TrackingProvider';
import stripTrailingSlash from 'utils/data/stripTrailingSlash';
import getCompanyRoute from 'utils/getCompanyRoute';

import CompanyFormFoundedDate from './CompanyFormFoundedDate';

const CompanyForm = () => {
  const trackingService = useTracking();

  const firestore = useFirestore();
  const { showSuccess, showError } = useNotifications();
  const { companyData: companyDataRaw } = useCompanyData();
  const { updateDocument } = useFirestoreWrapper();

  const companyData = {
    ...companyDataRaw,
    website: stripTrailingSlash(companyDataRaw.website),
  };

  const companyRef = doc(firestore, COMPANIES_COLLECTION, companyData?.uid);

  const [isUpdateLogoModalOpen, setUpdateLogoModalOpen] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    nativeValidation: false,
    defaultValues: companyData,
  });

  const defaultLanguage = watch('defaultLanguage');

  const onSubmit = async (newCompanyData = {}) => {
    try {
      // Log changed events
      if (companyData.numberOfEmployees !== newCompanyData.numberOfEmployees) {
        trackingService.track('companyData_update', {
          action: 'numberOfEmployees_change',
          company_numberOfEmployees_old: companyData.numberOfEmployees,
          company_numberOfEmployees_new: newCompanyData.numberOfEmployees,
        });
      }
      if (companyData.location !== newCompanyData.location) {
        trackingService.track('companyData_update', {
          action: 'location_change',
          company_location_old: companyData.location,
          company_location_new: newCompanyData.location,
        });
      }
      if (companyData.website !== newCompanyData.website) {
        trackingService.track('companyData_update', {
          action: 'website_change',
          company_website_old: companyData.website,
          company_website_new: newCompanyData.website,
        });
      }
      // Update properties
      await updateDocument(companyRef, newCompanyData);
      showSuccess({ message: 'Company settings updated successfully' });
    } catch (err) {
      console.error('Error updating profile', err); // eslint-disable-line no-console
      showError({ title: 'Error updating profile', message: err.message });
    }
  };

  const updateLogo = async ({ logo }) => {
    await updateDocument(companyRef, { logo });
    setUpdateLogoModalOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-12">
            <div className="w-full flex flex-row gap-2 col-span-12 sm:col-span-6">
              <InputFieldText
                name="name"
                defaultValue={companyData.name}
                placeholder="Name"
                containerClassName="w-full sm:w-1/2 pr-3"
                disabled
              >
                <InputFieldText.Label>Name</InputFieldText.Label>
              </InputFieldText>

              <div className="flex flex-col gap-2 col-span-12 sm:col-span-6">
                <Label className="block text-gray-700">Language</Label>
                <InputFieldDropdown
                  options={defaultLanguages}
                  value={defaultLanguage}
                  onChange={(value) => setValue('defaultLanguage', value)}
                  buttonClassName="w-40 sm:w-60"
                  wrapperClassName="mb-4"
                  placeholder="Select a language"
                />
              </div>
            </div>

            <p className="text-xs text-gray-500 mt-2">
              The URL of your press page is: <br className="sm:hidden" />
              <a
                className="font-semibold"
                href={getCompanyRoute(companyData.slug)}
              >
                https://app.mvpr.io/company/{companyData.slug.trim()}
              </a>
            </p>
          </div>

          <fieldset className="col-span-12 sm:col-span-12">
            <Label htmlFor="name">Founded date</Label>
            <CompanyFormFoundedDate
              control={control}
              watch={watch}
              setValue={setValue}
              companyData={companyData}
            />
          </fieldset>

          <div className="col-span-12">
            <Label htmlFor="logo">Logo</Label>
            <div className="mt-2 flex items-center">
              <CompanyImage
                image={companyData?.logo}
                alt={`Logo of company ${companyData?.name}`}
                className="w-16 h-16"
              />
              <div className="ml-4 flex">
                <Button
                  type="secondary"
                  onClick={() => setUpdateLogoModalOpen(true)}
                >
                  <span>Change</span>
                  <span className="sr-only"> user photo</span>
                </Button>
              </div>
            </div>
          </div>

          <InputFieldText
            {...register('location', {
              required: 'Please type in the location of your company',
            })}
            containerClassName="col-span-12 sm:col-span-6"
            defaultValue={companyData.location}
            placeholder="Location"
            errors={errors}
          >
            <InputFieldText.Label>Location</InputFieldText.Label>
          </InputFieldText>

          <InputFieldText
            containerClassName="col-span-12 sm:col-span-6"
            errors={errors}
            maxLength={2000}
            {...register('website', {
              pattern: {
                value: REGEX_URL_NO_PROTOCOL,
                message: 'Please type in the website of your company',
              },
            })}
            defaultValue={companyData.website}
            placeholder="Type your company website here..."
          >
            <InputFieldText.Prefix>https://</InputFieldText.Prefix>
            <InputFieldText.Label>Website</InputFieldText.Label>
          </InputFieldText>

          <div className="col-span-12">
            <Label htmlFor="name">Number of employees</Label>

            <div className="flex w-full mt-2 space-x-2">
              <Controller
                name="numberOfEmployees"
                control={control}
                defaultValue={
                  companyData.numberOfEmployees || numberOfEmployees[0]
                }
                render={({ field: { onChange, value } }) => (
                  <InputFieldDropdown
                    options={numberOfEmployees}
                    onChange={onChange}
                    value={value}
                    buttonClassName="w-36"
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="mt-4 flex flex-row justify-end">
          <Button type="secondary" submit disabled={isSubmitting || !isValid}>
            Submit
          </Button>
        </div>
      </form>

      {isUpdateLogoModalOpen ? (
        <UpdateLogoModal
          open={isUpdateLogoModalOpen}
          uploadFolder={`companies/${companyData.uid}/logo`}
          onSubmit={updateLogo}
          onClose={() => setUpdateLogoModalOpen(false)}
        />
      ) : null}
    </>
  );
};

export default CompanyForm;
