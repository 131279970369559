import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { generatePath, useParams, Link } from 'react-router-dom';

import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';

const ContentManagementNavbarTabsItem = ({ tab = {} }) => {
  const { tab: activeTab, id } = useParams();
  const isActive = tab.field === activeTab;
  const path = generatePath(CONTENT_MANAGEMENT_PATH, {
    id,
    tab: tab.field,
  });

  return (
    <Link
      to={path}
      className={classNames(
        'block py-3 pb-4 px-4 cursor-pointer w-auto space-x-1 text-sm hover:text-teal-500 relative',
        {
          'border-solid border-b-2 border-teal-500 font-medium text-teal-500':
            isActive,
        }
      )}
      onClick={() => window.scrollTo(0, 0)}
      data-test-id={`tab-${tab.field}`}
    >
      {tab.title}
    </Link>
  );
};

ContentManagementNavbarTabsItem.propTypes = {
  tab: PropTypes.shape({
    field: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default ContentManagementNavbarTabsItem;
