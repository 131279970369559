import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const trackEvent = async ({ threadId, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/threads/${threadId}/trackEvent`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        type: 'PRESS_PAGE_VISIT',
      }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to track press page visit: ${error.message}`);
  }
};

const useTrackPressPageVisit = (threadId = '') => {
  const auth = useAuth();
  useQuery({
    queryFn: () => trackEvent({ threadId, auth }),
    queryKey: ['trackPressPageVisit', { threadId }],
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: !!threadId,
  });
};

export default useTrackPressPageVisit;
