import React, { useState } from 'react';

import { ArrowDownTrayIcon, TrashIcon } from '@heroicons/react/20/solid';
import { DocumentIcon } from '@heroicons/react/24/outline';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';
import downloadFile from 'utils/file/downloadFile';

import useCurrentResourceContext from '../hooks/useCurrentResourceContext';

import ContentAddResourceModal from './ContentAddResourceModal';

const ContentManagementCustomResourcesList = () => {
  const { showError } = useNotifications();
  const [isAddResourceModalOpen, setIsAddResourceModalOpen] = useState(false);

  const {
    customResources: resources,
    addCustomResource,
    removeCustomResource,
  } = useCurrentResourceContext();

  const onSubmit = async (values) => {
    const { attachment, fileBody } = values.file;

    try {
      await addCustomResource({
        customResource: attachment,
        file: fileBody,
        name: values.name,
      });
    } catch (error) {
      showError({
        title: "We're sorry! Something went wrong.",
        message: "The document couldn't be added.",
      });
    } finally {
      setIsAddResourceModalOpen(false);
    }
  };

  const onDelete = (path) => {
    removeCustomResource({ path });
  };

  return (
    <>
      <ul className="grid grid-cols-1 gap-6 py-6 pt-0 sm:grid-cols-2">
        {resources?.map((customResource) => {
          const formattedDate = getDateFromTimestamp(
            customResource?.lastEdited
          );

          return (
            <li
              key={`${customResource.file.path}-key`}
              className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
            >
              <div
                className={`flex w-full h-full rounded-lg items-center justify-between space-x-6 p-6
            }`}
              >
                <div className="flex items-center space-x-3">
                  <DocumentIcon
                    className="h-8 w-8 text-gray-500"
                    aria-hidden="true"
                  />
                  <div className="flex-1 truncate">
                    <h3 className="truncate text-sm font-medium text-gray-900">
                      {customResource.title || 'Untitled'}
                    </h3>
                    <p className="mt-2 truncate text-xs font-light text-gray-500">
                      Last edited: {formattedDate}
                    </p>
                  </div>
                </div>

                <div className="h-full">
                  <DotsMenu
                    theme="dark"
                    options={[
                      {
                        label: `Download`,
                        icon: <ArrowDownTrayIcon className="w-4 h-4" />,
                        onClick: () =>
                          downloadFile(
                            customResource.file.downloadUrl,
                            customResource.file.fileName
                          ),
                      },
                      {
                        label: `Delete`,
                        icon: <TrashIcon className="w-4 h-4" />,
                        onClick: () => onDelete(customResource.file.path),
                      },
                    ]}
                  />
                </div>
              </div>
            </li>
          );
        })}
        <li
          key="add-resource"
          className="flex w-full h-full rounded-lg items-center justify-between"
        >
          <button
            type="button"
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center hover:border-gray-400 focus:outline-none"
            onClick={() => setIsAddResourceModalOpen(true)}
          >
            <span className="mt-2 block text-sm font-semibold text-gray-500">
              Add resource
            </span>
          </button>
        </li>
      </ul>

      {isAddResourceModalOpen && (
        <ContentAddResourceModal
          open={isAddResourceModalOpen}
          onClose={() => setIsAddResourceModalOpen(false)}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

ContentManagementCustomResourcesList.propTypes = {};

export default ContentManagementCustomResourcesList;
