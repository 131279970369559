import React, { useState } from 'react';

import AddEditCoverageModal from 'components/AddEditCoverageModal';
import Button from 'components/buttons/Button';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useRecordCoverage from 'routes/Inbox2/context/hooks/useRecordCoverage';

import CoverageExtraFields from './CoverageExtraFields';

const AddCoverageButton = () => {
  const [showModal, setShowModal] = useState(false);
  const { companyData } = useCompanyData();
  const recordCoverage = useRecordCoverage(companyData.uid, 'coverages');
  return (
    <>
      <Button onClick={() => setShowModal(true)} type="primary">
        Add Coverage
      </Button>
      {showModal ? (
        <AddEditCoverageModal
          onCancel={() => setShowModal(false)}
          onSubmit={recordCoverage}
          ExtraFields={CoverageExtraFields}
        />
      ) : null}
    </>
  );
};

export default AddCoverageButton;
