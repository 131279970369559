import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ImpersonateDropdown from '../ImpersonateDropdown';
import NavbarAccount from '../components/NavbarAccount';
import NavbarMessages from '../components/NavbarInbox/NavbarMessages';
import NavbarLinks from '../components/NavbarLinks/NavbarLinks';

import NavbarMobileHeader from './NavbarMobileHeader';
import NavbarMobileTransition from './NavbarMobileTransition';

const NavbarMobile = ({
  companySlug = '',
  claimsAdmin = false,
  claimsAgencyId = false,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <>
      <NavbarMobileHeader
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      <NavbarMobileTransition
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      >
        <div className="flex grow flex-col overflow-y-auto bg-white px-6 py-4">
          {(claimsAdmin || claimsAgencyId) && <ImpersonateDropdown />}
          <nav className="flex flex-1 flex-col mt-4">
            <ul
              className="flex flex-1 flex-col gap-y-7"
              onClickCapture={() => setSidebarOpen(false)}
            >
              <li>
                <NavbarLinks slug={companySlug} />
              </li>
              <li className="-mx-2 space-y-2">
                <NavbarMessages />
              </li>
              <li className="mt-auto pb-11">
                <NavbarAccount />
              </li>
            </ul>
          </nav>
        </div>
      </NavbarMobileTransition>
    </>
  );
};

NavbarMobile.propTypes = {
  companySlug: PropTypes.string,
  claimsAdmin: PropTypes.bool,
  claimsAgencyId: PropTypes.bool,
};

export default NavbarMobile;
