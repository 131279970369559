const FIREBASE_ANALYTICS = 'FirebaseAnalytics';
const SEGMENT = 'Segment';

const eventServiceMap = {
  // DEFAULT
  defaultAdapter: FIREBASE_ANALYTICS,

  // SEGMENT
  Login: SEGMENT,
  'User Signed Up': SEGMENT,

  'Thread Created': SEGMENT,

  'Invite Sent': SEGMENT,
  'Invite Claimed': SEGMENT,

  'Journalist Details Opened': SEGMENT,

  'Document Created': SEGMENT,
  'Document Edited': SEGMENT,
  'Resource Completed': SEGMENT,

  'Warm Intro Generated': SEGMENT,

  'Message Validated': SEGMENT,
  'Validated Message Approved': SEGMENT,
  'Message Template Selected': SEGMENT,
  'Message Preview Sent': SEGMENT,
  'Message Scheduled': SEGMENT,

  'Opportunities Hint Opened': SEGMENT,

  // FIREBASE ANALYTICS
};

export default eventServiceMap;
