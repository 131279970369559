import PropTypes from 'prop-types';
import React from 'react';

import Card from 'components/Card';

import AccountFormGoogleProvider from './AccountFormGoogleProvider';
import AccountFormPasswordProvider from './AccountFormPasswordProvider';

const AccountForm = ({ user = {} }) => {
  const googleProvider = user.providerData.find(
    (provider) => provider.providerId === 'google.com'
  );
  const passwordProvider = user.providerData.find(
    (provider) => provider.providerId === 'password'
  );

  return (
    <Card title="Account data">
      <div className="w-full flex flex-col gap-3">
        <AccountFormPasswordProvider
          user={user}
          passwordProvider={passwordProvider}
        />
        <AccountFormGoogleProvider
          user={user}
          googleProvider={googleProvider}
          hasPasswordProvider={!!passwordProvider}
        />
      </div>
    </Card>
  );
};

AccountForm.propTypes = {
  user: PropTypes.object.isRequired,
};

export default AccountForm;
