import { useCallback, useMemo, useState, useEffect } from 'react';

import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import { useTracking } from 'modules/segment/TrackingProvider';

import useCurrentResourceContext from './useCurrentResourceContext';

const useSendContentFormResource = ({ resource = {} }) => {
  const trackingService = useTracking();
  const { showError } = useNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const [autoSaveStatus, setAutoSaveStatus] = useState(null);
  const { updateResource, submitResource } = useCurrentResourceContext();
  const isSubmitDisabled =
    isLoading || autoSaveStatus === 'saving' || autoSaveStatus === 'idle';
  const {
    handleSubmit,
    register,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });
  const messageError = errors;

  const debouncedAutoSaveDraftAnswer = useMemo(
    () =>
      debounce(async () => {
        setAutoSaveStatus('saving');
        const { questions } = getValues();
        const lastEdited = new Date().toISOString();
        const questionsPayload = resource.questions.map((question) => ({
          id: question.id,
          draftAnswer: questions[question.id]?.answer ?? '',
        }));

        updateResource({
          ...resource,
          lastEdited,
          questions: questionsPayload,
        });
        setAutoSaveStatus('saved');

        setTimeout(() => setAutoSaveStatus(null), 1000);
      }, 1000),
    [getValues, resource, updateResource]
  );

  useEffect(
    () => () => {
      debouncedAutoSaveDraftAnswer.cancel();
    },
    [debouncedAutoSaveDraftAnswer]
  );

  const handleAutoSave = useCallback(() => {
    setAutoSaveStatus('idle');
    debouncedAutoSaveDraftAnswer();
  }, [debouncedAutoSaveDraftAnswer]);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const { questions } = formData;
    const lastEdited = new Date().toISOString();
    const questionsWithIds = resource.questions.map((question) => {
      const draftAnswer = questions[question.id]?.answer ?? null;
      return { id: question.id, draftAnswer };
    });
    trackingService.track('Resource Completed');

    const resourceDoc = {
      lastEdited,
      questions: questionsWithIds,
    };

    try {
      await submitResource(resourceDoc);
    } catch {
      showError({
        title: 'Whoops! Something went wrong',
        message: "We couldn't save your form, please try again",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleSubmit,
    onSubmit,
    handleAutoSave,
    register,
    messageError,
    reset,
    control,
    autoSaveStatus,
    isSubmitDisabled,
  };
};

export default useSendContentFormResource;
