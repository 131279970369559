import React from 'react';

import classNames from 'classnames';

import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

import journalistResponseOptions from '../../constants/journalistResponseOptions';

const InboxMessageJournalistResponse = () => {
  const { currentThread, threadDetails } = useInboxContext();
  const { descriptionHtml, query } = threadDetails.data.opportunity || {};

  if (!currentThread) {
    return null;
  }

  const { lastJournalistResponse, journalist } = currentThread;

  if (
    !lastJournalistResponse ||
    !journalistResponseOptions[lastJournalistResponse]
  ) {
    return null;
  }

  const { Icon, responseText, bgColor, textColor } =
    journalistResponseOptions[lastJournalistResponse];

  return (
    <div
      className={classNames(bgColor, 'w-full', {
        'py-3': descriptionHtml || query,
      })}
      data-test-id="inboxMessage-journalistFeedback"
    >
      <div className="max-w-7xl py-3 px-3">
        <div className="flex flex-wrap justify-between">
          <div className="flex">
            <span className="flex p-2">
              <Icon className={`h-6 w-6 ${textColor}`} aria-hidden="true" />
            </span>
            <p className={`ml-3 text-sm  ${textColor}`}>
              {journalist?.name || 'Journalist'} has shared some feedback:
              <br />
              <span className="font-medium">&ldquo;{responseText}&rdquo;</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InboxMessageJournalistResponse;
