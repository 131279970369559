import PropTypes from 'prop-types';
import React from 'react';

import { PlusIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import { CREATE_NEW_COMPANY } from 'constants/paths';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import { useTracking } from 'modules/segment/TrackingProvider';

const CreateCompanyButton = ({ eventType = '', eventProperties = {} }) => {
  const navigate = useNavigate();
  const { claimsAgencyId: agencyId } = useUserData();
  const { showSuccess } = useNotifications();
  const trackingService = useTracking();

  const handleAddCompanyClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newCompanyUrl = `${CREATE_NEW_COMPANY}?agencyId=${agencyId}`;
    navigate(newCompanyUrl);
    showSuccess({
      message: (
        <span>
          Redirecting to <strong>Create Company</strong> page.
        </span>
      ),
      Icon: PlusIcon,
    });
    trackingService.track(eventType, eventProperties);
    return false;
  };

  return (
    <button
      type="button"
      className="p-2 border border-gray-300 rounded-full hover:bg-gray-50 focus:outline-none focus:ring"
      onClick={handleAddCompanyClick}
    >
      <PlusIcon className="w-4 h-4 text-teal-500" />
    </button>
  );
};

CreateCompanyButton.propTypes = {
  eventType: PropTypes.string,
  eventProperties: PropTypes.object,
};

export default CreateCompanyButton;
