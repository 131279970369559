import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import Label from 'components/form/Label';

import InputFieldMultiLevelDropdown from './InputFieldMultiLevelDropdown';

const createAuthorOptions = (options) => {
  const members = options.map((member) => ({
    value: member.id,
    label: member.name,
  }));

  return members;
};

const ContentManagementResponseFormEditorAuthorDropdown = ({
  founders = [],
  author = '',
  onChange = noop,
}) => {
  const options = createAuthorOptions(founders);

  return (
    <div>
      <Label htmlFor="author">Author</Label>
      <small className="mt-1 text-gray-400 text-xs">
        {` We'll make sure the Generated Content is based on this
      founder's profile`}
      </small>
      <div className="flex w-full mt-1 space-x-2">
        <InputFieldMultiLevelDropdown
          name="author"
          testId="contentManagement-documentsTab-responseFormAuthodMultiLevelDropdown"
          options={{ others: options }}
          value={author}
          onChange={async (newAuthorId) => {
            const selectedAuthor = options.find(
              (option) => option.value === newAuthorId
            );
            onChange(selectedAuthor);
          }}
          wrapperClassName="w-fit"
          buttonClassName="w-60"
        />
      </div>

      {!founders.length ? (
        <p className="mt-2 text-red-600 text-xs">
          {`You haven't added any team members to your Press Page yet. Please add your
        first team memeber to attach it here.`}
        </p>
      ) : null}
    </div>
  );
};

ContentManagementResponseFormEditorAuthorDropdown.propTypes = {
  founders: PropTypes.array,
  author: PropTypes.string,
  onChange: PropTypes.func,
};

export default ContentManagementResponseFormEditorAuthorDropdown;
