import { useQuery } from 'react-query';
import { useAuth, useUser } from 'reactfire';

const fetchUserRoles = async (uid, auth) => {
  const BASE_URL = process.env.REACT_APP_UCD_URL;
  const endpoint = `/users/me/roles`;
  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch user roles: ${error.message}`);
  }
};

const useUserRoles = () => {
  const auth = useAuth();
  const { data: userDataAuth } = useUser();

  const {
    data: roles,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ['userRoles', userDataAuth?.uid],
    () => fetchUserRoles(userDataAuth?.uid, auth),
    {
      enabled: !!userDataAuth?.uid,
    }
  );

  const adminRole = roles?.find((role) => role.role === 'ADMIN');
  const agencyOwnerRole = roles?.find((role) => role.role === 'AGENCY_OWNER');
  const agencyMemberRole = roles?.find((role) => role.role === 'AGENCY_MEMBER');

  const claimsAdmin = !!adminRole;
  const claimsAgencyId =
    agencyOwnerRole?.agencyId || agencyMemberRole?.agencyId || null;

  return {
    roles: roles || [],
    claimsAdmin,
    claimsAgencyId,
    ownCompanySlug:
      roles?.find((role) => role.role === 'COMPANY_OWNER')?.companySlug || null,
    isLoading,
    error,
    refetch,
  };
};

export default useUserRoles;
