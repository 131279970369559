import { useCallback, useMemo, useRef } from 'react';

import debounce from 'lodash/debounce';
import noop from 'lodash/noop';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import { useTracking } from 'modules/segment/TrackingProvider';

import useCurrentDocumentContext from './hooks/useCurrentDocumentContext';

const ContentDocumentResponseFormDraftProvider = ({ children = null }) => {
  const trackingService = useTracking();
  const { companyData } = useCompanyData();

  const { currentDocument: document, updateDocument } =
    useCurrentDocumentContext();

  const isInitialLoad = useRef(false);

  const debouncedSaveDraft = useMemo(
    () =>
      debounce(async (newDoc) => {
        await updateDocument({
          id: document.id,
          title: newDoc.title,
          audiences: newDoc.audiences,
          threadData: newDoc.threadData,
          type: newDoc.type,
          author: newDoc.author,
          draft: {
            ...newDoc.draft,
            lastEdited: new Date(),
          },
        });

        trackingService.track('Document Edited', {
          documentId: document.id,
          type: document.type || 'blank',
          companyId: companyData.uid,
        });
      }, 1000),
    [
      companyData.uid,
      document.id,
      document.type,
      trackingService,
      updateDocument,
    ]
  );

  const saveDocument = useCallback(
    (newDoc = {}, cb = noop) => {
      const mergedDoc = { ...document, ...newDoc };
      if (!isInitialLoad.current) {
        debouncedSaveDraft(mergedDoc);
        cb();
      }
      isInitialLoad.current = false;
      return mergedDoc;
    },
    [debouncedSaveDraft, document]
  );

  return children({
    saveDocument,
  });
};

export default ContentDocumentResponseFormDraftProvider;
