import { useState } from 'react';

import { addDoc, collection, onSnapshot } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import {
  CHECKOUT_SESSION_COLLECTION,
  CUSTOMERS_COLLECTION,
} from 'constants/firebasePaths';
import { useTracking } from 'modules/segment/TrackingProvider';

import useQuery from './useQuery';

// redirects user to a specific price page on Stripe
const useRedirectToStripe = () => {
  const firestore = useFirestore();
  const trackingService = useTracking();
  const query = useQuery();
  const promoCode = query.get('promoCode');
  const [isRedirecting, setIsRedirecting] = useState(false);

  const redirectToStripe = async ({
    companyId,
    userId,
    slug,
    tierId,
    priceId,
    successUrl,
    cancelUrl,
  }) => {
    trackingService.track('payment_attempt', {
      company_uid: companyId,
      company_slug: slug,
      stripe_priceId: priceId,
    });

    const checkoutSession = {
      price: priceId,
      allow_promotion_codes: true,
      automatic_tax: true,
      success_url: successUrl,
      cancel_url: cancelUrl,
      tax_id_collection: {
        enabled: true,
      },
      metadata: {
        companyId,
        tierId,
      },
    };

    if (promoCode) {
      checkoutSession.promotion_code = promoCode;
    }

    setIsRedirecting(true);

    const col = collection(
      firestore,
      CUSTOMERS_COLLECTION,
      userId,
      CHECKOUT_SESSION_COLLECTION
    );
    const docRef = await addDoc(col, checkoutSession);

    // Wait for the CheckoutSession to get attached by the extension
    onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        // TODO-LOG: send this to logger
        // console.error(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  return { redirectToStripe, isRedirecting };
};

export default useRedirectToStripe;
