import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import noop from 'lodash/noop';
import { Controller } from 'react-hook-form';

import Spinner from 'components/Spinner';
import Label from 'components/form/Label';
import InputFieldTextDocumentEditor from 'components/form/inputFields/InputFieldTextDocumentEditor';
import { contentManagementTypes } from 'constants/contentManagementTypes';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';
import useAddSavedDraftNotification from 'hooks/useAddSavedDraftNotification';
import useCurrentDocumentContext from 'routes/ContentManagement/hooks/useCurrentDocumentContext';
import useDocumentState from 'routes/ContentManagement/hooks/useDocumentState';

import ContentManagementDocumentFormTitle from '../ContentManagementDocumentFormTitle';
import ContentManagementResponseFormAudiences from '../ContentManagementResponseFormAudiences';
import ContentManagementResponseFormEditorAuthorDropdown from '../ContentManagementResponseFormEditorAuthorDropdown';
import ContentManagementResponseFormResources from '../ContentManagementResponseFormResources';
import ContentManagementResponseFormThread from '../ContentManagementResponseFormThread';
import DocumentTag from '../DocumentTag';
import GenerateContentWarning from '../GenerateContentWarning';

import ContentGenerateArticleOutline from './Article/Fields/ContentGenerateArticleOutline';
import ContentManagementDocumentResponseFormResetQnAModal from './Article/Fields/ContentManagementDocumentResponseFormResetQnAModal';
import ContentManagementResponseFormArticleIdea from './Article/Fields/ContentManagementResponseFormArticleIdea';
import ContentManagementResponseFormQnA from './Article/Fields/ContentManagementResponseFormQnA';

const ContentDocumentArticle = ({
  control,
  reset = noop,
  messageError = [],
  saveDocumentDebounced = noop,
}) => {
  const containerRef = useRef();
  const editorRef = useRef(null);

  const { currentDocument, updateDocument, updateDocumentThreadData } =
    useCurrentDocumentContext();

  const { companyData } = useCompanyData();
  const addSavedDraftNotification = useAddSavedDraftNotification(containerRef);

  const [editorContent, setEditorContent] = useState(
    currentDocument?.draft?.content || ''
  );
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);

  const [pendingChange, setPendingChange] = useState(null);

  const { data: resourcesList, loading: isContentLoading } = useAPIRequest({
    service: 'MOD',
    endpoint: `/resources/${companyData.uid}`,
    method: 'GET',
    initialData: [],
  });

  const { loading: isLoading } = useAPIRequest({
    service: 'MOD',
    endpoint: `/ai/generate-document-content/${companyData.uid}/${currentDocument.id}`,
    method: 'POST',
  });

  const { data: teamMembers, loading: isTeamMembersLoading } = useAPIRequest({
    endpoint: `/companies/${companyData?.uid}/founders`,
    service: 'UCD',
    initialData: [],
  });

  const currentDocumentQuestions = useMemo(
    () => currentDocument?.questions ?? [],
    [currentDocument?.questions]
  );

  const doesQnAExist = useMemo(
    () => currentDocumentQuestions.length > 0,
    [currentDocumentQuestions]
  );

  const documentAudiences = useMemo(
    () => currentDocument?.audiences ?? [],
    [currentDocument]
  );

  const documentCustomResources = useMemo(
    () => currentDocument?.customResources ?? [],
    [currentDocument]
  );

  const contentIdeation = resourcesList.find(
    (doc) => doc.type === 'content-ideation-qa'
  );
  const { isContentIdeationCompleted, isAIGenerationAvailable } =
    useDocumentState({ contentIdeation, newDocument: currentDocument });

  const showGenerateContentWarning =
    isAIGenerationAvailable && !isContentIdeationCompleted;
  const shouldShowContentWarning =
    !isContentLoading && showGenerateContentWarning;

  const areAllQuestionsAnswered = useMemo(
    () =>
      currentDocumentQuestions.length > 0 &&
      !currentDocumentQuestions.some((q) => q.answer === ''),
    [currentDocumentQuestions]
  );

  const handleFieldChangeWithReset = ({ updateCb, updateCbReset }) => {
    if (
      currentDocument?.type === contentManagementTypes.ARTICLE &&
      doesQnAExist
    ) {
      setIsResetConfirmationOpen(true);
      setPendingChange(() => () => updateCbReset());
    } else {
      updateCb();
    }
  };

  const confirmChange = () => {
    if (pendingChange) {
      pendingChange();
      setPendingChange(null);
    }
    setIsResetConfirmationOpen(false);
  };

  const cancelChange = () => {
    setIsResetConfirmationOpen(false);
    setPendingChange(null);
  };

  useEffect(() => {
    if (currentDocument) {
      reset({
        document: {
          title: currentDocument?.title || '',
          type: currentDocument?.type || '',
          audiences: currentDocument?.audiences || [],
          customResources: currentDocument?.customResources || [],
          idea: currentDocument?.idea || [],
          author: currentDocument?.author || '',
          draft: currentDocument?.draft || {
            content: '',
            plainText: '',
          },
        },
      });
    }
  }, [currentDocument, reset]);

  return (
    <>
      <div className="relative">
        <div
          data-test-id="contentManagement-responseEditor"
          className="relative flex flex-col gap-6"
          ref={containerRef}
        >
          <Controller
            name="document"
            control={control}
            defaultValue={{
              title: currentDocument?.title || '',
              type: currentDocument?.type || '',
              audiences: currentDocument?.audiences || [],
              customResources: currentDocument?.customResources || [],
              idea: currentDocument?.idea || [],
              author: currentDocument?.author || '',
              threadData: currentDocument?.threadData || {},
              draft: currentDocument?.draft?.content || {
                content: '',
                plainText: '',
              },
            }}
            rules={{
              validate: {
                requiredTitle: (value) =>
                  value.title !== '' || 'Please enter a title',
              },
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <div>
                  <DocumentTag type={currentDocument.type} />
                </div>
                <ContentManagementDocumentFormTitle
                  value={value.title}
                  control={control}
                  messageError={{ title: messageError.document }}
                  onChange={(e) => {
                    onChange({ ...value, title: e.target.value });
                    saveDocumentDebounced(
                      {
                        title: e.target.value,
                        type: currentDocument?.type,
                        audiences: currentDocument?.audiences,
                        author: currentDocument?.author,
                        draft: currentDocument?.draft,
                      },
                      addSavedDraftNotification
                    );
                  }}
                />

                {!isTeamMembersLoading && currentDocument && (
                  <ContentManagementResponseFormEditorAuthorDropdown
                    founders={teamMembers}
                    author={
                      currentDocument?.author || 'Select a team member...'
                    }
                    onChange={(selectedAuthor) => {
                      handleFieldChangeWithReset({
                        updateCb: () =>
                          updateDocument({
                            author: selectedAuthor.label,
                          }),
                        updateCbReset: () =>
                          updateDocument({
                            author: selectedAuthor.label,
                            questions: [],
                          }),
                      });
                    }}
                  />
                )}

                {currentDocument && (
                  <ContentManagementResponseFormAudiences
                    audiences={documentAudiences}
                    onChange={(newAudiences) => {
                      handleFieldChangeWithReset({
                        updateCb: () =>
                          updateDocument({
                            audiences: newAudiences,
                          }),
                        updateCbReset: () =>
                          updateDocument({
                            audiences: newAudiences,
                            questions: [],
                          }),
                      });
                    }}
                  />
                )}

                {currentDocument && (
                  <ContentManagementResponseFormResources
                    resources={documentCustomResources}
                    onChange={(newResources) => {
                      handleFieldChangeWithReset({
                        updateCb: () =>
                          updateDocument({
                            customResources: newResources,
                          }),
                        updateCbReset: () =>
                          updateDocument({
                            customResources: newResources,
                            questions: [],
                          }),
                      });
                    }}
                  />
                )}

                <ContentManagementResponseFormThread
                  thread={currentDocument?.threadData}
                  onSelectThread={(threadData) => {
                    handleFieldChangeWithReset({
                      updateCb: () => {
                        updateDocumentThreadData({
                          threadId: threadData.id,
                          documentId: currentDocument.id,
                        });
                      },
                      updateCbReset: async () => {
                        await updateDocumentThreadData({
                          threadId: threadData.id,
                          documentId: currentDocument.id,
                          resetQuestions: true,
                        });
                      },
                    });
                  }}
                  onRemove={() => {
                    handleFieldChangeWithReset({
                      updateCb: () =>
                        updateDocument({
                          threadData: {},
                        }),
                      updateCbReset: () =>
                        updateDocument({
                          threadData: {},
                          questions: [],
                        }),
                    });
                  }}
                />

                {currentDocument && (
                  <ContentManagementResponseFormArticleIdea
                    idea={currentDocument?.idea}
                    onEdit={async (newIdea, setIsEditIdeaModalOpen) => {
                      handleFieldChangeWithReset({
                        updateCb: () =>
                          updateDocument({
                            idea: newIdea,
                          }),
                        updateCbReset: () =>
                          updateDocument({
                            idea: newIdea,
                            questions: [],
                          }),
                      });
                      setIsEditIdeaModalOpen(false);
                    }}
                  />
                )}

                {shouldShowContentWarning && (
                  <GenerateContentWarning
                    content="a Q&A and an Article Outline"
                    companyData={companyData}
                  />
                )}

                {currentDocument && (
                  <ContentManagementResponseFormQnA
                    contentIdeation={contentIdeation}
                    onSubmit={(newQnA) => {
                      updateDocument({
                        questions: newQnA,
                      });
                    }}
                  />
                )}

                {isResetConfirmationOpen && (
                  <ContentManagementDocumentResponseFormResetQnAModal
                    open={isResetConfirmationOpen}
                    title="Confirm Reset"
                    content="Changing these details will reset the Q&A section. Do you want to continue?"
                    onConfirm={confirmChange}
                    onCancel={cancelChange}
                  />
                )}

                <div>
                  <Label htmlFor="draft" className="mb-2">
                    Content
                  </Label>

                  <ContentGenerateArticleOutline
                    areAllQuestionsAnswered={areAllQuestionsAnswered}
                    shouldShowContentWarning={shouldShowContentWarning}
                    editorRef={editorRef}
                    setEditorContent={setEditorContent}
                  />

                  <InputFieldTextDocumentEditor
                    name="draft"
                    editorRef={editorRef}
                    initialValue={editorContent}
                    onChange={(content, plainText) => {
                      saveDocumentDebounced(
                        {
                          draft: { content, plainText },
                        },
                        addSavedDraftNotification
                      );
                    }}
                    testId="contentManagement-responseFormTextInput"
                    id="contentManagement-responseFormTextInput"
                  />
                </div>
              </>
            )}
          />
        </div>
      </div>
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-100 bg-opacity-50 flex justify-center items-center z-50">
          <Spinner />
        </div>
      )}
    </>
  );
};

ContentDocumentArticle.propTypes = {
  control: PropTypes.object,
  messageError: PropTypes.object,
  saveDocumentDebounced: PropTypes.func,
  reset: PropTypes.func,
};

export default ContentDocumentArticle;
