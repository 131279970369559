import { useEffect } from 'react';

import { useTracking } from 'modules/segment/TrackingProvider';

import { useInboxContext } from '../context/InboxContext';

const useTrackIsExpanded = ({
  message = {},
  isExpanded = false,
  currentThread = {},
}) => {
  const { readMessage } = useInboxContext();
  const trackingService = useTracking();

  useEffect(() => {
    if (isExpanded) {
      trackingService.track('inbox_interaction', {
        action: 'message_open',
        inbox_id: currentThread.companyId,
        inbox_hasUnreadMessages: !!currentThread.hasUnreadMessages,
        inbox_isJournalist: !!message.isJournalist,
      });
    }
  }, [
    isExpanded,
    message.isJournalist,
    currentThread.hasUnreadMessages,
    trackingService,
    currentThread.companyId,
  ]);

  useEffect(() => {
    if (isExpanded && message.isFromJournalist && !message.isReadByUser) {
      readMessage(message.id);
    }
  }, [
    isExpanded,
    message.id,
    message.isFromJournalist,
    message.isReadByUser,
    readMessage,
  ]);
};

export default useTrackIsExpanded;
