import React, { Fragment, useReducer } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import delay from 'lodash/delay';
import { Helmet } from 'react-helmet';

import PaginationNav from 'components/PaginationNav';
import SearchBar from 'components/SearchBar';
import Spinner from 'components/Spinner';
import { k, M } from 'constants/math';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useCompaniesBySearch from 'hooks/useCompaniesBySearch';
import useCompanySearchFilters from 'hooks/useCompanySearchFilters';
import useCompanySuggestionsBySearch from 'hooks/useCompanySuggestionsBySearch';
import getPageTitle from 'utils/getPageTitle';

import JournalistClaimsError from './JournalistClaimsError';
import JournalistFilters from './JournalistFilters/JournalistFilters';
import JournalistResults from './JournalistResults/JournalistResults';
import JournalistSearchError from './JournalistSearchError';
import { actions, initialState, reducer } from './journalistReducer';

const FUNDING_LEVELS = [0, 5 * k, 1 * M, 2 * M, 5 * M, 10 * M, 50 * M];

// Renders the page which hosts the company search for jornalists
const JournalistPage = () => {
  const user = useUserData();
  const claimsJournalist = user?.claimsJournalist;
  const isAdmin = user?.claimsAdmin;

  const [journalistState, dispatch] = useReducer(
    reducer,
    initialState,
    (state) => ({ ...state, maxFundingIndex: FUNDING_LEVELS.length - 1 })
  );

  const {
    showSuggestions,
    selectedTopics,
    selectedStages,
    selectedContentTypes,
    minFundingIndex,
    maxFundingIndex,
    currentPage,
    searchQuery,
    searchString,
    mobileFiltersOpen,
  } = journalistState;

  const companySearchFilters = useCompanySearchFilters(user?.token);

  const {
    companies,
    meta,
    loading: resultsLoading,
    error: resultsError,
  } = useCompaniesBySearch({
    searchQuery,
    minFunding: FUNDING_LEVELS[minFundingIndex],
    maxFunding: FUNDING_LEVELS[maxFundingIndex],
    fundingLevels: FUNDING_LEVELS,
    selectedTopics,
    selectedStages,
    selectedContentTypes,
    idToken: user?.token,
    page: currentPage,
  });

  const suggestions = useCompanySuggestionsBySearch(searchString, user?.token);

  // ask user to login again
  if (!claimsJournalist && !isAdmin) {
    return <JournalistClaimsError />;
  }

  if (companySearchFilters.error || resultsError) {
    return <JournalistSearchError />;
  }

  if (companySearchFilters.loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-white">
      <Helmet>
        <title>{getPageTitle('Journalist dashboard')}</title>
      </Helmet>
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={() => dispatch({ type: actions.HIDE_MOBILE_FILTERS })}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() =>
                        dispatch({ type: actions.HIDE_MOBILE_FILTERS })
                      }
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <JournalistFilters
                    className="mt-4 border-t border-gray-200 px-4"
                    companySearchFilters={companySearchFilters}
                    journalistState={journalistState}
                    fundingLevels={FUNDING_LEVELS}
                    dispatch={dispatch}
                  />
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-baseline justify-between border-b border-gray-200 pt-6 pb-6">
            <div className="w-full">
              <SearchBar
                value={searchString}
                onChange={(search) =>
                  dispatch({ type: actions.SET_SEARCH_STRING, payload: search })
                }
                suggestions={suggestions.map((s) => s.suggestion)}
                showSuggestions={showSuggestions}
                onSelect={(query) =>
                  dispatch({ type: actions.SET_SEARCH_QUERY, payload: query })
                }
                onBlur={() =>
                  delay(() => dispatch({ type: actions.HIDE_SUGGESTIONS }), 300)
                }
                onFocus={() => dispatch({ type: actions.SHOW_SUGGESTIONS })}
              />
            </div>
          </div>
          <div className="text-right mt-2">
            <button
              type="button"
              className="p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
              onClick={() => dispatch({ type: actions.SHOW_MOBILE_FILTERS })}
            >
              <FunnelIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <section aria-labelledby="products-heading" className="pb-24">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              <JournalistFilters
                className="hidden lg:block"
                companySearchFilters={companySearchFilters}
                journalistState={journalistState}
                fundingLevels={FUNDING_LEVELS}
                dispatch={dispatch}
              />
              <div className="lg:col-span-3">
                <JournalistResults
                  results={companies}
                  loading={resultsLoading}
                  totalResults={meta?.page?.total_results}
                />
              </div>
            </div>
            <PaginationNav
              loading={resultsLoading}
              currentPage={meta?.page.current}
              setPage={(page) => {
                dispatch({ type: actions.SET_CURRENT_PAGE, payload: page });
                window.scrollTo(0, 0);
              }}
              maxPages={meta?.page.total_pages}
            />
          </section>
        </main>
      </div>
    </div>
  );
};

export default JournalistPage;
