import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const MOD_URL = process.env.REACT_APP_MOD_URL;

const useOpportunity = (id) => {
  const auth = useAuth();
  return useQuery(
    ['opportunities', id],
    async () => {
      const jwt = await auth.currentUser?.getIdToken();
      const response = await fetch(`${MOD_URL}/opportunities/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });
      if (!response?.ok) {
        throw new Error('Failed fetching opportunity');
      }
      return response.json();
    },
    {
      enabled: !!id,
    }
  );
};

export default useOpportunity;
