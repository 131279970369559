import PropTypes from 'prop-types';
import React from 'react';

import {
  NewspaperIcon,
  DocumentTextIcon,
  ClipboardDocumentListIcon,
  UserGroupIcon,
  ChartPieIcon,
  CalendarIcon,
} from '@heroicons/react/24/outline';
import { generatePath } from 'react-router-dom';

import { tabNames as contentTabNames } from 'constants/contentTabs';
import featureMap, { featureNames } from 'constants/featureMap';
import { tabNames as journalistsTabNames } from 'constants/journalistsTabs';
import {
  COMPANY_PATH,
  CONTENT_MANAGEMENT_PATH,
  REPORTS_PATH,
  OPPORTUNITIES_PATH,
  SWEETHEART_PATH,
  PLANNING_PATH,
} from 'constants/paths';
import { tabNames as planningTabs } from 'constants/planningTabs';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useCompanyTier from 'hooks/useCompanyTier';

import NavbarLinksLink from './NavbarLinksLink';
import NavbarLinksNewBadge from './NavbarLinksNewBadge';
import NavbarLinksUpgradeBadge from './NavbarLinksUpgradeBadge';

const NavbarLinks = ({ slug = null }) => {
  const { companyData } = useCompanyData();

  const { data: tier } = useCompanyTier(companyData?.uid);

  const hasUpgradeBadge = (featureName) =>
    tier && !featureMap[featureName].includes(tier.id);

  return (
    <ul className="-mx-2 space-y-1">
      <NavbarLinksLink
        path={generatePath(PLANNING_PATH, {
          id: slug,
          tab: planningTabs.TASKS,
        })}
        Badge={<NavbarLinksNewBadge />}
        label="Planning"
        testId="planning"
      >
        <CalendarIcon className="h-6 w-6 text-gray-400" />
      </NavbarLinksLink>
      <NavbarLinksLink
        path={generatePath(OPPORTUNITIES_PATH, { id: slug })}
        label="Opportunities"
        testId="aside-opportunities"
        Badge={
          hasUpgradeBadge(featureNames.OPPORTUNITIES) ? (
            <NavbarLinksUpgradeBadge />
          ) : null
        }
      >
        <NewspaperIcon className="h-6 w-6 text-gray-400" />
      </NavbarLinksLink>

      <NavbarLinksLink
        path={generatePath(SWEETHEART_PATH, {
          id: slug,
          tab: journalistsTabNames.LISTS,
        })}
        label="Journalists"
        testId="aside-sweetheart"
        Badge={
          hasUpgradeBadge(featureNames.SWEETHEART_LIST) ? (
            <NavbarLinksUpgradeBadge />
          ) : null
        }
      >
        <UserGroupIcon className="h-6 w-6 text-gray-400" />
      </NavbarLinksLink>
      <NavbarLinksLink
        path={generatePath(CONTENT_MANAGEMENT_PATH, {
          id: slug,
          tab: contentTabNames.DOCUMENTS,
        })}
        label="Content"
        testId="aside-content"
        Badge={
          hasUpgradeBadge(featureNames.CONTENT_MANAGEMENT) ? (
            <NavbarLinksUpgradeBadge />
          ) : null
        }
      >
        <DocumentTextIcon className="h-6 w-6 text-gray-400" />
      </NavbarLinksLink>
      <NavbarLinksLink
        path={generatePath(COMPANY_PATH, { id: slug })}
        label="Press Kit"
        testId="aside-pressPage"
        intercomId="aside-pressPage"
        Badge={
          hasUpgradeBadge(featureNames.PRESS_PAGE) ? (
            <NavbarLinksUpgradeBadge />
          ) : null
        }
      >
        <ClipboardDocumentListIcon className="h-6 w-6 text-gray-400" />
      </NavbarLinksLink>
      <NavbarLinksLink
        path={generatePath(REPORTS_PATH, { id: slug })}
        label="Reports"
        testId="aside-dashboard"
        Badge={
          hasUpgradeBadge(featureNames.REPORTS) ? (
            <NavbarLinksUpgradeBadge />
          ) : null
        }
      >
        <ChartPieIcon className="h-6 w-6 text-gray-400" />
      </NavbarLinksLink>
    </ul>
  );
};

NavbarLinks.propTypes = {
  slug: PropTypes.string,
};

export default NavbarLinks;
