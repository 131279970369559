import PropTypes from 'prop-types';
import React, { useState } from 'react';

import classNames from 'classnames';

import JournalistTeaser from 'components/JournalistTeaser';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import OpportunityTags from 'components/opportunities/opportunity/OpportunityTags';
import OpportunityTimeLeft from 'components/opportunities/opportunity/OpportunityTimeLeft';
import Tag from 'components/tags/Tag';
import useAdminOpportunities from 'hooks/useAdminOpportunities';
import useCompanies from 'hooks/useCompaniesV2';
import useDeleteOpportunity from 'hooks/useDeleteOpportunity';
import useNotifyCompanyAboutOpportunity from 'hooks/useNotifyCompanyAboutOpportunity';
import useUpsertOpportunity from 'hooks/useUpsertOpportunity';

import AdminOpportunitiesCompanySelectModal from './AdminOpportunitiesCompanySelectModal';
import AdminOpportunitiesCompanyTag from './AdminOpportunitiesCompanyTag';

const AdminOpportunitiesRow = ({ opportunity = {} }) => {
  const { deleted, deadline, title, description } = opportunity;
  const { showSuccess } = useNotifications();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: companies } = useCompanies();
  const [expanded, setExpanded] = useState(false);
  const { mutateAsync: deleteOpportunity, isLoading: deleteLoading } =
    useDeleteOpportunity();
  const { mutateAsync: notifyCompany } = useNotifyCompanyAboutOpportunity();
  const { mutateAsync: updateOpportunity } = useUpsertOpportunity();
  const { refetch } = useAdminOpportunities();

  return (
    <tr>
      <td
        className={classNames('p-4 align-top', {
          'line-through opacity-50': deleted,
        })}
      >
        <div className="flex flex-col justify-start items-start max-w-prose">
          <div className="flex items-center justify-start w-full flex-wrap">
            <OpportunityTimeLeft
              deadline={deadline}
              standalone
              wrapperClassName="mt-0 mb-2 mr-2"
            />
            {opportunity?.source && (
              <Tag className="mt-0 mb-2">Source: {opportunity?.source}</Tag>
            )}
            <OpportunityTags searchTerms={opportunity.searchTerms} />
          </div>
          {opportunity.journalist && (
            <div className="mt-2">
              <JournalistTeaser journalist={opportunity.journalist} />
            </div>
          )}
          <h3 className="text-2xl font-semibold mt-2">{title}</h3>
          <div
            className={classNames('mt-2 text-sm max-w-full', {
              'line-clamp-3': !expanded,
            })}
          >
            {description}
          </div>

          <button
            type="button"
            className="text-sm text-teal-500"
            onClick={() => setExpanded((prev) => !prev)}
          >
            {expanded ? 'Show less' : 'Show more'}
          </button>
        </div>
      </td>
      <td
        className={classNames('p-4 min-w-[300px] align-top', {
          'opacity-50': deleted,
        })}
      >
        <div className="-mt-1 relative">
          {loading && (
            <div className="absolute inset-0 bg-white opacity-30">
              <Spinner />
            </div>
          )}
          {(companies || [])
            .filter((company) => opportunity.companyIds.includes(company.id))
            .map((company) => (
              <AdminOpportunitiesCompanyTag
                key={company.id}
                onClick={async () => {
                  setLoading(true);
                  await notifyCompany({
                    companyId: company.id,
                    opportunityId: opportunity.id,
                  });
                  // eslint-disable-next-line no-promise-executor-return
                  await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait until search index has setteled
                  await refetch();
                  showSuccess({ message: `${company.name} notified` });
                  setLoading(false);
                }}
                opportunity={opportunity}
                company={company}
              />
            ))}
          {(companies || [])
            .filter((company) =>
              opportunity.targetedCompanies.includes(company.id)
            )
            .map((company) => (
              <AdminOpportunitiesCompanyTag
                key={company.id}
                onClick={async () => {
                  setLoading(true);
                  await notifyCompany({
                    companyId: company.id,
                    opportunityId: opportunity.id,
                  });
                  // eslint-disable-next-line no-promise-executor-return
                  await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait until search index has setteled
                  await refetch();
                  showSuccess({ message: `${company.name} notified` });
                  setLoading(false);
                }}
                opportunity={opportunity}
                company={company}
                isTargeted
              />
            ))}
          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="font-semibold leading-3 inline-flex items-center mr-2 my-1 p-2 py-1 text-xs border rounded-md shadow-sm text-black hover:bg-gray-50 outline-dashed outline-gray-500"
          >
            🎯 Targeting
          </button>
        </div>
      </td>
      <td className="p-4">
        <Button
          className="relative"
          onClick={async () => {
            if (opportunity.deleted) {
              await updateOpportunity({
                id: opportunity.id,
                deleted: false,
              });
              showSuccess({ message: 'Opportunity recovered' });
            } else {
              await deleteOpportunity(opportunity.id);
              showSuccess({ message: 'Opportunity deleted' });
            }
            refetch();
          }}
        >
          {deleteLoading && <Spinner />}
          {opportunity.deleted ? 'Recover' : 'Delete'}
        </Button>
      </td>
      {showModal && (
        <AdminOpportunitiesCompanySelectModal
          selectedCompanies={opportunity.targetedCompanies}
          onClose={() => setShowModal(false)}
          onSelect={async (selectedCompanies) => {
            await updateOpportunity({
              id: opportunity.id,
              targetedCompanies: selectedCompanies,
            });
            await refetch();
            setShowModal(false);
          }}
        />
      )}
    </tr>
  );
};

AdminOpportunitiesRow.propTypes = {
  opportunity: PropTypes.object,
};

export default AdminOpportunitiesRow;
