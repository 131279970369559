import PropTypes from 'prop-types';
import React from 'react';

import { TrashIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

const UserSettingsDangerZoneConfirmationModal = ({
  open = false,
  onConfirm = noop,
  onAbort = noop,
}) => (
  <Modal open={open} onClose={onAbort}>
    <Modal.Icon Icon={TrashIcon} />
    <Modal.Title>Confirm Account Deletion</Modal.Title>
    <Modal.Description>
      Do you really want to delete your account? This process is not reversible.
    </Modal.Description>
    <Modal.RightButtons>
      <Button type="secondary" className="w-fit" onClick={onAbort}>
        Cancel
      </Button>
      <Button type="primary" className="w-fit" onClick={onConfirm}>
        Delete Account
      </Button>
    </Modal.RightButtons>
  </Modal>
);

UserSettingsDangerZoneConfirmationModal.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onAbort: PropTypes.func,
};

export default UserSettingsDangerZoneConfirmationModal;
