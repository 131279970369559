import { useMemo } from 'react';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import useJournalistsThreadActivity from './useJournalistsThreadActivity';

const useJournalistThreadActivity = (journalistId) => {
  const { companyData } = useCompanyData();
  const { data = {} } = useJournalistsThreadActivity(companyData?.uid);
  const journalistThreads = useMemo(
    () =>
      data[journalistId]?.sort((a, b) => {
        const dateA = new Date(a.dateCreated);
        const dateB = new Date(b.dateCreated);

        return dateB - dateA;
      }) || [],
    [data, journalistId]
  );

  return journalistThreads;
};

export default useJournalistThreadActivity;
