import { generatePath, redirect } from 'react-router-dom';

import { SETTINGS_PATH, SETTINGS_PATH as path } from 'constants/paths';
import settingsTabs, { tabNames } from 'constants/settingsTabs';

import SettingsPage from './SettingsPage';

const SettingsRoute = {
  path,
  authRequired: true,
  component: SettingsPage,
  loader: ({ params }) => {
    const { id, tab } = params;

    if (!tab || !settingsTabs.find((t) => t.field === tab)) {
      return redirect(
        generatePath(SETTINGS_PATH, {
          id,
          tab: tabNames.COMPANY,
        })
      );
    }

    return null;
  },
};

export default SettingsRoute;
