/* eslint-disable max-params */
import { useQuery } from 'react-query';
import { matchPath } from 'react-router-dom';
import { useAuth } from 'reactfire';

import { tabNames as contentTabNames } from 'constants/contentTabs';
import { tabNames as journalistsTabNames } from 'constants/journalistsTabs';
import {
  CONTENT_MANAGEMENT_PATH,
  MESSAGES_PATH_FULL,
  OPPORTUNITIES_PATH,
  SWEETHEART_PATH,
} from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import FetchError from 'utils/FetchError';

const isValidUrl = (url) => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const parseDeeplink = (url, uid) => {
  if (!url || !uid || !isValidUrl(url)) {
    return {
      type: 'unknown',
    };
  }

  const { pathname, searchParams } = new URL(url);
  const matchesMessages = matchPath(MESSAGES_PATH_FULL, pathname);
  const matchesJournalistLists = matchPath(SWEETHEART_PATH, pathname);
  const matchesContentManagement = matchPath(CONTENT_MANAGEMENT_PATH, pathname);
  const matchesOpportunities = matchPath(OPPORTUNITIES_PATH, pathname);
  const opportunityId = searchParams.get('id');

  if (matchesMessages && matchesMessages.params.threadId) {
    const BASE_URL = process.env.REACT_APP_MOD_URL;
    const endpoint = `/threads/${matchesMessages.params.threadId}/details`;
    const secondEndpoint = `companies/${uid}/threads/${matchesMessages.params.threadId}`;

    return {
      type: 'Thread',
      fetchUrl: `${BASE_URL}${endpoint}`,
      secondFetchUrl: `${BASE_URL}/${secondEndpoint}`,
      reference: `threads/${matchesMessages.params.threadId}`,
    };
  }

  if (
    matchesJournalistLists &&
    matchesJournalistLists.params.tab === journalistsTabNames.LISTS &&
    matchesJournalistLists.params.listId
  ) {
    const BASE_URL = process.env.REACT_APP_JPD_URL;
    const endpoint = `/companies/${uid}/journalistLists/${matchesJournalistLists.params.listId}`;

    return {
      type: 'Journalist List',
      fetchUrl: `${BASE_URL}${endpoint}`,
      reference: `journalistLists/${matchesJournalistLists.params.listId}`,
    };
  }

  if (
    matchesContentManagement &&
    matchesContentManagement.params.tab === contentTabNames.DOCUMENTS &&
    matchesContentManagement.params.documentId
  ) {
    const BASE_URL = process.env.REACT_APP_MOD_URL;
    const endpoint = `/documents/${uid}/${matchesContentManagement.params.documentId}`;

    return {
      type: 'Document',
      fetchUrl: `${BASE_URL}${endpoint}`,
      reference: `documents/${matchesContentManagement.params.documentId}`,
    };
  }

  if (matchesOpportunities && opportunityId) {
    const BASE_URL = process.env.REACT_APP_MOD_URL;
    const endpoint = `/opportunities/${opportunityId}`;

    return {
      type: 'Opportunity',
      fetchUrl: `${BASE_URL}${endpoint}`,
      reference: `opportunities/${opportunityId}`,
    };
  }

  return {
    type: 'unknown',
  };
};

const fetchResource = async ({ auth, fetchUrl, secondFetchUrl }) => {
  const jwt = await auth.currentUser?.getIdToken();
  let status;

  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };
    const [response, secondResponse] = await Promise.all([
      fetch(fetchUrl, options),
      secondFetchUrl ? fetch(secondFetchUrl, options) : null,
    ]);
    const [responseData, secondResponseData] = await Promise.all([
      response.json(),
      secondResponse ? secondResponse.json() : null,
    ]);

    if (!response.ok) {
      status = response.status;
      throw new Error(responseData?.msg || 'API call failed');
    }

    if (secondFetchUrl && !secondResponse.ok) {
      status = secondResponse.status;
      throw new Error(secondResponseData?.msg || 'API call failed');
    }

    if (secondResponseData) {
      return [responseData, secondResponseData];
    }

    return responseData;
  } catch (error) {
    throw new FetchError(`Failed to fetch resource: ${error.message}`, status);
  }
};

const useGetResource = (url = '') => {
  const { companyData: { uid } = {} } = useCompanyData();
  const { type, fetchUrl, secondFetchUrl } = parseDeeplink(url, uid);
  const auth = useAuth();

  return {
    type,
    ...useQuery({
      queryKey: ['companySettings', uid, fetchUrl],
      queryFn: () => fetchResource({ uid, auth, fetchUrl, secondFetchUrl }),
      enabled: !!uid && !!fetchUrl,
      retry: (failureCount, error) => {
        if (error.status === 404) {
          return false;
        }

        return failureCount < 3;
      },
    }),
  };
};

export default useGetResource;
