import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import Button from 'components/buttons/Button';
import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

const AnnouncementDocumentPreview = ({ announcement = {} }) => {
  const { companyData } = useCompanyData();
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const { announcementId } = useParams();
  const handleEditDocument = () => {
    const path = generatePath(CONTENT_MANAGEMENT_PATH, {
      id: companyData.slug,
      tab: 'documents',
      documentId: announcement?.document?.id,
    });
    navigate(`${path}?from=announcement:${announcementId}`);
  };

  if (!announcement?.document) {
    return (
      <div className="relative p-4 rounded-b-md shadow">No document found</div>
    );
  }

  return (
    <div
      className="relative"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && (
        <div className="absolute inset-0 bg-gray-300 bg-opacity-50 flex items-center justify-center z-10">
          <Button onClick={handleEditDocument} className="px-6 py-3 text-lg">
            Edit Document
          </Button>
        </div>
      )}

      <div className="flex-col flex gap-4 bg-white mx-auto p-4 shadow-lg relative z-0">
        <h3 className="font-medium whitespace-nowrap">Document</h3>
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          value={announcement?.document?.draft?.content}
          init={{
            branding: false,
            plugins: 'autoresize wordcount',
            menubar: false,
            toolbar: false,
            readonly: true,
            min_height: 500,
            autoresize_bottom_margin: 10,
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
          }}
          className="w-full"
        />
      </div>
    </div>
  );
};

AnnouncementDocumentPreview.propTypes = {
  announcement: PropTypes.object,
};

export default AnnouncementDocumentPreview;
