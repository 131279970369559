import React from 'react';

import classNames from 'classnames';
import { sortBy } from 'lodash';
import { generatePath, useParams, Link } from 'react-router-dom';

import { MESSAGES_PATH_FULL } from 'constants/paths';

import { useInboxContext } from '../context/InboxContext';
import createShowCalloutArray from '../helpers/createShowCalloutArray';

import InboxMessage from './InboxMessage/InboxMessage';
import InboxMessageJournalistResponse from './InboxMessage/InboxMessageJournalistResponse';
import InboxMessageResponseForm from './InboxMessage/InboxMessageResponseForm/InboxMessageResponseForm';
import InboxMessagesThreadDetail from './InboxMessageThreadDetail';
import InboxMessageTrackingEvents from './InboxMessageTrackingEvents';
import InboxMessagesCoverage from './InboxMessagesCoverage';
import InboxMessagesError from './InboxMessagesError';
import InboxMessagesWrapper from './InboxMessagesWrapper';

const combineMessagesAndTrackingEvents = (msgs, trackingEvents) => {
  const events = trackingEvents.map((event) => ({
    dateCreated: event.date,
    type: 'tracking',
  }));
  const messages = msgs.map((msg) => ({
    ...msg,
    dateCreated: msg.dateCreated,
    type: 'message',
  }));

  const combined = sortBy([...events, ...messages], 'dateCreated');

  const combinedAndGrouped = combined.reduce((acc, item) => {
    if (item.type === 'message') {
      acc.push(item);
    } else {
      const lastItem = acc[acc.length - 1];
      if (lastItem?.type === 'tracking_group') {
        lastItem.events.push(item);
      } else {
        acc.push({
          type: 'tracking_group',
          dateCreated: item.dateCreated,
          events: [item],
        });
      }
    }

    return acc;
  }, []);

  return combinedAndGrouped;
};

const InboxMessages = () => {
  const { threadId, id } = useParams();
  const { threadDetails, currentThread } = useInboxContext();
  const {
    messages = [],
    trackingEvents = [],
    grant = {},
  } = threadDetails?.data || {};
  const allMessages = combineMessagesAndTrackingEvents(
    messages,
    trackingEvents
  );

  const isLoadingThread = threadDetails?.loading;

  const showCalloutArray = createShowCalloutArray(messages);

  if (!currentThread && threadId) {
    return (
      <InboxMessagesError
        text="Thread not found"
        callout={
          <div>
            <p>Seems like the thread you tried to access doesn&apos;t exist</p>
            <p className="text-center mt-4 -ml-6 lg:hidden block">
              <Link
                to={generatePath(MESSAGES_PATH_FULL, { id })}
                className="text-teal-500 underline underline-offset-2"
              >
                Go back to Threads
              </Link>
            </p>
          </div>
        }
      />
    );
  }

  if (!currentThread) {
    return <InboxMessagesError />;
  }

  return (
    <div
      className={classNames('flex flex-col w-full h-full', {
        'opacity-50': isLoadingThread,
      })}
    >
      <InboxMessagesThreadDetail />
      <InboxMessagesWrapper testId="inboxMessage-wrapper" className="grow">
        <InboxMessageJournalistResponse />

        <InboxMessagesCoverage coverage={currentThread.coverage} />

        <div className="flex flex-col items-center">
          {allMessages?.map((item, index) =>
            item.type === 'message' ? (
              <InboxMessage
                key={item.id}
                message={item}
                showCallout={showCalloutArray[index]}
                grant={grant}
              />
            ) : (
              <InboxMessageTrackingEvents
                key={item.dateCreated}
                events={item.events}
                journalist={currentThread.journalist}
              />
            )
          )}
        </div>

        <InboxMessageResponseForm />
      </InboxMessagesWrapper>
    </div>
  );
};

export default InboxMessages;
