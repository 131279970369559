import React, { useEffect } from 'react';

import { signInWithEmailAndPassword } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAuth } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import PageWrapper from 'components/PageWrapper';
import Spinner from 'components/Spinner';
import { AGENCY_DASHBOARD_PATH, SIGNUP_AGENCY_PATH } from 'constants/paths';
import useAPIRequest from 'hooks/useAPIRequest';
import useQuery from 'hooks/useQuery';
import { useTracking } from 'modules/segment/TrackingProvider';
import getPageTitle from 'utils/getPageTitle';

import SignupAgencyForm from './SignupAgencyForm';

const SignupAgencyPage = () => {
  const trackingService = useTracking();
  const auth = useAuth();
  const query = useQuery();
  const loading = !!query.get('loading');

  const { fetchData: createAgency } = useAPIRequest({
    service: 'UCD',
    endpoint: '/agencies',
    method: 'PUT',
  });
  const { showError } = useNotifications();
  const navigate = useNavigate();

  const onSubmit = async (formValues = {}) => {
    // reset potential previous impersonation as this will break once your access rights change!
    window?.localStorage?.clear();
    navigate(`${SIGNUP_AGENCY_PATH}?loading=true`);
    try {
      const { email, password } = formValues;
      const agency = await createAgency({
        body: { name: formValues.agencyName, email, password },
        shouldThrow: true,
      });

      await trackingService.track('signup', {
        auth_method: 'email',
        auth_form: 'agency',
      });

      trackingService.track('User Signed Up', {
        type: 'agency',
        agencyId: agency.id,
      });

      await signInWithEmailAndPassword(auth, email, password);
      navigate(generatePath(AGENCY_DASHBOARD_PATH, { agencyId: agency.id }));
    } catch (err) {
      navigate(SIGNUP_AGENCY_PATH);
      showError({
        title: 'There was an issue signing up',
        message: err.message,
      });
    }
  };

  useEffect(() => {
    trackingService.page('AgencySignup');
  }, [trackingService]);

  if (loading) {
    return (
      <>
        <div className="absolute inset-0 mt-24 flex justify-center items-center text-center">
          <p>
            Signing you up...
            <br />
            This may take 10-20 seconds.
          </p>
        </div>
        <Spinner />
      </>
    );
  }

  return (
    <PageWrapper className="p-0">
      <Helmet>
        <title>{getPageTitle('Signup')}</title>
      </Helmet>
      <SignupAgencyForm onSubmit={onSubmit} />
    </PageWrapper>
  );
};

export default SignupAgencyPage;
