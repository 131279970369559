import PropTypes from 'prop-types';
import React, { useState } from 'react';

import noop from 'lodash/noop';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import useUpdateJournalist from 'hooks/useUpdateJournalist';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

const InboxMessageResponseFormSendModal = ({ onClose = noop }) => {
  const { currentThread, threads } = useInboxContext();
  const { showError, showSuccess } = useNotifications();
  const { journalist } = currentThread || {};
  const [email, setEmail] = useState(journalist?.email || '');
  const [loading, setLoading] = useState(false);
  const updateJournalist = useUpdateJournalist();

  return (
    <Modal open widthClass="max-w-xl">
      <Modal.Close
        srMessage="Close Send Preview modal"
        testId="inbox-sendPreviewModal-close"
        onClose={onClose}
      />
      <Modal.Title>Missing Journalist email</Modal.Title>
      <Modal.Description>
        <p className="max-w-prose mb-2">
          Seems like <b>{journalist.name}</b> from{' '}
          <b>{journalist.publication.name}</b> doesn&apos;t have an email. You
          can add it here.
        </p>
      </Modal.Description>

      <Modal.Content>
        <div className="flex gap-2 items-end">
          <InputFieldText
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          >
            <InputFieldText.Label>Journalist email</InputFieldText.Label>
          </InputFieldText>
        </div>
      </Modal.Content>

      <Modal.RightButtons>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>

        <Button
          type="primary"
          disabled={loading || threads.loading}
          onClick={async () => {
            setLoading(true);

            try {
              await updateJournalist({
                name: journalist.name,
                publicationName: journalist?.publication?.name,
                email,
              });
              await threads.fetchData();
              showSuccess({
                message: 'Email updated',
              });
              onClose();
            } catch (error) {
              showError({
                message: 'Error updating the email',
              });
            }

            setLoading(false);
          }}
          className="h-fit group relative"
        >
          <span className="group-disabled:opacity-30 transition-opacity">
            Save
          </span>

          <span className="group-disabled:block hidden text-teal-500">
            <Spinner color="text-white" />
          </span>
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

InboxMessageResponseFormSendModal.propTypes = {
  onClose: PropTypes.func,
};

export default InboxMessageResponseFormSendModal;
