import PropTypes from 'prop-types';
import React from 'react';

import Tag from 'components/tags/Tag';
import {
  bgColors,
  getDocumentType,
  textColors,
} from 'constants/contentManagementTypes';

const DocumentTag = ({ type = '', testId = '' }) => {
  const tag = getDocumentType({ type })?.label;

  const bgColor = bgColors[type];
  const textColor = textColors[type];

  return (
    <Tag
      className="mb-0 mt-0 mr-0"
      bgColor={bgColor}
      textColor={textColor}
      data-test-id={`${testId}_${type}`}
    >
      {tag}
    </Tag>
  );
};

DocumentTag.propTypes = {
  type: PropTypes.string,
  testId: PropTypes.string,
};

export default DocumentTag;
