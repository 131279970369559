import React from 'react';

import { Helmet } from 'react-helmet';
import { useParams, useSearchParams } from 'react-router-dom';

import PageWrapper from 'components/PageWrapper';
import TiersGuard from 'components/TiersGuard';
import { featureNames } from 'constants/featureMap';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useRedirectUsersToOwnCompany from 'hooks/useRedirectUsersToOwnCompany';
import getPageTitle from 'utils/getPageTitle';

import Inbox from './Inbox';
import InboxContextProvider from './context/InboxContext';

const InboxPage = () => {
  const [searchParams] = useSearchParams();
  const { companyData } = useCompanyData();
  const { threadId: paramThreadId, messageId: paramMessageId } = useParams();

  const threadId = searchParams.get('threadId');
  const messageId = searchParams.get('messageId');

  useRedirectUsersToOwnCompany(MESSAGES_PATH_FULL, {
    ...(threadId || paramThreadId
      ? { threadId: threadId || paramThreadId }
      : {}),
    ...(messageId || paramMessageId
      ? { messageId: messageId || paramMessageId }
      : {}),
  });

  if (!companyData?.slug) {
    return null;
  }

  return (
    <PageWrapper className="p-0 flex flex-col">
      <Helmet>
        <title>{getPageTitle('Inbox')}</title>
      </Helmet>
      <TiersGuard featureId={featureNames.INBOX}>
        <InboxContextProvider>
          <Inbox />
        </InboxContextProvider>
      </TiersGuard>
    </PageWrapper>
  );
};

export default InboxPage;
