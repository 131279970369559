import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';

import { useLocation, useMatch } from 'react-router-dom';

import { AGENCY_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import Footer from './Footer';
import Navbar from './Navbar/Navbar';
import Topbar from './Topbar/Topbar';
import useAdminRoute from './hooks/useAdminRoute';
import useShowNavbarOnRoute from './hooks/useShowNavbarOnRoute';

const CoreLayout = ({ children = null }) => {
  const { pathname } = useLocation();
  const { uid, isAnonymous } = useUserData();
  const { isActualOwnerOfCompany } = useCompanyData();

  const [showNavbarOnRoute, routeMatchCompany] = useShowNavbarOnRoute();
  const showTopbarException =
    isAnonymous && routeMatchCompany && !isActualOwnerOfCompany;
  const showNavbar = uid && showNavbarOnRoute && !showTopbarException;
  const isAdminRoute = useAdminRoute();
  const isAgencyRoute = useMatch(AGENCY_PATH);

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div
        className="flex flex-col"
        style={{ minHeight: 'calc(100vh - 64px)' }}
      >
        {!isAdminRoute && !isAgencyRoute && (
          <>
            {showNavbar && <Navbar />}
            {!showNavbar && <Topbar />}
          </>
        )}
        {children}
      </div>
      <Footer />
    </>
  );
};

CoreLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CoreLayout;
