import PropTypes from 'prop-types';
import React from 'react';

import { SparklesIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

const JournalistsBulkActions = ({ selected = [], createDrafts = noop }) => (
  <div className="flex flex-col gap-2 fixed w-full left-0 lg:pl-64 z-50 bottom-16 justify-center items-center text-sm">
    <div className="bg-teal-500 text-white rounded-xl flex items-center divide-x">
      <p className="px-4 py-2">
        {selected.length} journalist{selected.length > 1 ? 's' : ''} selected
      </p>
      <button
        type="button"
        onClick={createDrafts}
        className="px-4 p-2 flex items-center space-x-1 hover:bg-teal-600 cursor-pointer rounded-r-xl font-medium"
      >
        <SparklesIcon className="w-4" />
        <span>Create Drafts</span>
      </button>
    </div>
  </div>
);

JournalistsBulkActions.propTypes = {
  selected: PropTypes.array,
  createDrafts: PropTypes.func,
};

export default JournalistsBulkActions;
