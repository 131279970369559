import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { Link, generatePath } from 'react-router-dom';

import Callout from 'components/Callout/Callout';
import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import HtmlContainer from 'components/HtmlContainer';
import JournalistTeaser from 'components/JournalistTeaser';
import OpportunityTimeLeft from 'components/opportunities/opportunity/OpportunityTimeLeft';
import OpportunityType from 'components/opportunities/opportunity/OpportunityType';
import capitalizeFirstLetter from 'components/opportunities/opportunity/helpers/capitalizeFirstLetter';
import { MESSAGES_PATH_FULL } from 'constants/paths';

const ShareOpportunityDetails = ({
  opportunity = {},
  testId = '',
  threadSource = '',
}) => {
  const showStatus = threadSource === 'opportunity';
  const {
    deadline,
    descriptionHtml,
    title,
    journalist,
    response,
    tags,
    threadId,
  } = opportunity || {};

  const expired = deadline ? new Date(deadline) < new Date() : false;

  return (
    <>
      <div className="flex flex-row space-x-2 justify-start mb-2 flex-wrap">
        {tags.length > 0 && <OpportunityType type={tags[0]} />}
        <DomainAuthorityPill
          className="mb-2"
          domainAuthority={journalist?.publication?.domainAuthority}
        />
      </div>
      {journalist && (
        <div className="flex items-center space-x-4">
          <JournalistTeaser journalist={journalist} />
        </div>
      )}
      <div
        className={classNames('flex justify-between mt-2')}
        data-test-id={`${testId}-type`}
      >
        <h3 className="text-2xl font-semibold" data-test-id={`${testId}-title`}>
          {capitalizeFirstLetter(title)}
        </h3>
      </div>

      <HtmlContainer
        htmlString={descriptionHtml}
        className="max-w-full text-sm prose max-w-none prose-gray prose-p:my-4 prose-li:my-0 prose-a:text-teal-500 prose-pre:bg-gray-200 leading-relaxed"
      />

      {showStatus && (
        <OpportunityTimeLeft
          deadline={deadline}
          expired={expired}
          wrapperClassName="mt-4 mb-3"
          standalone
          testId={testId}
        />
      )}

      {response && (
        <Callout size="sm">
          <span>
            Your response has been sent. Go to your{' '}
            <Link
              to={generatePath(MESSAGES_PATH_FULL, { threadId })}
              className="inline-flex items-center gap-1 w-fit p-0.5 px-2 bg-yellow-300 rounded-md"
            >
              Messages
            </Link>{' '}
            to find your conversations with journalists.
          </span>
        </Callout>
      )}
    </>
  );
};

ShareOpportunityDetails.propTypes = {
  opportunity: PropTypes.object,
  threadSource: PropTypes.string,
  testId: PropTypes.string,
};

export default ShareOpportunityDetails;
