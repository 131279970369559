import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { noop } from 'lodash';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import { resourceStatus } from 'constants/contentManagementTypes';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';
import useCurrentDocumentContext from 'routes/ContentManagement/hooks/useCurrentDocumentContext';

import ContentManagementDocumentResponseFormQnAModal from './ContentManagementDocumentResponseFormQnAModal';

const ContentManagementResponseFormQnA = ({
  contentIdeation,
  onSubmit = noop,
}) => {
  const { companyData } = useCompanyData();
  const { showError } = useNotifications();

  const { currentDocument, updateDocument } = useCurrentDocumentContext();

  const isContentIdeationCompleted =
    contentIdeation?.status === resourceStatus.COMPLETED;

  const [questions, setQuestions] = useState(currentDocument?.questions || []);
  const [isQnAModalOpen, setIsQnAModalOpen] = useState(false);

  const currentDocumentQuestions = useMemo(
    () => currentDocument?.questions ?? [],
    [currentDocument?.questions]
  );

  const isDataAvailable = useMemo(
    () =>
      isContentIdeationCompleted &&
      JSON.stringify(currentDocument?.idea) !== '{}',
    [currentDocument?.idea, isContentIdeationCompleted]
  );

  const isCompleted = useMemo(
    () =>
      currentDocumentQuestions.length > 0 &&
      !currentDocumentQuestions.some((q) => q.answer === ''),
    [currentDocumentQuestions]
  );

  const { fetchData: generateQuestions, loading: areQuetionsLoading } =
    useAPIRequest({
      service: 'MOD',
      endpoint: `/ai/${companyData.uid}/${currentDocument.id}/generateQuestions`,
      method: 'POST',
    });

  const isQuestionsListEmpty = useMemo(
    () => currentDocumentQuestions.length === 0,
    [currentDocumentQuestions]
  );

  const handleAIQuestionsRequest = useCallback(async () => {
    try {
      const response = await generateQuestions();

      setQuestions(response);
      updateDocument({
        questions: response,
      });
    } catch (error) {
      setIsQnAModalOpen(false);
      showError({
        message: `There was an error communicating with the AI`,
      });
    }
    return 'Something went wrong.';
  }, [generateQuestions, showError, updateDocument]);

  const handleButtonClick = () => {
    if (isQuestionsListEmpty && isDataAvailable) {
      handleAIQuestionsRequest();
    }
    setIsQnAModalOpen(true);
  };

  const handleAnswerQuestions = (newQnA) => {
    setQuestions(newQnA);
    onSubmit(newQnA);
    setIsQnAModalOpen(false);
  };

  return (
    <>
      <div>
        <Label htmlFor="article-qna">Q&A</Label>

        {!isDataAvailable && (
          <p className="mt-2 text-gray-400 text-sm">
            The content ideation Q&A and article idea are required for Q&A to be
            enabled.
          </p>
        )}
        <p className="mt-2 text-gray-400 text-sm">
          Answering a couple of specific questions about this article ideas
          helps AI generate more accurate results.
        </p>
        <div className="flex items-center">
          <Button
            type="secondary"
            className="mt-2"
            onClick={handleButtonClick}
            disabled={!isDataAvailable}
          >
            {isQuestionsListEmpty ? 'Start Q&A...' : 'Continue Q&A'}
          </Button>
          {isCompleted && <span className="ml-2 mt-2 text-xl">✅</span>}
        </div>

        {!areQuetionsLoading && isQnAModalOpen && (
          <ContentManagementDocumentResponseFormQnAModal
            open={isQnAModalOpen}
            questions={questions}
            onQuestionsUpdated={handleAnswerQuestions}
            onClose={() => setIsQnAModalOpen(false)}
          />
        )}
      </div>

      {areQuetionsLoading && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-100 bg-opacity-50 flex justify-center items-center z-50">
          <Spinner />
        </div>
      )}
    </>
  );
};

ContentManagementResponseFormQnA.propTypes = {
  contentIdeation: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ContentManagementResponseFormQnA;
