import React from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import Spinner from 'components/Spinner';
import CompanyImageV2 from 'components/company/CompanyImageV2';
import getPageTitle from 'utils/getPageTitle';

import AgencyDashboardCompaniesCompanyMessages from './AgencyDashboardCompaniesCompanyMessages';
import useAgencyCompany from './hooks/useAgencyCompany';

const AgencyDashboardCompaniesCompany = () => {
  const { agencyId, companyId } = useParams();
  const { data, isLoading, refetch } = useAgencyCompany(agencyId, companyId);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Helmet>
        <title>{getPageTitle(`Dashboard - ${data.company.name}`)}</title>
      </Helmet>

      <div className="grid grid-cols-1 gap-y-8 xl:grid-cols-1 gap-x-8 mb-2">
        <div className="overflow-hidden rounded-xl border border-gray-200 bg-white">
          <div className="block h-full">
            <div className="flex items-center gap-x-4 border-gray-900/5 p-6">
              <CompanyImageV2 alt={data.company.name} url={data.company.logo} />
              <div>
                <p className="text-sm font-medium leading-6 text-gray-900">
                  <b>{data.company.name}</b>
                </p>
                <p className="text-sm font-medium leading-6 text-gray-900">
                  Tier: {data.tier.name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AgencyDashboardCompaniesCompanyMessages
        messages={data.messages}
        refetch={refetch}
      />
    </>
  );
};

export default AgencyDashboardCompaniesCompany;
