import { useParams } from 'react-router-dom';

import useAPIRequest from 'hooks/useAPIRequest';

const useSendPreview = () => {
  const { threadId } = useParams();

  return useAPIRequest({
    method: 'POST',
    endpoint: `/threads/${threadId}/draft/preview`,
    service: 'MOD',
  });
};

export default useSendPreview;
