import React from 'react';

const AssistantInstructions = () => (
  <div className="mr-5 bg-white prose rounded text-sm py-2 px-3 my-1 prose-p:my-1 prose-li:my-1 prose-ul:my-1 prose-h1:my-1 prose-h2:my-1 prose-h3:my-1 prose-a:text-teal-500 prose-hr:my-1 prose-pre:bg-gray-200 prose-pre:bg-gray-800 prose-pre:text-gray-100">
    <h4>Hey!</h4>
    <p>
      I&apos;m Ivy, the MVPR assistant and I can support you with writing this
      document.
      <br />
      Among the things you can ask me to do are:
    </p>
    <ul>
      <li>
        Make changes in the <b>text editor</b> to the left
      </li>
      <li>
        Read and summarize your <b>resources</b>
      </li>
      <li>
        Visit <b>websites</b> and extract information
      </li>
      <li>
        Recommend <b>journalists</b> for given topics
      </li>
      <li>
        Search <b>journalists</b> by name or publication
      </li>
      <li>
        Get <b>journalists</b> statistics
      </li>
    </ul>
    <p>If you have any questions, don&apos;t hesitate to ask.</p>
  </div>
);

export default AssistantInstructions;
