import React, { useState } from 'react';

import { v4 as uuid } from 'uuid';

import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';

import useSearchTerms from '../hooks/useSearchTerms';

import OpportunitiesControlsSearchHowTo from './OpportunitiesControlsSearchHowTo';
import OpportunitiesControlsSearchHowToModal from './OpportunitiesControlsSearchHowToModal';

const OpportunitiesControlsSearch = () => {
  const { terms, addTerm, removeTerm } = useSearchTerms();
  const [showHowToModal, setShowHowToModal] = useState(false);

  return (
    <div
      className="md:py-2 relative flex gap-2 itemse-center flex-1 xl:max-w-[50vw]"
      data-intercom-id="opportunities-search"
    >
      <InputFieldTagsAutocomplete
        placeholder="Add topics or keywords"
        customFieldName="oppSearch"
        tags={
          terms.map((term) => ({
            id: uuid(),
            name: term,
          })) || []
        }
        onAddition={(tag) => {
          if (terms.find((t) => t === tag.name)) {
            return;
          }
          addTerm(tag.name);
        }}
        showInstructions={false}
        showHowTo
        onDelete={(tag) => {
          removeTerm(tag.name);
        }}
      />
      <OpportunitiesControlsSearchHowTo
        onClick={() => setShowHowToModal(true)}
      />
      {showHowToModal ? (
        <OpportunitiesControlsSearchHowToModal
          onClose={() => setShowHowToModal(false)}
        />
      ) : null}
    </div>
  );
};

export default OpportunitiesControlsSearch;
