import React from 'react';

import SweetheartListAddJournalistModalOpenButton from '../SweetheartListJournalist/SweetheartListAddJournalistModal/SweetheartListAddJournalistModalOpenButton';

import SweetheartListHeaderTabs from './SweetheartListHeaderTabs';

const SweetheartListHeader = () => (
  <div className="w-[calc(100%+3rem)] sticky top-0 -ml-6 -mt-6 z-30 mb-4">
    <div className="w-full justify-between items-center flex bg-white shadow-sm text-gray-300 border-b border-gray-200 pr-4">
      <SweetheartListHeaderTabs />
      <SweetheartListAddJournalistModalOpenButton />
    </div>
  </div>
);

export default SweetheartListHeader;
