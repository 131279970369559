import { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import useAPIRequest from 'hooks/useAPIRequest';

const MB = 1048576;

const useAddAttachments = ({ revalidateDraft } = {}) => {
  const { threadId } = useParams();
  const [pendingAttachments, setPendingAttachments] = useState([]);
  const { showError } = useNotifications();
  const { fetchData: getUploadUrl } = useAPIRequest({
    service: 'MOD',
    endpoint: `/threads/${threadId}/draft/attachmentUploadUrl`,
    method: 'POST',
  });

  const { fetchData: addAttachments } = useAPIRequest({
    service: 'MOD',
    endpoint: `/threads/${threadId}/draft/attachments`,
    method: 'POST',
  });

  const uploadFile = useCallback(
    async (file) => {
      const { url, attachment, headers } = await getUploadUrl({
        body: {
          name: file.name,
          type: file.type,
        },
      });

      await fetch(url, {
        method: 'PUT',
        body: file,
        headers,
      });

      return attachment;
    },
    [getUploadUrl]
  );

  return {
    addAttachments: useCallback(
      async (files = []) => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < files.length; i++) {
          // check if all files conform to size limitations
          if (files[i].size >= 3 * MB) {
            showError({
              message: 'Only attachments of up to 3MB are supported.',
            });
            return;
          }
        }
        setPendingAttachments(
          files.map((f) => ({
            path: '',
            fileName: f.name,
            contentDisposition: '',
            downloadUrl: '',
            contentType: f.type,
            pending: true,
          }))
        );

        const newAttachments = await Promise.all(
          files.map(async (file) => uploadFile(file))
        );

        await addAttachments({
          body: {
            attachments: newAttachments,
          },
        });

        await revalidateDraft();
        setPendingAttachments([]);
      },
      [addAttachments, revalidateDraft, uploadFile, showError]
    ),
    pendingAttachments,
  };
};

export default useAddAttachments;
