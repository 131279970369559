const defaultLanguages = [
  { value: 'en-GB', label: '🇬🇧 English' },
  { value: 'de-DE', label: '🇩🇪 German' },
  { value: 'es-ES', label: '🇪🇸 Spanish' },
];

export const getFlagEmoji = (languageCode) => {
  const flags = {
    'en-GB': '🇬🇧',
    'de-DE': '🇩🇪',
    'es-ES': '🇪🇸',
  };
  return flags[languageCode] || '';
};

export const allLanguages = {
  label: 'All languages',
  value: defaultLanguages.map((lang) => lang.value).join(','),
};

export default defaultLanguages;
