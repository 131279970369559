import { useState, useMemo, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import onboardingSteps from 'constants/onboardingSteps';

const useSimpleStepsConfiguration = () => {
  const { step: stepName } = useParams();
  const [currentStepIndex, setCurrentStepIndex] = useState(() => {
    const initialStepIndex = onboardingSteps.findIndex(
      (step) => step.name === stepName
    );
    return initialStepIndex === -1 ? 0 : initialStepIndex;
  });

  const currentStep = useMemo(
    () => onboardingSteps[currentStepIndex] || onboardingSteps[0],
    [currentStepIndex]
  );

  const nextStep = useCallback(() => {
    if (currentStepIndex < onboardingSteps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  }, [currentStepIndex]);

  return [currentStep, onboardingSteps, nextStep];
};

export default useSimpleStepsConfiguration;
