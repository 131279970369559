import PropTypes from 'prop-types';
import React from 'react';

import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/20/solid';
import ReactDOMServer from 'react-dom/server';
import { Controller, useForm } from 'react-hook-form';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldFounders from 'components/form/inputFields/InputFieldFounders/InputFieldFounders';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import InputFieldToggle from 'components/form/inputFields/InputFieldToggle';
import { PAGE_TITLE } from 'constants/meta';
import { coreTopicsForUser } from 'constants/tags';
import { todaysDateObj } from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

import CompanyQuoteEditModalDate from './CompanyQuoteEditModalDate';

const CompanyQuoteEditModal = ({
  open,
  quote = null,
  onClose,
  onSubmit,
  founders = [],
}) => {
  const formName = 'company-quotes-editModal';
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, errors },
    setValue,
    watch,
  } = useForm({ mode: 'onChange' });

  const defaultValues = quote || {};
  const defaultDate = quote
    ? getDateObjFromTimestamp(quote.date)
    : todaysDateObj;

  return (
    <Modal open={open}>
      <Modal.Close srMessage="Close quote edit modal" onClose={onClose} />
      <Modal.Icon Icon={ChatBubbleBottomCenterTextIcon} />
      <Modal.Title>{quote ? 'Edit' : 'Add a'} quote</Modal.Title>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} id={formName}>
          <InputFieldText
            {...register('title', {
              required: 'Please put in a title',
            })}
            errors={errors}
            placeholder="Type in a quote title..."
            defaultValue={defaultValues?.title}
            testId="company-quotes-editModal-titleInput"
          >
            <InputFieldText.Label>Title*</InputFieldText.Label>
          </InputFieldText>
          <div className="mt-3 flex items-center">
            <Label htmlFor="body">Body*</Label>
            <Tooltip
              dataHTML
              id="tooltip-quotes-body"
              html={ReactDOMServer.renderToString(
                <p className="pointer-events-auto">
                  Quotes should be 2-4 sentences long and should be in the tone
                  of voice of the Founder or Company Spokesperson.
                  <br />
                  You can view an example from{' '}
                  <a
                    className="underline"
                    href="https://app.mvpr.io/company/mvpr#quote-bank"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {PAGE_TITLE}&apos;s Quote Bank here
                  </a>
                </p>
              )}
            />
          </div>

          <InputFieldTextArea
            {...register('body', {
              required: 'Please put in a body',
            })}
            placeholder="Type in a quote body..."
            defaultValue={defaultValues?.body}
            data-test-id="company-quotes-editModal-bodyInput"
          />
          {errors.body && (
            <ErrorMessage testId="company-quotes-editModal-bodyInput-error">
              {errors.body.message}
            </ErrorMessage>
          )}

          <fieldset className="mt-3">
            <Label htmlFor="date">When did it happen?</Label>
            <CompanyQuoteEditModalDate
              control={control}
              watch={watch}
              setValue={setValue}
              defaultDate={defaultDate}
            />
          </fieldset>

          <div className="mt-3">
            <Label htmlFor="tags">Tags</Label>
          </div>

          <div
            className="mt-2"
            data-test-id="company-quotes-editModal-tagsInput"
          >
            <Controller
              name="tags"
              control={control}
              defaultValue={defaultValues?.tags || []}
              render={({ field: { onChange, value } }) => (
                <InputFieldTagsAutocomplete
                  placeholder="Add a tag"
                  customFieldName="tag"
                  suggestions={coreTopicsForUser}
                  tags={value}
                  onAddition={(tag) => {
                    if (value.find((t) => t.id === tag.id)) {
                      return;
                    }
                    onChange([...value, tag]);
                  }}
                  onDelete={(tag) => {
                    onChange(value.filter((t) => tag.id !== t.id));
                  }}
                />
              )}
            />
          </div>

          <div className="mt-3">
            <Label htmlFor="users">Related team members</Label>
          </div>

          <div className="mt-2">
            <Controller
              name="founder"
              control={control}
              defaultValue={
                defaultValues.founder || defaultValues?.users?.[0] || null
              }
              render={({ field: { onChange, value } }) => (
                <InputFieldFounders
                  founders={founders}
                  onChange={onChange}
                  value={value}
                  testId="company-quotes-editModal-founderInput"
                />
              )}
            />
          </div>

          {errors.founder && (
            <ErrorMessage>{errors.founder.message}</ErrorMessage>
          )}

          <div className="mt-3">
            <Label htmlFor="private">Private</Label>
          </div>

          <div className="mt-2">
            <Controller
              name="private"
              control={control}
              defaultValue={!!defaultValues.private}
              render={({ field: { onChange, value } }) => (
                <InputFieldToggle
                  value={value}
                  onChange={onChange}
                  text="This quote should be private"
                  testId="company-quotes-editModal-privateInput"
                />
              )}
            />
          </div>
        </form>
        <ReactTooltip
          id="tooltip-quotes-body"
          className="max-w-xs text-sm"
          delayHide={1500}
        />
      </Modal.Content>

      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-quotes-editModal-cancel"
        >
          Cancel
        </Button>
        <Button
          submit
          form={formName}
          disabled={!isValid}
          eventType="pressPage_update"
          eventProperties={{ action: 'quote_add' }}
          data-test-id="company-quotes-editModal-submit"
        >
          {quote ? 'Update' : 'Add'} quote
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyQuoteEditModal.propTypes = {
  open: PropTypes.bool,
  quote: PropTypes.object,
  founders: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyQuoteEditModal;
