import React from 'react';

import { InboxIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from 'react-query';
import { Link, generatePath, useParams } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import { tabNames } from 'constants/inboxTabs';
import intents from 'constants/intents';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import threadSource from 'constants/threadSource';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useCreateThread from 'hooks/useCreateThread';
import useDeclineOpportunity from 'hooks/useDeclineOpportunity';
import useReactivateOpportunity from 'hooks/useReactivateOpportunity';
import trackIntercomEvent from 'modules/intercom/trackIntercomEvent';

const useCompaniesOpportunitiesOps = () => {
  const { id: slug } = useParams();
  const { createThread } = useCreateThread();
  const queryClient = useQueryClient();
  const { companyData } = useCompanyData();
  const { mutateAsync: declineOpportunity } = useDeclineOpportunity();
  const { mutateAsync: reactivateOpportunity } = useReactivateOpportunity();

  const { showError, showSuccess } = useNotifications();

  const ops = {
    // Decline an opportunity
    decline: async (opportunity) => {
      trackIntercomEvent('opportunity-decline');
      showSuccess({ message: 'Opportunity declined' });
      await declineOpportunity({
        opportunityId: opportunity.id,
        companyId: companyData.uid,
      });
    },
    undecline: async (opportunity) => {
      showSuccess({ message: 'Opportunity reactivated' });
      await reactivateOpportunity({
        opportunityId: opportunity.id,
        companyId: companyData.uid,
      });
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait until search index has setteled
      queryClient.invalidateQueries({ queryKey: ['opportunities'] });
    },
    respond: async (opportunity) => {
      const newThread = await createThread({
        reference: `opportunities/${opportunity.id}`,
        journalistId: opportunity.journalist.id,
        subject: opportunity.replySubject,
        source: threadSource.OPPORTUNITY,
        intent: intents.opportunities.OPPORTUNITY,
        status: tabNames.DRAFT,
      });

      if (!newThread.id) {
        showError({
          title: "We're sorry! Something went wrong",
          message: "And the message couldn't be sent.",
        });
        return;
      }

      showSuccess({
        title: 'Conversation started',
        Icon: InboxIcon,
        message: (
          <span data-test-id="opportunity-responseModal-success">
            This opportunity has now been moved to your{' '}
            <Link
              to={generatePath(MESSAGES_PATH_FULL, {
                id: slug,
                threadId: newThread.id,
              })}
              className="underline text-teal-500"
            >
              Inbox
            </Link>{' '}
            and saved as a draft.
          </span>
        ),
      });

      if (opportunity?.journalist?.publication?.name === 'Bdaily') {
        trackIntercomEvent('opportunity-respond-Bdaily');
      } else {
        trackIntercomEvent('opportunity-respond');
      }
    },
  };

  return {
    ...ops,
    bulkDecline: (newOpportunities) => newOpportunities.forEach(ops.decline),
    bulkRespond: (newOpportunities) => newOpportunities.forEach(ops.respond),
  };
};

export default useCompaniesOpportunitiesOps;
