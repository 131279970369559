import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const UCD_URL = process.env.REACT_APP_UCD_URL;

const useAgencyCompany = (agencyId = '', companyId = '') => {
  const auth = useAuth();
  const queryEnabled = agencyId && companyId;
  return useQuery(
    ['agencies', agencyId, 'companies', companyId],
    async () => {
      const jwt = await auth.currentUser?.getIdToken();
      const response = await fetch(
        `${UCD_URL}/agencies/${agencyId}/companies/${companyId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (!response?.ok) {
        throw new Error('Failed fetching companies');
      }
      return response.json();
    },
    queryEnabled
  );
};

export default useAgencyCompany;
