import { generatePath } from 'react-router-dom';

import { COMPANY_PATH } from 'constants/paths';

// get the company route for a specific slug
const getCompanyRoute = (slug, threadId) => {
  const url = generatePath(COMPANY_PATH, { id: slug });

  return threadId ? `${url}?threadId=${threadId}` : url;
};

export default getCompanyRoute;
