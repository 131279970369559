import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

import InputFieldUploadFile from './InputFieldUploadFile';

const AssistantAddActionModal = ({ open, onClose, onSubmit }) => {
  const form = useForm({ mode: 'onChange' });
  const formName = 'assistant-add-action-modal';
  const { handleSubmit, watch } = form;

  const files = watch('files');
  const [isUploading, setIsUploading] = useState(false);

  const handleFormSubmit = async (data) => {
    setIsUploading(true);
    const uploadedFileDetails = data.files.map((file) => file.uploadedFile);
    setIsUploading(false);
    onSubmit(uploadedFileDetails);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Close srMessage="Close file upload modal" onClose={onClose} />
      <Modal.Icon Icon={ArrowDownTrayIcon} />
      <Modal.Description>
        Upload one or more files to give context to the assistant.
      </Modal.Description>
      <Modal.Content>
        <form
          id={formName}
          className="space-y-3"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <InputFieldUploadFile
            form={form}
            name="files"
            rules={{
              required: 'Please upload at least one file',
            }}
            accept="application/pdf"
            multiple
          >
            <label
              htmlFor="files"
              className="block text-sm font-medium text-gray-700"
            >
              Files*
            </label>
          </InputFieldUploadFile>
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          submit
          form={formName}
          disabled={!files || files.length === 0 || isUploading}
        >
          {isUploading ? 'Uploading...' : 'Upload'}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AssistantAddActionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AssistantAddActionModal;
