import PropTypes from 'prop-types';
import React from 'react';

import findIndex from 'lodash/findIndex';
import noop from 'lodash/lodash';

import Avatar from 'components/Avatar';
import Tag from 'components/tags/Tag';
import getTagColors from 'utils/color/getTagColors';

const InputFieldTagsAutocompleteTag = ({
  showAvatar = false,
  tags = [],
  tagsIndex = null,
  tag = {},
  onDelete = noop,
}) => {
  const [bgColor, textColor] = getTagColors(
    findIndex(tagsIndex || tags, (t) => t.id === tag.id),
    tag.name?.length
  );
  const photoUrl = tag?.data?.photoUrl;
  return (
    <Tag
      className="!m-0 !rounded-[3px] h-7 items-center gap-1"
      bgColor={bgColor}
      textColor={textColor}
    >
      {showAvatar ? (
        <Avatar
          image={photoUrl ? { path: photoUrl } : null}
          className="w-5 h-5"
          alt={tag.name}
        />
      ) : null}
      <span>{tag.name}</span>
      <span
        role="button"
        tabIndex={0}
        className="ml-1 cursor-pointer hover:opacity-50"
        title={`Delete ${tag.name}`}
        onClick={onDelete}
      >
        &#x2715;
      </span>
    </Tag>
  );
};

InputFieldTagsAutocompleteTag.propTypes = {
  showAvatar: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  tagsIndex: PropTypes.arrayOf(PropTypes.shape({})),
  tag: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
  onDelete: PropTypes.func.isRequired,
};

export default InputFieldTagsAutocompleteTag;
