import PropTypes from 'prop-types';
import React from 'react';

import { Link, generatePath, useParams } from 'react-router-dom';

import { resourceStatus } from 'constants/contentManagementTypes';
import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';
import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';

import ContentManagementTag from '../ContentManagementResources/ContentManagementTag';

const renderStatusContent = (status, formattedDate) => {
  switch (status) {
    case resourceStatus.COMPLETED:
    case resourceStatus.DRAFTING:
      return (
        <span className="font-medium">{`Last edited ${
          formattedDate ?? ''
        }`}</span>
      );
    case resourceStatus.TODO:
      return <span className="font-medium">Not edited yet</span>;
    default:
      return null;
  }
};

const ContentManagementQnAList = ({ templates, slug }) => {
  const { tab } = useParams();

  return (
    <ul className="grid grid-cols-1 gap-6 py-6 pt-0 sm:grid-cols-2">
      {templates?.map((template) => {
        const formattedDate = getDateFromTimestamp(template?.lastEdited);

        return (
          <li
            key={template.type}
            className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
          >
            <Link
              to={generatePath(CONTENT_MANAGEMENT_PATH, {
                id: slug,
                documentId: template.type,
                tab,
              })}
              className="flex w-full h-full rounded-lg items-center justify-between space-x-6 p-6"
            >
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-sm font-medium text-gray-900">
                    {template.title || 'Untitled'}
                  </h3>
                  {template.status !== resourceStatus.TODO && (
                    <ContentManagementTag status={template.status} />
                  )}
                </div>
                <p className="mt-2 truncate text-xs font-light text-gray-500">
                  {renderStatusContent(template.status, formattedDate)}
                </p>
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

ContentManagementQnAList.propTypes = {
  templates: PropTypes.array,
  slug: PropTypes.string,
};

export default ContentManagementQnAList;
