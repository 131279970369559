import { tagsInsights } from 'constants/tags';

const defaultInsights = [
  {
    default: 1,
    order: 1,
    question: 'What problem is the company solving?',
    tag: tagsInsights[0],
  },
  {
    default: 2,
    order: 2,
    question: 'In simple terms, what does your startup product do?',
    tag: tagsInsights[3],
  },
  {
    default: 9,
    order: 3,
    question: 'Why did you create this product?',
    tag: tagsInsights[0],
  },
  {
    default: 3,
    order: 4,
    question: 'What traction can you demonstrate?',
    tag: tagsInsights[1],
  },
  {
    default: 4,
    order: 5,
    question: 'Who are your competitors?',
    tag: tagsInsights[5],
  },
  {
    default: 5,
    order: 6,
    question: 'Why is the product better than its competitors?',
    tag: tagsInsights[7],
  },
  {
    default: 7,
    order: 7,
    question: 'How do you make (or plan to make) money?',
    tag: tagsInsights[7],
  },
  {
    default: 8,
    order: 8,
    question: 'How big is the market your product is addressing?',
    tag: tagsInsights[7],
  },
];

export default defaultInsights;
