import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { NoSymbolIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import useGetResource from 'hooks/useGetResource';

import Spinner from '../Spinner';

import MvprDeeplinkDocument from './MvprDeeplinkDocument';
import MvprDeeplinkJournalistList from './MvprDeeplinkJournalistList';
import MvprDeeplinkOpportunity from './MvprDeeplinkOpportunity';
import MvprDeeplinkThread from './MvprDeeplinkThread';

const baseClassName =
  'p-6 bg-white rounded-md shadow-md border border-gray-100';
const MvprDeeplink = ({ url = '' }) => {
  const { type, data, isFetching } = useGetResource(url);
  const MvprDeeplinkPreview = useMemo(() => {
    if (type === 'Opportunity') {
      return MvprDeeplinkOpportunity;
    }
    if (type === 'Thread') {
      return MvprDeeplinkThread;
    }
    if (type === 'Document') {
      return MvprDeeplinkDocument;
    }
    if (type === 'Journalist List') {
      return MvprDeeplinkJournalistList;
    }

    return null;
  }, [type]);

  if (!url) {
    return null;
  }

  if (type === 'unknown') {
    return (
      <div
        className={classNames(
          baseClassName,
          ' text-red-500 flex flex-col justify-center items-center'
        )}
      >
        <NoSymbolIcon className="h-10 w-10 " />
        <p className="font-bold">Unknown resource type</p>
        <div className="flex text-gray-700 flex-col items-center justify-center text-xs text-center">
          <span>Please make sure the url is referencing one of these:</span>
          <ul className="flex gap-4 list-inside list-disc text-left mx-auto">
            <li className="list-none">Thread</li>
            <li>Journalist List</li>
            <li>Opportunity</li>
            <li>Document</li>
          </ul>
        </div>
      </div>
    );
  }

  if (!data && !isFetching) {
    return (
      <div
        className={classNames(
          baseClassName,
          'text-red-500 flex flex-col justify-center items-center'
        )}
      >
        <NoSymbolIcon className="h-10 w-10 " />
        <p className="font-bold">{type} not found</p>
        <p className="flex text-gray-700 flex-col items-center justify-center text-xs text-center">
          Please make sure the url is referencing a correct {type}.
        </p>
      </div>
    );
  }

  return (
    <div className={classNames(baseClassName, 'relative')}>
      {isFetching ? (
        <div className="inset-0 absolute bg-white/20">
          <Spinner />
        </div>
      ) : null}
      {!isFetching && <MvprDeeplinkPreview data={data} />}
    </div>
  );
};

MvprDeeplink.propTypes = {
  url: PropTypes.string,
};

export default MvprDeeplink;
