import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import noop from 'lodash/noop';
import { Controller } from 'react-hook-form';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';
import useAddSavedDraftNotification from 'hooks/useAddSavedDraftNotification';
import useCurrentDocumentContext from 'routes/ContentManagement/hooks/useCurrentDocumentContext';
import useDocumentState from 'routes/ContentManagement/hooks/useDocumentState';

import ContentManagementDocumentFormTitle from '../ContentManagementDocumentFormTitle';
import ContentManagementDocumentResponseFormThreadsModal from '../ContentManagementDocumentResponseFormThreadsModal';
import ContentManagementResponseFormAudiences from '../ContentManagementResponseFormAudiences';
import ContentManagementResponseFormEditorAuthorDropdown from '../ContentManagementResponseFormEditorAuthorDropdown';
import ContentManagementResponseFormResources from '../ContentManagementResponseFormResources';
import ContentManagementResponseFormThread from '../ContentManagementResponseFormThread';
import DocumentTag from '../DocumentTag';
import GenerateContentWarning from '../GenerateContentWarning';

import ContentManagementArticleIdeasList from './ArticleIdea/ContentManagementArticleIdeasList';

const ContentDocumentArticleIdea = ({
  control,
  reset = noop,
  messageError = [],
  saveDocumentDebounced = noop,
}) => {
  const containerRef = useRef();
  const { currentDocument, updateDocument } = useCurrentDocumentContext();

  const { companyData } = useCompanyData();
  const { showError } = useNotifications();
  const addSavedDraftNotification = useAddSavedDraftNotification(containerRef);
  const [generatedIdeas, setGeneratedIdeas] = useState(
    currentDocument?.generatedIdeas ?? []
  );
  const [isThreadsModalOpen, setIsThreadsModalOpen] = useState(false);

  const { data: resourcesList, loading: isContentLoading } = useAPIRequest({
    service: 'MOD',
    endpoint: `/resources/${companyData.uid}`,
    method: 'GET',
    initialData: [],
  });

  const { fetchData: generateContent, loading: isLoading } = useAPIRequest({
    service: 'MOD',
    endpoint: `/ai/generate-document-content/${companyData.uid}/${currentDocument.id}`,
    method: 'POST',
  });

  const { fetchData: addThreadDataOnDocument } = useAPIRequest({
    service: 'MOD',
    endpoint: `/documents/${companyData.uid}/selectThread`,
    method: 'POST',
  });

  const { data: teamMembers, loading: isTeamMembersLoading } = useAPIRequest({
    endpoint: `/companies/${companyData?.uid}/founders`,
    service: 'UCD',
    initialData: [],
  });

  const { data, loading: areThreadsLoading } = useAPIRequest({
    service: 'MOD',
    endpoint: `/companies/${companyData?.uid}/threads`,
    initialData: { threads: [] },
  });
  const { threads } = data;

  const activeThreads = threads.filter((thread) => thread.status === 'sent');

  const documentAudiences = useMemo(
    () => currentDocument?.audiences ?? [],
    [currentDocument]
  );

  const documentCustomResources = useMemo(
    () => currentDocument?.customResources ?? [],
    [currentDocument]
  );

  const contentIdeation = resourcesList.find(
    (doc) => doc.type === 'content-ideation-qa'
  );
  const { isContentIdeationCompleted, isAIGenerationAvailable } =
    useDocumentState({ contentIdeation, newDocument: currentDocument });

  const showGenerateContentWarning =
    isAIGenerationAvailable && !isContentIdeationCompleted;
  const shouldShowContentWarning =
    !isContentLoading && showGenerateContentWarning;

  const shouldShowAIGenerateFields =
    !isContentLoading && isAIGenerationAvailable && isContentIdeationCompleted;

  const handleAIRequest = useCallback(async () => {
    try {
      const response = await generateContent();

      setGeneratedIdeas(response);
    } catch (error) {
      showError({
        message: `There was an error communicating with the AI`,
      });
    }
    return 'Something went wrong.';
  }, [generateContent, showError]);

  useEffect(() => {
    if (currentDocument) {
      reset({
        document: {
          title: currentDocument?.title || '',
          type: currentDocument?.type || '',
          audiences: currentDocument?.audiences || [],
          threadData: currentDocument?.threadData || {},
          generatedIdeas: currentDocument?.generatedIdeas || [],
          author: currentDocument?.author || '',
          draft: currentDocument?.draft || {
            content: '',
            plainText: '',
          },
        },
      });
    }
  }, [currentDocument, reset]);

  return (
    <>
      <div className="relative">
        <div
          data-test-id="contentManagement-responseEditor"
          className="relative flex flex-col gap-6"
          ref={containerRef}
        >
          <Controller
            name="document"
            control={control}
            defaultValue={{
              title: currentDocument?.title || '',
              type: currentDocument?.type || '',
              audiences: currentDocument?.audiences || [],
              generatedIdeas: currentDocument?.generatedIdeas || [],
              author: currentDocument?.author || '',
              threadData: currentDocument?.threadData || {},
              draft: currentDocument?.draft?.content || {
                content: '',
                plainText: '',
              },
            }}
            rules={{
              validate: {
                requiredTitle: (value) =>
                  value.title !== '' || 'Please enter a title',
              },
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <div>
                  <DocumentTag type={currentDocument.type} />
                </div>
                <ContentManagementDocumentFormTitle
                  value={value.title}
                  control={control}
                  messageError={{ title: messageError.document }}
                  onChange={(e) => {
                    onChange({ ...value, title: e.target.value });
                    saveDocumentDebounced(
                      {
                        title: e.target.value,
                        type: currentDocument?.type,
                        audiences: currentDocument?.audiences,
                        author: currentDocument?.author,
                        draft: currentDocument?.draft,
                      },
                      addSavedDraftNotification
                    );
                  }}
                />

                {!isTeamMembersLoading && currentDocument && (
                  <ContentManagementResponseFormEditorAuthorDropdown
                    founders={teamMembers}
                    author={
                      currentDocument?.author || 'Select a team member...'
                    }
                    onChange={(selectedAuthor) => {
                      updateDocument(
                        { author: selectedAuthor.label },
                        addSavedDraftNotification
                      );
                    }}
                  />
                )}

                {currentDocument && (
                  <ContentManagementResponseFormAudiences
                    audiences={documentAudiences}
                    onChange={async (newAudiences) => {
                      updateDocument(
                        {
                          audiences: newAudiences,
                        },
                        addSavedDraftNotification
                      );

                      return true;
                    }}
                  />
                )}

                {currentDocument && (
                  <ContentManagementResponseFormResources
                    resources={documentCustomResources}
                    onChange={(newResources) => {
                      updateDocument({
                        customResources: newResources,
                      });
                    }}
                  />
                )}

                <ContentManagementResponseFormThread
                  thread={currentDocument?.threadData}
                  onSelectThread={async (threadData) => {
                    const { threadData: threadDta } =
                      await addThreadDataOnDocument({
                        body: {
                          threadId: threadData.id,
                          documentId: currentDocument.id,
                        },
                      });
                    updateDocument({ threadData: threadDta });
                  }}
                  onRemove={() =>
                    updateDocument(
                      {
                        threadData: {},
                      },
                      addSavedDraftNotification
                    )
                  }
                />

                {isThreadsModalOpen && !areThreadsLoading && (
                  <ContentManagementDocumentResponseFormThreadsModal
                    open={isThreadsModalOpen}
                    threads={activeThreads}
                    onSelect={async (threadData) => {
                      const { threadData: threadDta } =
                        await addThreadDataOnDocument({
                          body: {
                            threadId: threadData.id,
                            documentId: currentDocument.id,
                          },
                        });
                      updateDocument({ threadData: threadDta });
                      setIsThreadsModalOpen(false);
                    }}
                    onClose={() => setIsThreadsModalOpen(false)}
                  />
                )}

                <div>
                  {shouldShowContentWarning && (
                    <GenerateContentWarning companyData={companyData} />
                  )}

                  {shouldShowAIGenerateFields && (
                    <div className="mt-2 mb-8">
                      <Button
                        type="secondary"
                        disabled={isLoading}
                        onClick={handleAIRequest}
                      >
                        Generate article ideas
                      </Button>
                    </div>
                  )}

                  {generatedIdeas && (
                    <ContentManagementArticleIdeasList
                      ideas={generatedIdeas}
                      setIdeas={setGeneratedIdeas}
                      updateIdeas={updateDocument}
                    />
                  )}
                </div>
              </>
            )}
          />
        </div>
      </div>

      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-100 bg-opacity-50 flex justify-center items-center z-50">
          <Spinner />
        </div>
      )}
    </>
  );
};

ContentDocumentArticleIdea.propTypes = {
  control: PropTypes.object,
  messageError: PropTypes.object,
  saveDocumentDebounced: PropTypes.func,
  reset: PropTypes.func,
};

export default ContentDocumentArticleIdea;
