import PropTypes from 'prop-types';
import React from 'react';

import { Bars3Icon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

import HomeLogo from 'components/HomeLogo';

const AgencyNavbarMobileHeader = ({
  sidebarOpen = false,
  setSidebarOpen = noop,
}) => (
  <div className="lg:hidden">
    <div className="sticky top-0 z-40 lg:mx-auto lg:max-w-7xl lg:px-8">
      <div className="flex w-full h-16 justify-between items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
        <HomeLogo />
        {!sidebarOpen ? (
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        ) : null}
      </div>
    </div>
  </div>
);

AgencyNavbarMobileHeader.propTypes = {
  sidebarOpen: PropTypes.bool,
  setSidebarOpen: PropTypes.func,
};

export default AgencyNavbarMobileHeader;
