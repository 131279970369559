import PropTypes from 'prop-types';
import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

import Callout from 'components/Callout/Callout';
import Modal from 'components/Modal';

const CompanySectionsHowToModal = ({ open = false, onClose = noop }) => (
  <Modal open={open} widthClass="sm:max-w-4xl">
    <Modal.Close srMessage="Close helper modal" onClose={onClose} />
    <Modal.Description>
      <div className="text-left mt-4 text-gray-800 leading-relaxed">
        <h3 className="text-xl font-semibold">How to build your Press Page</h3>
        <p className="mt-1.5">
          We created the Press Page with the help of journalists; asking them
          what type of information they want to see from companies.
          <p className="mt-1.5">
            We&apos;ll now take you on a brief walkthrough of each of the Press
            Page sections, so you know where to add the most relevant content.
            You can also see our own{' '}
            <a
              className="underline text-teal-500"
              target="_blank"
              href="https://app.mvpr.io/company/mvpr"
              rel="noreferrer"
            >
              Press Page
            </a>{' '}
            here for reference.
          </p>
        </p>

        <Callout
          type="info"
          Icon={InformationCircleIcon}
          testId="company-pressPage-empty-section-hint"
        >
          Empty sections will not show on your published page.
        </Callout>

        <h4 className="mt-4 text-lg font-semibold">📸 Milestones</h4>
        <p className="mt-1.5">
          To showcase significant achievements, developments, and historical
          milestones in your company&apos;s journey.
        </p>

        <p className="mt-1.5">
          Examples include: the date your company was founded, fundraising,
          senior hires, grant, awards etc. We suggest adding between three and
          seven milestones so as not to overload your page.
        </p>
        <h4 className="mt-3 text-lg font-semibold">🎥 Videos</h4>
        <p className="mt-1.5">
          To provide visual content, such as promotional videos and product
          demos, to enhance journalists&apos; understanding of what you do. You
          can also add videos of your spokespeople speaking at events, if you
          have any - that helps journalists get an idea of their speaker/expert
          qualities.
        </p>
        <p className="mt-1.5 text-md">
          At the moment we only support Vimeo and Youtube, but we have more
          integrations coming in the future.
        </p>
        <h4 className="mt-3 text-lg font-semibold">🗞 Recent News</h4>
        <p className="mt-1.5">
          To highlight the most current and relevant news and updates about the
          company, making it easy for journalists to access recent developments.
          You can add the media coverage you are most proud of too, just
          don&apos;t include anything that&apos;s over three years old as this
          is outdated.
        </p>
        <h4 className="mt-3 text-lg font-semibold">✨ Insights</h4>
        <p className="mt-1.5">
          To give a snapshot of what you do and why you&apos;re different. These
          are the questions we created in collaboration with journalists who
          want to get to the most useful, no bullsh*** snippets of information
          about your company.
        </p>
        <p className="mt-1.5 text-md">
          Craft short, snappy answers that avoid self-promotional language and
          hyperlatives or obvious, generic adjectives
          (&quot;revolutionary&quot;, &quot;innovative&quot;, &quot;easy to
          use&quot; etc). Try and keep all sections succinct and clear - every
          word counts!
        </p>
        <h4 className="mt-3 text-lg font-semibold">🗯 Quotes</h4>
        <p className="mt-1.5">
          To feature notable quotes from company executives on their key topics
          of expertise, which journalists can either use to add credibility and
          perspective to their articles, or simply to get a sense of what types
          of insights they can come to you for.
        </p>
        <p className="mt-1.5 text-md">
          Some companies also use this section to include client testimonials -
          though we&apos;d suggest only doing this if the clients in question
          are particularly high profile.
        </p>
        <h4 className="mt-3 text-lg font-semibold">💾 Downloads</h4>
        <p className="mt-1.5">
          To provide downloadable resources for journalists to use to illustrate
          articles, such as product visuals, high resolution pictures of the
          team, individual headshots etc...{' '}
        </p>
        <p className="mt-1.5 text-md">
          This is an important section to keep updated as it makes
          journalists&apos; life a lot easier when writing about you.
        </p>
      </div>
    </Modal.Description>
    <Modal.Content />
  </Modal>
);

CompanySectionsHowToModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default CompanySectionsHowToModal;
