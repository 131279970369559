import React from 'react';

import { useParams } from 'react-router-dom';
import SwiperCore, { Navigation } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import { tabNames } from 'constants/settingsTabs';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import SettingsNavbarNavbarTabs from './SettingsNavbarNavbarTabs';

SwiperCore.use([Navigation]);

const SettingsNavbar = () => {
  const { claimsAdmin } = useUserData();
  const { tab } = useParams();
  const { isActualOwnerOfCompany, companyData } = useCompanyData();

  return (
    <div className="sticky top-0 z-10 -mx-6 -mt-6 mb-8">
      <div className="w-full flex justify-between items-center bg-white shadow-sm text-gray-300 border-b border-gray-200">
        <SettingsNavbarNavbarTabs />
        {tab !== tabNames.USER && claimsAdmin && !isActualOwnerOfCompany && (
          <p className="pr-4 text-gray-500 ">{companyData?.name}</p>
        )}
      </div>
    </div>
  );
};

export default SettingsNavbar;
