import PropTypes from 'prop-types';
import React from 'react';

import { BoltIcon, NewspaperIcon } from '@heroicons/react/20/solid';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';

const ReportsCoverageStories = ({ lastCoverages = [] }) => {
  if (!lastCoverages.length) {
    return (
      <div className="mt-1 w-full flex flex-col justify-center items-center text-gray-300 text-sm">
        <NewspaperIcon className="w-14 h-14 md:w-20 md:h-20" />
        <p>Coverage will be shown here once it&apos;s available</p>
      </div>
    );
  }

  return (
    <div className="mt-1 w-full">
      <div className="flex flex-col">
        {lastCoverages.map((coverage) => (
          <a
            key={coverage.title}
            className="flex space-x-4 p-2 items-center hover:bg-gray-100"
            href={coverage.link}
            target="__blank"
          >
            <div className="w-16 h-16 rounded-full overflow-hidden shrink-0 border">
              {coverage.journalist?.publication?.image ? (
                <img
                  src={coverage.journalist.publication.image}
                  alt={coverage.title}
                  className="object-cover w-16 h-16"
                />
              ) : (
                <BoltIcon className="w-16 h-16 p-2 text-teal-500" />
              )}
            </div>
            <div>
              <h4 className="text-lg font-medium text-gray-700 line-clamp-1">
                {coverage.title}
              </h4>
              <p className="w-full flex items-center text-sm text-gray-400 font-normal lg:space-x-2 overflow-clip">
                {coverage.journalist.name && (
                  <span className="truncate hidden lg:block">
                    {coverage.journalist.name}
                  </span>
                )}

                {coverage.journalist?.publication?.name && (
                  <>
                    <span className="hidden lg:block">&middot;</span>
                    <span className="truncate">
                      {coverage.journalist.publication.name}
                    </span>
                  </>
                )}
                {coverage.journalist?.publication?.domainAuthority && (
                  <>
                    <span className="hidden lg:block">&middot;</span>
                    <div className="hidden lg:block">
                      <DomainAuthorityPill
                        domainAuthority={Number(
                          coverage.journalist.publication.domainAuthority
                        )}
                      />
                    </div>
                  </>
                )}
              </p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

ReportsCoverageStories.propTypes = {
  lastCoverages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default ReportsCoverageStories;
