import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';

import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Callout from 'components/Callout/Callout';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useCompanyEmailGrant from 'hooks/useCompanyEmailGrant';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

import InboxMessageResponseFormEditor from './InboxMessageResponseFormEditor';
import InboxMessageResponseFormEditorAttachButton from './InboxMessageResponseFormEditorAttachButton';
import InboxMessageResponseFormEditorAttachments from './InboxMessageResponseFormEditorAttachments';
import InboxMessageResponseFormEditorProvider from './InboxMessageResponseFormEditorProvider';
import InboxMessageResponseFormErrors from './InboxMessageResponseFormErrors';
import InboxMessageResponseFormScheduleButton from './InboxMessageResponseFormScheduleButton';
import InboxMessageResponseFormSendButton from './InboxMessageResponseFormSendButton';
import InboxMessageResponseFormSendPreviewButton from './InboxMessageResponseFormSendPreviewButton';
import InboxMessageResponseFormValidateButton from './InboxMessageResponseFormValidateButton/InboxMessageResponseFormValidateButton';

const showBorder = ({ isActiveConversation, showDetails, descriptionHtml }) => {
  if (isActiveConversation) {
    return true;
  }

  if (!descriptionHtml || (showDetails && descriptionHtml)) {
    return true;
  }

  return false;
};

const createCanRespond = ({ messages, opportunity }) => {
  const hasDeadlinePassed = new Date(opportunity?.deadline) < new Date();
  if (opportunity && opportunity.deadline && hasDeadlinePassed) {
    const hasJournalistResponse = messages?.some(
      (message) => message.isFromJournalist
    );

    return hasJournalistResponse;
  }

  return true;
};

const InboxMessageResponseForm = ({ onSuccess } = {}) => {
  const { currentThread, threadDetails } = useInboxContext();
  const { opportunity, isInitialDraft, messages } = threadDetails?.data || {};
  const canRespond = useMemo(
    () => createCanRespond({ messages, opportunity }),
    [messages, opportunity]
  );
  const lastMessage = messages?.length ? messages[messages.length - 1] : {};
  const isLastMessageScheduledAndNotSent =
    lastMessage.dateSendAfter && !lastMessage.dateSent;
  const { claimsAdmin } = useUserData();
  const { companyData } = useCompanyData();
  const { data: emailGrant } = useCompanyEmailGrant(companyData?.uid);
  const [searchParams] = useSearchParams();
  const containerRef = useRef(null);

  if (!currentThread) {
    return <div />;
  }

  return (
    <div
      className={classNames('p-4 w-full relative', {
        'border-t': showBorder({
          isActiveConversation: currentThread.isActiveConversation,
          showDetails: searchParams.get('showDetails') === 'true',
          descriptionHtml: opportunity?.descriptionHtml,
        }),
      })}
      data-intercom-id="inbox-messages-form"
    >
      {isLastMessageScheduledAndNotSent ? (
        <div className="bg-white/80 absolute inset-0 flex items-center justify-center">
          <Callout type="warning">
            You can only reply to this thread once your scheduled message is
            sent
          </Callout>
        </div>
      ) : null}

      {canRespond ? (
        <InboxMessageResponseFormEditorProvider containerRef={containerRef}>
          <div
            data-test-id="inboxMessage-responseForm"
            className="flex flex-col w-full gap-4"
            ref={containerRef}
          >
            <InboxMessageResponseFormEditor />

            <InboxMessageResponseFormErrors />

            <InboxMessageResponseFormEditorAttachments />

            <div className="flex flex-col md:flex-row gap-2 items-center justify-between">
              <InboxMessageResponseFormEditorAttachButton />
              <InboxMessageResponseFormSendPreviewButton />
              {isInitialDraft ? (
                <InboxMessageResponseFormValidateButton onSuccess={onSuccess} />
              ) : null}
              {claimsAdmin || !isInitialDraft ? (
                <InboxMessageResponseFormScheduleButton
                  onSuccess={onSuccess}
                  className="!ml-0"
                />
              ) : null}
              {(isInitialDraft && (emailGrant || claimsAdmin)) ||
              !isInitialDraft ? (
                <InboxMessageResponseFormSendButton onSuccess={onSuccess}>
                  Send
                </InboxMessageResponseFormSendButton>
              ) : null}
            </div>
          </div>
        </InboxMessageResponseFormEditorProvider>
      ) : (
        <div className="flex items-center justify-center my-10">
          <Callout type="warning" wrapperClassName="!m-0">
            You can no longer respond to this opportunity as the deadline has
            passed and the journalist hasn&apos;t responded yet.
          </Callout>
        </div>
      )}
      <ReactTooltip
        id="tooltip-inbox-messages-form-sendButton"
        className="hidden lg:block max-w-xs text-sm"
      />
    </div>
  );
};

InboxMessageResponseForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default InboxMessageResponseForm;
