import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import PageWrapper from 'components/PageWrapper';
import Spinner from 'components/Spinner';
import ErrorPage from 'components/errors/ErrorPage';
import useOpportunity from 'hooks/useOpportunity';
import InboxContextProvider from 'routes/Inbox2/context/InboxContext';
import getPageTitle from 'utils/getPageTitle';

import ShareOpportunityResponseModal from './ShareOpportunityResponseModal';

const ShareOpportunityPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  const { showError } = useNotifications();

  const oppId = new URLSearchParams(location.search).get('id');

  const { data: opportunity, isLoading } = useOpportunity(oppId);

  const isExpired = useMemo(() => {
    if (isLoading) {
      return true;
    }
    const { deadline } = opportunity;

    if (!deadline) {
      // no deadline -> ongoing opp
      return false;
    }

    const currentTime = Date.now();

    return currentTime > new Date(deadline);
  }, [opportunity, isLoading]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    if (!isLoading && (!opportunity || isExpired)) {
      showError({
        message:
          'There was an error fetching the opportunity or the opportunity has expired. Please try again.',
      });
      setHasError(true);
    }
  }, [isLoading, opportunity, showError, isExpired]);

  if (isLoading) {
    return <Spinner />;
  }

  if (hasError) {
    return (
      <ErrorPage message="Something went wrong. Please try again later." />
    );
  }

  return (
    <PageWrapper className="px-6 pb-6 flex grow flex-col">
      <Helmet>
        <title>{getPageTitle('Shared Opportunities')}</title>
      </Helmet>
      <InboxContextProvider>
        {isModalOpen && (
          <ShareOpportunityResponseModal
            open={isModalOpen}
            opportunity={opportunity}
            onClose={handleCloseModal}
          />
        )}
      </InboxContextProvider>
    </PageWrapper>
  );
};

export default ShareOpportunityPage;
