import React from 'react';

import { Link } from 'react-router-dom';

import { LOGIN_PATH } from 'constants/paths';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useHandleLogout from 'hooks/useHandleLogout';

// Shows a link for logged-out users on the right side of the top nav bar
const AccountLink = () => {
  const { uid, isAnonymous } = useUserData();
  const handleLogout = useHandleLogout();
  if (uid && !isAnonymous) {
    return (
      <Link
        to="#"
        onClick={handleLogout}
        className="border-transparent text-teal-500 hover:border-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
        data-test-id="accountLink-logout"
      >
        Sign out
      </Link>
    );
  }

  return (
    <Link
      to={LOGIN_PATH}
      className="border-transparent text-teal-500 hover:border-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
      data-test-id="accountLink-login"
    >
      Sign in
    </Link>
  );
};

export default AccountLink;
