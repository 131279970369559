import PropTypes from 'prop-types';
import React from 'react';

import {
  Link,
  generatePath,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import JournalistTeaserWithModal from 'components/JournalistTeaserWithModal';
import OpportunityTimeLeft from 'components/opportunities/opportunity/OpportunityTimeLeft';
import ThreadTag from 'components/tags/ThreadTag';
import { MESSAGES_PATH_FULL } from 'constants/paths';

import InboxMessageThreadDetailOpportunitySubject from './InboxMessageThreadDetailOpportunitySubject';

const InboxMessageThreadDetailOpportunity = ({
  journalist = {},
  type = '',
  intent = '',
  deadline,
}) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <div className="flex flex-col gap-2">
      <p className="lg:hidden text-sm text-teal-500 mb-2">
        <Link
          to={{
            pathname: generatePath(MESSAGES_PATH_FULL, {
              id,
            }),
            search: searchParams.toString(),
          }}
          className="hover:underline"
        >
          ← Back to threads
        </Link>
      </p>
      <div className="flex gap-2 items-center">
        <ThreadTag
          threadIntent={intent}
          testId={`inboxMessage-${type}Context-tag`}
        />
      </div>

      <InboxMessageThreadDetailOpportunitySubject />

      <div className="flex flex-wrap gap-3 overflow-visible w-full items-center">
        {journalist && <JournalistTeaserWithModal journalist={journalist} />}
        <div className="flex gap-2">
          <DomainAuthorityPill
            domainAuthority={journalist?.publication?.domainAuthority}
          />
          {deadline && (
            <OpportunityTimeLeft
              wrapperClassName="m-0"
              expired={new Date(deadline) < new Date()}
              deadline={new Date(deadline)}
              standalone
            />
          )}
        </div>
      </div>
    </div>
  );
};

InboxMessageThreadDetailOpportunity.propTypes = {
  journalist: PropTypes.object,
  type: PropTypes.string,
  intent: PropTypes.string,
  deadline: PropTypes.string,
};

export default InboxMessageThreadDetailOpportunity;
