import PropTypes from 'prop-types';
import React from 'react';

import InboxThreadsThread from './InboxThreadsThread/InboxThreadsThread';

const InboxThreads = ({ threads = [] }) => (
  <ul data-test-id="inbox-threadsList" data-intercom-id="inbox-threads">
    {threads.map((thread) => (
      <InboxThreadsThread key={thread.id} thread={thread} />
    ))}
  </ul>
);

InboxThreads.propTypes = {
  threads: PropTypes.array.isRequired,
};

export default InboxThreads;
