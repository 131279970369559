import PropTypes from 'prop-types';
import React from 'react';

import { bgColors, documentTypes } from 'constants/contentManagementTypes';

const TypeOptionItem = ({
  icon,
  label,
  title,
  description,
  createDocument,
  isLoading,
}) => {
  const type = documentTypes.find((tag) => tag.label === label).name || null;

  return (
    <li className="flow-root">
      <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 hover:bg-gray-50">
        <div
          className={`flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-${bgColors[type]}`}
        >
          {icon}
        </div>

        <div>
          <h3 className="text-sm font-medium text-gray-900">
            <button
              type="button"
              onClick={createDocument}
              disabled={isLoading}
              className="focus:outline-none relative inline-flex w-full items-center text-gray-900 hover:text-gray-700"
            >
              <span className="text-left">{title}</span>
              <span aria-hidden="true" className="ml-2">
                {' '}
                &rarr;
              </span>
            </button>
          </h3>
          <p className="mt-1 text-xs text-gray-500">{description}</p>
        </div>
      </div>
    </li>
  );
};

TypeOptionItem.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  createDocument: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default TypeOptionItem;
