import React from 'react';

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

import useAgencySearchParams from './useAgencySearchParams';

const AgencySearch = () => {
  const { query, setSearchParams } = useAgencySearchParams();

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex justify-between">
        <InputFieldText
          type="text"
          autoComplete="company"
          containerClassName="w-full mr-2 bg-white"
          name="search"
          placeholder="Search by company name"
          value={query}
          onChange={(e) => setSearchParams('query', e.target.value)}
        >
          <InputFieldText.Prefix>
            <MagnifyingGlassIcon className="w-4 h-4" />
          </InputFieldText.Prefix>
        </InputFieldText>
      </div>
    </div>
  );
};

export default AgencySearch;
