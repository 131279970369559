import { getDownloadURL, ref } from 'firebase/storage';
import { useAuth, useStorage } from 'reactfire';

const BASE_URL = process.env.REACT_APP_JPD_URL;

const useUpdatePublication = () => {
  const auth = useAuth();
  const storage = useStorage();

  const updatePublication = async (publication = {}) => {
    const jwt = await auth.currentUser?.getIdToken();

    const imageUrl = publication?.image?.localName
      ? await getDownloadURL(ref(storage, publication?.image?.path))
      : publication?.image?.path;

    const response = await fetch(`${BASE_URL}/publications`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        source: 'adminUi',
        name: publication.name,
        fields: {
          website: publication.website,
          domainAuthority: publication.domainAuthority,
          defaultLanguage: publication.defaultLanguage,
          country: publication.country,
          city: publication.city,
          phoneNumber: publication.phoneNumber,
          description: publication.description,
          twitterHandle: publication.twitterHandle,
          linkedInHandle: publication.linkedInHandle,
          ...(imageUrl ? { imageUrl } : {}),
        },
      }),
    });
    return response.json();
  };

  return updatePublication;
};

export default useUpdatePublication;
