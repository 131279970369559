import React, { useMemo, useState } from 'react';

import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';

import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';

import useGetAnnouncement from './hooks/useGetAnnouncement';
import useUpsertAnnouncement from './hooks/useUpsertAnnouncement';

const AnnouncementSellIn = () => {
  const { announcementId } = useParams();
  const { data: announcement } = useGetAnnouncement();
  const [sellIn, setSellIn] = useState(announcement?.sellIn || '');
  const { mutateAsync: upsertAnnouncement } = useUpsertAnnouncement();
  const debouncedUpsertAnnouncement = useMemo(
    () => debounce(upsertAnnouncement, 1000),
    [upsertAnnouncement]
  );

  return (
    <div className="bg-white p-4 flex flex-col gap-4">
      <h3 className="font-medium whitespace-nowrap">Sell-in</h3>
      <InputFieldTextEditor
        value={sellIn}
        height={300}
        onChange={(value) => {
          setSellIn(value);
          debouncedUpsertAnnouncement({ id: announcementId, sellIn: value });
        }}
      />
    </div>
  );
};

export default AnnouncementSellIn;
