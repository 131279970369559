import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useUserName from 'hooks/useUserName';

const useSignature = () => {
  const { claimsAdmin } = useUserData();
  const userName = useUserName();
  const { companyData } = useCompanyData();
  const companyName = companyData?.name || '';

  if (claimsAdmin) {
    return '';
  }

  return `<p>Best,<br />${userName} | ${companyName}</p>`;
};

export default useSignature;
