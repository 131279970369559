import PropTypes from 'prop-types';
import React from 'react';

import JournalistTeaserWithModal from 'components/JournalistTeaserWithModal';

const MvprDeeplinkOpportunity = ({
  data: { title, description, journalist } = {},
}) => (
  <div className="flex flex-col gap-2 text-gray-700">
    <JournalistTeaserWithModal journalist={journalist} />
    <h2 className="font-bold">{title}</h2>
    <p className="line-clamp-3 text-sm">{description}</p>
  </div>
);

MvprDeeplinkOpportunity.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    journalist: PropTypes.object,
  }),
};

export default MvprDeeplinkOpportunity;
