import PropTypes from 'prop-types';
import React from 'react';

import PublicationImage from 'components/images/PublicationImage';

const AdminCoveragesTableRowPublication = ({ logo = {}, name = '' }) => (
  <div className="flex items-center gap-2">
    <div className="relative w-8 h-8 flex items-center justify-center">
      <PublicationImage url={logo} name={`${name} logo`} />
    </div>
    {name}
  </div>
);

AdminCoveragesTableRowPublication.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.object,
};

export default AdminCoveragesTableRowPublication;
