import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';
import InputFieldToggle from 'components/form/inputFields/InputFieldToggle';
import CoverageExtraFields from 'routes/Reports/ReportsCoverage/CoverageExtraFields';
import { todaysDateObj } from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

import AdminAddOpportunityModalDeadline from './AdminAddOpportunityModalDeadline';

const AdminAddOpportunityModal = ({
  open,
  onClose,
  onSubmit,
  companiesData = {},
  opportunity = null,
}) => {
  const [loading, setLoading] = useState(false);
  const formName = 'addOpportunityForm';
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      ...opportunity,
      type: opportunity?.tags?.[0] || '✨ Perfect Match',
      journalistName: opportunity?.journalist?.name,
      publicationName: opportunity?.journalist?.publication?.name,
      journalistId: opportunity?.journalist?.id,
      deadline: opportunity?.deadline
        ? getDateObjFromTimestamp(opportunity?.deadline)
        : todaysDateObj,
      ongoing: !opportunity?.deadline,
      description: {
        html: opportunity?.descriptionHtml,
        text: opportunity?.description,
      },
      targetedCompanies:
        opportunity?.targetedCompanies.map((companyId) => {
          const company = companiesData.find((c) => c.id === companyId);
          return {
            id: company.id,
            name: company.name,
          };
        }) || [],
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = form;

  const exclusive = watch('exclusive');
  const isOngoing = watch('ongoing');

  const onOpportunityAddRequested = async (data) => {
    setLoading(true);
    await onSubmit(data);
    setLoading(false);
  };

  return (
    <Modal open={open} testId="opportunityResponseModal">
      <Modal.Close
        srMessage="Close Add Opportunity Modal"
        onClose={onClose}
        testId="opportunityResponseModal-close"
      />
      <Modal.Title testId="dialog-1-title">
        {opportunity ? 'Edit' : 'Add'} opportunity
      </Modal.Title>
      <Modal.Content>
        <form
          onSubmit={handleSubmit(onOpportunityAddRequested)}
          id={formName}
          className="space-y-3"
        >
          <InputFieldText
            {...register('title', {
              required: 'Please put in an title',
              maxLength: {
                value: 140,
                message: 'Please limit this to 140 characters',
              },
            })}
            placeholder="Type in the title..."
            errors={errors}
          >
            <InputFieldText.Label>Title*</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('replySubject', {
              maxLength: {
                value: 140,
                message: 'Please limit this to 140 characters',
              },
            })}
            placeholder="Type in the reply subject..."
            errors={errors}
          >
            <InputFieldText.Label>Reply Subject</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('type', {
              maxLength: {
                value: 140,
                message: 'Please limit this to 60 characters',
              },
            })}
            placeholder="Type in the opportunity type..."
            errors={errors}
          >
            <InputFieldText.Label>Type</InputFieldText.Label>
          </InputFieldText>

          <div className="flex flex-col">
            <Label className="mb-2" htmlFor="query">
              Description*
            </Label>
            <Controller
              name="description"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Please put in a description',
                },
              }}
              render={({ field: { onChange } }) => (
                <InputFieldTextEditor
                  name="description"
                  height={300}
                  initialValue={opportunity?.descriptionHtml}
                  onChange={(html, text) => {
                    onChange({ html, text });
                  }}
                />
              )}
            />

            {errors.description && (
              <ErrorMessage>{errors.description.message}</ErrorMessage>
            )}
          </div>
          <CoverageExtraFields form={form} />

          <div className="input-field-wrapper">
            <div className="row-wrapper flex items-center justify-between mb-2">
              <div className="label-wrapper" style={{ flex: '1' }}>
                <InputFieldText.Label htmlFor="targetedCompanies">
                  Target Companies
                </InputFieldText.Label>
              </div>
              <div className="toggle-wrapper" style={{ flex: 'none' }}>
                <Controller
                  name="exclusive"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputFieldToggle
                      value={value}
                      onChange={onChange}
                      text="Exclusive"
                    />
                  )}
                />
              </div>
            </div>
            {exclusive && (
              <p className="text-sm text-gray-600 mb-4">
                When exclusive is enabled, this opportunity will only be visible
                to the companies added in here.
              </p>
            )}

            <Controller
              name="targetedCompanies"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputFieldTagsAutocomplete
                  placeholder="Type to search companies..."
                  customFieldName="target-companies"
                  tags={value || []}
                  suggestions={
                    companiesData?.map((company) => ({
                      id: company.id,
                      name: company.name,
                    })) || []
                  }
                  onAddition={(tag) => {
                    if (value.find((t) => t.id === tag.id)) {
                      return;
                    }
                    onChange([...value, tag]);
                  }}
                  showHowTo
                  onDelete={(tag) => {
                    onChange(value.filter((t) => t.id !== tag.id));
                  }}
                />
              )}
            />
          </div>

          <div className="flex justify-between items-center">
            <Label htmlFor="deadline">Deadline</Label>
            <Controller
              name="ongoing"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputFieldToggle
                  value={value}
                  onChange={onChange}
                  text="Ongoing"
                />
              )}
            />
          </div>

          <AdminAddOpportunityModalDeadline
            control={control}
            setValue={setValue}
            isOngoing={isOngoing}
            watch={watch}
          />
        </form>
      </Modal.Content>

      <Modal.RightButtons>
        <Button type="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button submit form={formName} className="relative">
          {loading && <Spinner />}
          {opportunity ? 'Update' : 'Add'} opportunity
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminAddOpportunityModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opportunity: PropTypes.object,
  companiesData: PropTypes.object,
  disabledAddButton: PropTypes.bool,
};

export default AdminAddOpportunityModal;
