import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const ContentManagementDocumentResponseFormQnAModal = ({
  open,
  questions,
  onQuestionsUpdated,
  onClose,
}) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      questions: questions?.map((q) => ({
        question: q.question,
        answer: q.answer || '',
      })),
    },
  });

  useEffect(() => {
    reset({
      questions: questions?.map((q) => ({
        question: q.question,
        answer: q.answer || '',
      })),
    });
  }, [questions, reset]);

  const onSubmit = (data) => {
    onQuestionsUpdated(data.questions);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>Article Q&A</Modal.Title>
      <Modal.Content>
        <form>
          {questions?.map((question, index) => (
            <div key={`${uuidv4()}-key`} className="mb-4">
              <Label htmlFor={`questions[${index}].answer`} className="mb-2">
                {question.question}
              </Label>
              <InputFieldText
                id={`questions[${index}].answer`}
                {...register(`questions[${index}].answer`)}
                placeholder="Type your answer here"
              />
            </div>
          ))}
          <div className="flex justify-end space-x-2">
            <Button type="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit(onSubmit)}>Save Changes</Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};

ContentManagementDocumentResponseFormQnAModal.propTypes = {
  open: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string,
    })
  ).isRequired,
  onQuestionsUpdated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ContentManagementDocumentResponseFormQnAModal;
