import PropTypes from 'prop-types';
import React from 'react';

import { Pie } from 'react-chartjs-2';

import capitalizeKeys from './utils/capitalizeKeys';
import { adaptColorsToDataLength } from './utils/colors';
import { legend, title, tooltip } from './utils/options';
import sortData from './utils/sortData';

const getLabel = (context) => context.parsed || 0;

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend,
    tooltip: tooltip(getLabel),
    title,
  },
};

const JournalistDetailsModalIndustries = ({ industries = {} }) => {
  const capitalizedIndustries = capitalizeKeys(industries);
  const sortedIndustries = sortData(capitalizedIndustries);

  return (
    <Pie
      options={options}
      data={{
        labels: sortedIndustries.map(([key]) => key),
        datasets: [
          {
            data: sortedIndustries.map(([, value]) => value),
            backgroundColor: adaptColorsToDataLength(sortedIndustries),
          },
        ],
      }}
    />
  );
};

JournalistDetailsModalIndustries.propTypes = {
  industries: PropTypes.object,
};

export default JournalistDetailsModalIndustries;
