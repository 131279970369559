import { useCallback, useState } from 'react';

import { allLanguages } from 'constants/defaultLanguages';
import useAPIRequest from 'hooks/useAPIRequest';

const createLanguagesString = (language = allLanguages.value) => {
  if (language === allLanguages.value) {
    return '';
  }
  const { storyLanguages, publicationLocations } = language
    .split(',')
    .map((lang) => lang.split('-'))
    .reduce((acc, [lang, country]) => {
      acc.storyLanguages = acc.storyLanguages
        ? `${acc.storyLanguages},${lang}`
        : lang;
      acc.publicationLocations = acc.publicationLocations
        ? `${acc.publicationLocations},${country}`
        : country;

      return acc;
    }, {});

  return `&storyLanguages=${storyLanguages}&publicationLocations=${publicationLocations}&defaultLanguages=${language}`;
};

const useJournalistsSemanticSearch = () => {
  const [journalists, setJournalists] = useState([]);
  const [isLoadingJournalists, setIsLoadingJournalists] = useState(false);
  const { fetchData, abort } = useAPIRequest({
    service: 'JPD',
    method: 'GET',
    autoFetch: false,
  });

  return {
    journalists,
    isLoadingJournalists,
    searchJournalists: useCallback(
      async (topics, language) => {
        setIsLoadingJournalists(true);

        if (!topics.length) {
          setJournalists([]);
          setIsLoadingJournalists(false);
          return;
        }

        try {
          const topicsString = `topics=${topics.join(',')}`;
          const languagesString = createLanguagesString(language);

          const newJournalists = await fetchData({
            endpoint: `/journalists/semanticSearch?${topicsString}&topN=50${languagesString}`,
          });
          setJournalists(newJournalists.items);
        } catch (error) {
          setJournalists([]);
        }

        setIsLoadingJournalists(false);
      },
      [fetchData]
    ),
    abortSearchJournalists: abort,
  };
};

export default useJournalistsSemanticSearch;
