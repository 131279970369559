import PropTypes from 'prop-types';
import React from 'react';

import JournalistDetailsModalInteractionsType from './JournalistDetailsModalInteractionsType';

const JournalistDetailsModalInteractions = ({ threads = [] }) => (
  <div className="flow-root">
    <ul className="-mb-8">
      {threads.map((thread, index) => {
        const isLast = index === threads.length - 1;

        return (
          <JournalistDetailsModalInteractionsType
            key={thread.id}
            threadData={thread}
            isLast={isLast}
          />
        );
      })}
    </ul>
  </div>
);

JournalistDetailsModalInteractions.propTypes = {
  threads: PropTypes.array,
};

export default JournalistDetailsModalInteractions;
