import PropTypes from 'prop-types';
import React from 'react';

import { LockClosedIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const UpdatePasswordModal = ({
  open = false,
  onClose = noop,
  onSubmit = noop,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({ mode: 'onChange' });

  return (
    <Modal open={open}>
      <Modal.Icon Icon={LockClosedIcon} />
      <Modal.Title>Update your password</Modal.Title>
      <Modal.Content>
        <form
          id="updateUserPasswordForm"
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-3"
        >
          <InputFieldText
            {...register('password', {
              required: 'Please enter your current password',
            })}
            type="password"
            placeholder="Your current password"
            errors={errors}
          >
            <InputFieldText.Label>Password</InputFieldText.Label>
          </InputFieldText>

          <InputFieldText
            {...register('newPassword', {
              required: 'Please enter a new password',
            })}
            type="password"
            placeholder="Type your new password here..."
            errors={errors}
          >
            <InputFieldText.Label>New password</InputFieldText.Label>
          </InputFieldText>
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button submit disabled={!isValid} form="updateUserPasswordForm">
          Update password
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

UpdatePasswordModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UpdatePasswordModal;
