import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import Markdown from 'react-markdown';

import assistantActions from 'constants/assistantActions';

import FileConversationItem from './FileConversationItem';
import JournalistsConversationItem from './JournalistsConversationItem';

const getToolText = (toolName, toolParams) => {
  switch (toolName) {
    case 'read_from_text_editor':
      return 'Reading from text editor...';

    case 'write_to_text_editor':
      return 'Writing to text editor...';

    case 'search_journalists':
      return `Searching for journalists with "${toolParams.query}"`;

    case 'recommend_journalists':
      return `Recommending journalists with topics ${toolParams.topics.join(
        ', '
      )}`;

    case 'get_journalist_stats':
      return 'Getting journalists statistics...';

    case 'get_resources_index':
      return 'Getting a list of all resources...';

    case 'get_resource':
      return 'Reading resource...';

    case 'read_uploaded_files':
      return 'Reading files...';

    case 'read_website':
      return 'Reading website...';

    case 'generate_funding_announcement':
      return 'Generating funding announcement...';

    case 'read_questionnaire':
      return 'Reading questionnaire...';

    case 'write_answer':
      return 'Writing answer...';

    case 'search_web':
      return `Searching the web for "${toolParams.query}"`;

    default:
      return '';
  }
};

const ConversationItem = ({
  message,
  role,
  toolCalls,
  toolName,
  toolParams,
  action,
}) => {
  if (role === 'system') {
    return null;
  }

  if (role === 'assistant' && toolCalls?.length > 0) {
    return null;
  }

  if (role === 'tool' && toolName === 'present_journalists') {
    return <JournalistsConversationItem toolParams={toolParams} />;
  }

  if (action?.type === 'ADD_FILES' && toolName === 'read_uploaded_files') {
    return (
      <>
        {action?.payload?.files?.length > 0 && (
          <FileConversationItem files={action.payload.files} />
        )}
        <div className="my-1 text-center text-teal-600 text-xs rounded-full mx-12">
          {getToolText(toolName, toolParams)}
        </div>
      </>
    );
  }

  if (
    action?.type === 'GENERATE_FUNDING_ANNOUNCEMENT' &&
    toolName === 'generate_funding_announcement'
  ) {
    return (
      <>
        <div className="flex justify-end space-x-2 min-w-max mt-1">
          <div className="flex items-center px-3 py-1 border border-teal-300 bg-white shadow-sm rounded-md text-teal-500 text-xs whitespace-nowrap">
            <span className="mr-1">✨</span>
            {assistantActions.GENERATE_FUNDING_ANNOUNCEMENT.label}
          </div>
        </div>
        <div className="my-1 text-center text-teal-600 text-xs rounded-full mx-12">
          {getToolText(toolName, toolParams)}
        </div>
      </>
    );
  }

  if (role === 'tool') {
    return (
      <div className="my-1 text-center text-teal-600 text-xs rounded-full mx-12">
        {getToolText(toolName, toolParams)}
      </div>
    );
  }

  const isUser = role === 'user';

  return (
    <div
      className={classNames(
        'prose rounded text-sm py-2 px-3 my-1 prose-p:my-1 prose-li:my-1 prose-ul:my-1 prose-h1:my-1 prose-h2:my-1 prose-h3:my-1 prose-a:text-teal-500 prose-hr:my-1 prose-pre:bg-gray-200 prose-pre:bg-gray-800 prose-pre:text-gray-100',
        {
          'bg-white': !isUser,
          'bg-gray-200': isUser,
          'ml-5': isUser,
          'mr-5': !isUser,
          'text-right': isUser,
        }
      )}
    >
      <Markdown>{message}</Markdown>
    </div>
  );
};

ConversationItem.propTypes = {
  message: PropTypes.string,
  role: PropTypes.string,
  toolCalls: PropTypes.array,
  toolName: PropTypes.string,
  toolParams: PropTypes.object,
  action: PropTypes.object,
};

export default ConversationItem;
