import React from 'react';

import ErrorMessage from 'components/form/ErrorMessage';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

const InboxMessageResponseFormErrors = () => {
  const { sendMessageErrors } = useInboxContext();

  if (!sendMessageErrors) {
    return null;
  }

  return sendMessageErrors?.map((error) => (
    <ErrorMessage
      key={error.field}
      className="!mt-0 w-full hover:bg-red-100 cursor-pointer"
      onClick={() => {
        if (error.field === 'response') {
          window.tinymce.execCommand(
            'mceFocus',
            false,
            'inboxMessage-responseFormTextInput'
          );
          document
            .querySelector(
              '[data-test-id="inboxMessage-responseFormTextInput"]'
            )
            ?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
        } else {
          document.querySelector(`input[name="${error.field}"]`)?.focus();
          document
            .querySelector(`input[name="${error.field}"]`)
            ?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
        }
      }}
    >
      {error.message}
    </ErrorMessage>
  ));
};

export default InboxMessageResponseFormErrors;
