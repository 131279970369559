import PropTypes from 'prop-types';
import React, { createContext } from 'react';

import { getApp, getApps, initializeApp } from 'firebase/app';

const environment = {
  isEmulated: !!process.env.REACT_APP_USE_EMULATORS,
  isLocal: window.location.hostname === 'localhost',
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  appId: process.env.REACT_APP_FIREBASE_appId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  // don't set measurement ID when using emulators
  measurementId: environment.isEmulated
    ? undefined
    : process.env.REACT_APP_FIREBASE_measurementId,
};

const firebaseApp = !getApps().length
  ? initializeApp(firebaseConfig)
  : getApp();

const context = {
  ...environment,
  isProduction: process.env.REACT_APP_STAGE === 'PROD',
  firebaseApp,
};
export const EnvironmentContext = createContext(context);

const { Provider } = EnvironmentContext;

const EnvironmentProvider = ({ children }) => (
  <Provider value={context}>{children(context)}</Provider>
);

EnvironmentProvider.propTypes = {
  children: PropTypes.func.isRequired,
};

export default EnvironmentProvider;
