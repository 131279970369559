import { useMutation } from 'react-query';
import { useAuth } from 'reactfire';

const notifyCompany = async ({ opportunityId, companyId, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/companies/${companyId}/opportunities/${opportunityId}/notify`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to notify company: ${error.message}`);
  }
};

const useNotifyCompanyAboutOpportunity = () => {
  const auth = useAuth();
  return useMutation(({ companyId, opportunityId }) =>
    notifyCompany({ companyId, opportunityId, auth })
  );
};

export default useNotifyCompanyAboutOpportunity;
