import PropTypes from 'prop-types';
import React from 'react';

import { documentTypes } from 'constants/contentManagementTypes';

import TypeOptionItem from './TypeOptionItem';

const getArticle = (word) => {
  const exceptions = ['M&A Announcement'];
  const normalizedWord = word.trim().toLowerCase();

  if (
    exceptions.some((exception) =>
      normalizedWord.startsWith(exception.toLowerCase())
    )
  ) {
    return 'an';
  }

  const vowels = ['a', 'e', 'i', 'o', 'u'];
  const firstLetter = normalizedWord[0];

  return vowels.includes(firstLetter) ? 'an' : 'a';
};

const ContentManagementDocumentsEmptyState = ({
  title = 'This is your space for creating and collaborating on content!',
  subTitle = 'You haven’t created a document yet. Get started by selecting a type or start a blank document.',
  createDocument,
  isLoading,
  showArchived = false,
}) => (
  <div>
    {showArchived === 'true' ? (
      <div className="flex w-full min-h-screen justify-center items-center p-8 -mt-32 text-center text-gray-500">
        <p className="max-w-prose">
          <span>
            {`You haven't archived any documents yet! If you come across content
            that doesn't suit your needs, simply hit "Archive" to help keep your
            documents in order. ✨`}
          </span>
        </p>
      </div>
    ) : (
      <div>
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h2>
        <p className="mt-1 text-sm text-gray-500">{subTitle}</p>
        <ul className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2">
          {documentTypes
            .filter((content) => content.name !== 'blank')
            .map((content) => (
              <TypeOptionItem
                key={content.id}
                icon={<content.Icon className="w-5 fill-current" />}
                title={`Create ${getArticle(content.label)} ${content.label}`}
                label={content.label}
                description={content.description}
                createDocument={() => createDocument(content.name)}
                isLoading={isLoading}
              />
            ))}
        </ul>
        <div className="mt-4 flex">
          <button
            type="button"
            className="text-sm font-medium text-teal-600 hover:text-teal-500"
            onClick={() => createDocument()}
            disabled={isLoading}
          >
            Or start a blank document <span aria-hidden="true"> &rarr;</span>
          </button>
        </div>
      </div>
    )}
  </div>
);

ContentManagementDocumentsEmptyState.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  createDocument: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showArchived: PropTypes.bool.isRequired,
};

export default ContentManagementDocumentsEmptyState;
