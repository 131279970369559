import React from 'react';

import { tabs } from 'constants/settingsTabs';

import SettingsNavbarNavbarTabsItem from './SettingsNavbarNavbarTabsItem';

const SettingsNavbarNavbarTabs = () => (
  <div className="flex pt-2" data-intercom-id="settings-tabs">
    {tabs.map((tab) => (
      <SettingsNavbarNavbarTabsItem key={tab.field} tab={tab} />
    ))}
  </div>
);
export default SettingsNavbarNavbarTabs;
