import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';
import useCurrentDocumentContext from 'routes/ContentManagement/hooks/useCurrentDocumentContext';

const ContentGenerateArticleOutline = ({
  areAllQuestionsAnswered,
  shouldShowContentWarning,
  editorRef,
  setEditorContent,
}) => {
  const { companyData } = useCompanyData();
  const { showError } = useNotifications();

  const { currentDocument, updateDocument } = useCurrentDocumentContext();

  const { fetchData: generateArticleOutline, loading: isArticleGenerated } =
    useAPIRequest({
      service: 'MOD',
      endpoint: `/ai/${companyData.uid}/${currentDocument.id}/generateArticleOutline`,
      method: 'POST',
    });

  const handleGenerateArticleOutline = useCallback(async () => {
    try {
      const response = await generateArticleOutline();

      const content = `
        <div class="article-outline">
          <h3>${response.headline}</h3>
          <p>${response.content}</p>
        </div>`;
      const plainText = `${response.headline}\n${response.content}\n`;

      setEditorContent(content);

      if (editorRef.current) {
        editorRef.current.setContent(content);
      }

      updateDocument({
        draft: { content, plainText },
      });
    } catch (error) {
      showError({
        message: `There was an error communicating with the AI`,
      });
    }
    return 'Something went wrong.';
  }, [
    editorRef,
    generateArticleOutline,
    setEditorContent,
    showError,
    updateDocument,
  ]);

  return (
    <>
      {!areAllQuestionsAnswered && (
        <p className="mt-2 text-gray-400 text-sm">
          {`Complete the Q&A to unlock the "Generate article outline"
            feature.`}
        </p>
      )}
      <div className="mt-2 mb-8">
        <Button
          type="secondary"
          disabled={
            !areAllQuestionsAnswered ||
            shouldShowContentWarning ||
            isArticleGenerated
          }
          onClick={handleGenerateArticleOutline}
        >
          Generate article outline
        </Button>
      </div>

      {isArticleGenerated && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-100 bg-opacity-50 flex justify-center items-center z-50">
          <Spinner />
        </div>
      )}
    </>
  );
};

ContentGenerateArticleOutline.propTypes = {
  areAllQuestionsAnswered: PropTypes.bool,
  shouldShowContentWarning: PropTypes.bool,
  editorRef: PropTypes.any,
  setEditorContent: PropTypes.func,
};

export default ContentGenerateArticleOutline;
