import React from 'react';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useCompanyMembers from 'hooks/useCompanyMembers';
import useCompanySettings from 'hooks/useCompanySettings';

import CompanyNotificationsTableRow from './CompanyNotificationsTableRow';

const notificationTypeLabels = {
  OPPORTUNITY_JOURNALIST_RESPONSE: 'Journalist response',
  INVITE_CONFIRMATION: 'New member',
  OPPORTUNITY_NOTIFICATION: 'New Opportunity',
};

const CompanyNotifications = () => {
  const { companyData } = useCompanyData();
  const { data: { members = [], owner = {} } = {} } = useCompanyMembers(
    companyData?.uid
  );
  const allMembers = [owner, ...members];
  const { data: { notifications = {} } = {} } = useCompanySettings(
    companyData?.uid
  );
  const notificationTypes = Object.keys(notifications).map(
    (key) => notificationTypeLabels[key]
  );

  return (
    <div className="max-h-full h-auto shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200 text-gray-500">
        <thead className="z-20 sticky top-0 bg-gray-50 text-xs font-medium uppercase">
          <tr className="text-left tracking-wider">
            <th
              scope="col"
              className="px-4 py-2 max-w-sm"
              aria-label="Members"
            />
            {notificationTypes.map((type) => (
              <th
                key={type}
                scope="col"
                className="text-center px-4 py-2 max-w-sm"
              >
                {type}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-sm">
          {allMembers.map((member) => (
            <CompanyNotificationsTableRow
              key={member.id}
              member={member}
              notifications={notifications}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompanyNotifications;
