import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth, useUser } from 'reactfire';

const getPublications = async ({ query, agencyId, auth }) => {
  const BASE_URL = process.env.REACT_APP_JPD_URL;
  let endpoint = `/agencies/${agencyId}/publications`;

  if (query) {
    endpoint += `?q=${encodeURIComponent(query)}`;
  }

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch agency publications: ${error.message}`);
  }
};

const upsertPublication = async ({ publication, agencyId, auth }) => {
  const BASE_URL = process.env.REACT_APP_JPD_URL;
  const endpoint = `/agencies/${agencyId}/publications`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(publication),
    });
  } catch (error) {
    throw new Error(`Failed to upsert agency publication: ${error.message}`);
  }
};

const deletePublication = async ({ publicationId, agencyId, auth }) => {
  const BASE_URL = process.env.REACT_APP_JPD_URL;
  const endpoint = `/agencies/${agencyId}/publications/${publicationId}`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    await fetch(`${BASE_URL}${endpoint}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  } catch (error) {
    throw new Error(`Failed to delete agency publication: ${error.message}`);
  }
};

const useAgencyPublications = (agencyId = '', query = '') => {
  const auth = useAuth();
  const { data: userDataAuth } = useUser();
  const queryClient = useQueryClient();

  const { data, error, isFetching } = useQuery(
    ['agencyPublications', query],
    () => getPublications({ query, agencyId, auth }),
    {
      enabled: !!userDataAuth?.uid && !!agencyId,
      initialData: { publications: [] },
    }
  );

  const invalidateAgencyPublications = () => {
    setTimeout(() => {
      queryClient.invalidateQueries('agencyPublications');
    }, 1000);
  };

  const upsertPublicationMutation = useMutation({
    mutationFn: async (publication) =>
      upsertPublication({
        publication,
        agencyId,
        auth,
      }),
    onSuccess: () => {
      invalidateAgencyPublications();
    },
  });

  const deletePublicationMutation = useMutation({
    mutationFn: async (publicationId) =>
      deletePublication({
        publicationId,
        agencyId,
        auth,
      }),
    onSuccess: () => {
      invalidateAgencyPublications();
    },
  });

  return {
    publications: data.publications,
    upsertPublicationMutation,
    deletePublicationMutation,
    isLoading: isFetching,
    error,
  };
};

export default useAgencyPublications;
