import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef } from 'react';

import { XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import noop from 'lodash/noop';

import { DURATION_MEDIUM, DURATION_SHORT } from 'constants/transitions';
import useMeasure from 'hooks/useMeasure';

import getNotificationIcon from './utils/getNotificationIcon';

const NOTIFICATION_TIMEOUT = 5000;
const NOTIFICATION_PADDING = 32;

const Notification = ({
  notification = {},
  deleteNotification = noop,
  isFirst = false,
}) => {
  const {
    key = '',
    message = '',
    title = '',
    type = '',
    iconClassName = '',
    timeout = NOTIFICATION_TIMEOUT,
  } = notification;

  const [ref, { height }] = useMeasure();
  const timeoutRef = useRef([]);
  const Icon = getNotificationIcon(type, notification.Icon);
  const firstAnimationCompleteRef = useRef(false);

  const clearTimeouts = useCallback(() => {
    timeoutRef.current.forEach((timeoutId) => clearTimeout(timeoutId));
    timeoutRef.current = [];
  }, []);

  const addNewTimeout = useCallback(() => {
    timeoutRef.current = [
      ...timeoutRef.current,
      setTimeout(() => {
        deleteNotification(key);
      }, timeout),
    ];
  }, [deleteNotification, key, timeout]);

  const variants = useMemo(
    () => ({
      hidden: {
        opacity: 0,
        x: '100%',
        height: height + NOTIFICATION_PADDING,
        scale: 0.3,
      },

      visible: {
        opacity: 1,
        scale: 1,
        x: '0%',
        height: height + NOTIFICATION_PADDING,
        marginTop: isFirst ? 0 : 16,
      },

      exit: {
        x: '100%',
        height: 0,
        opacity: 0,
        marginTop: 0,
        transition: {
          type: 'spring',
          duration: DURATION_SHORT,
        },
      },
    }),
    [height, isFirst]
  );

  return (
    <motion.li
      key={key}
      transition={{
        type: 'spring',
        duration: DURATION_MEDIUM,
      }}
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit"
      onAnimationComplete={() => {
        if (firstAnimationCompleteRef.current) {
          return;
        }
        firstAnimationCompleteRef.current = true;
        addNewTimeout();
      }}
      onHoverStart={clearTimeouts}
      onHoverEnd={addNewTimeout}
      className="w-full"
    >
      <div
        className="ml-auto pointer-events-auto max-w-md overflow-visible rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 p-4"
        ref={ref}
      >
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <Icon
              className={classNames(
                'h-6 w-6',
                {
                  'text-teal-500': type === 'success',
                  'text-red-400': type === 'error',
                  'text-yellow-400': type === 'warning',
                },
                iconClassName
              )}
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5 space-y-1">
            {title ? (
              <p className="text-sm font-medium text-gray-900">{title}</p>
            ) : null}
            {message ? (
              <p className="text-sm whitespace-pre-line text-gray-500">
                {message}
              </p>
            ) : null}
          </div>
          <div className="ml-4 flex flex-shrink-0">
            <button
              type="button"
              data-test-id="notification-close-button"
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
              onClick={() => {
                deleteNotification(key);
              }}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </motion.li>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape({
    key: PropTypes.number.isRequired,
    message: PropTypes.node,
    title: PropTypes.node,
    type: PropTypes.string,
    iconClassName: PropTypes.string,
    Icon: PropTypes.elementType,
    timeout: PropTypes.number,
  }),
  deleteNotification: PropTypes.func,
  isFirst: PropTypes.bool,
};

export default Notification;
