import { contentManagementTypes } from './contentManagementTypes';

const assistantActions = {
  GENERATE_FUNDING_ANNOUNCEMENT: {
    type: 'GENERATE_FUNDING_ANNOUNCEMENT',
    label: 'Generate Funding Announcement',
    compatibleType: contentManagementTypes.FUNDING,
  },
};

export default assistantActions;
