import PropTypes from 'prop-types';
import React from 'react';

import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import TextCounter from 'components/form/TextCounter';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import InputFieldUploadResource from 'components/form/inputFields/InputFieldUpload/InputFieldUploadResource';

import useCurrentResourceContext from '../hooks/useCurrentResourceContext';

const ContentAddResourceModal = ({ open, onClose, onSubmit }) => {
  const defaultValues = {};
  const form = useForm({ mode: 'onChange', defaultValues });
  const formName = 'content-resource-addModal';
  const { isAddCustomResourceApiLoading } = useCurrentResourceContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = form;

  const name = watch('name');

  return (
    <Modal open={open}>
      <Modal.Close srMessage="Close add resource modal" onClose={onClose} />
      <Modal.Title>Add a new resource</Modal.Title>
      <Modal.Description>
        Resources can be used when generating content
      </Modal.Description>
      <Modal.Content>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={formName}
          className="space-y-3"
        >
          <InputFieldText
            defaultValue={defaultValues.name}
            {...register('name', {
              required: 'Please put in a name',
              maxLength: {
                value: 140,
                message: 'Please keep the title below 140 characters',
              },
            })}
            errors={errors}
            placeholder="Type in the new resource name"
          >
            <InputFieldText.Label className="flex justify-between items-baseline mt-5">
              Name*
              <TextCounter str={name || defaultValues.name} />
            </InputFieldText.Label>
          </InputFieldText>

          <InputFieldUploadResource
            form={form}
            name="file"
            rules={{
              required: 'Please upload a file',
            }}
            presetName="file"
            testId="content-resource-modal-fileInput"
          >
            <InputFieldUpload.Label>File*</InputFieldUpload.Label>
          </InputFieldUploadResource>
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button
          type="secondary"
          disabled={isAddCustomResourceApiLoading}
          onClick={onClose}
          data-test-id="content-resources-addModal-cancel"
        >
          Cancel
        </Button>
        <Button
          submit
          form={formName}
          disabled={isAddCustomResourceApiLoading}
          eventType="contentManagement_resource_add"
          eventProperties={{ action: 'resource_add' }}
          data-test-id="content-resources-addModal-submit"
        >
          Add resource
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

ContentAddResourceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ContentAddResourceModal;
