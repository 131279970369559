import PropTypes from 'prop-types';
import React from 'react';

import { GlobeAltIcon } from '@heroicons/react/20/solid';
import {
  ArcElement,
  Chart,
  Tooltip,
  Legend,
  Title,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import noop from 'lodash/noop';

import Callout from 'components/Callout/Callout';
import JournalistTeaser from 'components/JournalistTeaser';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Tag from 'components/tags/Tag';
import useJournalistStats from 'hooks/useJournalistStats';
import useJournalistThreadActivity from 'hooks/useJournalistThreadActivity';
import SweetheartListJournalistAdd from 'routes/SweetheartList/SweetheartListJournalist/SweetheartListJournalistAdd';
import { ReactComponent as LinkedInIcon } from 'static/icon-linkedin.svg';
import { ReactComponent as TwitterIcon } from 'static/icon-twitter.svg';

import SweetheartListJournalistReachOut from '../../routes/SweetheartList/SweetheartListJournalist/SweetheartListJournalistReachOut';

import JournalistDetailsModalIndustries from './JournalistDetailsModalIndustries';
import JournalistDetailsModalInteractions from './JournalistDetailsModalInteractions';
import JournalistDetailsModalKeywords from './JournalistDetailsModalKeywords';
import JournalistDetailsModalLastStories from './JournalistDetailsModalLastStories';
import JournalistDetailsModalPublishingDays from './JournalistDetailsModalPublishingDays';
import JournalistDetailsModalStatTitle from './JournalistDetailsModalStatTitle';
import JournalistDetailsModalTopics from './JournalistDetailsModalTopics';

Chart.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  Tooltip,
  Legend,
  Title
);

const JournalistDetailsModal = ({ journalist = {}, onClose = noop }) => {
  const { data: journalistStats, isLoading } = useJournalistStats(
    journalist.id
  );
  const hasJournalistStats = Boolean(
    journalistStats && Object.keys(journalistStats).length
  );

  const currentJournalistThreads = useJournalistThreadActivity(journalist.id);
  const hasSocials = journalist.twitterHandle || journalist.linkedInHandle;

  return (
    <Modal
      open={Boolean(journalist)}
      widthClass="!max-w-4xl max-h-[calc(100vh-4rem)] flex flex-col"
      buttonsContainerClass="sticky bottom-0"
      contentContainerClass="overflow-y-auto"
    >
      <Modal.Close srMessage="Close edit Modal" onClose={onClose} />
      <Modal.TopBar>
        <div className="bg-gray-100 rounded-t-md flex items-center p-4 sticky top-0">
          <JournalistTeaser journalist={journalist} />
          {hasSocials && (
            <div className="ml-5 xs:hidden md:block">
              <div className="text-xs leanding-[10px] text-black">
                Social Media:
              </div>
              <div>
                {journalist.twitterHandle && (
                  <Tag
                    bgColor="blue-500"
                    textColor="white"
                    className="mt-0 mb-0 hover:bg-blue-300"
                    as="a"
                    href={`https://twitter.com/${journalist.twitterHandle}`}
                    target="_blank"
                  >
                    <TwitterIcon className="mr-1 w-3 h-3 m-auto" />{' '}
                    <span className="leading-4 text-base mr-1">/</span>
                    <span className="text-xs leading-4">
                      {journalist.twitterHandle}
                    </span>
                  </Tag>
                )}
                {journalist.linkedInHandle && (
                  <Tag
                    bgColor="blue-500"
                    textColor="white"
                    className="mt-0 mb-0 hover:bg-blue-300"
                    as="a"
                    href={`https://linkedin.com/in/${journalist.linkedInHandle}`}
                    target="_blank"
                  >
                    <LinkedInIcon className="mr-1 w-3 h-3 m-auto" />{' '}
                    <span className="leading-4 text-base mr-1">/</span>
                    <span className="text-xs leading-4">
                      {journalist.linkedInHandle}
                    </span>
                  </Tag>
                )}
              </div>
            </div>
          )}
          {journalist.authorUrl && (
            <div className="ml-5 xs:hidden md:block">
              <div className="text-xs leanding-[10px] text-black">
                Author Website:
              </div>
              <div>
                <Tag
                  bgColor="gray-800"
                  textColor="white"
                  className="mt-0 mb-0 hover:bg-gray-500"
                  as="a"
                  href={journalist.authorUrl}
                  target="_blank"
                >
                  <GlobeAltIcon className="mr-1 w-3 h-3 m-auto" />{' '}
                  <span className="leading-4 text-base mr-1">/</span>
                  <span className="text-xs leading-4">{journalist.name}</span>
                </Tag>
              </div>
            </div>
          )}
        </div>
      </Modal.TopBar>

      <Modal.Content className="h-full">
        <div className="text-gray-700 w-full -mt-4">
          <Callout type="info">
            The data shown below represents the journalist&apos;s activity of
            the last 3 months.
          </Callout>
          {hasJournalistStats && (
            <div className="grid grid-cols-2 w-full gap-6 mt-4">
              <div className="col-span-2 md:col-span-1">
                <JournalistDetailsModalStatTitle
                  title="Topics"
                  subtitle="Use these to understand at a glance if the journalist is writing about topics you want to talk about"
                  wrapperClassName="md:-mb-4"
                />
                <div className="h-72">
                  <JournalistDetailsModalTopics
                    topics={journalistStats.topicCounts}
                  />
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <JournalistDetailsModalStatTitle
                  title="Industries"
                  subtitle="Use these to make sure you're from an industry the journalist writes about frequently"
                  wrapperClassName="md:-mb-4"
                />
                <div className="h-72">
                  <JournalistDetailsModalIndustries
                    industries={journalistStats.industryCounts}
                  />
                </div>
              </div>
              <div className="col-span-2 md:col-span-2 space-y-6 md:-mt-8">
                <div>
                  <JournalistDetailsModalStatTitle
                    title="Keywords"
                    subtitle="Use these to find terms this journalist is writing about frequently, and then use them in your pitches"
                  />
                  <div className="h-56">
                    <JournalistDetailsModalKeywords
                      keywords={journalistStats.keywordCounts}
                    />
                  </div>
                </div>
                <div>
                  <JournalistDetailsModalStatTitle
                    title="Publishing days"
                    subtitle="See which days to avoid when pitching this journalist"
                  />
                  <div className="h-56">
                    <JournalistDetailsModalPublishingDays
                      publishingDays={journalistStats.publishingWeekdayCounts}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-2 md:col-span-2">
                <JournalistDetailsModalStatTitle
                  title="Latest stories"
                  subtitle="Reference these if you're cold emailing a journalist, they'll appreciate that you take an interest in what they're writing about!"
                />
                <JournalistDetailsModalLastStories
                  lastStories={journalistStats.lastFiveStories}
                />
              </div>

              {currentJournalistThreads.length ? (
                <div className="col-span-2 md:col-span-2">
                  <JournalistDetailsModalStatTitle title="Interactions" />
                  <JournalistDetailsModalInteractions
                    threads={currentJournalistThreads}
                  />
                </div>
              ) : null}
            </div>
          )}

          {isLoading && !hasJournalistStats && <Spinner />}

          {!isLoading &&
            !hasJournalistStats &&
            (currentJournalistThreads.length ? (
              <div className="col-span-2 md:col-span-2 mt-4">
                <JournalistDetailsModalStatTitle title="Interactions" />
                <JournalistDetailsModalInteractions
                  threads={currentJournalistThreads}
                />
              </div>
            ) : (
              <p className="text-center italic mt-2">
                This journalist doesn&apos;t have any stats yet.
              </p>
            ))}
        </div>
      </Modal.Content>
      <Modal.RightButtons>
        <SweetheartListJournalistReachOut journalist={journalist} />
      </Modal.RightButtons>
      <Modal.LeftButtons>
        <SweetheartListJournalistAdd journalistId={journalist.id} />
      </Modal.LeftButtons>
    </Modal>
  );
};

JournalistDetailsModal.propTypes = {
  journalist: PropTypes.object,
  onClose: PropTypes.func,
};

export default JournalistDetailsModal;
