import { useCallback } from 'react';

import useAPIRequest from 'hooks/useAPIRequest';

const useResourceCRUD = () => {
  const {
    fetchData: _getResourcesApi,
    loading: isGetResourcesApiLoading,
    error: isGetResourcesApiError,
  } = useAPIRequest({
    service: 'MOD',
    method: 'GET',
    autoFetch: false,
  });

  const {
    fetchData: _getCustomResourcesApi,
    loading: isGetCustomResourcesApiLoading,
    error: isGetCustomResourcesApiError,
  } = useAPIRequest({
    service: 'MOD',
    method: 'GET',
    autoFetch: false,
  });

  const { fetchData: _getResourceApi, loading: isGetResourceApiLoading } =
    useAPIRequest({
      service: 'MOD',
      method: 'GET',
      autoFetch: false,
    });

  const { fetchData: _updateResourceApi, loading: isUpdateResourceApiLoading } =
    useAPIRequest({
      service: 'MOD',
      method: 'POST',
    });

  const {
    fetchData: _removeCustomResourceApi,
    loading: isRemoveCustomResourceApiLoading,
  } = useAPIRequest({
    service: 'MOD',
    method: 'DELETE',
  });

  const {
    fetchData: _addCustomResourceApi,
    loading: isAddCustomResourceApiLoading,
  } = useAPIRequest({
    service: 'MOD',
    method: 'POST',
  });

  const { fetchData: _submitResourceApi, loading: isSubmitResourceApiLoading } =
    useAPIRequest({
      service: 'MOD',
      method: 'POST',
    });

  const getResourceApi = useCallback(
    ({ companyUid, resourceId }) =>
      _getResourceApi({
        endpoint: `/resources/${companyUid}/${resourceId}`,
        shouldThrow: true,
      }),
    [_getResourceApi]
  );

  const getResourcesApi = useCallback(
    ({ companyUid }) =>
      _getResourcesApi({
        endpoint: `/resources/${companyUid}`,
        shouldThrow: true,
      }),
    [_getResourcesApi]
  );

  const getCustomResourcesApi = useCallback(
    ({ companyUid }) =>
      _getCustomResourcesApi({
        endpoint: `/resources/${companyUid}/custom/list`,
        shouldThrow: true,
      }),
    [_getCustomResourcesApi]
  );

  const updateResourceApi = useCallback(
    ({ companyUid, resourceId, body }) =>
      _updateResourceApi({
        endpoint: `/resources/${companyUid}/${resourceId}/auto-save`,
        body,
      }),
    [_updateResourceApi]
  );

  const removeCustomResourceApi = useCallback(
    ({ companyUid, body }) =>
      _removeCustomResourceApi({
        endpoint: `/resources/${companyUid}/resource/delete`,
        body,
      }),
    [_removeCustomResourceApi]
  );

  const addCustomResourceApi = useCallback(
    ({ companyUid, body }) =>
      _addCustomResourceApi({
        endpoint: `/resources/${companyUid}/resource/add`,
        body,
      }),
    [_addCustomResourceApi]
  );

  const submitResourceApi = useCallback(
    ({ companyUid, resourceId, body }) =>
      _submitResourceApi({
        endpoint: `/resources/${companyUid}/${resourceId}/submit`,
        body,
      }),
    [_submitResourceApi]
  );

  return {
    getResourceApi,
    isGetResourceApiLoading,

    getResourcesApi,
    isGetResourcesApiError,
    isGetResourcesApiLoading,

    getCustomResourcesApi,
    isGetCustomResourcesApiLoading,
    isGetCustomResourcesApiError,

    updateResourceApi,
    isUpdateResourceApiLoading,

    addCustomResourceApi,
    isAddCustomResourceApiLoading,

    removeCustomResourceApi,
    isRemoveCustomResourceApiLoading,

    submitResourceApi,
    isSubmitResourceApiLoading,
  };
};

export default useResourceCRUD;
