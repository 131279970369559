import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { ClockIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import { messageStatus } from 'constants/messageStatus';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';
import formatDate from 'utils/date/formatDate';

import InboxMessageResponseFormScheduleModal from '../InboxMessageResponseForm/InboxMessageResponseFormScheduleModal';

const InboxMessageHeaderSchedule = ({
  status = messageStatus.UNAPPROVED,
  messageId = '',
  dateSendAfter = null,
}) => {
  const { scheduleMessage } = useInboxContext();
  const { showSuccess, showError } = useNotifications();
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const date = useMemo(() => new Date(dateSendAfter), [dateSendAfter]);
  const isScheduledIn10Minutes = date - 10 * 60 * 1000 < Date.now();
  const formatedDate = formatDate(date);

  return (
    <div className="flex items-center gap-2 rounded-md">
      <span
        className={classNames('flex gap-1 text-sm items-center ', {
          'text-gray-300': status === messageStatus.UNAPPROVED,
          'text-teal-500': status === messageStatus.APPROVED,
        })}
      >
        <ClockIcon className="h-4 w-4" />
        <span>
          Scheduled to be sent on
          <br className="block md:hidden" /> {formatedDate}
        </span>
      </span>
      <button
        type="button"
        disabled={isScheduledIn10Minutes}
        {...(isScheduledIn10Minutes
          ? {
              'data-tooltip-id': 'tooltip-inbox-readicon',
              'data-tooltip-content':
                'You can not edit a message that is scheduled to be sent in less than 10 minutes.',
            }
          : {})}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setShowScheduleModal(true);
        }}
        className="disabled:cursor-not-allowed flex gap-1 text-xs bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md text-gray-500"
      >
        Edit
      </button>
      {showScheduleModal ? (
        <InboxMessageResponseFormScheduleModal
          onCancel={() => setShowScheduleModal(false)}
          date={date}
          onSchedule={async (newDateSendAfter) => {
            const response = await scheduleMessage({
              messageId,
              dateSendAfter: newDateSendAfter,
            });
            if (response.ok) {
              showSuccess({
                message: `Your response will be sent to the journalist on\n${formatDate(
                  new Date(newDateSendAfter)
                )}`,
              });
            } else {
              showError({
                message:
                  'Seems like there was a problem scheduling your message, please try again later.',
              });
            }

            setShowScheduleModal(false);
          }}
        />
      ) : null}
    </div>
  );
};

InboxMessageHeaderSchedule.propTypes = {
  status: PropTypes.string,
  dateSendAfter: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  messageId: PropTypes.string,
};

export default InboxMessageHeaderSchedule;
