import React from 'react';

import HomeLogo from '../../HomeLogo';

import TopbarAccountLink from './TopbarAccountLink';

const Topbar = () => (
  <div className="w-full flex justify-between z-10 p-4 bg-white">
    <HomeLogo testId="homeLogo" />
    <TopbarAccountLink />
  </div>
);

export default Topbar;
