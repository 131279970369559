import PropTypes from 'prop-types';
import React from 'react';

import { PhotoIcon } from '@heroicons/react/20/solid';
import { StorageImage } from 'reactfire';

import useGetDownloadUrls from './hooks/useGetDownloadUrls';
import useGetResponsiveStorageRefs from './hooks/useGetResponsiveStorageRefs';

// Grabs an image from the Firebase storage, renders a placeholder while loading, but takes in account the display density.
const ResponsiveFirebaseImage = ({ path = '', alt = '', ...rest }) => {
  const imageStorageRefs = useGetResponsiveStorageRefs(path);
  const urls = useGetDownloadUrls(imageStorageRefs);

  const [lowResImagePath, highResImagePath, superResImagePath] = urls || [];

  return (
    <StorageImage
      alt={alt}
      storagePath={path}
      srcSet={
        urls
          ? `${lowResImagePath}, ${highResImagePath} 2x, ${superResImagePath} 3x`
          : ''
      }
      suspense={false}
      placeHolder={
        <div className="bg-gray-50 absolute inset-0 flex justify-center items-center text-gray-200">
          <PhotoIcon className="h-full" />
        </div>
      }
      {...rest}
    />
  );
};

ResponsiveFirebaseImage.propTypes = {
  path: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default ResponsiveFirebaseImage;
