import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from 'reactfire';

const scheduleMessage = async ({
  messageId = '',
  dateSendAfter = '',
  auth,
}) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/messages/${messageId}/schedule`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ dateSendAfter }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to schedule message: ${error.message}`);
  }
};

const useScheduleMessage = () => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { threadId } = useParams();
  const { mutateAsync } = useMutation({
    mutationFn: ({ messageId, dateSendAfter }) =>
      scheduleMessage({ messageId, dateSendAfter, auth }),
    onSettled: () => queryClient.invalidateQueries(['threadDetails', threadId]),
  });

  return mutateAsync;
};
export default useScheduleMessage;
