import PropTypes from 'prop-types';
import React from 'react';

import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

const CompanyInsightDeleteModal = ({
  open = false,
  title = '',
  onClose = noop,
  onDelete = noop,
}) => (
  <Modal open={open}>
    <Modal.Icon Icon={QuestionMarkCircleIcon} />
    <Modal.Title>Delete insight</Modal.Title>
    <Modal.Description>
      Are you sure you want to delete insight: <b>{title}</b>
    </Modal.Description>
    <Modal.RightButtons>
      <Button type="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button
        onClick={onDelete}
        eventType="pressPage_update"
        eventProperties={{ action: 'insight_delete' }}
        data-test-id="company-insights-deleteModal-delete"
      >
        Delete insight
      </Button>
    </Modal.RightButtons>
  </Modal>
);

CompanyInsightDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CompanyInsightDeleteModal;
