import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  ClipboardDocumentCheckIcon,
  ClipboardIcon,
  ShareIcon,
} from '@heroicons/react/24/outline';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { useTracking } from 'modules/segment/TrackingProvider';
import copyToClipboard from 'utils/copyToClipboard';

const CompanyMetaShareModal = ({ open = false, slug = '', onClose = noop }) => {
  const trackingService = useTracking();

  const url = `https://app.mvpr.io/company/${slug}`;

  const [copied, setCopied] = useState(false);
  const Icon = copied ? ClipboardDocumentCheckIcon : ClipboardIcon;

  const onClick = () => {
    setCopied(true);
    copyToClipboard(url);
    trackingService.track('pressPage_view', {
      action: 'company_url_copyToClipboard',
      url,
    });
  };

  return (
    <Modal open={open} testId="company-meta-shareModal">
      <Modal.Icon Icon={ShareIcon} />
      <Modal.Title>Share press page</Modal.Title>
      <Modal.Description>
        Use the following URL to share this press page:
      </Modal.Description>
      <Modal.Content>
        <InputFieldText
          testId="company-meta-shareModal-url"
          name="pressPageUrl"
          maxLength={2000}
          disabled
          defaultValue={url}
        >
          <InputFieldText.Suffix>
            <button
              type="button"
              data-test-id="company-meta-shareModal-copyToClipboard"
              className="-ml-px relative inline-flex items-center space-x-1 px-2 pr-3 py-2 border border-gray-200 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
              onClick={onClick}
            >
              <Icon className="w-4" />
              <span>Copy to clipboard</span>
            </button>
          </InputFieldText.Suffix>
        </InputFieldText>
      </Modal.Content>
      <Modal.RightButtons>
        <Button onClick={onClose} data-test-id="company-meta-shareModal-close">
          I&apos;m done
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyMetaShareModal.propTypes = {
  open: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanyMetaShareModal;
