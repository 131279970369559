import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const JPD_URL = process.env.REACT_APP_JPD_URL;

const useJournalistStats = (journalistId) => {
  const auth = useAuth();
  return useQuery(
    ['journalists', journalistId, 'stats'],
    async () => {
      const jwt = await auth.currentUser?.getIdToken();
      const response = await fetch(
        `${JPD_URL}/journalists/${journalistId}/stats`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (!response?.ok) {
        throw new Error('Failed fetching stats');
      }
      return response.json();
    },
    {
      retry: false,
    }
  );
};

export default useJournalistStats;
