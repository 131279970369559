import React, { useState } from 'react';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

import InboxMessageResponseFormSendPreviewModal from './InboxMessageResponseFormSendPreviewModal';

const InboxMessageResponseFormSendPreviewButton = () => {
  const [showSendPreviewModal, setShowSendPreviewModal] = useState(false);
  const { showError, showSuccess } = useNotifications();
  const { sendPreview } = useInboxContext();
  return (
    <>
      <Button
        data-test-id="inboxMessage-responseFormSendPreviewButton"
        type="secondary"
        onClick={() => setShowSendPreviewModal(true)}
        className="ml-auto w-full md:w-fit"
      >
        Send Preview
      </Button>
      {showSendPreviewModal && (
        <InboxMessageResponseFormSendPreviewModal
          onSendPreview={async () => {
            try {
              await sendPreview.fetchData();
              showSuccess({
                title: 'Preview sent',
                message: 'Please check your email.',
              });
            } catch (_) {
              showError({
                title: 'Error sending preview',
                message: 'Please try again later.',
              });
            }
            setShowSendPreviewModal(false);
          }}
          loading={sendPreview.loading}
          onCancel={() => setShowSendPreviewModal(false)}
        />
      )}
    </>
  );
};

export default InboxMessageResponseFormSendPreviewButton;
