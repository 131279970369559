import PropTypes from 'prop-types';
import React from 'react';

import usePhotoUrl from 'hooks/usePhotoUrl';

import Avatar from './Avatar';

// Displays an rounded avatar image
const UserAvatar = ({
  image = '',
  showUserPhoto = true,
  alt = 'User profile photo thumbnail',
  ...props
}) => {
  const userPhotoUrl = usePhotoUrl();

  return (
    <Avatar
      alt={alt}
      image={image || (showUserPhoto ? { path: userPhotoUrl } : '')}
      {...props}
    />
  );
};

UserAvatar.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  showUserPhoto: PropTypes.bool,
  alt: PropTypes.string,
};
export default UserAvatar;
