import React, { useState } from 'react';

import moment from 'moment';
import { Helmet } from 'react-helmet';

import PageWrapper from 'components/PageWrapper';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import SweetheartListProvider from 'contexts/SweetheartListContext/SweetheartListContext';
import ContentCalendarAnnouncementModal from 'routes/Planning/ContentCalendar/ContentCalendarAnnouncementModal';
import ReportsCoverage from 'routes/Reports/ReportsCoverage/ReportsCoverage';
import ReportsJournalist from 'routes/Reports/ReportsJournalist';
import getPageTitle from 'utils/getPageTitle';

import AnnouncementAttachJournalistList from './AnnouncementAttachJournalistList';
import AnnouncementDocumentPreview from './AnnouncementDocumentPreview';
import AnnouncementJournalistLists from './AnnouncementJournalistLists';
import AnnouncementOverview from './AnnouncementOverview';
import AnnouncementSellIn from './AnnouncementSellIn';
import useGetAnnouncement from './hooks/useGetAnnouncement';
import useUpsertAnnouncement from './hooks/useUpsertAnnouncement';

const AnnouncementPage = () => {
  const { companyData } = useCompanyData();
  const [isAnnouncementModalOpen, setIsAnnouncementModalOpen] = useState(false);

  const {
    data: announcement,
    isFetching: isAnnouncementLoading,
    refetch: fetchMilestone,
  } = useGetAnnouncement();

  const { mutateAsync: upsertAnnouncement } = useUpsertAnnouncement();

  const handleAnnouncementSubmit = async (data) => {
    const startTime = moment(
      `${data.startTime.day} ${data.startTime.month} ${data.startTime.year}`,
      'D MMMM YYYY'
    ).utcOffset(0, true);

    const announcementData = {
      id: announcement.id,
      documentId: announcement.documentId,
      title: data.title,
      type: data.type,
      startTime,
    };

    await upsertAnnouncement(announcementData);

    setIsAnnouncementModalOpen(false);
    await fetchMilestone();
  };

  if (!announcement && isAnnouncementLoading) {
    return <Spinner />;
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>{getPageTitle('Company Announcement')}</title>
      </Helmet>
      <SweetheartListProvider>
        <div className="flex flex-col gap-4 xl:flex-row p-4">
          <div className="w-full xl:w-2/3 flex flex-col gap-4">
            {announcement?.coverages?.length > 0 ? (
              <ReportsCoverage
                title="Media Coverage Statistics"
                companyData={companyData}
                coverages={announcement?.coverages}
              />
            ) : null}
            {announcement?.stats.messagesSent > 0 ? (
              <ReportsJournalist
                title="Outreach Statistics"
                stats={{ allTime: announcement.stats }}
              />
            ) : null}
            <div className="bg-white shadow rounded-md overflow-hidden">
              <div className="p-4 border-b flex justify-between items-center">
                <h1 className="text-xl font-medium">
                  {announcement?.document.title ?? announcement.title}
                </h1>
                <Button onClick={() => setIsAnnouncementModalOpen(true)}>
                  Edit
                </Button>
              </div>

              <AnnouncementSellIn />

              <AnnouncementDocumentPreview announcement={announcement} />
            </div>
          </div>

          <div className="flex flex-col gap-4 w-full xl:w-1/3">
            <AnnouncementOverview
              setIsAnnouncementModalOpen={setIsAnnouncementModalOpen}
            />

            <div className="flex flex-col gap-4">
              <AnnouncementJournalistLists announcement={announcement} />
              <AnnouncementAttachJournalistList announcement={announcement} />
            </div>
          </div>

          {isAnnouncementModalOpen && (
            <ContentCalendarAnnouncementModal
              open={isAnnouncementModalOpen}
              onSubmit={handleAnnouncementSubmit}
              onClose={() => setIsAnnouncementModalOpen(false)}
              announcement={announcement}
            />
          )}
        </div>
      </SweetheartListProvider>
    </PageWrapper>
  );
};

export default AnnouncementPage;
