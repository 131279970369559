import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const UCD_URL = process.env.REACT_APP_UCD_URL;

const useAgencyCompanies = (agencyId = '') => {
  const auth = useAuth();
  return useQuery(
    ['agencies', agencyId, 'companies'],
    async () => {
      const jwt = await auth.currentUser?.getIdToken();
      const response = await fetch(
        `${UCD_URL}/agencies/${agencyId}/companies`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (!response?.ok) {
        throw new Error('Failed fetching companies');
      }
      return response.json();
    },
    {
      enabled: !!agencyId,
      initialData: { items: [] },
    }
  );
};

export default useAgencyCompanies;
