import React from 'react';

import UserAvatar from 'components/UserAvatar';
import Tag from 'components/tags/Tag';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useCompanyMembers from 'hooks/useCompanyMembers';

import CompanyInviteUserForm from '../CompanyInviteUserForm/CompanyInviteUserForm';

import CompanyMember from './CompanyMember';

const CompanyMembers = () => {
  const { companyData } = useCompanyData();
  const { data, refetch } = useCompanyMembers(companyData?.uid);

  const invites = (data?.invites || []).filter((i) => i.status === 'pending');
  const members = data?.members || [];

  return (
    <div>
      <ul>
        {members.map((m) => (
          <li className="flex justify-between mb-2" key={m.id}>
            <CompanyMember member={m} />
          </li>
        ))}
        {invites.map((i) => (
          <li className="flex justify-between mb-2" key={i.token}>
            <div className="flex min-w-0 flex-auto max-w-sm gap-x-4">
              <UserAvatar showUserPhoto={false} className="w-10 h-10" />
              <div className="flex min-w-0 flex-auto">
                <p className="text-sm text-gray-500 self-center whitespace-normal break-all max-w-32">
                  {i.email}
                </p>
              </div>
              <div className="self-center">
                <Tag bold bgColor="teal-500" textColor="white">
                  Pending
                </Tag>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <CompanyInviteUserForm onSuccess={refetch} />
    </div>
  );
};

export default CompanyMembers;
