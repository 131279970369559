import React, { useMemo } from 'react';

import { ArrowLeftIcon, TrashIcon } from '@heroicons/react/20/solid';
import debounce from 'lodash/debounce';
import { useQueryClient } from 'react-query';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
  Link,
} from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import {
  bgColors,
  documentTypes,
  textColors,
} from 'constants/contentManagementTypes';
import { ANNOUNCEMENT_PATH, CONTENT_MANAGEMENT_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import useDocument from './hooks/useDocument';
import useUpdateDocument from './hooks/useUpdateDocument';

const createClassName = (type) => {
  const bgColor = bgColors[type.name];
  const textColor = textColors[type.name];

  return `bg-${bgColor} text-${textColor} rounded-md w-fit px-2 py-0.5 items-center flex`;
};

const types = documentTypes.map((type) => ({
  label: () => <div className={createClassName(type)}>{type.label}</div>,
  value: type.name,
}));

const DocumentHeaderBar = () => {
  const navigate = useNavigate();
  const { id: companySlug, documentId } = useParams();
  const [searchParams] = useSearchParams();
  const { companyData } = useCompanyData();
  const { showSuccess } = useNotifications();

  const { data } = useDocument(companyData.uid, documentId);
  const { mutateAsync: updateDocument } = useUpdateDocument(companyData.uid);
  const debouncedUpdateDocument = useMemo(
    () => debounce(updateDocument, 300),
    [updateDocument]
  );

  const queryClient = useQueryClient();

  const onTitleChange = (title) => {
    const newDoc = {
      ...data,
      title,
    };
    queryClient.setQueryData(['documents', documentId], newDoc);
    debouncedUpdateDocument(newDoc);
  };

  const onTypeChange = (type) => {
    const newDoc = {
      ...data,
      type,
    };
    queryClient.setQueryData(['documents', documentId], newDoc);
    debouncedUpdateDocument(newDoc);
  };

  const onArchiveDocument = async () => {
    await updateDocument({
      ...data,
      isArchived: !data.isArchived,
    });

    queryClient.invalidateQueries(['documents', documentId]);

    showSuccess({
      message: data.isArchived
        ? 'Document unarchived successfully'
        : 'Document archived successfully',
    });

    if (!data.isArchived) {
      navigate(
        generatePath(CONTENT_MANAGEMENT_PATH, {
          id: companySlug,
          tab: 'documents',
        })
      );
    }
  };

  const from = searchParams.get('from') || '';
  const backPath =
    from.split(':')[0] === 'announcement'
      ? generatePath(ANNOUNCEMENT_PATH, {
          id: companySlug,
          announcementId: from.split(':')[1],
        })
      : generatePath(CONTENT_MANAGEMENT_PATH, {
          id: companySlug,
          tab: 'documents',
        });

  return (
    <div className="sticky top-0 z-20 -mx-6 -mt-6 mb-8">
      <div className="w-full bg-white shadow-sm border-b border-gray-200">
        <div className="flex py-3 flex-row justify-between items-stretch">
          <div className="flex flex-row items-stretch flex-1 pl-2 mr-1">
            <Link
              to={backPath}
              className="inline-flex items-center justify-center h-10 w-10 bg-teal-500 text-white rounded hover:bg-teal-600 mr-2 shrink-0"
              aria-label="Back to overview"
            >
              <ArrowLeftIcon className="text-white w-4 h-4" />
            </Link>
            <InputFieldText
              inputClassName="h-10"
              value={data?.title}
              onChange={(e) => onTitleChange(e.target.value)}
              placeholder="Blank document"
            />
          </div>
          <div className="flex flex-row items-stretch md:w-96 pl-1 pr-2 shrink-1 justify-end justify-between">
            <InputFieldDropdown
              onChange={onTypeChange}
              placeholder="Select type"
              value={data?.type}
              options={types}
              wrapperClassName="md:w-64 w-full min-[390px]:w-fit md:shrink-0"
              buttonClassName="w-full h-10"
              optionsClassName="z-20"
              sameWidthOption
            />

            <Button
              type="danger"
              className="text-xs mx-2"
              onClick={onArchiveDocument}
            >
              {!data?.isArchived ? 'Archive' : 'Unarchive'}&nbsp;
              <TrashIcon className="w-4 h-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentHeaderBar;
