import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

import { filterOptions } from 'constants/opportunitiesOptions';

const findFilter = (key) => (i) =>
  filterOptions.find((f) => f.index === i)[key];

const createSearchQuery = ({
  companyId = '',
  terms = [],
  queryParams = {},
}) => {
  const searchParams = new URLSearchParams();
  searchParams.append('page', 1);
  searchParams.append('companyId', companyId);
  searchParams.append('searchTerms', terms || []);

  const { id, sort, sortDir, filter } = queryParams;

  // We only want to add the opportunityId to the search query on the initial load
  // This is to prevent the search query from being reset when the user clicks on an opportunity
  if (id) {
    searchParams.append('opportunityId', id);
  }

  if (filter.length > 0) {
    const min = Math.min(...filter.map(findFilter('min')));
    const max = Math.max(...filter.map(findFilter('max')));

    searchParams.append('minDomainAuthority', min);
    searchParams.append('maxDomainAuthority', max);
  }

  if (sort) {
    const sortDirection = sortDir === 1 ? 'desc' : 'asc';
    searchParams.append('sortDirection', sortDirection);

    if (sort === 'domainAuthority') {
      searchParams.append('sortBy', 'domainAuthority');
    }

    if (sort === 'timeLeft') {
      searchParams.append('sortBy', 'deadline');
    }
  }

  const searchQuery = searchParams.toString();

  searchParams.delete('opportunityId');
  const changeKey = searchParams.toString();

  return { searchQuery, changeKey };
};

const MOD_URL = process.env.REACT_APP_MOD_URL;

const useOpportunitiesBySearch = ({ companyId, searchTerms, queryParams }) => {
  const { searchQuery, changeKey } = createSearchQuery({
    companyId,
    terms: searchTerms,
    queryParams,
  });

  const auth = useAuth();

  const queryKey = ['opportunities', 'search', changeKey];
  const query = useQuery(
    queryKey,
    async () => {
      const jwt = await auth.currentUser?.getIdToken();
      const response = await fetch(
        `${MOD_URL}/opportunities/search?${searchQuery}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (!response?.ok) {
        throw new Error('Failed fetching opportunities');
      }
      return response.json();
    },
    {
      enabled: !!companyId,
    }
  );

  return {
    queryKey,
    ...query,
  };
};

export default useOpportunitiesBySearch;
