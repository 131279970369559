import PropTypes from 'prop-types';
import React from 'react';

const AutoSaveIndicator = ({ status }) => (
  <div className="text-sm text-gray-500 h-6">
    {status === 'saving' && (
      <p className={`${status !== 'saving' ? 'invisible' : ''}`}>
        Saving draft...
      </p>
    )}

    {status === 'saved' && (
      <p className={`${status !== 'saved' ? 'invisible' : ''}`}>Draft saved</p>
    )}
  </div>
);

AutoSaveIndicator.propTypes = {
  status: PropTypes.string,
};

export default AutoSaveIndicator;
