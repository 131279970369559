import React from 'react';

const NavbarLinksUpgradeBadge = () => (
  <div
    className="rounded-full bg-gray-200 w-fit h-4 py-3 px-2 flex text-xs items-center justify-center -mr-2"
    data-test-id="navbar-inbox-unreadIcon"
  >
    Upgrade
  </div>
);

export default NavbarLinksUpgradeBadge;
