import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const CreatedDateDisplay = ({
  publishedAt,
  wrapperClassName = '',
  standalone = false,
}) => {
  const formattedDate = new Date(publishedAt).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return (
    <div
      className={classNames(
        wrapperClassName,
        {
          'items-end lg:items-start w-full lg:w-40': !standalone,
          'w-fit': standalone,
        },
        'flex flex-col max-w-prose'
      )}
    >
      <div
        className={classNames(
          'flex items-center py-1 px-2 lg:pr-3 text-xs space-x-2 bg-opacity-40 truncate whitespace-nowrap font-semibold mr-4 rounded-t-md lg:mr-0 lg:rounded-l-none lg:rounded-r-md lg:mt-2  bg-gray-200 text-gray-400',
          {
            'mr-4 rounded-t-md lg:mr-0 lg:rounded-l-none lg:rounded-r-md lg:mt-2 ':
              !standalone,
            'rounded-md': standalone,
          }
        )}
      >
        <p>
          <span>{formattedDate}</span>{' '}
        </p>
      </div>
    </div>
  );
};

CreatedDateDisplay.propTypes = {
  publishedAt: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  standalone: PropTypes.bool,
};

export default CreatedDateDisplay;
