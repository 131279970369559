import PropTypes from 'prop-types';
import React, { useState } from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

import { useResponseFormEditorContext } from './InboxMessageResponseFormEditorProvider';
import InboxMessageResponseFormScheduleModal from './InboxMessageResponseFormScheduleModal';

const InboxMessageResponseFormScheduleButton = ({
  onSuccess = noop,
  className = '',
}) => {
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const { showError, showSuccess } = useNotifications();
  const { sendScheduledDraft } = useInboxContext();
  const { saveDraftNow } = useResponseFormEditorContext();

  return (
    <>
      <Button
        data-test-id="inboxMessage-responseFormSendPreviewButton"
        type="secondary"
        onClick={() => setShowScheduleModal(true)}
        className={classNames('w-full md:ml-auto md:w-fit', className)}
      >
        Schedule
      </Button>
      {showScheduleModal && (
        <InboxMessageResponseFormScheduleModal
          onSchedule={async (dateSendAfter = null) => {
            await saveDraftNow();
            const response = await sendScheduledDraft(dateSendAfter);
            if (response?.errors?.length) {
              showError({
                title: 'Failed to schedule message',
                message:
                  'Some fields are missing or invalid. Please check the form and try again.',
              });
            } else {
              showSuccess({
                title: 'Message scheduled',
                message:
                  'Your message has been scheduled to be sent to the journalist.',
              });
              setShowScheduleModal(false);
              onSuccess();
            }
          }}
          onCancel={() => setShowScheduleModal(false)}
        />
      )}
    </>
  );
};

InboxMessageResponseFormScheduleButton.propTypes = {
  className: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default InboxMessageResponseFormScheduleButton;
