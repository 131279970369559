import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';

const JournalistTeaser = ({ journalist, className }) => {
  const { name, jobTitle, image, publication } = journalist;

  return (
    <div
      className={classNames('flex flex-row items-center text-sm', className)}
    >
      <div className="relative">
        <JournalistImage
          className="w-[46px] h-[46px]"
          name={name}
          url={image}
        />
        {publication?.image && (
          <PublicationImage
            className="absolute w-[26px] h-[26px] -bottom-[5px] -right-[5px]"
            url={publication?.image}
          />
        )}
      </div>
      <div className="ml-3 flex flex-col min-w-0">
        <span className="truncate text-ellipsis font-semibold">{name}</span>
        <span className="truncate text-ellipsis text-gray-500">
          {jobTitle || 'Writer'} | {publication?.name}
        </span>
      </div>
    </div>
  );
};

JournalistTeaser.propTypes = {
  journalist: PropTypes.object,
  className: PropTypes.string,
};

export default JournalistTeaser;
