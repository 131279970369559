import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useForm, Controller } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldTagsList from 'components/form/inputFields/InputFieldTagsList/InputFieldTagsList';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import announcementTypes from 'constants/announcementTypes';
import { todaysDateObj } from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

import ContentCalendarMilestoneModalStartTime from './ContentCalendarMilestoneModalStartTime';

const ContentCalendarAnnouncementModal = ({
  open = false,
  onSubmit,
  onClose,
  announcement = {},
}) => {
  const defaultDate = useMemo(
    () =>
      announcement.startTime
        ? getDateObjFromTimestamp(announcement.startTime)
        : todaysDateObj,
    [announcement.startTime]
  );

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      title: announcement?.document?.title || '',
      startTime: defaultDate,
      type: announcement?.document?.type || '',
      journalistLists: announcement?.journalistLists || [],
    },
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Close srMessage="Close announcement modal" onClose={onClose} />
      <Modal.Title>
        {announcement && announcement.id
          ? 'Edit Announcement'
          : 'Create an Announcement'}
      </Modal.Title>
      <Modal.Content>
        <form
          id="announcement-modal"
          className="p-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-4">
            <Label htmlFor="announcement-title">Title*</Label>
            <Controller
              name="title"
              control={control}
              rules={{ required: 'Please enter a title' }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <InputFieldText
                    id="announcement-title"
                    {...field}
                    placeholder="Type the title..."
                  />
                  {error && <ErrorMessage>{error.message}</ErrorMessage>}
                </>
              )}
            />
          </div>

          <div className="mb-4">
            <Label htmlFor="contentPlan-milestone-date">Date*</Label>
            <ContentCalendarMilestoneModalStartTime
              control={control}
              watch={watch}
              setValue={setValue}
              defaultDate={defaultDate}
            />
          </div>

          <div className="mb-4">
            <Label htmlFor="announcement-type">Type*</Label>
            <Controller
              name="type"
              control={control}
              rules={{ required: 'Please select a type' }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <InputFieldTagsList
                    tags={announcementTypes}
                    onChange={(selectedTag) => {
                      field.onChange(selectedTag.id);
                    }}
                    value={{ id: field.value }}
                  />
                  {error && <ErrorMessage>{error.message}</ErrorMessage>}
                </>
              )}
            />
          </div>

          <div className="flex justify-end">
            <Button submit>
              {announcement && announcement.id
                ? 'Edit Announcement'
                : 'Create Announcement'}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};

ContentCalendarAnnouncementModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  announcement: PropTypes.object,
};

export default ContentCalendarAnnouncementModal;
