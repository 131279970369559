import React, { useState } from 'react';

import { BoltIcon, PlusIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import Tag from 'components/tags/Tag';
import useAPIRequest from 'hooks/useAPIRequest';
import useCustomOpportunities from 'hooks/useCustomOpportunities';
import useDeleteOpportunity from 'hooks/useDeleteOpportunity';
import useUpsertOpportunity from 'hooks/useUpsertOpportunity';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';
import getPageTitle from 'utils/getPageTitle';

import AdminWrapper from '../AdminWrapper';

import AdminAddOpportunityModal from './AdminAddOpportunityModal';

const AdminCreateOpportunity = () => {
  const { showError } = useNotifications();
  const { mutateAsync: upsertOpportunity } = useUpsertOpportunity();
  const { mutateAsync: deleteOpportunity } = useDeleteOpportunity();
  const { data: companiesData } = useAPIRequest({
    endpoint: '/companies/list',
    service: 'UCD',
  });

  const { data: opportunities, refetch } = useCustomOpportunities();

  const [showAddOpportunityModal, setShowAddOpportunityModal] = useState(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);

  const onSubmit = async (data) => {
    const opportunity = {
      ...data,
      tags: [data.type],
      source: 'ADMIN_UI',
      deadline: data.ongoing
        ? undefined
        : createTimestampFromDateObj(data.deadline, true),
      description: data.description.text,
      descriptionHtml: data.description.html,
      targetedCompanies: data.targetedCompanies.map((company) => company.id),
    };

    try {
      await upsertOpportunity(opportunity);
      await refetch();
      setShowAddOpportunityModal(false);
      setSelectedOpportunity(null);
    } catch (error) {
      showError({ message: 'There was an error saving opportunity' });
    }
  };

  return (
    <AdminWrapper title="Custom opportunities">
      <Helmet>
        <title>{getPageTitle('Admin - Custom opportunities')}</title>
      </Helmet>
      <div className="flex justify-between items-start">
        <p className="mt-2">
          Below you can find a list of <strong>custom opportunities</strong>
        </p>

        <Button size="base" onClick={() => setShowAddOpportunityModal(true)}>
          <PlusIcon className="w-4 mr-2" />
          Add opportunity
        </Button>
      </div>
      <div className="flex flex-col mt-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 text-xs font-medium text-gray-500 uppercase">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      Enquiry summary / Query
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      Media outlet
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      <BoltIcon className="w-4" />
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      Journalist
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      Deadline
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {(opportunities || [])
                    .filter((opportunity) => !opportunity.deleted)
                    .map((opportunity) => (
                      <tr key={opportunity.id}>
                        <td className="p-4 align-top">
                          {opportunity.tags.map((tag) => (
                            <Tag
                              key={tag}
                              bold
                              bgColor="blue-500"
                              textColor="white"
                              data-intercom-id="opportunity-type"
                            >
                              {tag}
                            </Tag>
                          ))}
                          <p className="text-sm text-gray-700 font-medium">
                            {opportunity.title}
                          </p>

                          <p className="line-clamp-3 text-sm text-gray-700">
                            {opportunity.description}
                          </p>
                        </td>

                        <td className="p-4 align-top">
                          <p className="text-sm text-gray-900">
                            <a
                              className="underline"
                              rel="noreferrer"
                              href={opportunity.mediaOutletWebsite}
                              target="_blank"
                            >
                              {opportunity.journalist?.publication?.name}
                            </a>
                          </p>
                        </td>

                        <td className="p-4 align-top">
                          <p className="text-sm text-gray-900">
                            <DomainAuthorityPill
                              domainAuthority={
                                opportunity.journalist?.publication
                                  ?.domainAuthority
                              }
                            />
                          </p>
                        </td>
                        <td className="p-4 align-top">
                          <p className="text-sm text-gray-90">
                            {opportunity.journalist?.name}
                          </p>
                        </td>
                        <td className="p-4 align-top">
                          <p className="text-sm text-gray-900">
                            {opportunity.deadline
                              ? moment(opportunity.deadline).format('LLL')
                              : 'Ongoing'}
                          </p>
                        </td>
                        <td>
                          <div className="p-4 space-y-2 flex flex-col">
                            <Button
                              type="secondary"
                              onClick={() => {
                                setSelectedOpportunity(opportunity);
                                setShowAddOpportunityModal(true);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              type="secondary"
                              onClick={async () => {
                                await deleteOpportunity(opportunity.id);
                                await refetch();
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {showAddOpportunityModal && (
        <AdminAddOpportunityModal
          open={showAddOpportunityModal}
          onClose={() => {
            setShowAddOpportunityModal(false);
            setSelectedOpportunity(null);
          }}
          onSubmit={onSubmit}
          companiesData={companiesData}
          opportunity={selectedOpportunity}
        />
      )}
    </AdminWrapper>
  );
};

export default AdminCreateOpportunity;
