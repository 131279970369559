import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import {
  ChartBarSquareIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { debounce } from 'lodash';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import JournalistImage from 'components/images/JournalistImage';
import useJournalistsBySearch from 'hooks/useJournalistsBySearch';

const SearchJournalistModalNoResultFound = () => (
  <div className="flex flex-col items-center justify-center h-full py-12">
    <ExclamationTriangleIcon className="w-16 h-16 mb-4 text-gray-500" />
    <h2 className="text-xl font-semibold mb-2">Oops! No Matches Yet.</h2>
    <p className="text-gray-500 text-center">
      We couldn&apos;t find any matching results. Please try again with
      different criteria.
    </p>
  </div>
);

const defaultLabels = {
  title: 'Add journalist to Sweetheart List',
  searchPlaceholder: 'Search by journalist',
  isAlreadyInList: 'Added',
  isDeactivated: 'Deactivated',
  add: 'Add',
};

const getButtonLabel = ({
  isAlreadyInList = false,
  isDeactivated = false,
  labels = defaultLabels,
}) => {
  if (isAlreadyInList) {
    return labels.isAlreadyInList;
  }

  if (isDeactivated) {
    return labels.isDeactivated;
  }

  return labels.add;
};

const SearchJournalistModal = ({
  onCancel = noop,
  selected = [],
  onSelect = noop,
  labels = {},
}) => {
  const mergedLabels = { ...defaultLabels, ...labels };
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');

  const { loading, journalists: suggestions } =
    useJournalistsBySearch(debouncedInputValue);

  const onChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    const handleDebouncedSearch = debounce(() => {
      setDebouncedInputValue(inputValue);
    }, 500);

    if (inputValue.length >= 3 || inputValue === '') {
      handleDebouncedSearch();
    }

    // Cleanup the debounce on unmount
    return () => handleDebouncedSearch.cancel();
  }, [inputValue]);

  return (
    <Modal open widthClass="max-w-3xl">
      <Modal.Close srMessage="Close find journalist Modal" onClose={onCancel} />
      <Modal.Title>{mergedLabels.title}</Modal.Title>
      <Modal.Content>
        <div className="mb-4">
          <InputFieldText
            type="text"
            containerClassName="w-full"
            name="search"
            placeholder={mergedLabels.searchPlaceholder}
            value={inputValue}
            onChange={onChange}
          >
            <InputFieldText.Prefix>
              <MagnifyingGlassIcon className="w-4 h-4" />
            </InputFieldText.Prefix>
          </InputFieldText>
        </div>
        <ul
          className={classNames(
            'h-[calc(100vh-370px)] overflow-auto flex flex-col',
            {
              'opacity-20': loading,
            }
          )}
        >
          {suggestions.length > 0 &&
            suggestions.map((j) => {
              const isAlreadyInList = Boolean(
                selected.find((s) => s?.id === j?.id)
              );
              const isDisabled = isAlreadyInList || j?.isDeactivated;

              return (
                <li
                  key={j.id}
                  className={classNames(
                    'grid grid-cols-[auto,1fr,1fr,0.7fr] items-center gap-2',
                    {
                      'odd:bg-gray-200 opacity-50 pointer-events-none':
                        isDisabled,
                      'odd:bg-gray-50': !isDisabled,
                    }
                  )}
                >
                  <JournalistImage
                    url={j.image}
                    className="h-6 w-6 ml-1"
                    name={j.name}
                  />
                  <div className="flex items-center gap-2">
                    <b>{j.name}</b>
                    {j.hasStats && (
                      <ChartBarSquareIcon className="h-5 w-5 text-teal-500" />
                    )}
                  </div>
                  <span>{j?.publication?.name || 'n/a'}</span>
                  <Button
                    disabled={isDisabled}
                    type={isDisabled ? 'secondary' : 'primary'}
                    onClick={() => onSelect(j)}
                    className="my-2"
                  >
                    {getButtonLabel({
                      isAlreadyInList,
                      isDeactivated: j.isDeactivated,
                      labels: mergedLabels,
                    })}
                  </Button>
                </li>
              );
            })}
          {loading && <Spinner />}
          {!suggestions.length && !loading && (
            <SearchJournalistModalNoResultFound />
          )}
        </ul>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" className="w-fit" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

SearchJournalistModal.propTypes = {
  onCancel: PropTypes.func,
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  labels: PropTypes.object,
};

export default SearchJournalistModal;
