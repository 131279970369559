import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import ThreadTag from 'components/tags/ThreadTag';

const ContentManagementDocumentResponseFormThreadsModal = ({
  open,
  threads,
  onSelect,
  onClose,
}) => {
  const handleSelectThread = async (threadData) => {
    onSelect(threadData);
  };

  return (
    <Modal open={open}>
      <Modal.Close
        srMessage="Close meta audience edit modal"
        onClose={onClose}
      />
      <Modal.Title>Select a thread from your inbox</Modal.Title>
      <Modal.Content>
        <ul className="divide-y divide-gray-100 text-left mt-1">
          {threads.map((thread) => (
            <li
              key={thread.id}
              className="flex items-center justify-between gap-x-6 py-5"
            >
              <div className="min-w-0">
                <div>
                  <ThreadTag threadIntent={thread?.intent} />
                </div>
                <div className="mt-0.5 flex items-start gap-x-3">
                  <p
                    className={
                      thread.subject
                        ? `text-sm font-semibold leading-6 text-gray-900`
                        : 'text-sm font-semibold italic leading-6 text-gray-400 line-clamp-1'
                    }
                  >
                    {thread.subject ?? 'No subject'}
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p className="whitespace-nowrap">{thread.journalist.name}</p>
                  <span className="mx-1">&middot;</span>
                  <p className="truncate">
                    {thread.journalist.publication.name}
                  </p>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <Button
                  type="secondary"
                  onClick={() => handleSelectThread(thread)}
                >
                  Select
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </Modal.Content>
    </Modal>
  );
};
ContentManagementDocumentResponseFormThreadsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  threads: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ContentManagementDocumentResponseFormThreadsModal;
