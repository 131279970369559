import PropTypes from 'prop-types';
import React from 'react';

import Tag from 'components/tags/Tag';
import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';

const SweetheartListJournalistContacted = ({
  journalist = {},
  isActiveConversation = false,
  date = '',
}) => {
  const bgColor = isActiveConversation ? 'blue-100' : 'green-100';
  const textColor = isActiveConversation ? 'blue-500' : 'green-500';
  let formattedDate = getDateFromTimestamp(date);

  if (formattedDate === 'Today') {
    formattedDate = 'today';
  } else {
    formattedDate = `on ${formattedDate}`;
  }

  return (
    <Tag
      bgColor={bgColor}
      textColor={textColor}
      data-test-id={`sweetheartListJournalist-startConversation-${journalist.name}-tag`}
    >
      {isActiveConversation ? 'Last contacted ' : 'Last draft created '}
      {formattedDate}
    </Tag>
  );
};

SweetheartListJournalistContacted.propTypes = {
  journalist: PropTypes.object.isRequired,
  isActiveConversation: PropTypes.bool,
  date: PropTypes.string,
};

export default SweetheartListJournalistContacted;
