import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import MvprDeeplink from 'components/MvprDeeplink/MvprDeeplink';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import CompanyImage from 'components/company/CompanyImage';
import Label from 'components/form/Label';
import SeparatorTextLine from 'components/form/SeparatorTextLine';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';
import InputFieldToggle from 'components/form/inputFields/InputFieldToggle';
import { REGEX_URL } from 'constants/regex';
import tasksColumns, { activityTypes } from 'constants/tasks';
import { ReactComponent as Logo } from 'static/mvpr-logo.svg';

import TasksAddEditModalDeadline from './TasksAddEditModalDeadline';
import TasksDeleteModal from './TasksDeleteModal';

const TasksAddEditModal = ({
  task = {},
  collaborators = [],
  onCancel = noop,
  onSubmit = noop,
  showCompanySelector = false,
  companies = [],
  onCompanyChange = noop,
}) => {
  const { showError, showSuccess } = useNotifications();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isUpserting, setIsUpserting] = useState(false);
  const [mvprDeeplink, setMvprDeeplink] = useState(task.reference || '');
  const form = useForm({
    defaultValues: task,
  });

  const companiesOptions = useMemo(
    () =>
      companies
        .map((company) => ({
          value: company.id,
          label: company.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [companies]
  );

  const {
    register,
    formState: { errors },
    control,
    getValues,
    setValue,
    handleSubmit,
  } = form;

  return (
    <Modal open>
      <Modal.Title>{task.id ? 'Edit Task' : 'Add Task'}</Modal.Title>
      <Modal.Content className="flex flex-col gap-2">
        <div className="flex flex-col gap-2 w-full">
          {showCompanySelector ? (
            <div className="flex flex-col gap-2">
              <Label htmlFor="name">Company</Label>
              <div className={task.id ? 'pointer-events-none opacity-20' : ''}>
                <Controller
                  name="companyId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputFieldDropdown
                      disabled={task.id}
                      options={companiesOptions}
                      onChange={(v) => {
                        if (task.id) {
                          return;
                        }

                        onChange(v);
                        onCompanyChange(v);
                      }}
                      placeholder="Select a company"
                      value={value}
                      buttonClassName="w-full"
                    />
                  )}
                />
              </div>
            </div>
          ) : null}
          <div className="flex gap-2 items-end justify-between">
            <div className="flex grow flex-col gap-2">
              <Label htmlFor="status">Activity Type</Label>
              <Controller
                name="activityType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputFieldDropdown
                    placeholder="Select an activity type"
                    options={activityTypes.map((activityType) => ({
                      value: activityType.id,
                      label: activityType.title,
                    }))}
                    onChange={onChange}
                    value={value}
                    buttonClassName="w-full"
                  />
                )}
              />
            </div>

            <Controller
              name="withAgency"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className="flex gap-2 items-center justify-center">
                  <div className="flex items-center justify-center p-1 h-[38px] w-[38px] bg-teal-500 rounded-sm">
                    <Logo className="text-white" />
                  </div>
                  <InputFieldToggle value={value} onChange={onChange} />
                  <CompanyImage
                    image={task.companyLogo}
                    className="h-[38px] w-[38px]"
                    alt={`${task.companyName} logo`}
                  />
                </div>
              )}
            />
          </div>

          <InputFieldText
            defaultValue=""
            maxLength={2000}
            {...register('title', {
              required: {
                value: true,
                message: 'Please provide a title',
              },
            })}
            errors={errors}
            placeholder="Title of the task"
          >
            <InputFieldText.Label>Title*</InputFieldText.Label>
          </InputFieldText>

          <div className="flex flex-col gap-2">
            <Label htmlFor="name">Description</Label>
            <Controller
              name="descriptionHtml"
              control={control}
              render={({ field: { onChange } }) => (
                <InputFieldTextEditor
                  name="descriptionHtml"
                  height={300}
                  initialValue={task?.descriptionHtml}
                  onChange={(html, text) => {
                    onChange(html);
                    setValue('description', text);
                  }}
                />
              )}
            />
          </div>

          <InputFieldText
            defaultValue=""
            maxLength={2000}
            {...register('externalLink', {
              pattern: {
                value: REGEX_URL,
                message: 'Please type in a valid URL',
              },
            })}
            errors={errors}
            placeholder="The URL to some external resource..."
          >
            <InputFieldText.Label>External link</InputFieldText.Label>
          </InputFieldText>

          <div className="flex gap-2 items-end">
            <InputFieldText
              defaultValue=""
              maxLength={2000}
              {...register('reference', {
                pattern: {
                  value: REGEX_URL,
                  message: 'Please type in a valid URL',
                },
              })}
              errors={errors}
              placeholder="The URL to some internal resource (thread, opportunity, journalist list etc.)..."
            >
              <InputFieldText.Label>MVPR Deeplink</InputFieldText.Label>
            </InputFieldText>
            <Button
              type={mvprDeeplink ? 'danger' : 'primary'}
              onClick={() => {
                const reference = getValues('reference');
                if (mvprDeeplink) {
                  setValue('reference', '');
                  setMvprDeeplink('');
                } else {
                  setMvprDeeplink(reference);
                }
              }}
            >
              {mvprDeeplink ? 'Remove' : 'Add'}
            </Button>
          </div>

          {mvprDeeplink ? (
            <div className="mt-1">
              <MvprDeeplink url={mvprDeeplink} />
            </div>
          ) : null}

          <div className="flex flex-col gap-2">
            <Label htmlFor="collaborators">Collaborators</Label>
            <Controller
              name="collaborators"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <InputFieldTagsAutocomplete
                  placeholder=""
                  maxSuggestionsLength={15}
                  customFieldName="collaborator"
                  allowNew={false}
                  suggestions={(collaborators || [])
                    .filter(
                      (collaborator) =>
                        value.find((tag) => tag.id === collaborator.id) ===
                        undefined
                    )
                    .map((collaborator) => ({
                      id: collaborator.id,
                      name: collaborator.firstName,
                    }))}
                  showAvatar
                  tags={value}
                  onAddition={(tag) => {
                    if (value.find((t) => t.id === tag.id)) {
                      return;
                    }
                    const data = collaborators.find(
                      (collaborator) => collaborator.id === tag.id
                    );
                    onChange([
                      ...value,
                      {
                        ...tag,
                        data,
                      },
                    ]);
                  }}
                  onDelete={(tag) => {
                    onChange(value.filter((t) => tag.id !== t.id));
                  }}
                />
              )}
            />
          </div>
          <SeparatorTextLine text="OR" />

          <Controller
            name="externalCollaborators"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <InputFieldTagsAutocomplete
                placeholder=""
                customFieldName="externalCollaborator"
                allowNew
                suggestions={[]}
                tags={value}
                onAddition={(tag) => {
                  if (value.find((t) => t.id === tag.id)) {
                    return;
                  }
                  onChange([...value, tag]);
                }}
                showAvatar
                onDelete={(tag) => {
                  onChange(value.filter((t) => tag.id !== t.id));
                }}
              />
            )}
          />

          <div className="flex flex-col gap-2">
            <Label htmlFor="status">Status</Label>
            <Controller
              name="status"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputFieldDropdown
                  placeholder="Select a status"
                  options={tasksColumns.map((column) => ({
                    value: column.id,
                    label: column.title,
                  }))}
                  onChange={onChange}
                  value={value}
                  buttonClassName="w-full"
                />
              )}
            />
          </div>

          <TasksAddEditModalDeadline control={control} setValue={setValue} />

          {showDeleteModal ? (
            <TasksDeleteModal
              title={task.title}
              onCancel={() => setShowDeleteModal(false)}
              onDelete={handleSubmit(async () => {
                try {
                  showSuccess({
                    message: `Task ${
                      task.id ? 'edited' : 'created'
                    } successfully`,
                  });
                  onCancel();
                } catch {
                  showError({
                    message: 'Something went wrong',
                  });
                }
              })}
            />
          ) : null}
        </div>
      </Modal.Content>

      <Modal.LeftButtons>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        {/* {task.id ? (
          <Button
            type="danger"
            className="relative"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete Task
          </Button>
        ) : null} */}
      </Modal.LeftButtons>
      <Modal.RightButtons>
        <Button
          className="relative"
          onClick={handleSubmit(async (newTask) => {
            setIsUpserting(true);
            try {
              await onSubmit(newTask);
              showSuccess({
                message: `Task ${task.id ? 'edited' : 'created'} successfully`,
              });
              onCancel();
            } catch (error) {
              showError({
                message: 'Something went wrong',
              });
            }
            setIsUpserting(false);
          })}
        >
          <span
            className={classNames({
              'opacity-20 transition-opacity': isUpserting,
            })}
          >
            {task.id ? 'Update' : 'Create'} Task
          </span>
          {isUpserting ? <Spinner color="text-white" /> : null}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

TasksAddEditModal.propTypes = {
  collaborators: PropTypes.arrayOf(PropTypes.object),
  task: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  showCompanySelector: PropTypes.bool,
  companies: PropTypes.arrayOf(PropTypes.object),
  onCompanyChange: PropTypes.func,
};

export default TasksAddEditModal;
