import { generatePath } from 'react-router-dom';

import { ANNOUNCEMENT_PATH, CONTENT_MANAGEMENT_PATH } from 'constants/paths';

const redirectPaths = {
  announcement: (companySlug, announcementId) =>
    generatePath(ANNOUNCEMENT_PATH, {
      id: companySlug,
      announcementId,
    }),
  // eslint-disable-next-line max-params
  documents: (companySlug, tab, documentId) =>
    generatePath(CONTENT_MANAGEMENT_PATH, {
      id: companySlug,
      tab,
      documentId,
    }),
};

export default redirectPaths;
