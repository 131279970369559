import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import useUserData from 'contexts/UserContext/hooks/useUserData';
import useAPIRequest from 'hooks/useAPIRequest';
import useCurrentUser from 'hooks/useCurrentUser';

import IntercomWithUserAndCompany from './IntercomWithUserAndCompany';
import bootIntercom from './helpers/bootIntercom';
import createUserAttributes from './helpers/createUserAttributes';

const IntercomWithUser = ({ userDataAuth = {} }) => {
  const { data: user } = useCurrentUser();
  const userClaims = useUserData();

  const [userHash, setUserHash] = useState(null);

  const { fetchData: ensureUserIntercomHash } = useAPIRequest({
    method: 'PUT',
    service: 'UCD',
  });

  const userAttributes = useMemo(
    () =>
      createUserAttributes({
        userDataAuth,
        user,
        userClaims,
        userHash,
      }),
    [userDataAuth, user, userClaims, userHash]
  );

  const shouldTrackOnlyUserAttributes =
    userClaims.isAnonymous ||
    userClaims.claimsAdmin ||
    userClaims.claimsAgencyId;

  useEffect(() => {
    const generateUserHash = async () => {
      const response = await ensureUserIntercomHash({
        endpoint: `/users/${userDataAuth?.uid}/hash`,
      });
      if (response?.hash) {
        setUserHash(response.hash);
      }
    };
    generateUserHash();
  }, [ensureUserIntercomHash, userDataAuth?.uid, user?.intercomUserHash]);

  useEffect(() => {
    if (shouldTrackOnlyUserAttributes && userAttributes) {
      bootIntercom(userAttributes);
    }
  }, [shouldTrackOnlyUserAttributes, userAttributes, userHash]);

  if (shouldTrackOnlyUserAttributes || !userAttributes) {
    return null;
  }

  return <IntercomWithUserAndCompany userAttributes={userAttributes} />;
};

IntercomWithUser.propTypes = {
  userDataAuth: PropTypes.object,
};

export default IntercomWithUser;
