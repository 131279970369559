const funnelStatus = {
  DRAFT: 'DRAFT',
  PITCHED: 'PITCHED',
  OPENED: 'OPENED',
  PRESS_PAGE_VISIT: 'PRESS_PAGE_VISIT',
  RESPONDED: 'RESPONDED',
  COVERED: 'COVERED',
};

const funnelNames = {
  '': 'No Draft',
  [funnelStatus.DRAFT]: 'Draft',
  [funnelStatus.PITCHED]: 'Pitched',
  [funnelStatus.OPENED]: 'Opened',
  [funnelStatus.PRESS_PAGE_VISIT]: 'Press Page Visit',
  [funnelStatus.RESPONDED]: 'Responded',
  [funnelStatus.COVERED]: 'Covered',
};

const funnelBackgroundColors = {
  '': '#D1D5DB',
  [funnelStatus.DRAFT]: '#6B7280',
  [funnelStatus.PITCHED]: '#0d2752',
  [funnelStatus.OPENED]: '#a8557f',
  [funnelStatus.PRESS_PAGE_VISIT]: '#fee183',
  [funnelStatus.RESPONDED]: '#5eead4',
  [funnelStatus.COVERED]: '#14b8a6',
};

const funnelTextColors = {
  '': '#0d2752',
  [funnelStatus.DRAFT]: '#ffffff',
  [funnelStatus.PITCHED]: '#ffffff',
  [funnelStatus.OPENED]: '#ffffff',
  [funnelStatus.PRESS_PAGE_VISIT]: '#0d2752',
  [funnelStatus.RESPONDED]: '#0d2752',
  [funnelStatus.COVERED]: '#ffffff',
};

export const colors = [
  '#14b8a6', // Largest portion
  '#0d2752', // Second largest portion
  '#fee183', // Third largest portion
  '#a8557f', // Fourth largest portion
  '#2dd4bf', // Seventh largest portion
  '#5eead4', // Eighth largest portion
  '#99f6e4', // Ninth largest portion
  '#ccfbf1', // Tenth largest portion
  '#D1D5DB', // Sixth largest portion
];

export { funnelStatus, funnelNames, funnelBackgroundColors, funnelTextColors };
