import PropTypes from 'prop-types';
import React from 'react';

import { Link, generatePath } from 'react-router-dom';

import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';

const GenerateContentWarning = ({
  companyData = {},
  content = 'Article Ideas',
}) => (
  <div className="flex items-center overflow-hidden rounded-lg bg-amber-100 px-3 py-2 sm:px-6">
    <p className="text-sm leading-6 text-gray-700">
      {` We can auto-generate ${content} for you using AI ✨`}
      <br />
      Head over to the{' '}
      <Link
        to={generatePath(CONTENT_MANAGEMENT_PATH, {
          id: companyData.slug,
          tab: 'resources',
          documentId: 'content-ideation-qa',
        })}
        className="text-gray-900 underline"
      >
        Content Ideation Q&A
      </Link>{' '}
      and fill in the form to unlock this feature!
    </p>
  </div>
);

GenerateContentWarning.propTypes = {
  companyData: PropTypes.object,
  content: PropTypes.string,
};

export default GenerateContentWarning;
