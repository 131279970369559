import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import useQueryParams from 'routes/Opportunities/hooks/useQueryParams';

const BackButton = ({
  companySlug = '',
  defaultLabel = 'Back to overview',
  defaultPath = '/',
  redirectPaths,
}) => {
  const { from } = useQueryParams();
  const [fromType, id] = from?.split(':') || [];

  const navigateToPath = fromType && redirectPaths[fromType];
  return (
    <div>
      {navigateToPath ? (
        <Link to={navigateToPath(companySlug, id)} className="hover:underline">
          ← Back to {fromType}
        </Link>
      ) : (
        <Link to={defaultPath} className="hover:underline">
          ← {defaultLabel}
        </Link>
      )}
    </div>
  );
};

BackButton.propTypes = {
  companySlug: PropTypes.string,
  redirectPaths: PropTypes.object,
  defaultLabel: PropTypes.string,
  defaultPath: PropTypes.string,
};

export default BackButton;
