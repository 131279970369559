import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'reactfire';

import useThreadsSearchParams from './useThreadsSearchParams';

const archiveDraft = async ({ threadId = '', auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/threads/${threadId}/archive`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to archive thread: ${error.message}`);
  }
};

const useArchiveThread = (companyId) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const [searchParams] = useThreadsSearchParams();
  const threadsKey = ['companyThreads', companyId, searchParams.toString()];
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (threadId) => archiveDraft({ threadId, auth }),
    onSettled: () => {
      queryClient.invalidateQueries(threadsKey);
    },
  });

  return {
    archiveThread: mutateAsync,
    isArchiving: isLoading,
  };
};

export default useArchiveThread;
