import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { noop, uniqBy } from 'lodash';
import { generatePath, useNavigate } from 'react-router-dom';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import { SWEETHEART_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import AnnouncementJournalistListFunnel from './AnnouncementJournalistListFunnel';
import AnnouncementJournalistListItem from './AnnouncementJournalistListItem';

const AnnouncementJournalistList = ({
  data = {},
  selectedJournalists = [],
  setSelectedJournalists = noop,
  handleRemoveList = noop,
}) => {
  const navigate = useNavigate();
  const { companyData } = useCompanyData();
  const { journalists, id, name } = data;

  const [isListVisible, setIsListVisible] = useState(true);

  const isJournalistSelected = (journalistId) =>
    selectedJournalists.some((journalist) => journalist.id === journalistId);

  const handleCheckboxChange = (journalist, isChecked) => {
    if (isChecked) {
      setSelectedJournalists((prev) =>
        uniqBy([...prev, { id: journalist.id, ...journalist }], 'id')
      );
    } else {
      setSelectedJournalists((prev) =>
        prev.filter((item) => item.id !== journalist.id)
      );
    }
  };

  return (
    <div className="shadow rounded-md overflow-hidden bg-white">
      <div className="flex xl:flex-wrap 2xl:flex-nowrap gap-4 border-b items-center justify-between  p-4">
        <span className="flex xl:w-full 2xl:w-auto items-center font-medium">
          {name}
        </span>
        <div className="flex justify-between gap-1 xl:w-full 2xl:w-auto">
          <AnnouncementJournalistListFunnel journalists={journalists} />
          <DotsMenu
            theme="dark"
            verticalDots
            options={[
              {
                label: 'Detach list',
                onClick: handleRemoveList,
              },
              {
                label: 'Go to list',
                icon: <UserGroupIcon className="w-4 h-4" />,
                onClick: () =>
                  navigate(
                    generatePath(SWEETHEART_PATH, {
                      id: companyData.slug,
                      tab: 'lists',
                      listId: id,
                    })
                  ),
              },
            ]}
          />
        </div>
      </div>

      {isListVisible && (
        <div className="p-4">
          {journalists.map((journalist) => (
            <AnnouncementJournalistListItem
              key={journalist.id}
              journalist={journalist}
              onCheckboxChange={handleCheckboxChange}
              isJournalistSelected={isJournalistSelected(journalist.id)}
            />
          ))}
        </div>
      )}

      <div
        onClick={() => setIsListVisible((prev) => !prev)}
        className="hover:bg-gray-50 cursor-pointer flex items-center justify-between p-2"
      >
        <ChevronDownIcon
          className={classNames('w-6 h-6 mx-auto text-gray-400  ', {
            'transform rotate-180': isListVisible,
          })}
        />
      </div>
    </div>
  );
};

AnnouncementJournalistList.propTypes = {
  data: PropTypes.object,
  handleRemoveList: PropTypes.func,
  selectedJournalists: PropTypes.array,
  setSelectedJournalists: PropTypes.func,
};

export default AnnouncementJournalistList;
