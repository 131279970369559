import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ContentManagementDocumentsEmptyState from './ContentManagementDocuments/ContentManagementDocumentsEmptyState';
import CreateDocumentModal from './ContentManagementDocuments/CreateDocumentModal';

const ContentManagementCreateDocument = ({ createDocument, isLoading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="mt-6">
        <ul className="grid grid-cols-1 gap-6 border-gray-200 py-6 pt-0 sm:grid-cols-2">
          <li>
            <button
              type="button"
              className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center hover:border-gray-400 focus:outline-none"
              onClick={() => setIsModalOpen(true)}
              disabled={isLoading}
            >
              <span className="mt-2 block text-sm font-semibold text-gray-500">
                {isLoading ? 'Creating...' : 'Create a new document...'}
              </span>
            </button>
          </li>
        </ul>
      </div>

      {isModalOpen && (
        <CreateDocumentModal
          isOpen={isModalOpen}
          onAbort={() => setIsModalOpen(false)}
        >
          <ContentManagementDocumentsEmptyState
            title="Create a new document"
            subTitle="Please choose one of the templates below."
            createDocument={createDocument}
            isLoading={isLoading}
          />
        </CreateDocumentModal>
      )}
    </>
  );
};
ContentManagementCreateDocument.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  createDocument: PropTypes.func.isRequired,
};

export default ContentManagementCreateDocument;
