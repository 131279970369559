import React from 'react';

import { CogIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { sortBy } from 'lodash';

const createTemplateOptions = (templates = []) => [
  ...sortBy(templates, ['default', 'label']).map((t) => ({
    value: t.name,
    label: t.name,
  })),
  {
    value: 'manage_templates',
    label: (isActive) => (
      <span className="flex gap-2">
        <CogIcon
          className={classNames('w-5 h-5', {
            'text-teal-500': !isActive,
            'text-white': isActive,
          })}
        />
        Manage templates...
      </span>
    ),
  },
];

export default createTemplateOptions;
