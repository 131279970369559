import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';

const TasksDeleteModal = ({ title = '', onCancel = noop, onDelete = noop }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <Modal widthClass="max-w-md" open>
      <Modal.Icon Icon={ChatBubbleBottomCenterTextIcon} />
      <Modal.Title>Delete task</Modal.Title>
      <Modal.Description>
        Are you sure you want to delete task: <b>{title}</b>
      </Modal.Description>
      <Modal.RightButtons>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="danger"
          className="relative"
          onClick={async () => {
            setIsDeleting(true);
            await onDelete();
            setIsDeleting(false);
          }}
        >
          <span
            className={classNames({
              'opacity-20 transition-opacity': isDeleting,
            })}
          >
            Delete Task
          </span>
          {isDeleting ? <Spinner color="text-white" /> : null}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

TasksDeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TasksDeleteModal;
