import PropTypes from 'prop-types';
import React from 'react';

import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import noop from 'lodash/noop';
import ReactSlider from 'react-slider';

import getHistogram from './helpers/getHistogram';
import getTickLabel from './helpers/getTickLabel';

const JournalistFiltersFundingAmount = ({
  name = '',
  maxFundingIndex = 0,
  minFundingIndex = 0,
  fundingLevels = [],
  onChange = noop,
  fundingCounts,
}) => {
  const heights = getHistogram(fundingCounts, fundingLevels);
  const maxHeight = Math.max(...heights);

  return (
    <Disclosure defaultOpen as="div" className="border-b border-gray-200 py-6">
      {({ open }) => (
        <>
          <h3 className="-my-3 flow-root">
            <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
              <span className="font-medium text-gray-900">{name}</span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="py-6">
            <div className="space-y-4">
              <div>
                <div className="h-24 flex mt-2 -mb-2 mx-[10px]">
                  {heights.map((h, i) => (
                    <div
                      key={`hist-${fundingLevels[i]}`}
                      className={classNames('self-end', {
                        'bg-teal-500':
                          minFundingIndex <= i && maxFundingIndex >= i + 1,
                        'bg-gray-300': !(
                          minFundingIndex <= i && maxFundingIndex >= i + 1
                        ),
                      })}
                      style={{
                        width: `${100 / heights.length}%`,
                        height: `${(h * 100) / maxHeight}%`,
                        outline: 'solid 1px white',
                      }}
                    />
                  ))}
                </div>
                <ReactSlider
                  trackClassName="h-1 mt-2 bg-teal-300 ml-[10px] mr-[10px]"
                  thumbClassName="w-5 h-5 bg-teal-500 rounded-full shadow-lg outline outline-teal-100 cursor-grab"
                  markClassName="mt-5 text-sm"
                  value={[minFundingIndex, maxFundingIndex]}
                  marks={1}
                  min={0}
                  max={fundingLevels.length - 1}
                  onChange={onChange}
                  defaultValue={[0, fundingLevels.length - 1]}
                  minDistance={1}
                  renderMark={(markProps) => (
                    <span {...markProps}>
                      {getTickLabel(fundingLevels[markProps.key])}
                    </span>
                  )}
                />
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

JournalistFiltersFundingAmount.propTypes = {
  name: PropTypes.string,
  maxFundingIndex: PropTypes.number,
  minFundingIndex: PropTypes.number,
  fundingLevels: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  fundingCounts: PropTypes.array,
};

export default JournalistFiltersFundingAmount;
