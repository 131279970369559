import React, { Suspense, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import ContentManagementNavbar from '../ContentManagementNavbar';
import useCurrentResourceContext from '../hooks/useCurrentResourceContext';

import ContentManagementCustomResourcesList from './ContentManagementCustomResourcesList';

const ContentManagementResourcesTab = () => {
  const { documentId: selectedResourceTemplate } = useParams();
  const { areCustomResourcesLoading, getResources, getCustomResources } =
    useCurrentResourceContext();

  useEffect(() => {
    getResources();
    getCustomResources();
  }, [getCustomResources, getResources]);

  return (
    <>
      <ContentManagementNavbar />
      <div>
        <div className="flex flex-col ">
          {!selectedResourceTemplate ? (
            <aside>
              <Suspense>
                {!areCustomResourcesLoading ? (
                  <ContentManagementCustomResourcesList />
                ) : null}
              </Suspense>
            </aside>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ContentManagementResourcesTab;
