import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { PencilSquareIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

import MessageStatus from 'components/MessageStatus';
import Button from 'components/buttons/Button';
import Tag from 'components/tags/Tag';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useAPIRequest from 'hooks/useAPIRequest';

import AddEmailModal from './AddEmailModal';

const AgencyDashboardCompaniesCompanyMessagesMessage = ({
  message = {},
  onExpand = noop,
  onEdit = noop,
  fetchData = noop,
}) => {
  const { claimsAdmin } = useUserData();
  const [showAddEmailModal, setShowAddEmailModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const journalistEmail = message?.journalist?.email;
  const { fetchData: approve } = useAPIRequest({
    method: 'POST',
    service: 'MOD',
  });

  const handleApprove = async () => {
    if (claimsAdmin && !journalistEmail) {
      setShowAddEmailModal(true);
      return;
    }

    setIsProcessing(true);
    await approve({
      endpoint: `/messages/${message.id}/send`,
    });
    await fetchData();

    setIsProcessing(false);
  };

  return (
    <tr key={message.id} className={isProcessing ? 'opacity-50' : ''}>
      <td className="py-4 pl-4 pr-3 text-sm text-gray-500">
        <MessageStatus status={message.status} className="!mr-2" />
        {claimsAdmin && !journalistEmail ? (
          <Tag bold bgColor="yellow-100" textColor="amber-500">
            MISSING EMAIL
          </Tag>
        ) : null}
      </td>
      <td className="py-4 px-3 text-sm text-gray-500">
        {message.journalist.name}
      </td>
      <td className="py-4 px-3 text-sm text-gray-500">
        {message.journalist.publication.name}
      </td>
      <td className="group space-y-2 py-4 px-3 text-sm text-gray-500 relative">
        <p className="font-bold line-clamp-1 max-w-prose">{message.subject}</p>
        <p className="line-clamp-2 max-w-prose">{message.text}</p>
        <div className="opacity-0 hover:opacity-100 transition-opacity group-hover:pointer-events-auto pointer-events-none flex items-center justify-center inset-0 bg-white/90 absolute">
          <button
            type="button"
            onClick={onExpand}
            className="text-teal-500 bg-teal-50 hover:bg-teal-100 px-3 py-1.5 rounded-md text-sm font-medium"
          >
            Expand
          </button>
        </div>
      </td>
      <td className="py-4 px-3 text-sm text-gray-500">
        <Button onClick={handleApprove} disabled={isProcessing}>
          Approve
        </Button>
      </td>

      <td>
        <Button type="danger" onClick={onEdit}>
          <PencilSquareIcon className="w-4 h-4" />
        </Button>
      </td>
      {showAddEmailModal && claimsAdmin ? (
        <AddEmailModal
          journalist={message.journalist}
          onCancel={() => setShowAddEmailModal(false)}
          revalidate={fetchData}
        />
      ) : null}
    </tr>
  );
};

AgencyDashboardCompaniesCompanyMessagesMessage.propTypes = {
  message: PropTypes.object.isRequired,
  onExpand: PropTypes.func,
  onEdit: PropTypes.func,
  fetchData: PropTypes.func,
};
export default AgencyDashboardCompaniesCompanyMessagesMessage;
