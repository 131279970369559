import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';

import { debounce, isEqual } from 'lodash';
import noop from 'lodash/noop';
import { useParams } from 'react-router-dom';

import useAddSavedDraftNotification from 'hooks/useAddSavedDraftNotification';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

const defaultValues = {
  draft: {
    html: '',
    subject: '',
    template: '',
    text: '',
  },
  saveDraft: noop,
  saveDraftNow: noop,
};

export const InboxMessageResponseFormEditorContext =
  createContext(defaultValues);

export const useResponseFormEditorContext = () =>
  useContext(InboxMessageResponseFormEditorContext);

const InboxMessageResponseFormEditorProvider = ({
  containerRef = {},
  children = null,
}) => {
  const { threadId } = useParams();
  const { saveDraft } = useInboxContext();
  const { data, setData } = useInboxContext().draft || {};

  const addSavedDraftNotification = useAddSavedDraftNotification(containerRef);

  const debouncedSaveDraft = useMemo(
    () =>
      debounce(async (newDraft = {}) => {
        await saveDraft(newDraft);
        addSavedDraftNotification();
      }, 1000),
    [addSavedDraftNotification, saveDraft]
  );

  const value = useMemo(
    () => ({
      draft: data,
      saveDraft: (newValues = {}) => {
        if (threadId !== data.threadId) {
          return;
        }

        setData((prev) => {
          const newDraft = {
            ...prev,
            ...newValues,
          };

          if (isEqual(newDraft, prev)) {
            return prev;
          }

          debouncedSaveDraft(newDraft);
          return newDraft;
        });
      },
      saveDraftNow: async () => debouncedSaveDraft.flush(),
    }),
    [data, threadId, setData, debouncedSaveDraft]
  );

  return (
    <InboxMessageResponseFormEditorContext.Provider value={value}>
      {children}
    </InboxMessageResponseFormEditorContext.Provider>
  );
};

InboxMessageResponseFormEditorProvider.propTypes = {
  containerRef: PropTypes.object,
  children: PropTypes.node,
};

export default InboxMessageResponseFormEditorProvider;
