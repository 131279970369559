import PropTypes from 'prop-types';
import React from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';

import DashLine from 'components/DashLine';
import OpportunityDescription from 'components/opportunities/opportunity/OpportunityDescription';

const InboxMessageThreadDetailDescription = ({
  opportunity = {},
  type = '',
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const showDetails = searchParams.get('showDetails') === 'true';

  const showExpandCollapseButton =
    opportunity?.descriptionHtml || opportunity?.query;
  const ExpandCollapseIcon = showDetails ? ChevronUpIcon : ChevronDownIcon;

  return (
    <>
      {showExpandCollapseButton ? (
        <div className="flex justify-center pointer-events-none items-center sticky bottom-4 -mb-4">
          <DashLine />
          <button
            type="button"
            className="flex gap-1 items-center pointer-events-auto bg-white border border-gray-300 rounded-md px-2 py-1 text-xs uppercase text-gray-700 hover:bg-gray-50"
            data-test-id={`inboxMessage-${type}Context-toggle`}
            onClick={() =>
              setSearchParams((prev) => {
                prev.set('showDetails', !showDetails);
                return prev;
              })
            }
          >
            <span>{showDetails ? 'Collapse' : 'Expand'}</span>
            <ExpandCollapseIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          <DashLine />
        </div>
      ) : null}

      <motion.div
        initial={false}
        animate={{
          height: showDetails ? 'auto' : 0,
          opacity: showDetails ? 1 : 0,
          pointerEvents: showDetails ? 'auto' : 'none',
        }}
      >
        <div className="flex flex-col px-4">
          <h4 className="text-xl mt-6 font-semibold">{opportunity?.title}</h4>
          {opportunity?.descriptionHtml && (
            <OpportunityDescription
              descriptionHtml={opportunity?.descriptionHtml}
            />
          )}
        </div>
      </motion.div>
    </>
  );
};

InboxMessageThreadDetailDescription.propTypes = {
  opportunity: PropTypes.object,
  type: PropTypes.string,
};

export default InboxMessageThreadDetailDescription;
