import { useMutation } from 'react-query';
import { useAuth } from 'reactfire';

const updateUser = async ({ data, auth }) => {
  const BASE_URL = process.env.REACT_APP_UCD_URL;
  const endpoint = '/users/me';

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to update current user: ${error.message}`);
  }
};

const useUpateCurrentUser = () => {
  const auth = useAuth();
  return useMutation((data) => updateUser({ data, auth }));
};

export default useUpateCurrentUser;
