import { useEffect } from 'react';

import noop from 'lodash/noop';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  AGENCY_DASHBOARD_PATH,
  COMPANIES_JOURNALIST_PATH,
  LOGIN_PATH,
  OPPORTUNITIES_PATH,
} from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

// redirect hook for homepage, redirecting the user to the correct path on load
const useRedirectHome = () => {
  const { isAnonymous, uid, claimsJournalist, claimsAgencyId } = useUserData();
  const { companyData } = useCompanyData();
  const navigate = useNavigate();
  const slug = companyData?.slug;
  const isLoggedIn = !isAnonymous && uid;

  useEffect(() => {
    if (claimsAgencyId) {
      navigate(
        generatePath(AGENCY_DASHBOARD_PATH, { agencyId: claimsAgencyId }),
        { replace: true }
      );
      return noop;
    }

    if (claimsJournalist) {
      navigate(COMPANIES_JOURNALIST_PATH, { replace: true });
      return noop;
    }

    if ((isLoggedIn || isAnonymous) && slug) {
      navigate(generatePath(OPPORTUNITIES_PATH, { id: slug }), {
        replace: true,
      });
      return noop;
    }

    // If none of the above, link to login
    navigate(LOGIN_PATH, { replace: true });

    return noop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useRedirectHome;
