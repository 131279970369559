/*
This lists all combinations of bgColor textColor and borderColor used in the app
It is necessary to include them in the build and a good lookup to see how we colored tags in the past

bg-purple-100 text-purple-500 border-purple-400
bg-indigo-100 text-indigo-500 border-indigo-400
bg-red-100 text-red-500 border-red-400
bg-blue-100 text-blue-500 border-blue-400
bg-green-100 text-green-500 border-green-400
bg-yellow-100 text-yellow-500 border-yellow-400
bg-pink-100 text-pink-500 border-pink-400
bg-teal-100 text-teal-500 border-teal-400
bg-orange-100 text-orange-500 border-orange-400
bg-amber-100 text-amber-500 border-amber-400
bg-lime-100 text-lime-500 border-lime-400
bg-emerald-100 text-emerald-500 border-emerald-400
bg-cyan-100 text-cyan-500 border-cyan-400
bg-violet-100 text-violet-500 border-violet-400
bg-fuchsia-100 text-fuchsia-500 border-fuchsia-400
bg-rose-100 text-rose-500 border-rose-400
bg-amber-300 text-amber-700
bg-green-300 text-green-700
bg-gray-700
bg-gray-200 text-gray-600
bg-yellow-200 text-yellow-600
bg-green-200 text-green-600
bg-red-200 text-red-600
bg-gray-200 text-gray-600
bg-gray-100 text-gray-500 border-gray-500
bg-blue-200 text-blue-700
bg-amber-200 text-amber-700
bg-teal-200 text-teal-600
bg-red-100 text-red-700
bg-gray-200 text-gray-700
bg-red-200 text-red-400
bg-gray-200 text-gray-400
bg-blue-500 text-white
bg-red-500 text-red-100
bg-teal-100 text-teal-700
bg-gray-800
*/

// bgColor, textColor, borderColor
const tagColorTuples = [
  ['purple-100', 'purple-500', 'purple-400'],
  ['indigo-100', 'indigo-500', 'indigo-400'],
  ['blue-100', 'blue-500', 'blue-400'],
  ['green-100', 'green-500', 'green-400'],
  ['yellow-100', 'yellow-500', 'yellow-400'],
  ['pink-100', 'pink-500', 'pink-400'],
  ['teal-100', 'teal-500', 'teal-400'],
  ['orange-100', 'orange-500', 'orange-400'],
  ['amber-100', 'amber-500', 'amber-400'],
  ['lime-100', 'lime-500', 'lime-400'],
  ['emerald-100', 'emerald-500', 'emerald-400'],
  ['cyan-100', 'cyan-500', 'cyan-400'],
  ['violet-100', 'violet-500', 'violet-400'],
  ['fuchsia-100', 'fuchsia-500', 'fuchsia-400'],
  ['rose-100', 'rose-500', 'rose-400'],
];

// get the bg/text/border className combination for a tag, based on its index or (if not available) its length
// eslint-disable-next-line max-params
const getTagColors = (index = 0, length = 0, tag = {}) => {
  if (tag && tag.bgColor && tag.textColor && tag.borderColor) {
    return [tag.bgColor, tag.textColor, tag.borderColor];
  }
  if (index === -2) {
    return ['black', 'white', ''];
  }
  const idx = index === -1 ? length : index;
  return tagColorTuples[idx % tagColorTuples.length];
};

export default getTagColors;
