import PropTypes from 'prop-types';
import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

// Displays a tooltip with some information about something
const Tooltip = ({
  dataHTML = false,
  copy = null,
  html = null,
  id = '',
  wrapperClassName = '',
  testId = '',
  timeout = 0,
}) => (
  <div
    className={classNames('inline ml-1', wrapperClassName)}
    data-test-id={testId}
  >
    <InformationCircleIcon
      data-html={dataHTML}
      data-tooltip-id={id}
      data-tooltip-content={copy}
      data-tooltip-html={html}
      data-tooltip-hide={timeout}
      className="w-4 h-4 text-teal-500 cursor-pointer"
    />
  </div>
);

Tooltip.propTypes = {
  dataHTML: PropTypes.bool,
  copy: PropTypes.string,
  html: PropTypes.string,
  id: PropTypes.string,
  wrapperClassName: PropTypes.string,
  testId: PropTypes.string,
  timeout: PropTypes.number,
};

export default Tooltip;
