/* eslint-disable max-params */

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from 'reactfire';

const fetchTemplates = async (companyId, threadId, auth) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/companies/${companyId}/templates?threadId=${threadId}`;
  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch templates: ${error.message}`);
  }
};

const upsertTemplate = async (companyId, data, auth) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/companies/${companyId}/templates`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(
      `Failed to ${data.id ? 'update' : 'create'} template: ${error.message}`
    );
  }
};

const deleteTemplate = async (companyId, templateId, auth) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/companies/${companyId}/templates/${templateId}`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to delete template: ${error.message}`);
  }
};

const useTemplates = (companyId = '') => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { threadId } = useParams();
  const templates = useQuery({
    queryKey: ['templates', companyId, threadId],
    queryFn: () => fetchTemplates(companyId, threadId, auth),
    enabled: !!companyId && !!threadId,
    initialData: [],
  });

  const upsertTemplateMutation = useMutation({
    mutationFn: async (data) => upsertTemplate(companyId, data, auth),
    onMutate: async (data) => {
      await queryClient.cancelQueries(['templates', companyId, threadId]);
      const previousTemplates = queryClient.getQueryData([
        'templates',
        companyId,
      ]);
      queryClient.setQueryData(['templates', companyId, threadId], (old) => [
        ...old.filter((template) => template.id !== data.id),
        { ...data, pending: true },
      ]);

      return { previousTemplates };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        ['templates', companyId, threadId],
        context.previousTemplates
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['templates', companyId, threadId]);
    },
  });

  const deleteTemplateMutation = useMutation({
    mutationFn: (templateId) => deleteTemplate(companyId, templateId, auth),
    onMutate: async (templateId) => {
      await queryClient.cancelQueries(['templates', companyId, threadId]);
      const previousTemplates = queryClient.getQueryData([
        'templates',
        companyId,
        threadId,
      ]);
      queryClient.setQueryData(
        ['templates', companyId, threadId],
        previousTemplates.filter((template) => template.id !== templateId)
      );

      return { previousTemplates };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        ['templates', companyId, threadId],
        context.previousTemplates
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['templates', companyId, threadId]);
    },
  });

  return {
    templates,
    upsertTemplate: upsertTemplateMutation.mutateAsync,
    deleteTemplate: deleteTemplateMutation.mutateAsync,
  };
};

export default useTemplates;
