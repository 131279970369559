import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import JournalistTeaserWithModal from 'components/JournalistTeaserWithModal';
import Tag from 'components/tags/Tag';
import ThreadTag from 'components/tags/ThreadTag';
// eslint-disable-next-line import/no-named-as-default
import tabs from 'constants/inboxTabs';

const MvprDeeplinkThread = ({ data = [] }) => {
  const [threadDetails, thread] = data;

  const { subject, journalist, source, status } = thread || {};
  const { opportunity, messages = [] } = threadDetails || {};

  const { description } = opportunity || {};

  return (
    <div className="flex flex-col gap-2 text-gray-700">
      <div className="flex gap-2 justify-between">
        <JournalistTeaserWithModal journalist={journalist} />
        <div className="flex gap-1 items-end flex-col">
          <div className="flex gap-2">
            <ThreadTag className="!m-0" threadIntent={source} />
            <Tag className="!m-0">
              {tabs.find((tab) => tab.field === status).title}
            </Tag>
          </div>
          <p className="text-xs text-gray-500">{messages.length} messages</p>
        </div>
      </div>
      <h2
        className={classNames({
          'font-bold': subject,
          'italic text-gray-400': !subject,
        })}
      >
        {subject || 'No subject line'}
      </h2>
      <p className="line-clamp-3 text-sm">{description}</p>
    </div>
  );
};

MvprDeeplinkThread.propTypes = {
  data: PropTypes.array,
};

export default MvprDeeplinkThread;
