import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { noop } from 'lodash';

import Button from 'components/buttons/Button';
import useCurrentDocumentContext from 'routes/ContentManagement/hooks/useCurrentDocumentContext';

import ContentManagementDocumentResponseFormEditIdeaModal from './ContentManagementDocumentResponseFormEditIdeaModal';

const ContentManagementArticleIdeasList = ({
  ideas = [],
  setIdeas = noop,
  updateIdeas = noop,
}) => {
  const { createArticleFromArticleIdeaDocument: createArticle } =
    useCurrentDocumentContext();
  const [currentIdeaIndex, setCurrentIdeaIndex] = useState(null);
  const [isEditIdeaModalOpen, setIsEditIdeaModalOpen] = useState(false);
  const [currentIdea, setCurrentIdea] = useState(null);

  const handleSaveEditedIdea = (index, updatedIdea) => {
    const updatedIdeas = ideas.map((item, idx) =>
      idx === index ? updatedIdea : item
    );
    setIdeas(updatedIdeas);
    setIsEditIdeaModalOpen(false);
    updateIdeas({
      generatedIdeas: updatedIdeas,
    });
  };

  const handleDeleteIdea = (headlineToDelete) => {
    const updatedIdeas = ideas.filter(
      (idea) => idea.headline !== headlineToDelete
    );
    setIdeas(updatedIdeas);
    updateIdeas({
      generatedIdeas: updatedIdeas,
    });
  };

  return (
    <>
      {ideas?.map((idea, index) => (
        <div key={idea.headline} className="space-y-4 my-4">
          <label className="relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none">
            <input
              type="radio"
              name="server-size"
              value="Hobby"
              className="sr-only"
              aria-labelledby="server-size-0-label"
              aria-describedby="server-size-0-description-0 server-size-0-description-1"
            />
            <div className="flex flex-col">
              <span className="flex items-center text-sm">
                <span className="flex flex-col space-y-1">
                  <span
                    id="server-size-0-label"
                    className="font-medium text-gray-900 line-clamp-1"
                  >
                    {idea.headline}
                  </span>
                  <span
                    id="server-size-0-description-0"
                    className="text-gray-500"
                  >
                    <span className="line-clamp-2">{idea.description}</span>
                  </span>
                </span>
              </span>
              <div className="flex flex-col mt-2 sm:flex-row justify-between">
                <div className="flex gap-4">
                  <Button
                    type="secondary"
                    className="bg-gray-50 border-gray-300 border rounded-md text-sm px-2.5 py-1.5"
                    onClick={() => {
                      setCurrentIdeaIndex(index);
                      setCurrentIdea(idea);
                      setIsEditIdeaModalOpen(true);
                    }}
                  >
                    Edit article idea
                  </Button>
                  <Button
                    type="secondary"
                    onClick={() => handleDeleteIdea(idea.headline)}
                  >
                    Delete
                  </Button>
                </div>
                <Button
                  type="primary"
                  onClick={() => createArticle({ idea })}
                  className="mt-2 sm:mt-0"
                >
                  Create article from idea
                </Button>
              </div>
            </div>
            <span
              className="pointer-events-none absolute -inset-px rounded-lg border-2"
              aria-hidden="true"
            />
          </label>
        </div>
      ))}
      {isEditIdeaModalOpen && (
        <ContentManagementDocumentResponseFormEditIdeaModal
          open={isEditIdeaModalOpen}
          idea={currentIdea}
          onSubmit={(updatedIdea) =>
            handleSaveEditedIdea(currentIdeaIndex, updatedIdea)
          }
          onClose={() => {
            setIsEditIdeaModalOpen(false);
            setCurrentIdea(null);
            setCurrentIdeaIndex(null);
          }}
        />
      )}
    </>
  );
};

ContentManagementArticleIdeasList.propTypes = {
  ideas: PropTypes.array,
  setIdeas: PropTypes.func,
  updateIdeas: PropTypes.func,
};

export default ContentManagementArticleIdeasList;
