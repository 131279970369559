import { useParams } from 'react-router-dom';

import useCompanyThreads from 'hooks/useCompanyThreads';

import useThreadsSearchParams from './useThreadsSearchParams';

const useGetThreads = (companyId = '') => {
  const { threadId } = useParams();
  const [searchParams] = useThreadsSearchParams();
  const searchString = searchParams.toString();

  return useCompanyThreads(companyId, threadId, searchString);
};

export default useGetThreads;
