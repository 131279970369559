import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const MOD_URL = process.env.REACT_APP_MOD_URL;

const useCompanyEmailGrant = (companyId) => {
  const auth = useAuth();

  return useQuery(
    ['companies', companyId, 'grant'],
    async () => {
      const { currentUser } = auth;
      if (!currentUser) {
        throw new Error('User is not authenticated');
      }

      const jwt = await currentUser.getIdToken();
      const response = await fetch(`${MOD_URL}/companies/${companyId}/grant`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Failed fetching tier: ${errorMessage}`);
      }

      return response.json();
    },
    {
      enabled: !!companyId,
      retry: false,
    }
  );
};

export default useCompanyEmailGrant;
