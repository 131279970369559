import numberOfEmployees from './numberOfEmployees';

export const defaultUser = {
  prGoals: [],
  prGoalsOther: '',
  prKnowledge: '',
  firstName: '',
  lastName: '',
  email: '',
  photoUrl: null,
};

export const defaultCompany = {
  name: '',
  foundedDate: {
    month: 'January',
    year: 2021,
  },
  logo: null,
  location: '',
  numberOfEmployees: numberOfEmployees[0],
  website: '',
  topics: [],
  topicsOther: '',
  audiences: [],
  audiencesOther: '',
  vcs: [],
  commsContactEmail: '',
};
