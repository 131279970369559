import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import CopyToClipboardButton from './CopyToClipboardButton';

const CopyToClipboardSocialButton = ({
  Icon = null,
  copyText = '',
  moduleName = '',
  tooltipId = '',
  className = '',
}) => (
  <CopyToClipboardButton copyText={copyText} moduleName={moduleName}>
    <Icon
      data-tooltip-id={tooltipId}
      data-tooltip-content={copyText ? 'Click to copy' : `No ${moduleName}`}
      className={classNames(
        'w-4 h-4 focus:outline-none focus-visible:outline-none',
        {
          'text-gray-100': !copyText,
          'text-blue-300 hover:text-blue-500 cursor-pointer': copyText,
        },
        className
      )}
    />
  </CopyToClipboardButton>
);

CopyToClipboardSocialButton.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  copyText: PropTypes.string,
  moduleName: PropTypes.string.isRequired,
  tooltipId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CopyToClipboardSocialButton;
