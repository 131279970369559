import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { UserCircleIcon } from '@heroicons/react/24/outline';

const TimelineItem = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
  onEditItem,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

  return (
    <div
      onClick={() => onEditItem(item.id)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...getItemProps({
        ...item.itemProps,
        style: {
          position: 'relative',
          border: 'none',
          outline: 'none',
          background: 'transparent',
          cursor: 'pointer',
          width: isHovered ? '15rem' : '0.5rem',
          height: '2rem',
          borderRadius: '0.375rem',
        },
      })}
    >
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
      <div className="text-gray-900 text-md px-5 h-full flex flex-row items-center absolute left-1 overflow-hidden pl-1 whitespace-nowrap">
        <div
          className="w-2 h-8 rounded-md"
          style={{ backgroundColor: item.color }}
        />
        <div className="border rounded-full w-8 h-8 mr-1 ml-1 flex items-center justify-center">
          {item?.author?.image ? null : (
            <UserCircleIcon className="w-8 h-8 text-gray-300" />
          )}
        </div>
        <div className="flex flex-col justify-center">
          <div className="leading-4 font-bold max-w-[180px] truncate">
            {item.title}
          </div>
          <div className="leading-4">{item.dueDate}</div>
        </div>
      </div>
      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
    </div>
  );
};

TimelineItem.propTypes = {
  item: PropTypes.object.isRequired,
  itemContext: PropTypes.object.isRequired,
  getItemProps: PropTypes.func.isRequired,
  getResizeProps: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onEditItem: PropTypes.func,
};

export default TimelineItem;
