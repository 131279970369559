const createTemplateHtml = ({
  templates = [],
  newTemplate = '',
  signature = '',
}) => {
  const { text = '', isDefault = false } =
    templates.find((t) => t.name === newTemplate) || {};

  if (!isDefault) {
    return text;
  }

  return text + signature;
};

export default createTemplateHtml;
