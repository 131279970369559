import React, { useMemo } from 'react';

import debounce from 'lodash/debounce';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import Assistant from './Assistant';
import TextEditor from './TextEditor';
import useDocument from './hooks/useDocument';
import useUpdateDocument from './hooks/useUpdateDocument';

const ContentManagementDocumentContentV2 = () => {
  const { documentId } = useParams();
  const { companyData } = useCompanyData();

  const { data, isLoading } = useDocument(companyData.uid, documentId);
  const { mutateAsync: updateDocument } = useUpdateDocument(companyData.uid);
  const debouncedUpdateDocument = useMemo(
    () => debounce(updateDocument, 300),
    [updateDocument]
  );
  const queryClient = useQueryClient();

  const onEditorChange = ({ text, html }) => {
    const newDoc = {
      ...data,
      draft: {
        ...data.draft,
        content: html,
        plainText: text,
        lastEdited: new Date().toISOString(),
      },
    };
    queryClient.setQueryData(['documents', documentId], newDoc);
    debouncedUpdateDocument(newDoc);
  };

  return (
    <div className="-mx-6 -mt-8 -mb-6 flex flex-row">
      <div className="flex-grow">
        {!isLoading && (
          <TextEditor value={data?.draft?.content} onChange={onEditorChange} />
        )}
      </div>
      <Assistant reference={`documents/${documentId}`} />
    </div>
  );
};

export default ContentManagementDocumentContentV2;
