import React from 'react';

import InboxMessageResponseFormEditorAttachmentsFile from 'routes/Inbox2/InboxMessages/InboxMessage/InboxMessageResponseForm/InboxMessageResponseFormEditorAttachmentsFile';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

const InboxMessageResponseFormEditorAttachments = () => {
  const { draft, pendingAttachments } = useInboxContext();
  const { attachments = [] } = draft?.data || {};
  const allAttachments = attachments.concat(pendingAttachments);

  return (
    <ul className="mt-2 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
      {allAttachments.map((v) => (
        <InboxMessageResponseFormEditorAttachmentsFile
          key={v.path || v.fileName}
          file={v}
        />
      ))}
    </ul>
  );
};

export default InboxMessageResponseFormEditorAttachments;
