import React from 'react';

const InputFieldFoundersEmptyState = () => (
  <div className="block p-4 bg-gray-50 text-gray-500 rounded-lg">
    <p className="text-sm">You haven&apos;t added any team members yet</p>
    <p className="text-xs mt-1 text-gray-400">
      You can add a team member here once you have added at least one team
      member to your company.
    </p>
  </div>
);

InputFieldFoundersEmptyState.propTypes = {};

export default InputFieldFoundersEmptyState;
