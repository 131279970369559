/* eslint-disable max-params */
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from 'reactfire';

import useThreadsSearchParams from './useThreadsSearchParams';

const putSubject = async ({ threadId, subject, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/threads/${threadId}/subject`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ subject }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to update subject: ${error.message}`);
  }
};

const useSaveSubject = (companyId = '') => {
  const { threadId } = useParams();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const [searchParams] = useThreadsSearchParams();
  const threadsKey = ['companyThreads', companyId, searchParams.toString()];
  const { mutate } = useMutation({
    mutationFn: (subject) => putSubject({ threadId, subject, auth }),
    onMutate: async (subject) => {
      await queryClient.cancelQueries(threadsKey);
      const previousThreads = queryClient.getQueryData(threadsKey);

      queryClient.setQueryData(threadsKey, (old) => ({
        ...old,
        threads: old.threads.map((thread) => {
          if (thread.id === threadId) {
            return {
              ...thread,
              subject,
            };
          }
          return thread;
        }),
        redirectTo: null,
      }));

      return { previousThreads };
    },
    onError: (err, subject, context) => {
      queryClient.setQueryData(threadsKey, context.previousThreads);
    },
    onSettled: () => {
      queryClient.invalidateQueries(threadsKey);
    },
  });

  return mutate;
};

export default useSaveSubject;
