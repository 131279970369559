import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TrashIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

import CopyToClipboardButton from 'components/buttons/CopyToClipboardButton';
import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import Founder from 'components/company/Founder';
import trackIntercomEvent from 'modules/intercom/trackIntercomEvent';
import { useTracking } from 'modules/segment/TrackingProvider';

import CompanyInsightDeleteModal from '../CompanyInsightDeleteModal';
import CompanyInsightEditModal from '../CompanyInsightEditModal';

import CompanyInsightAnswer from './CompanyInsightAnswer';
import CompanyInsightPlaceholder from './CompanyInsightPlaceholder';
import CompanyInsightQuestion from './CompanyInsightQuestion';
import CompanyInsightTag from './CompanyInsightTag';
import CompanyInsightWrapper from './CompanyInsightWrapper';

const CompanyInsight = ({
  insight = {},
  showOwnerView = false,
  ops = {},
  founders = [],
  onView = noop,
  index = 0,
}) => {
  const trackingService = useTracking();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  if (!showOwnerView && !insight.answer) {
    return null;
  }

  const hasAnswer = !!insight.answer;

  const onSubmit = (data = {}) => {
    const { question, answer, tag, founder } = data;
    const doc = {
      question,
      answer,
      tag,
      founder: founder?.name ? { name: founder.name } : null,
      default: insight.default || null,
    };
    if (!insight.uid) {
      ops.createDoc(doc);
    } else {
      ops.updateDoc(insight.uid, doc);
    }
    trackIntercomEvent('pressPage-update');
    trackIntercomEvent('pressPage-update-insight');
    setIsEditModalOpen(false);
  };

  const onDelete = () => {
    ops.deleteDoc(insight.uid);
    setIsDeleteModalOpen(false);
  };

  const onClick = (e) => {
    e.preventDefault();
    trackingService.track('pressPage_view', {
      action: 'insight_view',
      insight_title: insight.question,
      insight_body: insight.answer,
    });

    onView(insight.uid);
  };

  return (
    <>
      <CompanyInsightWrapper
        insight={insight}
        hasAnswer={hasAnswer}
        showOwnerView={showOwnerView}
        onClick={onClick}
        testId={`company-insights-${index + 1}`}
      >
        <div>
          <CompanyInsightTag
            tag={insight.tag}
            testId={`company-insight-${insight.question}-tag`}
          />

          {hasAnswer && showOwnerView && (
            <div className="absolute top-4 right-4">
              <DotsMenu
                theme="medium"
                options={[
                  {
                    label: `${insight.answer ? 'Edit' : 'Add'} answer`,
                    onClick: () => setIsEditModalOpen(true),
                    testId: `company-insight-${insight.question}-menu-edit`,
                  },
                  {
                    label: `Delete insight`,
                    icon: <TrashIcon className="w-4 h-4" />,
                    onClick: () => setIsDeleteModalOpen(true),
                    testId: `company-insight-${insight.question}-menu-delete`,
                  },
                ]}
                testId={`company-insight-${insight.question}-menu`}
              />
            </div>
          )}

          <CompanyInsightQuestion
            question={insight.question}
            hasAnswer={hasAnswer}
            testId={`company-insight-${insight.question}-question`}
          />
        </div>

        {hasAnswer ? (
          <CompanyInsightAnswer
            answer={insight.answer}
            clamp
            testId={`company-insight-${insight.question}-answer`}
          />
        ) : (
          <CompanyInsightPlaceholder
            onClick={() => setIsEditModalOpen(true)}
            testId={`company-insight-${insight.question}`}
          />
        )}

        {hasAnswer && (
          <div className="w-full flex flex-row justify-between items-center mt-4">
            <Founder
              founder={founders.find((f) => f.name === insight?.founder?.name)}
              founders={founders}
              widthClassName="flex-shrink w-3/4"
              testId={`company-insight-${insight.question}-founder`}
            />

            <CopyToClipboardButton
              copyText={insight.answer}
              moduleName="insight"
              eventType="pressPage_view"
              eventProperties={{
                action: 'insight_view_copyToClipboard',
                title: insight.question,
                body: insight.answer,
              }}
            />
          </div>
        )}
      </CompanyInsightWrapper>

      {showOwnerView && isEditModalOpen ? (
        <CompanyInsightEditModal
          open={isEditModalOpen}
          insight={insight}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onSubmit}
          founders={founders}
        />
      ) : null}

      <CompanyInsightDeleteModal
        open={isDeleteModalOpen}
        title={insight.question}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={onDelete}
      />
    </>
  );
};

CompanyInsight.propTypes = {
  insight: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string,
  }),
  founders: PropTypes.array,
  showOwnerView: PropTypes.bool,
  ops: PropTypes.object,
  onView: PropTypes.func.isRequired,
  index: PropTypes.number,
};

export default CompanyInsight;
