import PropTypes from 'prop-types';
import React from 'react';

import { generatePath, Link } from 'react-router-dom';

import JournalistTeaser from 'components/JournalistTeaser';
import {
  funnelBackgroundColors,
  funnelNames,
  funnelTextColors,
} from 'constants/announcementFunnelStatus';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import formatTimestamp from 'utils/date/formatTimestamp';

const AnnouncementJournalistListItem = ({
  journalist,
  onCheckboxChange,
  isJournalistSelected,
}) => {
  const { companyData } = useCompanyData();

  if (!journalist) {
    return null;
  }

  return (
    <div
      key={journalist.id}
      className="flex gap-4 items-center justify-between p-2 hover:bg-gray-100 rounded-lg"
    >
      <div
        className={`flex-grow truncate ${
          journalist?.publication?.image && 'pb-2'
        }`}
      >
        <JournalistTeaser journalist={journalist} />
      </div>

      {journalist?.thread?.id ? (
        <Link
          style={{
            color: funnelTextColors[journalist.thread.status],
            backgroundColor: funnelBackgroundColors[journalist.thread.status],
          }}
          className="text-xs min-w-fit rounded-md px-2 py-1"
          data-tooltip-id="funnel-tooltip"
          data-tooltip-content={funnelNames[journalist.thread.status]}
          to={generatePath(MESSAGES_PATH_FULL, {
            id: companyData?.slug,
            threadId: journalist.thread.id,
          })}
        >
          {formatTimestamp(journalist?.thread.date)}
        </Link>
      ) : (
        <input
          type="checkbox"
          className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded cursor-pointer"
          checked={isJournalistSelected}
          onChange={(e) => {
            onCheckboxChange(journalist, e.target.checked);
          }}
        />
      )}
    </div>
  );
};

AnnouncementJournalistListItem.propTypes = {
  journalist: PropTypes.object.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  isJournalistSelected: PropTypes.bool.isRequired,
};

export default AnnouncementJournalistListItem;
