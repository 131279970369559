import { useMutation } from 'react-query';
import { useAuth } from 'reactfire';

const useDeleteCompanyEmailGrant = () => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const auth = useAuth();

  return useMutation({
    mutationFn: async (companyId) => {
      const jwt = await auth.currentUser?.getIdToken();
      const endpoint = `/companies/${companyId}/grant`;

      try {
        await fetch(`${BASE_URL}${endpoint}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
        });
      } catch (error) {
        throw new Error(`Failed to disconnect email: ${error.message}`);
      }
    },
  });
};

export default useDeleteCompanyEmailGrant;
