import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const Step = ({
  name = '',
  title = '',
  children = null,
  type = '',
  number = 0,
}) => (
  <li className="flex align-center items-center">
    <div
      className={classNames('text-sm font-medium', {
        'text-teal-500': type === 'current',
        'text-gray-300 cursor-not-allowed pointer-events-none':
          type === 'upcoming',
        'text-gray-500': type === 'completed',
      })}
      data-test-id={`onboarding-step-${name}`}
    >
      <span className="hidden md:block">{title}</span>
      <span className="block md:hidden">{number}</span>
    </div>
    {children}
  </li>
);

Step.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  number: PropTypes.number,
  type: PropTypes.oneOf(['current', 'upcoming', 'completed']).isRequired,
};

export default Step;
