import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const InboxMessageResponseFormValidateModalCriteriaScore = ({ score = 0 }) => (
  <p
    className={classNames('text-sm px-2 py-1 rounded-md', {
      'text-red-500 bg-red-100': score < 5,
      'text-yellow-500 bg-yellow-100': score > 4 && score < 8,
      'text-green-500 bg-green-100': score > 7,
    })}
  >
    {score}
  </p>
);

InboxMessageResponseFormValidateModalCriteriaScore.propTypes = {
  score: PropTypes.number,
};

export default InboxMessageResponseFormValidateModalCriteriaScore;
