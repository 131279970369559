import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Tooltip } from 'react-tooltip';

import JournalistsBulkActions from 'routes/SweetheartList/JournalistsBulkActions';
import SweetheartListJournalistListsListBulkConversationsModal from 'routes/SweetheartList/SweetheartListJournalistListsListBulkConversationsModal';

import AnnouncementJournalistList from './AnnouncementJournalistList';
import useGetAnnouncement from './hooks/useGetAnnouncement';
import useUpsertAnnouncement from './hooks/useUpsertAnnouncement';

const AnnouncementJournalistLists = ({ announcement = {} }) => {
  const [selectedJournalists, setSelectedJournalists] = useState([]);
  const [isBulkConversationsModalOpen, setIsBulkConversationsModalOpen] =
    useState(false);
  const { refetch: getAnnouncement } = useGetAnnouncement();
  const { mutateAsync: upsertAnnouncement } = useUpsertAnnouncement();

  const handleRemoveList = async (listId) => {
    const updatedList = (announcement.journalistLists || []).filter(
      (list) => list.id !== listId
    );

    await upsertAnnouncement({
      ...announcement,
      journalistLists: updatedList.map((list) => list.id),
    });
  };

  return (
    <div className="flex flex-col gap-4">
      {announcement?.journalistLists?.map((list) => (
        <AnnouncementJournalistList
          key={list.id}
          data={list}
          selectedJournalists={selectedJournalists}
          setSelectedJournalists={setSelectedJournalists}
          handleRemoveList={() => handleRemoveList(list.id)}
        />
      ))}
      {selectedJournalists.length ? (
        <JournalistsBulkActions
          selected={selectedJournalists}
          createDrafts={() => setIsBulkConversationsModalOpen(true)}
        />
      ) : null}

      {isBulkConversationsModalOpen && (
        <SweetheartListJournalistListsListBulkConversationsModal
          onCancel={() => {
            setIsBulkConversationsModalOpen(false);
            getAnnouncement();
          }}
          selected={selectedJournalists}
          draftContent={`${announcement?.sellIn || ''}${
            announcement.sellIn ? '\n' : ''
          }${announcement?.document?.draft?.content || ''}`}
        />
      )}

      <Tooltip id="funnel-tooltip" />
    </div>
  );
};

AnnouncementJournalistLists.propTypes = {
  announcement: PropTypes.object,
};

export default AnnouncementJournalistLists;
