import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';
import { Link } from 'react-router-dom';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import JournalistTeaser from 'components/JournalistTeaser';
import OpportunityTags from 'components/opportunities/opportunity/OpportunityTags';
import OpportunityTimeLeft from 'components/opportunities/opportunity/OpportunityTimeLeft';
import OpportunityType from 'components/opportunities/opportunity/OpportunityType';
import capitalizeFirstLetter from 'components/opportunities/opportunity/helpers/capitalizeFirstLetter';

import OpportunitiesListOpportunityActions from './OpportunitiesListOpportunityActions/OpportunitiesListOpportunityActions';

const OpportunitiesListOpportunity = ({
  thread,
  opportunity = {},
  onExpand = noop,
  onSelect = noop,
  isSelected = false,
}) => {
  const { tags, deadline, title, id, searchTerms, description, journalist } =
    opportunity;
  const expired = deadline && new Date(deadline) < new Date();

  return (
    <article
      className={classNames(
        'relative flex flex-col-reverse items-center lg:items-start lg:flex-row lg:justify-center group lg:mt-2',
        {
          'opacity-50': expired || thread,
        }
      )}
      data-test-id={`opportunity-${id}`}
      data-intercom-id="opportunity"
    >
      <OpportunitiesListOpportunityActions
        opportunity={opportunity}
        expired={expired}
        onSelect={() => onSelect(id)}
        isSelected={isSelected}
      />

      <Link
        className="flex flex-1 flex-col justify-between relative p-4 mb-4 rounded-md md:border border-white max-w-screen-md w-full shadow cursor-pointer hov border bg-white hover:border-teal-500 hover:shadow-md"
        onClick={() => onExpand()}
        data-intercom-id="opportunity-container"
      >
        <div className="flex flex-row justify-start mb-2 flex-wrap">
          {tags.length > 0 && <OpportunityType type={tags[0]} />}
          <DomainAuthorityPill
            className="mb-2 mr-2"
            domainAuthority={journalist?.publication?.domainAuthority}
          />
          {searchTerms.filter(Boolean).length > 0 && (
            <OpportunityTags searchTerms={searchTerms.filter(Boolean)} />
          )}
        </div>
        {journalist && (
          <div className="flex items-center space-x-4">
            <JournalistTeaser journalist={journalist} />
          </div>
        )}
        <div className="flex justify-between mt-2">
          <h3 className="text-2xl font-semibold">
            {capitalizeFirstLetter(title)}
          </h3>
        </div>

        <div className="mt-2 text-sm leading-relaxed max-w-full overflow-hidden break-words">
          <p className="line-clamp-3">{description}</p>
        </div>
      </Link>
      <div className="flex flex-col max-w-prose items-end lg:items-start w-full lg:w-40">
        <OpportunityTimeLeft deadline={deadline} expired={expired} />
      </div>
    </article>
  );
};

OpportunitiesListOpportunity.propTypes = {
  opportunity: PropTypes.object,
  onExpand: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  thread: PropTypes.object,
};

export default OpportunitiesListOpportunity;
