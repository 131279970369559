import React, { useCallback, useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import Callout from 'components/Callout/Callout';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';
import InboxMessageResponseForm from 'routes/Inbox2/InboxMessages/InboxMessage/InboxMessageResponseForm/InboxMessageResponseForm';

const ShareOpportunityResponseModalEditor = () => {
  const { companyData } = useCompanyData();
  const navigate = useNavigate();
  const { threadId } = useParams();

  const [slugAlreadyExists, setSlugAlreadyExists] = useState(false);
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);

  const { setError, clearErrors, control, watch } = useForm();

  const { fetchData: checkIsSlugAlreadyExists } = useAPIRequest({
    service: 'UCD',
    endpoint: '/company/validate-slug',
    method: 'POST',
    initialData: false,
  });

  const { fetchData: checkIsEmailAlreadyExists } = useAPIRequest({
    service: 'UCD',
    endpoint: '/company/validate-email',
    method: 'POST',
    initialData: false,
  });

  const debouncedCheckSlug = useRef(
    debounce(async (slug) => {
      if (slug) {
        const isSlugValid = await checkIsSlugAlreadyExists({ body: { slug } });
        setSlugAlreadyExists(isSlugValid);
      }
    }, 500)
  ).current;

  const debouncedCheckEmail = useRef(
    debounce(async (email) => {
      if (email) {
        const isEmailValid = await checkIsEmailAlreadyExists({
          body: { email },
        });
        setEmailAlreadyExists(isEmailValid);
      }
    }, 500)
  ).current;

  const watchedSlug = watch('slug');
  const watchedEmail = watch('email');

  useEffect(() => {
    if (watchedSlug) {
      debouncedCheckSlug(watchedSlug);
    }
  }, [debouncedCheckSlug, watchedSlug]);

  useEffect(() => {
    if (watchedEmail) {
      debouncedCheckEmail(watchedEmail);
    }
  }, [debouncedCheckEmail, watchedEmail]);

  useEffect(() => {
    if (slugAlreadyExists) {
      setError('slug', {
        type: 'manual',
        message: 'This company name is already taken',
      });
    } else {
      clearErrors('slug');
    }
    if (emailAlreadyExists) {
      setError('email', {
        type: 'manual',
        message: 'This email is already in use',
      });
    } else {
      clearErrors('email');
    }
  }, [clearErrors, emailAlreadyExists, setError, slugAlreadyExists]);

  const handleOnMessageSentSuccess = useCallback(() => {
    navigate(
      generatePath(MESSAGES_PATH_FULL, { id: companyData.slug, threadId })
    );
  }, [companyData.slug, navigate, threadId]);

  return (
    <>
      <div className="mt-6">
        <Callout wrapperClassName="!my-4 bg-green-100 text-green-800">
          Type your response and use our AI-powered quality check before sending
        </Callout>
      </div>

      <Controller
        name="shareOpportunityResponse"
        control={control}
        defaultValue={{
          draft: {
            content: '',
            plainText: '',
          },
        }}
        render={() => (
          <div>
            {companyData.uid ? (
              <InboxMessageResponseForm
                onSuccess={handleOnMessageSentSuccess}
              />
            ) : null}
          </div>
        )}
      />
    </>
  );
};

ShareOpportunityResponseModalEditor.propTypes = {};

export default ShareOpportunityResponseModalEditor;
