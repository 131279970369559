import React from 'react';

import SwiperCore, { Navigation } from 'swiper';

import 'swiper/swiper-bundle.min.css';

import InboxNavbarThreadStatus from './InboxNavbarThreadStatus';
import InboxNavbarThreadTypes from './InboxNavbarThreadTypes';

SwiperCore.use([Navigation]);

const InboxNavbar = () => (
  <div className="sticky top-0 z-20">
    <div className="w-full p-4 items-center flex-wrap lg:flex-nowrap flex gap-2 xl:gap-3 bg-white shadow-sm border-b border-gray-200">
      <InboxNavbarThreadTypes />
      <InboxNavbarThreadStatus />
    </div>
  </div>
);

export default InboxNavbar;
