// eslint-disable jsx-a11y/no-noninteractive-element-interactions

import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';
import ReactTags from 'react-tag-autocomplete';
import { v4 as uuidv4 } from 'uuid';

import InputFieldTagsAutocompleteInstructions from './InputFieldTagsAutocompleteInstructions';
import InputFieldTagsAutocompleteSuggestion from './InputFieldTagsAutocompleteSuggestion';
import InputFieldTagsAutocompleteTag from './InputFieldTagsAutocompleteTag';
import reactTagsClassnames from './config/reactTagsClassnames';

// Renders a autocomplete field with tags and a suggestions flyout
const InputFieldTagsAutocomplete = ({
  tags = [],
  customFieldName = '',
  showAvatar = false,
  onDelete = noop,
  onAddition = noop,
  onInput = noop,
  suggestionComponent = undefined,
  suggestions = [],
  placeholder = '',
  allowNew = true,
  showInstructions = true,
  wrapperClassName = '2xl:max-w-[50vw]',
  testId = '',
  maxSuggestionsLength = 6,
}) => (
  <section className={classNames('flex-1', wrapperClassName)}>
    <div className="relative flex items-start">
      <label
        className={classNames(
          'min-h-[38px] flex flex-wrap p-1 items-center gap-1 focus-within:border-teal-500 relative px-1 bg-white border border-gray-300 min-w-0 cursor-text rounded-md w-full',
          {
            'pl-3': tags.length === 0,
          }
        )}
        htmlFor={customFieldName}
        data-test-id={testId}
      >
        {tags.map((tag) => (
          <InputFieldTagsAutocompleteTag
            key={tag.id}
            tag={tag}
            showAvatar={showAvatar}
            onDelete={() => onDelete(tag)}
            tags={tags}
          />
        ))}
        <ReactTags
          autoresize
          minQueryLength={1}
          maxSuggestionsLength={maxSuggestionsLength}
          inputAttributes={{ id: customFieldName }}
          classNames={reactTagsClassnames}
          tags={[]}
          suggestionsTransform={() => suggestions}
          suggestions={suggestions.map((suggestion) => ({
            ...suggestion,
            showAvatar,
          }))}
          onInput={onInput}
          onDelete={() => onDelete(tags[tags.length - 1])}
          onAddition={(tag) =>
            tag.id
              ? onAddition(tag)
              : onAddition({
                  ...tag,
                  id: tag.id || `custom-${customFieldName}-${uuidv4()}`,
                })
          }
          suggestionComponent={
            suggestionComponent || InputFieldTagsAutocompleteSuggestion
          }
          allowNew={allowNew}
          placeholderText={placeholder}
        />
      </label>
    </div>
    {showInstructions ? (
      <div className="mt-2 w-full">
        <InputFieldTagsAutocompleteInstructions />
      </div>
    ) : null}
  </section>
);

InputFieldTagsAutocomplete.propTypes = {
  onInput: PropTypes.func,
  customFieldName: PropTypes.string,
  showAvatar: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  onDelete: PropTypes.func,
  onAddition: PropTypes.func,
  suggestionComponent: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  allowNew: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  testId: PropTypes.string,
  showInstructions: PropTypes.bool,
  maxSuggestionsLength: PropTypes.number,
};

export default InputFieldTagsAutocomplete;
