import { useLayoutEffect, useRef } from 'react';

import noop from 'lodash/noop';

const getScrollParent = (element, includeHidden) => {
  let style = getComputedStyle(element);
  const excludeStaticParent = style.position === 'absolute';
  const overflowRegex = includeHidden
    ? /(auto|scroll|hidden)/
    : /(auto|scroll)/;

  if (style.position === 'fixed') return document.body;
  // eslint-disable-next-line no-cond-assign
  for (let parent = element; (parent = parent.parentElement); ) {
    style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === 'static') {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX))
      return parent;
  }

  return document.body;
};

const options = {
  rootMargin: '-123px 0px -77px 0px', // 123px is the height of the 2 navbars, 77px is the height of the message header
  threshold: 1, // 1 means the entire element must be in the viewport
};

const useScrollIntoView = (getElement = noop, windowScroll = false) => {
  const hasScrolled = useRef(false);

  useLayoutEffect(() => {
    const element = getElement();
    let timeoutId;

    hasScrolled.current = false;

    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting && !hasScrolled.current) {
        timeoutId = setTimeout(() => {
          if (windowScroll) {
            window.scrollTo({
              // center the element in the viewport
              top: entry.target.offsetTop,
              behavior: 'smooth',
            });
          } else {
            const scrollParent = getScrollParent(entry.target, true);
            scrollParent.scrollTo({
              top: entry.target.offsetTop - scrollParent.offsetTop,
              behavior: 'smooth',
            });
          }
        }, 300);
      }
      hasScrolled.current = true;
    }, options);

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
        clearTimeout(timeoutId);
        hasScrolled.current = false;
      }
    };
  }, [getElement, windowScroll]);
};

export default useScrollIntoView;
