import React, { useState } from 'react';

import classNames from 'classnames';

import { useTracking } from 'modules/segment/TrackingProvider';

import OpportunitiesMetaHowTo from './OpportunitiesMetaHowTo';
import OpportunitiesMetaHowToModal from './OpportunitiesMetaHowToModal';
import OpportunitiesMetaToggle from './OpportunitiesMetaToggle';

const smallScreenClasses =
  'flex justify-center sticky order-last bottom-0 p-4 bg-white w-[calc(100%+3rem)] -mb-6 -ml-6 z-[8] gap-12';
const largeScreenClasses =
  'lg:bg-transparent lg:w-full lg:pointer-events-none lg:p-0 lg:order-none lg:ml-0 lg:justify-between lg:-mb-[56px] lg:top-[24px] lg:mt-6 lg:-ml-2';

const OpportunitiesMeta = () => {
  const [showHowToModal, setShowHowToModal] = useState(false);
  const trackingService = useTracking();

  return (
    <>
      <div className={classNames(smallScreenClasses, largeScreenClasses)}>
        <OpportunitiesMetaHowTo
          onClick={() => {
            setShowHowToModal(true);
            trackingService.track('Opportunities Hint Opened');
          }}
        />
        <OpportunitiesMetaToggle />
      </div>
      <OpportunitiesMetaHowToModal
        open={showHowToModal}
        onClose={() => setShowHowToModal(false)}
      />
    </>
  );
};

export default OpportunitiesMeta;
