/* eslint-disable max-params */

import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from 'reactfire';

import useThreadsSearchParams from './useThreadsSearchParams';

const markMessageAsRead = async ({ messageId, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/messages/${messageId}/read`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to read message: ${error.message}`);
  }
};

const useReadMessage = (companyId) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { threadId } = useParams();
  const [searchParams] = useThreadsSearchParams();
  const allThreadsKey = ['companyThreads', companyId, ''];
  const threadsKey = ['companyThreads', companyId, searchParams.toString()];
  const threadDetailsKey = ['threadDetails', threadId];

  const { mutate } = useMutation({
    mutationFn: (messageId) => markMessageAsRead({ messageId, auth }),
    onMutate: async (messageId) => {
      const previousThreads = queryClient.getQueryData(threadsKey);
      const previousThreadDetails = queryClient.getQueryData(threadDetailsKey);
      const previousAllThreads = queryClient.getQueryData(allThreadsKey);

      const newThreadDetails = queryClient.setQueryData(
        threadDetailsKey,
        (old) => ({
          ...old,
          messages: old.messages.map((message) => {
            if (message.id === messageId) {
              return {
                ...message,
                isReadByUser: true,
              };
            }
            return message;
          }),
        })
      );
      const lastMessage =
        newThreadDetails.messages[newThreadDetails.messages.length - 1];

      queryClient.setQueryData(threadsKey, (old) => ({
        ...old,
        threads: old.threads.map((thread) => {
          if (thread.id === previousThreadDetails.id) {
            return {
              ...thread,
              hasUnreadMessages: lastMessage
                ? !lastMessage.isReadByUser
                : false,
            };
          }
          return thread;
        }),
      }));

      return { previousThreads, previousThreadDetails, previousAllThreads };
    },
    onError: (err, subject, context) => {
      queryClient.setQueryData(threadsKey, context.previousThreads);
      queryClient.setQueryData(threadDetailsKey, context.previousThreadDetails);
      queryClient.setQueryData(allThreadsKey, context.previousAllThreads);
    },
    onSettled: () => {
      queryClient.invalidateQueries(threadDetailsKey);
      queryClient.invalidateQueries(allThreadsKey);
    },
  });

  return mutate;
};

export default useReadMessage;
