import PropTypes from 'prop-types';
import React from 'react';

import HomeLogo from '../../../HomeLogo';
import ImpersonateDropdown from '../ImpersonateDropdown';
import NavbarAccount from '../components/NavbarAccount';
import NavbarMessages from '../components/NavbarInbox/NavbarMessages';
import NavbarLinks from '../components/NavbarLinks/NavbarLinks';

const NavbarDesktop = ({
  homeRoute = '',
  companySlug = '',
  claimsAdmin = false,
  claimsAgencyId = false,
}) => (
  <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
    <div className="flex grow flex-col gap-y-2 overflow-y-auto border-r border-gray-200 bg-white px-4 pb-4">
      <div className="flex h-16 shrink-0 items-center">
        <HomeLogo
          homeRoute={homeRoute}
          testId="homeLogo"
          className="ml-2 sm:ml-0 text-teal-500"
          white
        />
      </div>
      {(claimsAdmin || claimsAgencyId) && <ImpersonateDropdown />}
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <NavbarLinks slug={companySlug} />
          </li>
          <li className="-mx-2 space-y-2">
            <NavbarMessages />
          </li>
          <li className="mt-auto pb-11">
            <NavbarAccount />
          </li>
        </ul>
      </nav>
    </div>
  </div>
);

NavbarDesktop.propTypes = {
  homeRoute: PropTypes.string,
  companySlug: PropTypes.string,
  claimsAdmin: PropTypes.bool,
  claimsAgencyId: PropTypes.bool,
};

export default NavbarDesktop;
