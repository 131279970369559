import { SHARE_OPPORTUNITY as path } from 'constants/paths';

import ShareOpportunityPage from './ShareOpportunityPage';

const ShareOpportunityRoute = {
  path,
  component: ShareOpportunityPage,
};

export default ShareOpportunityRoute;
