import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { get, noop } from 'lodash';

import JournalistDetailsModal from 'components/JournalistDetailsModal/JournalistDetailsModal';
import { useTracking } from 'modules/segment/TrackingProvider';

import SweetheartListJournalist from './SweetheartListJournalist/SweetheartListJournalist';

const getNormalizedValue = (obj, key) => {
  const value = get(obj, key);
  return typeof value === 'string' ? value.toLowerCase() : value;
};

const JournalistsTable = ({
  journalists = [],
  selected = [],
  setSelected = noop,
  isLoading = false,
  hideColumns = [],
}) => {
  const trackingService = useTracking();
  const [journalistToShow, setJournalistToShow] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedBy, setSortedBy] = useState('');

  const sortedJournalists = useMemo(
    () =>
      [...journalists].sort((a, b) => {
        const valueA = getNormalizedValue(a, sortedBy);
        const valueB = getNormalizedValue(b, sortedBy);

        if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
        if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
        return 0;
      }),
    [journalists, sortedBy, sortOrder]
  );

  const handleSort = (column) => {
    if (sortedBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedBy(column);
      setSortOrder('asc');
    }
  };

  return (
    <div
      className={classNames(
        'max-h-full h-auto shadow overflow-auto border-b border-gray-200 sm:rounded-lg',
        {
          'animate-pulse': isLoading,
        }
      )}
    >
      <table className="min-w-full divide-y divide-gray-200 text-gray-500">
        <thead className="z-20 sticky top-0 bg-gray-50 text-xs font-medium uppercase">
          <tr className="text-left tracking-wider">
            <th scope="col" className="sticky left-0 bg-gray-50 px-4 py-2 ">
              <input
                checked={selected.length === journalists.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelected(journalists);
                  } else {
                    setSelected([]);
                  }
                }}
                type="checkbox"
                className="h-4 w-4 mr-2 text-teal-600 focus:ring-teal-500 border-gray-300 rounded cursor-pointer"
              />
            </th>
            <th
              scope="col"
              className={`px-3 py-3.5 text-left font-medium text-gray-500 cursor-pointer ${
                sortedBy === 'name' ? 'bg-gray-100' : ''
              } `}
              role="button"
              onClick={() => handleSort('name')}
            >
              <div className="group inline-flex items-center">
                Journalist
                <span className="ml-2 flex-none rounded bg-gray-100 group-hover:bg-gray-200 h-fit">
                  {sortedBy === 'name' && sortOrder === 'asc' ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </div>
            </th>
            <th
              scope="col"
              className={`px-3 py-3.5 text-left font-medium text-gray-500 cursor-pointer ${
                sortedBy === 'jobTitle' ? 'bg-gray-100' : ''
              } `}
              role="button"
              onClick={() => handleSort('jobTitle')}
            >
              <div className="group inline-flex items-center">
                Job Title
                <span className="ml-2 flex-none rounded bg-gray-100 group-hover:bg-gray-200 h-fit">
                  {sortedBy === 'jobTitle' && sortOrder === 'asc' ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </div>
            </th>

            <th
              scope="col"
              className={`px-3 py-3.5 text-left font-medium text-gray-500 cursor-pointer ${
                sortedBy === 'publication.name' ? 'bg-gray-100' : ''
              } `}
              role="button"
              onClick={() => handleSort('publication.name')}
            >
              <div className="group inline-flex items-center">
                Publication
                <span className="ml-2 flex-none rounded bg-gray-100 group-hover:bg-gray-200 h-fit">
                  {sortedBy === 'publication.name' && sortOrder === 'asc' ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </div>
            </th>

            <th
              scope="col"
              className={`px-3 py-3.5 font-medium text-gray-500 cursor-pointer text-center ${
                sortedBy === 'publication.tier' ? 'bg-gray-100' : ''
              } `}
              role="button"
              onClick={() => handleSort('publication.tier')}
            >
              <div className="group inline-flex items-center">
                Tier
                <span className="ml-2 flex-none rounded bg-gray-100 group-hover:bg-gray-200 h-fit">
                  {sortedBy === 'publication.tier' && sortOrder === 'asc' ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </div>
            </th>
            <th
              scope="col"
              className={`px-3 py-3.5 font-medium text-gray-500 cursor-pointer text-center ${
                sortedBy === 'publication.domainAuthority' ? 'bg-gray-100' : ''
              } `}
              role="button"
              onClick={() => handleSort('publication.domainAuthority')}
            >
              <div className="group inline-flex items-center">
                Domain Authority
                <span className="ml-2 flex-none rounded bg-gray-100 group-hover:bg-gray-200 h-fit">
                  {sortedBy === 'publication.domainAuthority' &&
                  sortOrder === 'asc' ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </div>
            </th>

            {!hideColumns.includes('activity') ? (
              <th
                scope="col"
                className="px-6 py-3 font-medium text-center text-gray-500"
              >
                Activity
              </th>
            ) : null}

            {!hideColumns.includes('reachout') ? (
              <th
                scope="col"
                className="px-4 py-3 font-medium text-center text-gray-500 min-w-[210px]"
              >
                Reach out
              </th>
            ) : null}

            {!hideColumns.includes('delete') ? (
              <th
                scope="col"
                className="px-4 py-3 font-medium text-center text-gray-500"
              >
                Delete
              </th>
            ) : null}

            {!hideColumns.includes('add') ? (
              <th
                scope="col"
                className="px-4 py-3 font-medium text-center text-gray-500"
              >
                Add
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody className="text-sm text-gray-900">
          {sortedJournalists.map((journalist) => (
            <SweetheartListJournalist
              key={journalist.id}
              journalist={journalist}
              hideColumns={hideColumns}
              isSelected={selected.find((c) => c.id === journalist.id)}
              handleSelected={() => {
                if (selected.find((c) => c.id === journalist.id)) {
                  setSelected(selected.filter((c) => c.id !== journalist.id));
                } else {
                  setSelected([...selected, journalist]);
                }
              }}
              onClick={() => {
                setJournalistToShow(journalist);
                trackingService.track('Journalist Details Opened', {
                  journalistId: journalist.id,
                });
              }}
            />
          ))}
        </tbody>
      </table>
      {journalistToShow ? (
        <JournalistDetailsModal
          journalist={journalistToShow}
          onClose={() => setJournalistToShow(null)}
        />
      ) : null}
    </div>
  );
};

JournalistsTable.propTypes = {
  journalists: PropTypes.array,
  isLoading: PropTypes.bool,
  hideColumns: PropTypes.array,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

export default JournalistsTable;
