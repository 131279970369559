import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from 'reactfire';

const fetchJournalistList = async ({ uid, listId, auth }) => {
  const BASE_URL = process.env.REACT_APP_JPD_URL;
  const endpoint = `/companies/${uid}/journalistLists/${listId}`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch journalist list: ${error.message}`);
  }
};

const upsertJournalistList = async ({ uid, journalistList, auth }) => {
  const BASE_URL = process.env.REACT_APP_JPD_URL;
  const endpoint = `/companies/${uid}/journalistLists`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(journalistList),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to upsert journalist list: ${error.message}`);
  }
};

const deleteJournalistList = async ({ uid, journalistListId, auth }) => {
  const BASE_URL = process.env.REACT_APP_JPD_URL;
  const endpoint = `/companies/${uid}/journalistLists/${journalistListId}`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to delete journalist list: ${error.message}`);
  }
};
const useJournalistList = (uid) => {
  const { listId } = useParams();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { data, error, isFetching, refetch } = useQuery(
    ['journalistLists', listId],
    () => fetchJournalistList({ uid, listId, auth }),
    {
      enabled: !!listId && !!uid,
      initialData: { journalists: [] },
    }
  );

  const upsertMutation = useMutation(
    (journalistList) => upsertJournalistList({ uid, journalistList, auth }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('journalistLists');
      },
    }
  );

  const deleteMutation = useMutation((journalistListId) =>
    deleteJournalistList({ uid, journalistListId, auth })
  );

  return {
    getJournalistList: refetch,
    journalistList: data,
    isLoadingJournalistList: isFetching,
    journalistListError: error,
    upsertJournalistList: upsertMutation.mutateAsync,
    isUpsertingJournalistList: upsertMutation.isLoading,
    deleteJournalistList: deleteMutation.mutateAsync,
    isDeletingJournalistList: deleteMutation.isLoading,
  };
};

export default useJournalistList;
