import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import noop from 'lodash/noop';
import { useNavigate, useParams, generatePath } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import { contentManagementTypes } from 'constants/contentManagementTypes';
import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useImpersonation from 'contexts/ImpersonationContext/hooks/useImpersonation';
import useAPIRequest from 'hooks/useAPIRequest';
import { useTracking } from 'modules/segment/TrackingProvider';

import useDocumentCRUD from './useDocumentCRUD';

const defaultContext = {
  currentDocument: null,
  documents: [],
  areDocumentsLoading: false,
  fetchDocumentsError: {},
  isCreateDocumentLoading: false,
  getDocuments: noop,
  updateDocument: noop,
  createDocument: noop,
};

export const CurrentDocumentContext = createContext(defaultContext);

const { Provider } = CurrentDocumentContext;

const CurrentDocumentProvider = ({ children }) => {
  const { documentId: selectedDocumentId } = useParams();
  const { companyData } = useCompanyData();

  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState(null);
  const { impersonatedCompanySlug } = useImpersonation();

  const trackingService = useTracking();
  const navigate = useNavigate();

  const { showError, showSuccess } = useNotifications();
  const [isCreatingDocument, setIsCreatingDocument] = useState(false);

  const {
    getDocumentApi,
    isGetDocumentsApiError,

    getDocumentsApi,
    isGetDocumentsApiLoading,

    updateDocumentApi,

    createDocumentApi,

    updateDocumentThreadDataApi,

    cloneDocumentApi,

    createArticleFromArticleIdeaApi,
  } = useDocumentCRUD();

  const currentDocumentIsValid = useMemo(
    () => documents.some((doc) => doc.id === selectedDocumentId),
    [documents, selectedDocumentId]
  );

  const { fetchData: fetchAudiences } = useAPIRequest({
    endpoint: `/company/${companyData.uid}/audiences`,
    service: 'UCD',
    autoFetch: false,
    initialData: [],
  });

  const getBodyByDocumentType = useCallback(
    async (type) => {
      let defaultAudiences = [];
      switch (type) {
        case contentManagementTypes.ARTICLEIDEA:
        case contentManagementTypes.ARTICLE:
          try {
            const audiencesResponse = await fetchAudiences();
            defaultAudiences =
              audiencesResponse?.map((item) => item?.name).filter(Boolean) ??
              [];
          } catch (error) {
            // do nothing
          }

          return {
            type: type ?? contentManagementTypes.BLANK,
            audiences: defaultAudiences,
          };
        default:
          return { type: type ?? contentManagementTypes.BLANK };
      }
    },
    [fetchAudiences]
  );
  useEffect(() => {
    if (selectedDocumentId && companyData.uid) {
      const prepare = async () => {
        try {
          const response = await getDocumentApi({
            docId: selectedDocumentId,
            companyUid: companyData.uid,
          });

          setDocument(response);
        } catch (error) {
          showError({
            title: "We're sorry! Something went wrong.",
            message: `The document ${selectedDocumentId} couldn't be loaded.`,
          });

          navigate(
            generatePath(CONTENT_MANAGEMENT_PATH, {
              id: companyData.slug ?? impersonatedCompanySlug,
              tab: 'documents',
            })
          );
        }
      };

      prepare();
    } else {
      setDocument(null);
    }
  }, [
    companyData.slug,
    companyData.uid,
    getDocumentApi,
    impersonatedCompanySlug,
    navigate,
    selectedDocumentId,
    showError,
  ]);

  const getDocuments = useCallback(async () => {
    if (!selectedDocumentId) {
      setDocument(null);
    }

    const response = await getDocumentsApi({ companyUid: companyData.uid });
    setDocuments(response);
  }, [selectedDocumentId, getDocumentsApi, companyData.uid]);

  const updateDocument = useCallback(
    async (newDoc) => {
      const body = {
        ...(document ? { ...document } : {}),
        ...newDoc,
      };

      const response = await updateDocumentApi({
        companyUid: companyData.uid,
        body,
      });

      setDocument(response);
    },
    [companyData.uid, document, updateDocumentApi]
  );

  const updateDocumentThreadData = useCallback(
    async ({ threadId, documentId, resetQuestions }) => {
      const body = {
        threadId,
        documentId,
        resetQuestions: resetQuestions ?? false,
      };

      const response = await updateDocumentThreadDataApi({
        companyUid: companyData.uid,
        body,
      });

      setDocument(response);
    },
    [companyData.uid, updateDocumentThreadDataApi]
  );

  const createDocument = useCallback(
    async (type = undefined) => {
      try {
        setIsCreatingDocument(true);
        const body = await getBodyByDocumentType(type);

        const response = await createDocumentApi({
          companyUid: companyData.uid,
          body,
        });

        trackingService.track('Document Created', {
          documentId: response.id,
          type: response.type || 'blank',
          companyId: companyData.uid,
        });
        showSuccess({ message: 'Document created successfully.' });
        setDocument(response);

        navigate(
          generatePath(CONTENT_MANAGEMENT_PATH, {
            id: companyData.slug ?? impersonatedCompanySlug,
            tab: 'documents',
            documentId: response.id,
          })
        );
      } catch (error) {
        showError({
          title: "We're sorry! Something went wrong.",
          message: "The document couldn't be created.",
        });
      } finally {
        setIsCreatingDocument(false);
      }
    },
    [
      getBodyByDocumentType,
      createDocumentApi,
      companyData.uid,
      companyData.slug,
      trackingService,
      showSuccess,
      navigate,
      impersonatedCompanySlug,
      showError,
    ]
  );

  const cloneDocument = useCallback(async () => {
    try {
      setIsCreatingDocument(true);

      const response = await cloneDocumentApi({
        companyUid: companyData.uid,
        docId: selectedDocumentId,
      });

      trackingService.track('Document Created', {
        documentId: response.id,
        type: response.type || 'blank',
        companyId: companyData.uid,
      });
      showSuccess({ message: 'Document cloned successfully.' });
      setDocument(response);

      navigate(
        generatePath(CONTENT_MANAGEMENT_PATH, {
          id: companyData.slug ?? impersonatedCompanySlug,
          tab: 'documents',
          documentId: response.id,
        })
      );
    } catch (error) {
      showError({
        title: "We're sorry! Something went wrong.",
        message: "The document couldn't be created.",
      });
    } finally {
      setIsCreatingDocument(false);
    }
  }, [
    cloneDocumentApi,
    companyData.uid,
    companyData.slug,
    selectedDocumentId,
    trackingService,
    showSuccess,
    navigate,
    impersonatedCompanySlug,
    showError,
  ]);
  const createArticleFromArticleIdeaDocument = useCallback(
    async (body = {}) => {
      try {
        setIsCreatingDocument(true);

        const response = await createArticleFromArticleIdeaApi({
          companyUid: companyData.uid,
          docId: selectedDocumentId,
          body,
        });

        trackingService.track('Document Created', {
          documentId: response.id,
          type: response.type || 'blank',
          companyId: companyData.uid,
        });
        showSuccess({ message: 'Document created successfully.' });
        setDocument(response);

        navigate(
          generatePath(CONTENT_MANAGEMENT_PATH, {
            id: companyData.slug ?? impersonatedCompanySlug,
            tab: 'documents',
            documentId: response.id,
          })
        );
      } catch (error) {
        showError({
          title: "We're sorry! Something went wrong.",
          message: "The document couldn't be created.",
        });
      } finally {
        setIsCreatingDocument(false);
      }
    },
    [
      createArticleFromArticleIdeaApi,
      companyData.uid,
      companyData.slug,
      selectedDocumentId,
      trackingService,
      showSuccess,
      navigate,
      impersonatedCompanySlug,
      showError,
    ]
  );

  const providerValue = useMemo(
    () => ({
      currentDocument: document,
      documents,
      areDocumentsLoading: isGetDocumentsApiLoading,
      fetchDocumentsError: isGetDocumentsApiError,
      isCreateDocumentLoading: isCreatingDocument,
      currentDocumentIsValid,

      getDocuments,
      updateDocument,
      createDocument,
      updateDocumentThreadData,
      cloneDocument,
      createArticleFromArticleIdeaDocument,
    }),
    [
      createDocument,
      currentDocumentIsValid,
      document,
      documents,
      getDocuments,
      isCreatingDocument,
      isGetDocumentsApiError,
      isGetDocumentsApiLoading,
      updateDocument,
      updateDocumentThreadData,
      cloneDocument,
      createArticleFromArticleIdeaDocument,
    ]
  );

  return <Provider value={providerValue}>{children}</Provider>;
};

CurrentDocumentProvider.propTypes = {
  children: PropTypes.node,
};

export default CurrentDocumentProvider;
