import { useEffect } from 'react';

import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from 'reactfire';

import defaultValues from '../defaultValues';

const getDraft = async (threadId, auth) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/threads/${threadId}/draft`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return { threadId, ...responseData };
  } catch (error) {
    throw new Error(`Failed to fetch thread draft: ${error.message}`);
  }
};

const useGetDraft = () => {
  const { threadId } = useParams();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { data, error, refetch, isFetching } = useQuery({
    queryKey: ['draft', threadId],
    queryFn: () => getDraft(threadId, auth),
    initialData: defaultValues.draft.data,
    refetchOnWindowFocus: false,
    enabled: !!threadId,
  });

  useEffect(() => {
    if (threadId) {
      refetch();
    }
  }, [refetch, threadId]);

  return {
    error,
    data,
    setData: (newData) => {
      if (typeof newData === 'function') {
        queryClient.setQueryData(['draft', threadId], (oldData) =>
          newData(oldData)
        );
        return;
      }
      queryClient.setQueryData(['draft', threadId], newData);
    },
    loading: isFetching,
    revalidateDraft: refetch,
  };
};

export default useGetDraft;
