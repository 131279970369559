import PropTypes from 'prop-types';
import React from 'react';

import { Bar } from 'react-chartjs-2';

import capitalizeKeys from './utils/capitalizeKeys';
import { title, tooltip } from './utils/options';

const getLabel = (context) => context.parsed.x || 0;
const options = {
  maintainAspectRatio: false,
  plugins: {
    title,
    tooltip: tooltip(getLabel),
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        autoSkip: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
      },
    },
  },
};
const defaultPublishingDays = {
  Monday: 0,
  Tuesday: 0,
  Wednesday: 0,
  Thursday: 0,
  Friday: 0,
};

const JournalistDetailsModalPublishingDays = ({ publishingDays = {} }) => {
  const filteredKeywords = {
    ...defaultPublishingDays,
    ...Object.fromEntries(
      Object.entries(publishingDays).filter(
        ([key, value]) => key.toLowerCase() !== 'other' && value > 0
      )
    ),
  };

  const capitalizedKeywords = capitalizeKeys(filteredKeywords);
  return (
    <Bar
      width="100%"
      className="text-teal-500"
      options={options}
      data={{
        labels: Object.keys(capitalizedKeywords),
        datasets: [
          {
            data: Object.values(capitalizedKeywords),
            backgroundColor: '#14b8a6',
          },
        ],
      }}
    />
  );
};

JournalistDetailsModalPublishingDays.propTypes = {
  publishingDays: PropTypes.object,
};

export default JournalistDetailsModalPublishingDays;
