import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'reactfire';

const createCoverageInvoice = async ({
  data: { companyId, ...data },
  auth,
}) => {
  const BASE_URL = process.env.REACT_APP_UCD_URL;
  const endpoint = `/v2/companies/${companyId}/coverageInvoices/create`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (responseData.errors || responseData.errors?.length) {
      return responseData;
    }

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to create coverages invoice: ${error.message}`);
  }
};

const useCreateCoveragesInvoice = () => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: (data) => createCoverageInvoice({ data, auth }),
    onSettled: () => {
      queryClient.invalidateQueries(['coverages']);
    },
  });

  return mutateAsync;
};

export default useCreateCoveragesInvoice;
