import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import InputFieldTagsListTag from './InputFieldTagsListTag';

// Maps over an array of tags and displays a tag for each
const InputFieldTagsList = ({
  tags = [],
  value = {},
  onChange = noop,
  testId = '',
}) =>
  tags.map((tag, index) => (
    <InputFieldTagsListTag
      key={tag.id}
      index={index}
      tag={tag}
      isSelected={tag.id === value.id}
      onChange={onChange}
      testId={testId}
    />
  ));

InputFieldTagsList.propTypes = {
  tags: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }),
  testId: PropTypes.string,
};

export default InputFieldTagsList;
