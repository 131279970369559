import PropTypes from 'prop-types';
import React, { useState } from 'react';

import classNames from 'classnames';
import { getDownloadURL, ref } from 'firebase/storage';
import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';
import { useStorage } from 'reactfire';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { REGEX_URL } from 'constants/regex';
import useDateTimeSelector from 'hooks/useDateTimeSelector';
import useGetPrefillData from 'hooks/useGetPrefillData';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';
import { todaysDateObj } from 'utils/date/dateRange';
import getDateObjFromTimestamp, {
  getDateObjFromTimestampCurrentUserTimezone,
} from 'utils/date/getDateObjFromTimestamp';

import Spinner from './Spinner';

const UPLOAD_FOLDER = 'coverageImages';
const tierOptions = [
  { label: 'National Media - Coverage', value: 'NATIONAL_COVERAGE' },
  { label: 'Tier 1 Media - Coverage', value: 'TIER_1' },
  { label: 'Tier 2 Media - Coverage', value: 'TIER_2' },
  { label: 'Tier 3 Media - Coverage', value: 'TIER_3' },
  { label: 'Tier 4 Media - Coverage', value: 'TIER_4' },
  { label: 'National Media - Mention', value: 'NATIONAL_MENTION' },
  { label: 'Tier 1 Media - Mention', value: 'TIER_1_MENTION' },
  { label: 'Tier 2 Media - Mention', value: 'TIER_2_MENTION' },
  { label: 'Tier 3 Media - Mention', value: 'TIER_3_MENTION' },
  { label: 'Tier 4 Media - Mention', value: 'TIER_4_MENTION' },
  { label: 'Mentions & Quotes (legacy)', value: 'MENTION', disabled: true },
];

const getDefaultTier = (journalist) => {
  const { tier } = journalist?.publication || {};
  if (tier) {
    return tierOptions[tier - 1]?.value;
  }
  return '';
};

const AddEditCoverageModal = ({
  coverage = null,
  journalist = {},
  threadId = null,
  ExtraFields = null,
  onCancel = noop,
  onSubmit = noop,
}) => {
  const { showError, showSuccess } = useNotifications();
  const [isUpserting, setIsUpserting] = useState(false);
  const storage = useStorage();
  const { mutateAsync: getPrefillData, isLoading: prefillLoading } =
    useGetPrefillData();
  const form = useForm({
    defaultValues: coverage
      ? {
          ...coverage,
          publishedAt: getDateObjFromTimestamp(coverage.publishedAt),
          image: coverage.image
            ? {
                path: coverage.image,
                type: 'image',
              }
            : null,
        }
      : {
          title: '',
          summary: '',
          publishedAt: getDateObjFromTimestampCurrentUserTimezone(Date.now()),
          tier: getDefaultTier(journalist),
          link: '',
          journalistId: journalist.id || '',
          threadId,
        },
  });

  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
    handleSubmit,
  } = form;
  const publishedAt = watch('publishedAt');

  const validOptionRanges = useDateTimeSelector({
    config: {
      type: 'past',
    },
    selection: {
      selectedDay: publishedAt?.day,
      selectedMonth: publishedAt?.month,
      selectedYear: publishedAt?.year,
    },
    updateSelections: ({ day, month, year }) => {
      setValue('publishedAt.day', day);
      setValue('publishedAt.month', month);
      setValue('publishedAt.year', year);
    },
  });

  return (
    <Modal open>
      <Modal.Title>Coverage</Modal.Title>
      <Modal.Content className="flex flex-col gap-2">
        <div className="flex gap-2 w-full">
          <div className="flex flex-col gap-2 w-full">
            <InputFieldText
              defaultValue=""
              maxLength={2000}
              {...register('title', {
                required: {
                  value: true,
                  message: 'Please provide a title',
                },
              })}
              errors={errors}
              placeholder="Title of the coverage"
            >
              <InputFieldText.Label>Title*</InputFieldText.Label>
            </InputFieldText>

            <div className="flex flex-col gap-2">
              <Label htmlFor="name">Summary*</Label>
              <InputfieldTextArea
                className="!mt-0"
                {...register('summary', {
                  required: {
                    value: true,
                    message: 'Please provide a summary',
                  },
                })}
              />
            </div>

            {ExtraFields ? <ExtraFields form={form} /> : null}

            <InputFieldText
              defaultValue=""
              maxLength={2000}
              {...register('link', {
                required: {
                  value: true,
                  message: 'Please provide an URL',
                },
                pattern: {
                  value: REGEX_URL,
                  message: 'Please type in a valid URL',
                },
              })}
              errors={errors}
              placeholder="The URL of the article..."
            >
              <InputFieldText.Label>Article link*</InputFieldText.Label>
            </InputFieldText>

            <InputFieldUpload name="image" folder={UPLOAD_FOLDER} form={form}>
              <InputFieldUpload.Label>Image</InputFieldUpload.Label>
            </InputFieldUpload>

            <Button
              onClick={async () => {
                try {
                  const link = getValues('link');
                  const data = await getPrefillData(link);
                  if (data.image) {
                    setValue('image', {
                      path: data.image,
                      type: 'image',
                    });
                  }
                } catch (error) {
                  showError({
                    message: `Something went wrong`,
                  });
                }
              }}
            >
              {prefillLoading ? 'Loading...' : 'Prefill from url'}
            </Button>

            <div className="flex flex-col gap-2">
              <Label htmlFor="name">Publication date</Label>
              <div className="flex w-full gap-2">
                <Controller
                  name="publishedAt.day"
                  control={control}
                  defaultValue={todaysDateObj.day}
                  render={({ field: { onChange, value } }) => (
                    <InputFieldDropdown
                      options={validOptionRanges.days}
                      onChange={onChange}
                      value={value}
                      buttonClassName="w-24"
                    />
                  )}
                />
                <Controller
                  name="publishedAt.month"
                  control={control}
                  defaultValue={todaysDateObj.month}
                  render={({ field: { onChange, value } }) => (
                    <InputFieldDropdown
                      options={validOptionRanges.months}
                      onChange={onChange}
                      value={value}
                      wrapperClassName="w-full"
                      buttonClassName="w-full"
                    />
                  )}
                />
                <Controller
                  name="publishedAt.year"
                  control={control}
                  defaultValue={todaysDateObj.year}
                  render={({ field: { onChange, value } }) => (
                    <InputFieldDropdown
                      options={validOptionRanges.years}
                      onChange={onChange}
                      value={value}
                      buttonClassName="w-28"
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="tier">Tier</Label>
              <Controller
                name="tier"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputFieldDropdown
                    placeholder="Select a tier"
                    options={tierOptions}
                    onChange={onChange}
                    value={value}
                    buttonClassName="w-full"
                  />
                )}
              />
            </div>
          </div>
        </div>
      </Modal.Content>

      <Modal.LeftButtons>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.LeftButtons>
      <Modal.RightButtons>
        <Button
          className="relative"
          onClick={handleSubmit(async ({ image, ...restOfCoverage }) => {
            setIsUpserting(true);
            try {
              const imageUrl = image?.localName
                ? await getDownloadURL(ref(storage, image?.path))
                : image?.path;

              await onSubmit({
                ...restOfCoverage,
                publishedAt: createTimestampFromDateObj(
                  restOfCoverage.publishedAt
                ),
                image: imageUrl,
              });
              showSuccess({
                message: 'Coverage recorded successfully',
              });
              onCancel();
            } catch (error) {
              showError({
                message: 'Something went wrong',
              });
            }
            setIsUpserting(false);
          })}
        >
          <span
            className={classNames({
              'opacity-20 transition-opacity': isUpserting,
            })}
          >
            {coverage?.id ? 'Update' : 'Record'} Coverage
          </span>
          {isUpserting ? <Spinner color="text-white" /> : null}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AddEditCoverageModal.propTypes = {
  threadId: PropTypes.string,
  coverage: PropTypes.object,
  journalist: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  ExtraFields: PropTypes.elementType,
};

export default AddEditCoverageModal;
