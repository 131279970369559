import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from 'react-tooltip';

import StripeRedirectModal from 'components/StripeRedirectModal';
import Button from 'components/buttons/Button';
import stripePortalConfigNames from 'constants/stripePortalConfigNames';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useRedirectToStripePortal from 'hooks/useRedirectToStripePortal';
import { useTracking } from 'modules/segment/TrackingProvider';

import useCompanySubscription from '../hooks/useCompanySubscription';

const BillingSettingsPaymentBillingInformation = ({
  line1 = '',
  line2 = '',
  city = '',
  country = '',
  name = '',
  postal_code: postalCode = '',
  email = '',
}) => {
  const trackingService = useTracking();
  const { companyData } = useCompanyData();
  const { uid } = useUserData();
  const { data: subscription } = useCompanySubscription(companyData?.uid);

  const { redirectToStripePortal, isRedirecting } = useRedirectToStripePortal(
    stripePortalConfigNames.BILLING_UPDATE
  );
  const lines = [line1, line2].filter(Boolean).join(', ');
  const cityCountryPostalCode = [city, country, postalCode]
    .filter(Boolean)
    .join(', ');

  const updateBillingInfo = async (event) => {
    await trackingService.track('subscription_update', {
      action: 'billingInformation_update',
    });
    redirectToStripePortal(event);
  };

  if (!subscription) {
    return null;
  }
  return (
    <div className="flex flex-col">
      <p>{name}</p>
      <p>{lines}</p>
      <p>{cityCountryPostalCode}</p>
      <p className="mt-2 text-gray-500 mb-4">{email}</p>
      <Button
        disabled={subscription.subscriptionOwner.id !== uid}
        type="secondary"
        className="w-fit mt-auto"
        onClick={updateBillingInfo}
        data-tooltip-id={
          subscription.subscriptionOwner.id !== uid
            ? 'billing-button-explanation'
            : null
        }
        data-tooltip-html={`<span className="text-center">Only <b>${subscription.subscriptionOwner.email}</b> <br/> can update the billing information</span>`}
      >
        Update billing information
      </Button>
      <StripeRedirectModal isRedirecting={isRedirecting} />
      <Tooltip id="billing-button-explanation" className="text-sm" />
    </div>
  );
};

BillingSettingsPaymentBillingInformation.propTypes = {
  line1: PropTypes.string,
  line2: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  name: PropTypes.string,
  postal_code: PropTypes.string,
  email: PropTypes.string,
};
export default BillingSettingsPaymentBillingInformation;
