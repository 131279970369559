import PropTypes from 'prop-types';
import React from 'react';

import { BuildingOfficeIcon } from '@heroicons/react/20/solid';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';

const CompanyMetaDescriptionEditModal = ({
  open,
  description,
  onClose,
  onSubmit,
}) => {
  const formName = 'company-meta-description-editModal';
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    watch,
  } = useForm({ mode: 'onChange' });

  const descriptionWatch = watch('description') || description;

  return (
    <Modal open={open}>
      <Modal.Close
        srMessage="Close edit company description modal"
        onClose={onClose}
      />
      <Modal.Icon Icon={BuildingOfficeIcon} />

      <Modal.Title>
        {description ? 'Edit' : 'Add'} company information
      </Modal.Title>
      <Modal.Description>
        Explain what your company does in 2-3 sentences.
      </Modal.Description>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} id={formName}>
          <div className="mt-5 flex items-baseline justify-between">
            <Label htmlFor="date">Description*</Label>
            <p className="text-xs text-gray-400">
              {descriptionWatch?.length ?? 0} characters
            </p>
          </div>

          <InputfieldTextArea
            {...register('description', {
              required: 'Please put in a long description',
            })}
            defaultValue={description}
            placeholder="A longer description of your company (about 140 characters)..."
            data-test-id="company-meta-description-editModal-descriptionInput"
          />

          {errors.description && (
            <ErrorMessage testId="company-meta-description-editModal-descriptionInput-error">
              {errors.description.message}
            </ErrorMessage>
          )}
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-meta-description-editModal-cancel"
        >
          Cancel
        </Button>

        <Button
          submit
          form={formName}
          disabled={!isValid}
          eventType="pressPage_update"
          eventProperties={{ action: 'description_add' }}
          data-test-id="company-meta-description-editModal-update"
        >
          {description ? 'Update' : 'Add'} description
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyMetaDescriptionEditModal.propTypes = {
  open: PropTypes.bool,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyMetaDescriptionEditModal;
