import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

import { useUser } from 'reactfire';

import Spinner from 'components/Spinner';
import { useTracking } from 'modules/segment/TrackingProvider';

import ClaimsWrapper from './ClaimsWrapper';

const defaultUserData = {
  isAnonymous: null,
  claimsAgencyId: null,
  claimsAdmin: null,
  claimsJournalist: null,
  claimsCompany: null,
  uid: null,
};

export const UserContext = createContext(defaultUserData);
const { Provider } = UserContext;

const UserProvider = ({ children }) => {
  const user = useUser();
  const trackingService = useTracking();
  const { data } = user;

  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const isAnonymous = data?.isAnonymous ?? false;
  const uid = data?.uid ?? null;

  useEffect(() => {
    if (uid) {
      trackingService.identify(uid, {});
    }
  }, [trackingService, uid]);

  const value = { isAnonymous, uid, isAuthenticating, setIsAuthenticating };

  // TODO: this is not ideal, because it'll render a new tree as the user signs up or logs in
  // resulting in a visual reload of the page because it re-renders the navbar
  // sc-2053 - https://app.shortcut.com/mvpr/story/2053/consider-refactoring-userprovider-to-look-for-a-more-elegant-way-to-pass-down-user-claims
  return (
    <ClaimsWrapper user={data} hasAuth={!!(!isAnonymous && uid)}>
      {(userClaims) =>
        userClaims.isLoading || isAuthenticating ? (
          <Spinner />
        ) : (
          <Provider value={{ ...value, ...userClaims }}>
            {children(uid)}
          </Provider>
        )
      }
    </ClaimsWrapper>
  );
};

UserProvider.propTypes = {
  children: PropTypes.func,
};

export default UserProvider;
