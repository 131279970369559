export const tabNames = {
  USER: 'user',
  COMPANY: 'company',
  BILLING: 'billing',
};

export const tabs = [
  { title: 'User', field: tabNames.USER },
  { title: 'Company', field: tabNames.COMPANY },
  { title: 'Billing', field: tabNames.BILLING },
];

export default tabs;
