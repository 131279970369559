import PropTypes from 'prop-types';
import React from 'react';

import Tag from 'components/tags/Tag';
import {
  bgColors,
  documentTypes,
  textColors,
} from 'constants/contentManagementTypes';

const ContentManagementTag = ({ status = '' }) => {
  const name =
    documentTypes.find((tag) => tag.label === status)?.name || status;
  const bgColor = bgColors[name] || 'gray-100';
  const textColor = textColors[name] || 'gray-700';

  return (
    status !== '' && (
      <Tag
        uppercase
        bgColor={bgColor}
        textColor={textColor}
        className="!my-0 !mr-0 text-xxs"
      >
        {status}
      </Tag>
    )
  );
};

ContentManagementTag.propTypes = {
  status: PropTypes.string,
};

export default ContentManagementTag;
