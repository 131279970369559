import React from 'react';

import {
  CalendarIcon,
  HomeIcon,
  NewspaperIcon,
  BuildingOfficeIcon,
  ShieldCheckIcon,
  UsersIcon,
} from '@heroicons/react/20/solid';
import {
  ArrowRightOnRectangleIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { generatePath, useParams } from 'react-router-dom';
import { useRemoteConfigBoolean } from 'reactfire';

import HomeLogo from 'components/HomeLogo';
import ImpersonateDropdown from 'components/layout/Navbar/ImpersonateDropdown';
import NavbarLinksLink from 'components/layout/Navbar/components/NavbarLinks/NavbarLinksLink';
import {
  ADMIN_JOURNALISTS_PATH,
  ADMIN_PATH,
  ADMIN_PUBLICATIONS_PATH,
  AGENCY_AGENCY_SETTINGS_PATH,
  AGENCY_JOURNALISTS_PATH,
  AGENCY_PATH,
  AGENCY_PUBLICATIONS_PATH,
  AGENCY_USER_SETTINGS_PATH,
  AGENCY_PLANNING_PATH,
} from 'constants/paths';
import useUserRoles from 'contexts/UserContext/hooks/useUserRoles';
import useHandleLogout from 'hooks/useHandleLogout';

const MVPR_AGENCY_ID = '72f4e80ed133ce03c57f3cf549af4a18';

const AgencyNavbarContent = () => {
  const { data: showJournalistsAndPublications } = useRemoteConfigBoolean(
    'showJournalistsAndPublications'
  );
  const { claimsAdmin, claimsAgencyId } = useUserRoles();
  const { agencyId } = useParams();
  const handleLogout = useHandleLogout();

  return (
    <>
      <div className="flex h-16 shrink-0 items-center">
        <HomeLogo
          homeRoute={generatePath(AGENCY_PATH, {
            agencyId,
          })}
          testId="homeLogo"
          className="ml-2 sm:ml-0 text-teal-500"
          white
        />
      </div>
      {(claimsAdmin || claimsAgencyId) && <ImpersonateDropdown />}
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <NavbarLinksLink
              label="Dashboard"
              path={generatePath(AGENCY_PATH, {
                agencyId,
              })}
              end
            >
              <HomeIcon className="w-6 h-6 text-gray-400" />
            </NavbarLinksLink>
            <NavbarLinksLink
              label="Planning"
              path={generatePath(AGENCY_PLANNING_PATH, {
                agencyId,
              })}
            >
              <CalendarIcon className="w-6 h-6 text-gray-400" />
            </NavbarLinksLink>
            {agencyId === MVPR_AGENCY_ID && (
              <>
                <NavbarLinksLink
                  className="hover:bg-red-50 text-gray-500 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
                  path={ADMIN_PUBLICATIONS_PATH}
                  label="Publications"
                >
                  <NewspaperIcon className="w-6 h-6 group-hover:text-red-500" />
                </NavbarLinksLink>
                <NavbarLinksLink
                  className="hover:bg-red-50 text-gray-500 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
                  path={ADMIN_JOURNALISTS_PATH}
                  label="Journalists"
                >
                  <UsersIcon className="w-6 h-6 group-hover:text-red-500" />
                </NavbarLinksLink>
              </>
            )}
            {showJournalistsAndPublications && agencyId !== MVPR_AGENCY_ID && (
              <>
                <NavbarLinksLink
                  path={generatePath(AGENCY_PUBLICATIONS_PATH, {
                    agencyId,
                  })}
                  label="Publications"
                >
                  <NewspaperIcon className="w-6 h-6 text-gray-400" />
                </NavbarLinksLink>
                <NavbarLinksLink
                  path={generatePath(AGENCY_JOURNALISTS_PATH, {
                    agencyId,
                  })}
                  label="Journalists"
                >
                  <UsersIcon className="w-6 h-6 text-gray-400" />
                </NavbarLinksLink>
              </>
            )}
          </li>

          <li className="mt-auto pb-11">
            <p className="text-xs font-semibold leading-6 text-gray-400">
              Settings
            </p>
            {claimsAdmin && (
              <NavbarLinksLink
                path={ADMIN_PATH}
                label="Admin Panel"
                className="bg-red-50 text-gray-500 hover:text-gray-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
                testId="navbar-account-admin"
              >
                <ShieldCheckIcon className="w-6 h-6 text-red-500" />
              </NavbarLinksLink>
            )}
            <NavbarLinksLink
              path={generatePath(AGENCY_AGENCY_SETTINGS_PATH, {
                agencyId,
              })}
              label="Agency Settings"
              className="text-gray-500 hover:text-gray-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
              testId="navbar-account-userSettings"
            >
              <BuildingOfficeIcon className="w-6 h-6 text-gray-400" />
            </NavbarLinksLink>
            <NavbarLinksLink
              path={generatePath(AGENCY_USER_SETTINGS_PATH, {
                agencyId,
              })}
              label="User Settings"
              className="text-gray-500 hover:text-gray-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
              testId="navbar-account-userSettings"
            >
              <UserIcon className="w-6 h-6 text-gray-400" />
            </NavbarLinksLink>
            <li>
              <div className="border-t mx-2 mt-2 mb-2" />
              <a
                href="#"
                onClick={handleLogout}
                className="text-gray-500 hover:text-gray-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
                data-test-id="navbar-account-signOut"
              >
                <ArrowRightOnRectangleIcon className="w-6 h-6 text-gray-400" />
                <span className="truncate">Logout</span>
              </a>
            </li>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default AgencyNavbarContent;
