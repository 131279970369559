import { ClockIcon, BoltIcon, NoSymbolIcon } from '@heroicons/react/20/solid';

export const tabs = [
  { title: 'Opportunities', field: 'upcoming', emoji: '⏳' },
];

export const filterOptions = [
  { index: 1, label: 'Tier 1', min: 80, max: 100 },
  { index: 2, label: 'Tier 2', min: 60, max: 79 },
  { index: 3, label: 'Tier 3', min: 40, max: 59 },
  { index: 4, label: 'Tier 4', min: 20, max: 39 },
  { index: 5, label: 'Other', min: -1, max: 29 },
];

export const sortOptions = [
  { name: 'timeLeft', label: 'Time left', Icon: ClockIcon },
  {
    name: 'domainAuthority',
    label: 'Domain authority',
    Icon: BoltIcon,
  },
  {
    name: 'none',
    label: 'None',
    Icon: NoSymbolIcon,
  },
];
