import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { noop } from 'lodash';
import { v4 as uuid } from 'uuid';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import defaultLanguages, { allLanguages } from 'constants/defaultLanguages';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

const languages = [allLanguages, ...defaultLanguages];

const SweetheartListJournalistsSearchControls = ({
  terms = [],
  setTerms = noop,
  language = allLanguages.value,
  setLanguage = noop,
}) => {
  const { searchJournalists, abortSearchJournalists } =
    useSweetheartListContext();

  useEffect(() => {
    searchJournalists(terms, language);
    return () => abortSearchJournalists();
  }, [terms, searchJournalists, abortSearchJournalists, language]);

  return (
    <div
      className="md:py-2 relative flex gap-4 flex-1 max-w-full"
      data-intercom-id="sweetheartlist-search"
    >
      <div className="relative flex-1 max-w-full">
        <InputFieldTagsAutocomplete
          wrapperClassName=""
          customFieldName="journoSearch"
          placeholder="Add topics or keywords"
          tags={
            terms.map((term) => ({
              id: uuid(),
              name: term,
            })) || []
          }
          onAddition={(tag) => {
            if (terms.find((t) => t === tag.name)) {
              return;
            }
            setTerms([...terms, tag.name]);
          }}
          showHowTo
          onDelete={(tag) => {
            setTerms(terms.filter((t) => t !== tag.name));
          }}
        />
      </div>
      <InputFieldDropdown
        options={languages}
        value={language}
        onChange={(value) => setLanguage(value)}
        buttonClassName="w-40 sm:w-60"
        placeholder="Select a language"
      />
    </div>
  );
};

SweetheartListJournalistsSearchControls.propTypes = {
  terms: PropTypes.array,
  setTerms: PropTypes.func,
  language: PropTypes.string,
  setLanguage: PropTypes.func,
};

export default SweetheartListJournalistsSearchControls;
