import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const JPD_URL = process.env.REACT_APP_JPD_URL;

const useJournalistsByIds = (ids) => {
  const auth = useAuth();
  return useQuery(['journalists', ...ids], async () => {
    const jwt = await auth.currentUser?.getIdToken();
    const response = await fetch(`${JPD_URL}/journalists/resolve`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      method: 'POST',
      body: JSON.stringify({ ids }),
    });
    if (!response?.ok) {
      throw new Error('Failed fetching companies');
    }
    return response.json();
  });
};

export default useJournalistsByIds;
