import PropTypes from 'prop-types';
import React from 'react';

const FileConversationItem = ({ files = [] }) => {
  if (!files || files.length === 0) {
    return null;
  }

  return (
    <div className="file-list flex flex-col items-end ">
      {files.map((file) => (
        <div
          key={file.fileName}
          className="file-item flex items-center bg-gray-200 p-2 my-1 rounded-lg"
        >
          <span className="mr-3 text-xl">📄</span>
          <span className="font-semibold text-gray-700">{file.fileName}</span>
        </div>
      ))}
    </div>
  );
};

FileConversationItem.propTypes = {
  files: PropTypes.array,
};

export default FileConversationItem;
