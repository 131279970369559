import { getDateRange } from './dateRange';

// turn a timestamp into a date obj (reverses what `createTimestampFromDateObj` does)
const getDateObjFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return {
    day: date.getUTCDate(),
    month: getDateRange('months')[date.getUTCMonth()],
    year: date.getUTCFullYear(),
    hour: getDateRange('hours')[date.getUTCHours()],
  };
};

const getHourAndMinutes = (date, increment) => {
  const hour = `${date.getHours()}`.padStart(2, '0');
  const minutesOptions = Array.from({ length: 60 / increment }, (_, i) =>
    `${i * increment}`.padStart(2, '0')
  );
  const minutesIndex = Number.parseInt(date.getMinutes() / increment, 10);
  const minutes = minutesOptions[minutesIndex];

  return `${hour}:${minutes}`;
};
export const getDateObjFromTimestampCurrentUserTimezone = (
  timestamp,
  increment = 60
) => {
  const date = new Date(timestamp);

  return {
    day: date.getDate(),
    month: getDateRange('months')[date.getMonth()],
    year: date.getFullYear(),
    hour: getHourAndMinutes(date, increment),
  };
};

export default getDateObjFromTimestamp;
