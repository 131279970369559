import { useEffect, useMemo } from 'react';

import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { ROOT_PATH } from 'constants/paths';
import useUserRoles from 'contexts/UserContext/hooks/useUserRoles';

const useRedirectUsersToOwnCompany = (path = '', params = {}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { id: companyIdInUrl } = useParams();
  const { claimsAdmin, roles, ownCompanySlug } = useUserRoles();

  const isMemberOfCompany = useMemo(() => {
    const companySlugs = roles
      .filter(
        (role) =>
          role.role === 'COMPANY_MEMBER' || role.role === 'COMPANY_OWNER'
      )
      .map((role) => role.companySlug);

    return companySlugs.includes(companyIdInUrl);
  }, [roles, companyIdInUrl]);

  const searchParamsString = searchParams.toString();
  const newPath = ownCompanySlug
    ? `${generatePath(path, {
        ...params,
        id: ownCompanySlug,
      })}?${searchParamsString}`
    : ROOT_PATH;

  useEffect(() => {
    if (claimsAdmin || isMemberOfCompany) {
      return;
    }

    navigate(newPath, {
      replace: true,
    });
  }, [claimsAdmin, isMemberOfCompany, navigate, newPath]);
};

export default useRedirectUsersToOwnCompany;
