import React, { useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';
import noop from 'lodash/noop';
import { useLocation } from 'react-router-dom';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useIsAnalyticsDisabled from 'hooks/useIsAnalyticsDisabled';
import useQuery from 'hooks/useQuery';
import { useTracking } from 'modules/segment/TrackingProvider';

import AnalyticscPageLoggerCustomer from './AnalyticscPageLoggerCustomer';
import getAnalyticsPageTitle from './helpers/getAnalyticsPageTitle';

/**
 * Logs the current page by sending a `page_view` event to Analytics
 * Generates the current page title using a helper
 */
const AnalyticsPageLogger = () => {
  const { state } = useStateMachine();
  const trackingService = useTracking();
  const query = useQuery();

  const { uid, claimsAdmin: isAdmin } = useUserData();
  const { companyData } = useCompanyData();

  const disableAnalytics = useIsAnalyticsDisabled();

  if (disableAnalytics) {
    trackingService.setAnalyticsCollectionEnabled(false);
  }

  const location = useLocation();

  const signupStatus = companyData?.signupStatus;

  useEffect(() => {
    if (uid) {
      trackingService.identify(uid, {
        isAdmin,
      });
    }
  }, [uid, isAdmin, trackingService]);

  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  useEffect(() => {
    // don't log page_view event if analytics is disabled
    if (disableAnalytics) {
      return noop;
    }

    const pageTitle = getAnalyticsPageTitle(location.pathname);
    if (!pageTitle) {
      return noop;
    }

    // if there's any query string (e.g. success or preview), don't log page_view events
    if (query.toString()) {
      return noop;
    }

    // only log page_view events if the sign up status is `undefined` or 2
    if (!(signupStatus === undefined || signupStatus === 2)) {
      return noop;
    }

    trackingService.track('page_view', {
      path_name: location.pathname,
      // we're overriding the automatically generated 'page_title' here, see above why
      page_title: pageTitle,
    });

    return noop;
  }, [
    disableAnalytics,
    location.pathname,
    query,
    signupStatus,
    state.cookiesEnabled,
    trackingService,
  ]);

  // don't set up user properties if analytics is disabled
  if (disableAnalytics) {
    return null;
  }

  if (!uid) {
    return null;
  }

  return <AnalyticscPageLoggerCustomer uid={uid} />;
};

export default AnalyticsPageLogger;
