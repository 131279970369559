import { useMutation } from 'react-query';
import { useAuth } from 'reactfire';

const getPrefillData = async ({ auth, url }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/coverages/prefill`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        url,
      }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to record coverage: ${error.message}`);
  }
};

const useGetPrefillData = () => {
  const auth = useAuth();

  return useMutation({
    mutationFn: (url) => getPrefillData({ url, auth }),
  });
};

export default useGetPrefillData;
