import React from 'react';

import { Helmet } from 'react-helmet';

import getPageTitle from 'utils/getPageTitle';

import AdminWrapper from '../AdminWrapper';

import AdminCoverages from './AdminCoverages';

const AdminCoveragesPage = () => (
  <AdminWrapper
    title="Coverage"
    containerClassName="grow overflow-hidden"
    mainClassName="flex flex-col h-screen"
  >
    <Helmet>
      <title>{getPageTitle('Admin - Coverage')}</title>
    </Helmet>
    <AdminCoverages />
  </AdminWrapper>
);

export default AdminCoveragesPage;
