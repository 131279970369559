import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import Tag from 'components/tags/Tag';

const AdminOpportunitiesCompanyTag = ({
  company = {},
  opportunity = '',
  onClick = noop,
  isTargeted = false,
}) => {
  const isNotified = opportunity.notifiedCompanies.includes(company.id);
  const notificationCount = opportunity.notifiedCompanies.filter(
    (companyId) => companyId === company.id
  ).length;

  let bgColor = 'gray-100';
  let textColor = '';
  let className = 'hover:bg-gray-50';

  if (isNotified) {
    bgColor = 'green-300';
    textColor = 'green-700';
    className = 'hover:bg-green-300';
  }

  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      key={company.id}
    >
      <Tag
        bgColor={bgColor}
        textColor={textColor}
        className={`${className} outline outline-gray-100`}
      >
        <span>
          {isTargeted ? '🎯 ' : ''}
          {notificationCount > 1
            ? `${company.name} (+${notificationCount - 1})`
            : company.name}
        </span>
      </Tag>
    </a>
  );
};

AdminOpportunitiesCompanyTag.propTypes = {
  company: PropTypes.object.isRequired,
  opportunity: PropTypes.string,
  isTargeted: PropTypes.bool,
  onClick: PropTypes.func,
};

export default AdminOpportunitiesCompanyTag;
