import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ShareIcon } from '@heroicons/react/24/outline';
import { useNavigate, Link, generatePath } from 'react-router-dom';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import { SETTINGS_PATH } from 'constants/paths';
import { tabNames as settingsTabNames } from 'constants/settingsTabs';
import stripTrailingSlash from 'utils/data/stripTrailingSlash';

import CompanyMetaAudiences from './CompanyMetaAudiences';
import CompanyMetaDescription from './CompanyMetaDescription/CompanyMetaDescription';
import CompanyMetaFounders from './CompanyMetaFounders/CompanyMetaFounders';
import CompanyMetaPlace from './CompanyMetaPlace';
import CompanyMetaShareModal from './CompanyMetaShareModal';
import CompanyMetaVideo from './CompanyMetaVideo';

const CompanyMeta = ({
  slug,
  name,
  website: websiteRaw,
  foundedDate,
  location,
  description,
  companyRef,
  showOwnerView,
  foundersCrud,
  videosRef,
}) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState();
  const navigate = useNavigate();

  const website = stripTrailingSlash(websiteRaw);

  return (
    <>
      <div className="relative w-full flex-none md:min-h-screen pt-8 md:pt-16 md:w-80 bg-white shadow md:shadow-none">
        <div data-intercom-id="pressPage-sidebar">
          <div className="flex justify-between items-center px-6 pt-4">
            <h2
              className="text-2xl font-medium break-words"
              data-test-id="company-meta-title"
            >
              {name}
            </h2>
            {showOwnerView && (
              <DotsMenu
                options={[
                  {
                    label: 'Edit company settings',
                    onClick: () =>
                      navigate(
                        generatePath(SETTINGS_PATH, {
                          id: slug,
                          tab: settingsTabNames.COMPANY,
                        })
                      ),
                    testId: 'company-meta-title-menu-edit',
                  },
                ]}
                theme="medium"
                testId="company-meta-title-menu"
              />
            )}
          </div>
          <Link
            to="#"
            data-test-id="company-meta-sharePressPage"
            className="absolute top-32 mt-1.5 md:mt-0 right-6 md:top-4 md:right-4 md:bottom-auto border border-gray-200 rounded-full p-2 hover:bg-gray-50 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              setIsShareModalOpen(true);
            }}
          >
            <ShareIcon className="w-5 h-5 -mt-px text-teal-500" />
          </Link>
          <CompanyMetaPlace
            website={website}
            location={location}
            foundedDate={foundedDate}
            showOwnerView={showOwnerView}
          />

          <CompanyMetaVideo
            videosRef={videosRef}
            showOwnerView={showOwnerView}
          />

          {/* Description */}
          <CompanyMetaDescription
            description={description}
            companyRef={companyRef}
            showOwnerView={showOwnerView}
          />

          {/* Founders */}
          <CompanyMetaFounders
            crud={foundersCrud}
            showOwnerView={showOwnerView}
            companyId={companyRef.id}
          />

          {/* Audiences */}
          <CompanyMetaAudiences showOwnerView={showOwnerView} />
        </div>
      </div>

      {/* Share modal */}
      <CompanyMetaShareModal
        open={isShareModalOpen}
        slug={slug}
        onClose={() => setIsShareModalOpen(false)}
      />
    </>
  );
};

CompanyMeta.propTypes = {
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string,
  website: PropTypes.string,
  foundedDate: PropTypes.shape({
    month: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  description: PropTypes.string,
  showOwnerView: PropTypes.bool.isRequired,
  companyRef: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  foundersCrud: PropTypes.array.isRequired,
  videosRef: PropTypes.object.isRequired,
};

export default CompanyMeta;
