import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

const allowedHtmlTags = ['span', 'div', 'button', 'a'];

const Tag = ({
  children = null,
  bgColor = 'gray-100',
  textColor = '',
  borderColor = '',
  borderStyle = '',
  className = '',
  as = 'span',
  bold = false,
  uppercase = false,
  onClick = noop,
  ...rest
}) => {
  const Component = allowedHtmlTags.includes(as) ? as : 'span';
  return (
    <Component
      onClick={onClick}
      className={classNames(
        'inline-flex mr-2 my-1 p-2 py-1 text-xs rounded-md whitespace-nowrap',
        className,
        {
          [`bg-${bgColor}`]: !!bgColor,
          [`text-${textColor}`]: !!textColor,
          border: borderColor || borderStyle,
          [`border-${borderColor}`]: !!borderColor,
          [`border-${borderStyle}`]: !!borderStyle,
          'font-semibold': bold,
          uppercase,
        }
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};

Tag.propTypes = {
  children: PropTypes.node,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderStyle: PropTypes.string,
  className: PropTypes.string,
  as: PropTypes.oneOf(allowedHtmlTags),
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Tag;
