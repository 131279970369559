import PropTypes from 'prop-types';
import React from 'react';

import { BookmarkIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';
import { generatePath, useNavigate } from 'react-router-dom';

import Button from 'components/buttons/Button';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useCompaniesOpportunitiesOps from 'hooks/useCompaniesOpportunitiesOps';
import useQueryParams from 'routes/Opportunities/hooks/useQueryParams';

const OpportunitiesListResponseModalRightActions = ({
  thread = null,
  onClose = noop,
  opportunity = {},
}) => {
  const navigate = useNavigate();
  const { showDeclined } = useQueryParams();
  const { companyData } = useCompanyData();

  const companiesOpportunitiesOps = useCompaniesOpportunitiesOps(
    companyData.uid
  );

  if (showDeclined) {
    return (
      <Button
        type="secondary"
        className="space-x-1"
        onClick={() => {
          companiesOpportunitiesOps.undecline(opportunity);
          onClose();
        }}
      >
        <BookmarkIcon className="w-4" /> <span>Reactivate</span>
      </Button>
    );
  }

  if (thread) {
    return (
      <Button
        onClick={() => {
          navigate(
            generatePath(MESSAGES_PATH_FULL, {
              id: companyData.slug,
              threadId: thread?.id,
            })
          );
        }}
        data-test-id="opportunity-responseModal-form-submit"
      >
        Go to thread
      </Button>
    );
  }

  return (
    <Button
      onClick={() => {
        companiesOpportunitiesOps.respond(opportunity);
        onClose(false);
      }}
      data-test-id="opportunity-responseModal-form-submit"
      data-intercom-id="opportunity-responseModal-submit"
    >
      Create Draft
    </Button>
  );
};

OpportunitiesListResponseModalRightActions.propTypes = {
  onClose: PropTypes.func.isRequired,
  opportunity: PropTypes.object.isRequired,
  thread: PropTypes.object,
};

export default OpportunitiesListResponseModalRightActions;
