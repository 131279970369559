import React from 'react';

import EmptyState from 'components/EmptyState';
import PageWrapper from 'components/PageWrapper';

const ForbiddenPage = () => (
  <PageWrapper>
    <EmptyState
      helmetTitle="Forbidden Access"
      testId="page-forbidden"
      title="Access Denied"
      subtitle="You do not have permission to access this page."
    />
  </PageWrapper>
);

export default ForbiddenPage;
