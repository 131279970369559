import PropTypes from 'prop-types';
import React from 'react';

import { useRouteError } from 'react-router-dom';

import Callout from 'components/Callout/Callout';

import InboxMessagesWrapper from './InboxMessagesWrapper';

const InboxMessagesError = ({ text = '', callout = null }) => {
  const error = useRouteError();

  return (
    <InboxMessagesWrapper className="text-gray-600 h-full flex items-center justify-center">
      <div className="p-8 flex flex-col gap-2 items-center">
        <p className="max-w-prose text-lg text-center">
          {text || error?.message}
        </p>
        <Callout size="sm" testId="inbox-noThreadSelected">
          {callout ||
            'Select a thread on your left to follow the conversation.'}
        </Callout>
      </div>
    </InboxMessagesWrapper>
  );
};

InboxMessagesError.propTypes = {
  text: PropTypes.string,
  callout: PropTypes.node,
};

export default InboxMessagesError;
