import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const InboxMessageHeaderPlaceholder = ({
  isFromJournalist = false,
  isFromUser = false,
}) => (
  <>
    <div className="ml-4 bg-gray-200 shrink-0 w-12 h-12 rounded-full" />
    <div
      className={classNames('ml-2 bg-gray-200 shrink-0 h-4 rounded-full', {
        'w-20': isFromJournalist,
        'w-12': isFromUser,
      })}
    />
    <div className="ml-2 bg-gray-200 shrink-0 w-48 h-4 rounded-full" />
    <div className="ml-auto bg-gray-200 shrink-0 w-12 h-4 rounded-full" />
    <div className="ml-2 mr-4 bg-gray-200 shrink-0 w-6 h-4 rounded-full" />
  </>
);

InboxMessageHeaderPlaceholder.propTypes = {
  isFromJournalist: PropTypes.bool,
  isFromUser: PropTypes.bool,
};

export default InboxMessageHeaderPlaceholder;
