import { useSearchParams } from 'react-router-dom';

const useThreadsSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (searchParams.get('status') === 'all') {
    searchParams.delete('status');
  }

  if (searchParams.get('intent') === 'all') {
    searchParams.delete('intent');
  }

  searchParams.delete('showDetails');

  return [searchParams, setSearchParams];
};

export default useThreadsSearchParams;
