import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { PlusIcon } from '@heroicons/react/20/solid';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import SectionHeadline from 'components/company/SectionHeadline';
import trackIntercomEvent from 'modules/intercom/trackIntercomEvent';

import CompanyMetaFounder from './CompanyMetaFounder';
import CompanyMetaFoundersEditModal from './CompanyMetaFoundersEditModal';

const CompanyMetaFounders = ({ crud, showOwnerView, companyId }) => {
  const [status, founders, ops] = crud;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const onSubmit = (founder) => {
    trackIntercomEvent('pressPage-update');
    trackIntercomEvent('pressPage-update-founders');
    ops.createDoc({
      name: founder.name,
      position: founder.position,
      image: founder.image,
      linkedinHandle: founder.linkedinHandle,
      twitterHandle: founder.twitterHandle,
      description: founder.description ?? null,
    });
    setIsEditModalOpen(false);
  };

  if (status === 'loading') {
    return null;
  }

  return (
    <div data-intercom-id="pressPage-sidebar-founders" className="py-5 -mt-5">
      <div className="pt-5 mx-6 border-t border-gray-100">
        <div className="flex justify-between items-center">
          <SectionHeadline>Team</SectionHeadline>
          {showOwnerView && (
            <DotsMenu
              options={[
                {
                  label: 'Add team member',
                  icon: <PlusIcon className="w-4 h-4" />,
                  onClick: () => setIsEditModalOpen(true),
                  testId: 'company-meta-founders-menu-add',
                },
              ]}
              theme="medium"
              testId="company-meta-founders-menu"
            />
          )}
        </div>
        {founders.length ? (
          <div className="mt-5 space-y-4">
            {founders.map((founder) => (
              <CompanyMetaFounder
                key={founder.uid}
                founder={founder}
                showOwnerView={showOwnerView}
                ops={ops}
                companyId={companyId}
              />
            ))}
          </div>
        ) : (
          <p
            className="mt-3 text-gray-300 text-sm"
            data-test-id="company-meta-founders-text"
          >
            No team members added yet.
          </p>
        )}
      </div>

      {showOwnerView && isEditModalOpen ? (
        <CompanyMetaFoundersEditModal
          open={isEditModalOpen}
          uploadFolder={`companies/${companyId}/founders`}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onSubmit}
        />
      ) : null}
    </div>
  );
};

CompanyMetaFounders.propTypes = {
  crud: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default CompanyMetaFounders;
