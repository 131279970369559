import PropTypes from 'prop-types';
import React from 'react';

import { useParams } from 'react-router-dom';
import { useUser } from 'reactfire';

import { tabNames } from 'constants/settingsTabs';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useUserByUID from 'hooks/useUserByUID';

import AccountForm from './AccountForm/AccountForm';
import ProfileForm from './ProfileForm/ProfileForm';
import UserSettingsDangerZone from './UserSettingsDangerZone/UserSettingsDangerZone';

const UserSettings = ({ useTabToShow = true }) => {
  const { data: user } = useUser();
  const { isActualOwnerOfCompany } = useCompanyData();
  const { uid } = useUserData();
  const { userDoc, status, userRef } = useUserByUID(uid);
  const { tab } = useParams();

  if (useTabToShow ? tab !== tabNames.USER : false) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6">
      <AccountForm user={user} userData={userDoc} />

      {status === 'success' && (
        <ProfileForm user={user} userRef={userRef} userData={userDoc} />
      )}

      {isActualOwnerOfCompany && <UserSettingsDangerZone />}
    </div>
  );
};

UserSettings.propTypes = {
  useTabToShow: PropTypes.bool,
};

export default UserSettings;
