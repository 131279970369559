import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import noop from 'lodash/noop';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import HtmlContainer from 'components/HtmlContainer';
import JournalistTeaser from 'components/JournalistTeaser';
import Modal from 'components/Modal';
import Stepper from 'components/Stepper';
import OpportunityTags from 'components/opportunities/opportunity/OpportunityTags';
import OpportunityType from 'components/opportunities/opportunity/OpportunityType';
import capitalizeFirstLetter from 'components/opportunities/opportunity/helpers/capitalizeFirstLetter';
import trackIntercomEvent from 'modules/intercom/trackIntercomEvent';

import OpportunitiesListResponseModalLeftActions from './OpportunitiesListResponseModalLeftActions';
import OpportunitiesListResponseModalRightActions from './OpportunitiesListResponseModalRightActions';

// eslint-disable-next-line no-unused-vars
const OpportunitiesListResponseModal = ({
  open = false,
  thread = null,
  onClose = noop,
  opportunity = {},
  currentCount = 0,
  totalCount = 0,
  next = noop,
  prev = noop,
}) => {
  useEffect(() => {
    if (open && opportunity?.journalist?.publication?.name === 'Bdaily') {
      trackIntercomEvent('opportunity-open-Bdaily');
    }
  }, [open, opportunity?.journalist?.publication?.name]);

  const { journalist, descriptionHtml, title, searchTerms, tags } = opportunity;

  return (
    <Modal
      open={open}
      testId="opportunity-responseModal"
      widthClass="max-w-xl lg:max-w-2xl xl:max-w-3xl"
    >
      <Modal.Close
        srMessage="Close Opportunity Response modal"
        testId="opportunity-responseModal-close"
        onClose={onClose}
      />
      <Modal.TopBar>
        <Stepper
          currentStep={currentCount}
          testId="opportunity-responseModal"
          totalSteps={totalCount}
          stepLabel="Opportunity"
          stepsLabel="Opportunities"
          onPrevious={prev}
          onNext={next}
        />
      </Modal.TopBar>
      <Modal.Content>
        <div className="flex flex-row flex-wrap items-center" />
        <div className="flex flex-row space-x-2 justify-start mb-2 flex-wrap">
          {tags.length > 0 && <OpportunityType type={tags[0]} />}
          <DomainAuthorityPill
            className="mb-2"
            domainAuthority={journalist?.publication?.domainAuthority}
          />
          {searchTerms.filter(Boolean).length > 0 && (
            <OpportunityTags searchTerms={searchTerms.filter(Boolean)} />
          )}
        </div>
        {journalist && (
          <div className="flex items-center space-x-4">
            <JournalistTeaser
              journalist={journalist}
              className="max-w-[300px]"
            />
          </div>
        )}
        <div className="flex justify-between mt-2">
          <h3 className="text-2xl font-semibold">
            {capitalizeFirstLetter(title)}
          </h3>
        </div>
        <HtmlContainer
          htmlString={descriptionHtml}
          className="max-w-full text-sm prose max-w-none prose-gray prose-p:my-4 prose-li:my-0 prose-a:text-teal-500 prose-pre:bg-gray-200 leading-relaxed"
        />
      </Modal.Content>

      <Modal.LeftButtons>
        <OpportunitiesListResponseModalLeftActions
          opportunity={opportunity}
          onClose={onClose}
        />
      </Modal.LeftButtons>

      <Modal.RightButtons>
        <OpportunitiesListResponseModalRightActions
          thread={thread}
          opportunity={opportunity}
          onClose={onClose}
        />
      </Modal.RightButtons>
    </Modal>
  );
};

OpportunitiesListResponseModal.propTypes = {
  open: PropTypes.bool,
  opportunity: PropTypes.object,
  currentCount: PropTypes.number,
  totalCount: PropTypes.number,
  next: PropTypes.func,
  prev: PropTypes.func,
  onClose: PropTypes.func,
  thread: PropTypes.object,
};

export default OpportunitiesListResponseModal;
