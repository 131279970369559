import { useCallback } from 'react';

import useAPIRequest from 'hooks/useAPIRequest';

// eslint-disable-next-line max-params
const useAPIRequestWithRetry = (params = {}, retries = 3, delay = 5000) => {
  const { fetchData } = useAPIRequest(params);

  const fetchDataWithRetry = useCallback(
    async (fetchParams = {}) => {
      const attemptFetch = async (attempts) => {
        try {
          const response = await fetchData(fetchParams);

          if (!response.msg || attempts >= retries) {
            return response;
          }

          // eslint-disable-next-line no-promise-executor-return
          await new Promise((resolve) => setTimeout(resolve, delay));

          return attemptFetch(attempts + 1);
        } catch (error) {
          if (attempts >= retries) {
            throw error;
          }

          // eslint-disable-next-line no-promise-executor-return
          await new Promise((resolve) => setTimeout(resolve, delay));

          return attemptFetch(attempts + 1);
        }
      };

      return attemptFetch(0);
    },
    [fetchData, retries, delay]
  );

  return {
    fetchData: fetchDataWithRetry,
  };
};

export default useAPIRequestWithRetry;
