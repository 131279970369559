import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const MOD_URL = process.env.REACT_APP_MOD_URL;

const useConversationItems = (reference) => {
  const auth = useAuth();
  return useQuery(['assistant', reference], {
    queryFn: async () => {
      const jwt = await auth.currentUser?.getIdToken();
      const response = await fetch(`${MOD_URL}/assistant/${reference}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });
      if (!response?.ok) {
        throw new Error('Failed fetching conversation items');
      }
      return response.json();
    },
    refetchOnWindowFocus: false,
  });
};

export default useConversationItems;
