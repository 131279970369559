import PropTypes from 'prop-types';
import { useEffect } from 'react';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useActiveSubscriptionWithTier from 'hooks/useActiveSubscriptionWithTier';

import bootIntercom from './helpers/bootIntercom';

const IntercomWithUserAndCompany = ({ userAttributes = {} }) => {
  const { companyData } = useCompanyData();
  const [currentTier] = useActiveSubscriptionWithTier();
  const createdAt = companyData?.signupDate?.seconds || null;

  useEffect(() => {
    if (!companyData?.uid) {
      return;
    }
    bootIntercom({
      ...userAttributes,
      company: {
        id: companyData?.uid,
        name: companyData?.name,
        slug: companyData?.slug,
        plan: currentTier?.name || null,
        website: companyData?.website,
        created_at: createdAt,
      },
    });
  }, [
    companyData?.uid,
    companyData?.name,
    companyData?.slug,
    currentTier?.name,
    userAttributes,
    companyData?.website,
    createdAt,
  ]);

  return null;
};

IntercomWithUserAndCompany.propTypes = {
  userAttributes: PropTypes.object,
};

export default IntercomWithUserAndCompany;
