import React, { useEffect } from 'react';

import noop from 'lodash/noop';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import PageWrapper from 'components/PageWrapper';
import TiersGuard from 'components/TiersGuard';
import { featureNames } from 'constants/featureMap';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useCompanyDocBySlug from 'hooks/useCompanyDocBySlug';
import { useTracking } from 'modules/segment/TrackingProvider';
import getPageTitle from 'utils/getPageTitle';

import CompanyNotFound from './CompanyNotFound';
import CompanyPageContent from './CompanyPageContent';

const CompanyPage = () => {
  const { id } = useParams();
  const { ref, name } = useCompanyDocBySlug(id) || {};

  const trackingService = useTracking();

  const { isAnonymous, claimsJournalist } = useUserData();

  useEffect(() => {
    // don't log this if the user is the owner of the company
    if (isAnonymous || claimsJournalist) {
      trackingService.track('pressPage_visit', {
        company_title: id,
      });
    }

    return noop;
  }, [id, isAnonymous, claimsJournalist, trackingService]);

  return (
    <PageWrapper className="p-0">
      <Helmet>
        <title>{getPageTitle(`Press page - ${name || ''}`)}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <TiersGuard allowPublic featureId={featureNames.PRESS_PAGE}>
        {ref ? <CompanyPageContent companyRef={ref} /> : <CompanyNotFound />}
      </TiersGuard>
    </PageWrapper>
  );
};

export default CompanyPage;
