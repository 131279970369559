import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import assistantActions from 'constants/assistantActions';

import useCurrentDocumentContext from '../hooks/useCurrentDocumentContext';

const ActionButtonsContainer = ({ inProgress, execute, conversationItems }) => {
  const { currentDocument } = useCurrentDocumentContext();
  const matchingAction = Object.values(assistantActions).find(
    (action) => action.compatibleType === currentDocument.type
  );

  const isActionExecuted = conversationItems.some(
    (item) =>
      item?.action?.type === assistantActions.GENERATE_FUNDING_ANNOUNCEMENT.type
  );

  if (!matchingAction) {
    return null;
  }

  return (
    <div className="relative w-full max-w-full my-1 mx-2">
      <div className="flex space-x-2 min-w-max">
        <button
          key={assistantActions.GENERATE_FUNDING_ANNOUNCEMENT.type}
          type="button"
          className={classNames(
            'flex items-center px-3 py-1 border bg-white shadow-sm rounded-full text-xs whitespace-nowrap transition-colors duration-150',
            {
              'text-teal-500 hover:text-teal-600 border-teal-300 hover:bg-gray-50':
                !inProgress && !isActionExecuted,
              'text-gray-400 border-gray-300 cursor-not-allowed opacity-50':
                inProgress || isActionExecuted,
            }
          )}
          onClick={() =>
            execute({
              type: assistantActions.GENERATE_FUNDING_ANNOUNCEMENT.type,
            })
          }
          disabled={inProgress || isActionExecuted}
        >
          <span className="mr-1">✨</span>
          {assistantActions.GENERATE_FUNDING_ANNOUNCEMENT.label}
        </button>
      </div>
    </div>
  );
};

ActionButtonsContainer.propTypes = {
  inProgress: PropTypes.bool.isRequired,
  execute: PropTypes.func.isRequired,
  conversationItems: PropTypes.array.isRequired,
};

export default ActionButtonsContainer;
