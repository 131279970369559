import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from 'reactfire';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

const getAnnouncement = async ({ uid, announcementId, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/milestones/${uid}/announcements/${announcementId}`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return {
      ...responseData,
      isInitialDraft:
        !responseData?.messages || !responseData?.messages?.length,
    };
  } catch (error) {
    throw new Error(`Failed to get announcement: ${error.message}`);
  }
};

const useGetAnnouncement = () => {
  const auth = useAuth();
  const { uid } = useCompanyData()?.companyData || {};
  const { announcementId } = useParams();

  return useQuery({
    queryFn: () => getAnnouncement({ announcementId, uid, auth }),
    queryKey: ['announcements', uid, announcementId],
    enabled: !!announcementId && !!uid,
    initialData: null,
  });
};

export default useGetAnnouncement;
