/* eslint-disable max-params */
import React, { useCallback, useEffect, useState } from 'react';

import { ControlledBoard, moveCard } from '@caldwell619/react-kanban';
import { noop, sortBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import tasksColumns from 'constants/tasks';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import { parseDeeplink } from 'hooks/useGetResource';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';
import { todaysDateObj } from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

import useCollaborators from '../../../hooks/useCollaborators';

import TasksAddEditModal from './TasksAddEditModal';
import TasksCard from './TasksCard';
import TasksColumnHeader from './TasksColumnHeader';
import useTasks from './hooks/useTasks';
import './kanban.css';
import useUpdateTaskStatus from './hooks/useUpdateTaskStatus';
import useUpsertTask from './hooks/useUpsertTask';

export const parseReference = (reference = '', companySlug = '') => {
  if (!reference) {
    return '';
  }
  const [type, id] = reference.split('/');

  switch (type) {
    case 'opportunities':
      return {
        type: 'Opportunity',
        reference: `https://app.mvpr.io/opportunities/${companySlug}?id=${id}`,
      };
    case 'journalistLists':
      return {
        type: 'Journalist List',
        reference: `https://app.mvpr.io/sweetheart-list/${companySlug}/lists/${id}`,
      };
    case 'threads':
      return {
        type: 'Thread',
        reference: `https://app.mvpr.io/messages/${companySlug}/${id}`,
      };
    case 'documents':
      return {
        type: 'Document',
        reference: `https://app.mvpr.io/content/${companySlug}/documents/${id}`,
      };
    default:
      return {
        type: 'Unknown',
        reference: '',
      };
  }
};

const parseTask = ({ collaborators = [], task = {}, companySlug = '' }) => ({
  ...task,
  withAgency: !task.withAgency,
  collaborators:
    task.collaborators
      ?.map((collaboratorId) => {
        const collaborator = collaborators.find((c) => c.id === collaboratorId);

        if (!collaborator) {
          return null;
        }

        return {
          id: collaborator.id,
          name: collaborator.firstName,
          data: collaborator,
        };
      })
      .filter(Boolean) || [],
  externalCollaborators:
    task.externalCollaborators?.map((name) => ({
      id: `external-${name}-${uuidv4()}`,
      name,
    })) || [],
  deadlineObject: task.deadline
    ? getDateObjFromTimestamp(task.deadline)
    : todaysDateObj,
  isDeadlineEnabled: !!task.deadline,
  ...parseReference(task.reference, companySlug),
});

export const orderTasks = (tasks) => {
  const tasksWithDeadline = tasks.filter((card) => card.deadline);
  const tasksWithoutDeadline = tasks.filter((card) => !card.deadline);

  return [...sortBy(tasksWithDeadline, 'deadline'), ...tasksWithoutDeadline];
};

const orderColumnCards = ({ columns }) => {
  const columnsWithOrderedCards = columns.map((column) => ({
    ...column,
    cards: orderTasks(column.cards),
  }));

  return {
    columns: columnsWithOrderedCards,
  };
};

export const createInitialBoard = ({
  tasks = [],
  onClick = noop,
  companyLogo,
  companyName,
  companySlug,
  collaborators = [],
}) => {
  const columnsWithCards = tasksColumns.map((column) => ({
    ...column,
    onClick: () => onClick({ status: column.id }),
    cards: [
      ...tasks
        .filter((task) => task.status === column.id)
        .map((task) => {
          const parsedTask = parseTask({
            collaborators,
            task,
            companySlug,
          });

          return {
            ...parsedTask,
            companyLogo,
            companyName,
            onClick: () => onClick({ ...parsedTask, companyLogo, companyName }),
          };
        }),
    ],
  }));

  return {
    columns: columnsWithCards,
  };
};

const TasksTab = () => {
  const { companyData } = useCompanyData();
  const { slug, logo, name, uid } = companyData;
  const { data: tasks = [], dataUpdatedAt: tasksUpdatedAt } = useTasks(uid);
  const { mutateAsync: upsertTask } = useUpsertTask(uid);
  const { mutateAsync: updateTaskStatus } = useUpdateTaskStatus(uid);
  const { data: collaborators = [], dataUpdatedAt: collaboratorsUpdatedAt } =
    useCollaborators(uid);
  const [showAddEditTaskModal, setShowAddEditTaskModal] = useState(false);

  const [controlledBoard, setBoard] = useState(
    orderColumnCards(
      createInitialBoard({
        tasks,
        companyLogo: logo,
        companyName: name,
        companySlug: slug,
        onClick: setShowAddEditTaskModal,
        collaborators,
      })
    )
  );

  const handleCardMove = useCallback(
    (card, source, destination) => {
      setBoard((currentBoard) => moveCard(currentBoard, source, destination));
      updateTaskStatus({
        taskId: card.id,
        status: destination.toColumnId,
      });
    },
    [updateTaskStatus]
  );

  const handleSubmit = useCallback(
    async (newTask) =>
      upsertTask({
        ...newTask,
        deadline: newTask.isDeadlineEnabled
          ? createTimestampFromDateObj(newTask.deadlineObject)
          : null,
        withAgency: !newTask.withAgency,
        reference: parseDeeplink(newTask.reference, uid)?.reference || '',
        collaborators: newTask.collaborators.map(
          (collaborator) => collaborator.id
        ),
        externalCollaborators: newTask.externalCollaborators.map(
          (collaborator) => collaborator.name
        ),
      }),
    [upsertTask, uid]
  );

  useEffect(() => {
    setBoard(
      orderColumnCards(
        createInitialBoard({
          tasks,
          companyLogo: logo,
          companyName: name,
          companySlug: slug,
          onClick: setShowAddEditTaskModal,
          collaborators,
        })
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaboratorsUpdatedAt, slug, logo, name, tasksUpdatedAt]);

  return (
    <div className="grow -mx-6">
      <ControlledBoard
        disableColumnDrag
        allowRemoveColumn={false}
        allowRenameColumn={false}
        renderCard={TasksCard}
        onCardDragEnd={handleCardMove}
        renderColumnHeader={TasksColumnHeader}
        allowAddCard={false}
      >
        {controlledBoard}
      </ControlledBoard>
      {showAddEditTaskModal && (
        <TasksAddEditModal
          task={showAddEditTaskModal}
          collaborators={collaborators}
          onCancel={() => {
            setShowAddEditTaskModal(null);
          }}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default TasksTab;
