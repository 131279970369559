import PropTypes from 'prop-types';
import React from 'react';

import { TrashIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';

import useAgencyPublications from './hooks/useAgencyPublications';

const AgencyDashboardPublicationsTableDeleteButton = ({
  tooltipId = '',
  publicationId = '',
}) => {
  const { agencyId } = useParams();
  const { deletePublicationMutation } = useAgencyPublications(agencyId);
  const { showSuccess } = useNotifications();

  return (
    <Button
      type="danger"
      data-tooltip-id={tooltipId}
      data-tooltip-content="Delete"
      onClick={async () => {
        await deletePublicationMutation.mutateAsync(publicationId);
        showSuccess({ title: 'Publication deleted' });
      }}
      className="relative"
    >
      <TrashIcon
        className={classNames('w-4 h-4', {
          'opacity-50': deletePublicationMutation.isLoading,
        })}
      />
      {deletePublicationMutation.isLoading && (
        <Spinner
          color="text-white"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      )}
    </Button>
  );
};

AgencyDashboardPublicationsTableDeleteButton.propTypes = {
  tooltipId: PropTypes.string,
  publicationId: PropTypes.string,
};

export default AgencyDashboardPublicationsTableDeleteButton;
