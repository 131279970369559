import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';

const clearConversation = async ({ reference, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/assistant/${reference}`;

  const jwt = await auth.currentUser?.getIdToken();

  const response = await fetch(`${BASE_URL}${endpoint}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  });

  if (!response.ok) {
    throw new Error('API call failed');
  }
};

const useClearAssistant = (reference) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { showError } = useNotifications();
  return useMutation({
    mutationFn: () => clearConversation({ reference, auth }),
    onSuccess: () => queryClient.invalidateQueries(['assistant', reference]),
    onError: () =>
      showError({
        message: 'There was an error communicating with the assistant',
      }),
  });
};

export default useClearAssistant;
