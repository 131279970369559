import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import CreatedDateDisplay from './CreateDateDisplay';
import StoryHeading from './StoryHeading';
import StorySummary from './StorySummary';
import StoryTitle from './StoryTitle';

const StoryCard = ({ story = {}, onClick }) => {
  const { title, summary, image, publishedAt } = story;

  return (
    <article
      className={classNames(
        'relative flex items-start flex-row justify-center group mt-2 mx-auto'
      )}
      data-test-id={`story-${story.id}`}
    >
      <div
        className="flex-1 p-4 max-w-screen-md w-full rounded-lg shadow hover:shadow-md cursor-pointer bg-white"
        onClick={onClick}
      >
        <StoryHeading {...story} />
        <div className="flex items-start">
          {image && (
            <div className="flex-none w-full sm:w-1/3 h-48 relative">
              <img
                src={image}
                alt=""
                className="w-full h-full object-cover rounded-l-lg"
              />
            </div>
          )}

          <div className={`flex-1 ${image && 'ml-4'}`}>
            <StoryTitle title={title} />
            <StorySummary summary={summary} />
          </div>
        </div>
      </div>

      <div className="flex flex-col max-w-prose items-end lg:items-start lg:w-40 mt-2">
        <CreatedDateDisplay publishedAt={publishedAt} />
      </div>
    </article>
  );
};

StoryCard.propTypes = {
  story: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
    createdAt: PropTypes.string,
    publishedAt: PropTypes.string,
    summary: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string,
    topics: PropTypes.array,
    journalist: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  onClick: PropTypes.func,
};

export default StoryCard;
