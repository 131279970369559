import { CREATE_NEW_COMPANY as path } from 'constants/paths';

import NewCompanyPage from './NewCompanyPage';

const NewCompanyRoute = {
  path,
  component: NewCompanyPage,
};

export default NewCompanyRoute;
