import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

export const textAnimation = {
  initial: { opacity: 1, y: 0, width: 'auto', height: 'auto' },
  animate: { opacity: 1, y: 0, width: 'auto', height: 'auto' },
  exit: { opacity: 0, y: -10, width: 0, height: 0 },
};

const InboxMessageHeaderName = ({
  journalist = '',
  publication = '',
  isFromJournalist = false,
  isExpanded = false,
  isReadByUser = true,
  grant,
}) => {
  if (isFromJournalist) {
    return (
      <div>
        <p
          data-test-id="inboxMessage-fromJournalist"
          className={classNames('shrink-0 text-gray-700', {
            'font-normal': isReadByUser,
            'font-bold': !isReadByUser,
          })}
        >
          {journalist || 'Unknown'}
        </p>
        <AnimatePresence mode="popLayout">
          {isExpanded && (
            <motion.p {...textAnimation} className="text-gray-400 text-sm">
              Writer{publication && `, ${publication}`}
            </motion.p>
          )}
        </AnimatePresence>
      </div>
    );
  }

  return (
    <div>
      <p
        data-test-id="inboxMessage-fromUser"
        className="shrink-0 text-gray-700"
      >
        You
      </p>
      {grant && <p className="text-gray-400 text-sm">{grant.email}</p>}
    </div>
  );
};

InboxMessageHeaderName.propTypes = {
  journalist: PropTypes.string,
  publication: PropTypes.string,
  isFromJournalist: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isReadByUser: PropTypes.bool,
  grant: PropTypes.object,
};

export default InboxMessageHeaderName;
