import { useMutation } from 'react-query';
import { useAuth } from 'reactfire';

const deleteOpportunity = async ({ id, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}/opportunities/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to delete opportunity: ${error.message}`);
  }
};

const useDeleteOpportunity = () => {
  const auth = useAuth();
  return useMutation((id) => deleteOpportunity({ id, auth }));
};

export default useDeleteOpportunity;
