import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

const NavbarMobileTransition = ({
  sidebarOpen = false,
  setSidebarOpen = noop,
  children = null,
}) => (
  <Transition.Root show={sidebarOpen} as={Fragment}>
    <Dialog
      as="div"
      className="relative z-50 lg:hidden"
      onClose={setSidebarOpen}
    >
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-900/80" />
      </Transition.Child>

      <div className="fixed inset-0 flex">
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <Dialog.Panel className="relative mr-14 flex w-full max-w-xs flex-1">
            {children}
          </Dialog.Panel>
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed right-0 top-0 flex justify-center">
            <button
              type="button"
              className="p-2.5 mr-2 sm:mr-3 mt-2.5"
              onClick={() => setSidebarOpen(false)}
            >
              <span className="sr-only">Close sidebar</span>
              <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

NavbarMobileTransition.propTypes = {
  sidebarOpen: PropTypes.bool,
  setSidebarOpen: PropTypes.func,
  children: PropTypes.node,
};

export default NavbarMobileTransition;
