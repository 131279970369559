import PropTypes from 'prop-types';
import React from 'react';

import { noop } from 'lodash';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

const JournalistsSearchBulkActions = ({ selected = [], onChange = noop }) => {
  const { journalistLists } = useSweetheartListContext();

  return (
    <div className="flex flex-col gap-2 fixed w-full left-0 lg:pl-64 z-50 bottom-16 justify-center items-center text-sm">
      <div className="bg-teal-500 text-white rounded-xl flex items-center divide-x">
        <p className="px-4 py-2">
          {selected.length} journalist{selected.length > 1 ? 's' : ''} selected
        </p>
        <InputFieldDropdown
          onChange={onChange}
          options={journalistLists.map((list) => ({
            label: list.name,
            value: list.id,
          }))}
          buttonClassName="w-40 rounded-r-xl rounded-l-none !border-l-0!"
          placeholder="Add to list"
        />
      </div>
    </div>
  );
};

JournalistsSearchBulkActions.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.array,
};

export default JournalistsSearchBulkActions;
