import React from 'react';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import EmptyState from 'components/EmptyState';
import Button from 'components/buttons/Button';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

const SweetheartListJournalistListError = () => {
  const { getJournalistList } = useSweetheartListContext();
  return (
    <EmptyState
      helmetTitle="Journalists"
      testId="sweetheartList-ListError"
      title="There's been an error fetching the Journalist List."
      Icon={ExclamationTriangleIcon}
      instructions={
        <>
          <p>
            Whoops, something went wrong. You can try refreshing the list by
            clicking the button below.
          </p>
          <Button onClick={getJournalistList} className="mt-4">
            Refresh List
          </Button>
        </>
      }
    />
  );
};

export default SweetheartListJournalistListError;
