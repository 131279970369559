import PropTypes from 'prop-types';
import React from 'react';

import { ChartBarSquareIcon } from '@heroicons/react/24/outline';

const SweetheartListJournalistProfile = ({
  name = '',
  url = '',
  ImageComponent = null,
  hasStats = false,
  testId = '',
}) => (
  <div className="flex gap-2 items-center">
    <div className="flex-shrink-0 w-8 h-8 relative">
      <ImageComponent url={url} name={name} />
    </div>
    <div className="text-sm font-medium" data-test-id={testId}>
      {name}
    </div>
    {hasStats ? (
      <ChartBarSquareIcon className="h-5 w-5 text-teal-500 shrink-0" />
    ) : null}
  </div>
);

SweetheartListJournalistProfile.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  ImageComponent: PropTypes.elementType,
  hasStats: PropTypes.bool,
  testId: PropTypes.string,
};

export default SweetheartListJournalistProfile;

// TODO Refactor as UniversalImageAndText and pass params for text, url, layout, alignment
