/* eslint-disable react-hooks/rules-of-hooks */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { LockClosedIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

import featureMap from 'constants/featureMap';
import { PAGE_TITLE } from 'constants/meta';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useCompanyTier from 'hooks/useCompanyTier';
import useQuery from 'hooks/useQuery';

import EmptyState from './EmptyState';
import Spinner from './Spinner';
import TiersModal from './TiersModal/TiersModal';
import TiersModalPaymentErrorModal from './TiersModal/TiersModalPaymentErrorModal';
import TiersModalSuccessModal from './TiersModal/TiersModalSuccessModal';
import Button from './buttons/Button';
import ErrorPage from './errors/ErrorPage';

const TiersGuard = ({ featureId, allowPublic, children }) => {
  const [showTiersModal, setShowTiersModal] = useState(true);
  const company = useCompanyData();

  const {
    data: tier,
    isLoading: tierLoading,
    error: tierError,
  } = useCompanyTier(company?.companyData?.uid);

  // get history for push and replace actions
  const navigate = useNavigate();
  const query = useQuery();
  const { isAnonymous, uid, claimsAdmin: isAdmin } = useUserData();

  if (tier?.id === 'BANNED') {
    return (
      <EmptyState
        title="This account has been suspended"
        subtitle="Reach out to tom@mvpr.io for further information"
        Icon={LockClosedIcon}
      />
    );
  }

  if (isAdmin) {
    return children;
  }

  const isPublicView = !uid || isAnonymous;

  // Disable TiersGuard when an unauthenticated user is on a public page (press page)
  if (isPublicView && allowPublic) {
    return children;
  }
  const hasPaymentSucceeded = !!query.get('subscription_success');
  const hasError = !!query.get('subscription_error');

  // removes the query parameter after the work it triggered is done
  const removeSearchQuery = () =>
    navigate(
      {
        search: null,
      },
      { replace: true }
    );

  if (tierLoading) {
    return <Spinner />;
  }

  if (tierError || !tier) {
    return <ErrorPage />;
  }

  const featureIsIncludedInTier = featureMap[featureId].includes(tier.id);

  if (!hasPaymentSucceeded && featureIsIncludedInTier) {
    return children;
  }

  return (
    <>
      <EmptyState
        title="This feature is not included in your current tier."
        subtitle={`Upgrade to get the most out of ${PAGE_TITLE}!`}
        Icon={LockClosedIcon}
        instructions={
          <Button onClick={() => setShowTiersModal(true)}>Upgrade now</Button>
        }
      />
      {!hasError && !hasPaymentSucceeded && (
        <TiersModal
          open={showTiersModal}
          onClose={() => setShowTiersModal(false)}
        />
      )}
      {hasError && (
        <TiersModalPaymentErrorModal open onClose={removeSearchQuery} />
      )}
      {hasPaymentSucceeded && (
        <TiersModalSuccessModal open onClose={removeSearchQuery} />
      )}
    </>
  );
};

TiersGuard.propTypes = {
  allowPublic: PropTypes.bool,
  children: PropTypes.node,
  featureId: PropTypes.string.isRequired,
};

export default TiersGuard;
