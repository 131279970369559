import PropTypes from 'prop-types';
import React from 'react';

import journalistResponseOptions from '../../constants/journalistResponseOptions';

const InboxThreadsThreadJournalistResponse = ({
  journalistResponse = '',
  isSelectedThread = false,
}) => {
  const { dotColor, selectedDotColor } =
    journalistResponseOptions[journalistResponse] || {};
  return (
    <span
      className={`flex box-border rounded-full ${
        isSelectedThread ? selectedDotColor : dotColor
      } h-2.5 w-2.5 ml-1`}
      data-test-id={`inboxThread-qualifyingIcon_${journalistResponse}`}
    />
  );
};

InboxThreadsThreadJournalistResponse.propTypes = {
  journalistResponse: PropTypes.oneOf(['positive', 'neutral', 'negative']),
  isSelectedThread: PropTypes.bool,
};

export default InboxThreadsThreadJournalistResponse;
