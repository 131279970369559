import PropTypes from 'prop-types';
import React from 'react';

import { findIndex } from 'lodash';

import Tag from 'components/tags/Tag';
import getTagColors from 'utils/color/getTagColors';

const OpportunityTags = ({ searchTerms = [] }) => (
  <>
    {searchTerms.map((term, index) => {
      const [bgColor, textColor] = getTagColors(findIndex(index), term?.length);
      return (
        <Tag
          key={`term-${term}`}
          bgColor={bgColor}
          textColor={textColor}
          className="mt-0 mb-2"
        >
          {term}
        </Tag>
      );
    })}
  </>
);

OpportunityTags.propTypes = {
  searchTerms: PropTypes.arrayOf(PropTypes.string),
};

export default OpportunityTags;
