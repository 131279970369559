import React from 'react';

import * as Sentry from '@sentry/react';
import { StateMachineProvider, createStore } from 'little-state-machine';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { FirebaseAppProvider, SuspenseWithPerf } from 'reactfire';

import PreloadFirebase from 'components/PreloadFirebase';
import Spinner from 'components/Spinner';
import ErrorPage from 'components/errors/ErrorPage';
import IubendaCookieConsentModal from 'components/integrations/IubendaCookieConsentModal/IubendaCookieConsentModal';
import { defaultUser, defaultCompany } from 'constants/defaultData';
import { LOGOUT_PATH } from 'constants/paths';
import EnvironmentProvider from 'contexts/EnvironmentContext/EnvironmentProvider';

import Router from './routes/Router';

createStore({
  user: defaultUser,
  company: defaultCompany,
  cookiesEnabled: null,
  onboardingRef: null,
});

const queryClient = new QueryClient();

const App = () => (
  <Sentry.ErrorBoundary
    fallback={({ error }) => (
      <ErrorPage instructions={<p>${error.toString()}</p>} />
    )}
    onError={(error) => {
      if (
        error?.code === 'auth/requires-recent-login' &&
        window.location.pathname !== LOGOUT_PATH
      ) {
        window.location = LOGOUT_PATH;
      }
    }}
  >
    <Helmet>
      <title>MVPR - Loading...</title>
    </Helmet>
    <EnvironmentProvider>
      {({ firebaseApp }) => (
        <StateMachineProvider>
          <QueryClientProvider client={queryClient}>
            <FirebaseAppProvider suspense firebaseApp={firebaseApp}>
              <SuspenseWithPerf fallback={<Spinner />} traceId="preload-wait">
                <PreloadFirebase>
                  <SuspenseWithPerf
                    fallback={<Spinner />}
                    traceId="router-wait"
                  >
                    <Router />
                  </SuspenseWithPerf>
                  <SuspenseWithPerf
                    fallback={<Spinner />}
                    traceId="iubenda-wait"
                  >
                    <IubendaCookieConsentModal />
                  </SuspenseWithPerf>
                </PreloadFirebase>
              </SuspenseWithPerf>
            </FirebaseAppProvider>
          </QueryClientProvider>
        </StateMachineProvider>
      )}
    </EnvironmentProvider>
  </Sentry.ErrorBoundary>
);

export default App;
