import { NYLAS_CALLBACK_PAGE_PATH } from 'constants/paths';

import NylasCallbackPage from './NylasCallbackPage';

const NylasCallbackRoute = {
  path: NYLAS_CALLBACK_PAGE_PATH,
  authRequired: true,
  component: NylasCallbackPage,
};

export default NylasCallbackRoute;
