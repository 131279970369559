import React from 'react';

import { Link, generatePath, useParams } from 'react-router-dom';

import Spinner from 'components/Spinner';
import { tabNames } from 'constants/journalistsTabs';
import { SWEETHEART_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

import SweetheartListJournalistListsCreateButton from './SweetheartListJournalistListsCreateButton';

const SweetheartListJournalistLists = () => {
  const { isLoadingJournalistLists, journalistLists } =
    useSweetheartListContext();
  const { listId, tab } = useParams();
  const { companyData } = useCompanyData();
  const { slug } = companyData;

  if (listId || tab !== tabNames.LISTS) {
    return null;
  }

  return (
    <ul
      data-test-id="journalistLists-lists"
      className="relative overflow-y-auto grid grid-cols-1 gap-6 py-6 pt-0 sm:grid-cols-2"
    >
      {isLoadingJournalistLists && <Spinner />}

      {journalistLists?.map((journalistList) => (
        <li
          key={journalistList.id}
          className="col-span-1 hover:shadow-md rounded-lg bg-white shadow"
        >
          <Link
            to={generatePath(SWEETHEART_PATH, {
              id: slug,
              tab: tabNames.LISTS,
              listId: journalistList.id,
            })}
            className="flex w-full h-full focus-visible:outline-teal-500 rounded-lg items-center justify-between space-x-6 p-6"
            data-test-id={`journalistLists-lists-${journalistList.name}`}
          >
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">
                  {journalistList.name || 'Untitled'}
                </h3>
              </div>
              <p className="mt-2 truncate text-xs font-light text-gray-500">
                {journalistList.description}
              </p>
            </div>
          </Link>
        </li>
      ))}
      <SweetheartListJournalistListsCreateButton />
    </ul>
  );
};

export default SweetheartListJournalistLists;
