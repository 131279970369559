import PropTypes from 'prop-types';
import React from 'react';

import { PlusIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';
import { Link } from 'react-router-dom';

// A round button with a plus in the middle, used for empty states to indicate that something can be added
const AddButton = ({ onClick = noop, testId = '' }) => (
  <Link
    to="#"
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
    className="w-full flex justify-center items-center pt-2"
    data-test-id={testId}
  >
    <div className="w-12 h-12 border-dashed border border-teal-500 hover:bg-teal-50 rounded-full flex justify-center items-center">
      <PlusIcon className="w-6 h-6 text-teal-500" />
    </div>
  </Link>
);

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

export default AddButton;
