import React, { useState } from 'react';

import Button from 'components/buttons/Button';

import AgencyDashboardJournalistsEditModal from './AgencyDashboardJournalistsEditModal';

const AgencyDashboardJournalistsEditModalButton = () => {
  const [isAddJournalistModalOpen, setIsAddJournalistModalOpen] =
    useState(false);

  return (
    <>
      <Button
        onClick={() => setIsAddJournalistModalOpen(true)}
        className="w-fit self-end"
      >
        Add Journalist
      </Button>
      {isAddJournalistModalOpen ? (
        <AgencyDashboardJournalistsEditModal
          onClose={() => setIsAddJournalistModalOpen(false)}
        />
      ) : null}
    </>
  );
};

export default AgencyDashboardJournalistsEditModalButton;
