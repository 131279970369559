import { useEffect, useState } from 'react';

import noop from 'lodash/noop';
import { useAuth } from 'reactfire';

const useCustomResourcesSearchSuggestions = (
  searchQuery = '',
  customResources = []
) => {
  const auth = useAuth();
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!searchQuery) {
      setIsLoading(false);
      setSuggestions([]);
      return noop;
    }

    const controller = new AbortController();

    (async () => {
      setIsLoading(true);

      try {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const resourcesArray = customResources.map((resource) => ({
          id: resource.file.path,
          name: resource.title.toLowerCase(),
        }));

        const filteredSuggestions = resourcesArray.filter((resource) =>
          resource.name.includes(lowerCaseQuery)
        );

        setSuggestions(filteredSuggestions);
        setIsLoading(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          setIsLoading(false);
        }
      }
    })();

    return () => controller.abort();
  }, [searchQuery, customResources, auth]);

  return [suggestions, isLoading];
};

export default useCustomResourcesSearchSuggestions;
