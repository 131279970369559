import humanizeDuration from 'humanize-duration';

// returns the time left for an opportunity
const getTimeLeft = ({
  diff = 0,
  units = [],
  dateUtc = {},
  expired = false,
}) => {
  const date = dateUtc.toDate ? dateUtc?.toDate?.() : dateUtc;
  if (expired) {
    return `Expired on ${date.toLocaleDateString()}`;
  }

  const timeLeft = humanizeDuration(diff, {
    units,
    round: true,
    largest: 2,
    delimiter: ' ',
  });

  return `${timeLeft} left`;
};

export default getTimeLeft;
