const pressPageSections = [
  {
    name: 'achievements',
    hash: '#milestones',
    label: 'Milestones',
    emoji: '📸',
  },
  {
    name: 'videos',
    hash: '#videos',
    label: 'Videos',
    emoji: '🎥',
  },
  {
    name: 'articles',
    hash: '#recent-news',
    label: 'Recent news',
    emoji: '🗞',
  },
  {
    name: 'insights',
    hash: '#insights',
    label: 'Insights',
    emoji: '✨',
  },
  {
    name: 'quotes',
    hash: '#quote-bank',
    label: 'Quote bank',
    emoji: '🗯',
  },
  {
    name: 'downloads',
    hash: '#downloads',
    label: 'Downloads',
    emoji: '💾',
  },
];

export default pressPageSections;
