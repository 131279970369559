import React, { useState } from 'react';

import { UserPlusIcon } from '@heroicons/react/20/solid';
import { Navigate, generatePath, useParams } from 'react-router-dom';

import EmptyState from 'components/EmptyState';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import { allLanguages } from 'constants/defaultLanguages';
import { tabNames } from 'constants/journalistsTabs';
import { SWEETHEART_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

import JournalistsSearchTable from './JournalistSearchTable';
import JournalistsSearchBulkActions from './JournalistsSearchBulkActions';
import SweetheartListJournalistsSearchControls from './SweetheartListJournalistsSearchControls';

const SweetheartListJournalistsSearch = () => {
  const { showSuccess, showError } = useNotifications();
  const [selected, setSelected] = useState([]);
  const [terms, setTerms] = useState([]);
  const [language, setLanguage] = useState(allLanguages.value);

  const { journalists, isLoadingJournalists, insertJournalists } =
    useSweetheartListContext();
  const { companyData } = useCompanyData();
  const { slug } = companyData;

  const { tab, id: companyIdInUrl } = useParams();

  if (tab !== tabNames.SEARCH) {
    return null;
  }

  if (companyIdInUrl !== slug) {
    return (
      <Navigate
        to={generatePath(SWEETHEART_PATH, {
          id: slug,
          tab: tabNames.SEARCH,
        })}
        replace
      />
    );
  }

  return (
    <div className="relative grow">
      <SweetheartListJournalistsSearchControls
        terms={terms}
        setTerms={setTerms}
        language={language}
        setLanguage={setLanguage}
      />

      <div className="relative">
        {journalists?.length > 0 && (
          <JournalistsSearchTable
            selected={selected}
            setSelected={setSelected}
            journalists={journalists}
            isLoading={isLoadingJournalists}
            hideColumns={['activity', 'reachout', 'delete']}
          />
        )}
        {isLoadingJournalists && <Spinner />}
      </div>

      {selected.length ? (
        <JournalistsSearchBulkActions
          selected={selected}
          onChange={async (listId) => {
            setSelected([]);
            try {
              await insertJournalists({
                listId,
                journalistIds: selected.map((journalist) => journalist.id),
              });
              showSuccess({
                message: 'Journalists added to list successfully',
              });
            } catch (error) {
              showError({
                message: 'Failed to add journalists to list',
              });
            }
          }}
        />
      ) : null}

      {!isLoadingJournalists && !terms.length ? (
        <EmptyState
          title="Search for journalists"
          subtitle="Add any number of search terms to find journalists that match all terms"
          Icon={UserPlusIcon}
        />
      ) : null}
    </div>
  );
};

export default SweetheartListJournalistsSearch;
