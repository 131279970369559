import React from 'react';

import { tabs } from 'constants/journalistsTabs';

import SweetheartListHeaderTabsItem from './SweetheartListHeaderTabsItem';

const SweetheartListHeaderTabs = () => (
  <div className="flex pt-2" data-intercom-id="content-tabs">
    {tabs.map((tab) => (
      <SweetheartListHeaderTabsItem key={tab.field} tab={tab} />
    ))}
  </div>
);

export default SweetheartListHeaderTabs;
