import { tagsAudiences } from 'constants/tags';

const getSearchSuggestions = ({ searchQuery = '' }) => {
  if (!searchQuery.trim()) return [];

  const searchQueryLowerCase = searchQuery.toLowerCase();
  const filteredSuggestions = tagsAudiences.filter(({ name }) =>
    name.toLowerCase().includes(searchQueryLowerCase)
  );

  return filteredSuggestions;
};

export default getSearchSuggestions;
