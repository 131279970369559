import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import Callout from 'components/Callout/Callout';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';

const InboxMessageResponseFormSendPreviewModal = ({
  onSendPreview = noop,
  onCancel = noop,
  loading = false,
}) => (
  <Modal open widthClass="max-w-xl">
    <Modal.Close
      srMessage="Close Send Preview modal"
      testId="inbox-sendPreviewModal-close"
      onClose={onCancel}
    />
    <Modal.Title>Send Preview</Modal.Title>
    <Modal.Description>
      <div className="flex flex-col gap-2 items-center">
        <p className="max-w-prose text-left">
          Send a preview of your message to your email address to see how it
          will look to the journalist.
        </p>
        <Callout type="info" wrapperClassName="w-full !mt-0">
          The preview will not be sent to the journalist.
        </Callout>
      </div>
    </Modal.Description>

    <Modal.RightButtons>
      <Button type="secondary" onClick={onCancel}>
        Cancel
      </Button>

      <Button
        disabled={loading}
        onClick={onSendPreview}
        type="primary"
        className="group relative"
      >
        <span className="group-disabled:opacity-30 transition-opacity">
          Send preview
        </span>

        <span className="group-disabled:block hidden text-teal-500">
          <Spinner color="text-white" />
        </span>
      </Button>
    </Modal.RightButtons>
  </Modal>
);

InboxMessageResponseFormSendPreviewModal.propTypes = {
  onSendPreview: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
};

export default InboxMessageResponseFormSendPreviewModal;
