const announcementTypes = [
  {
    id: 'funding-announcement',
    name: 'Funding Announcement',
    color: 'blue-100',
    bgColor: 'blue-100',
    textColor: 'blue-500',
    borderColor: 'blue-400',
  },
  {
    id: 'partnership-announcement',
    name: 'Partnership Announcement',
    color: 'indigo-100',
    bgColor: 'indigo-100',
    textColor: 'indigo-500',
    borderColor: 'indigo-400',
  },
  {
    id: 'product-announcement',
    name: 'Product Announcement',
    color: 'pink-100',
    bgColor: 'pink-100',
    textColor: 'pink-500',
    borderColor: 'pink-400',
  },
  {
    id: 'hiring-announcement',
    name: 'Hiring Announcement',
    color: 'fuchsia-100',
    bgColor: 'fuchsia-100',
    textColor: 'fuchsia-500',
    borderColor: 'fuchsia-400',
  },
  {
    id: 'market-announcement',
    name: 'Market Announcement',
    color: 'lime-100',
    bgColor: 'lime-100',
    textColor: 'lime-500',
    borderColor: 'lime-400',
  },
  {
    id: 'ma-announcement',
    name: 'M&A Announcement',
    color: 'teal-100',
    bgColor: 'teal-100',
    textColor: 'teal-500',
    borderColor: 'teal-400',
  },
  {
    id: 'brand-announcement',
    name: 'Brand Announcement',
    color: 'amber-100',
    bgColor: 'amber-100',
    textColor: 'amber-500',
    borderColor: 'amber-400',
  },
  {
    id: 'ipo-announcement',
    name: 'IPO Announcement',
    color: 'orange-100',
    bgColor: 'orange-100',
    textColor: 'orange-500',
    borderColor: 'orange-400',
  },
];

export const getAnnouncementType = (id) =>
  announcementTypes.find((tag) => tag.id === id) || null;

export default announcementTypes;
