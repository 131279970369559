import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { debounce, noop } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import Label from 'components/form/Label';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';

import useCustomResourcesSearchSuggestions from '../hooks/useCustomResourcesSearchSuggestions';

const ContentManagementResponseFormResources = ({
  resources = [],
  onChange = noop,
}) => {
  const { companyData } = useCompanyData();
  const { data: customResources } = useAPIRequest({
    service: 'MOD',
    endpoint: `/resources/${companyData.uid}/custom/list`,
    method: 'GET',
    initialData: [],
  });

  const [searchString, setSearchString] = useState('');
  const [suggestions] = useCustomResourcesSearchSuggestions(
    searchString,
    customResources
  );

  const debouncedOnChange = useMemo(
    () => debounce(setSearchString, 250),
    [setSearchString]
  );

  return (
    <div>
      <Label htmlFor="resources">Resources</Label>
      <small className="mt-1 text-gray-400 text-xs">
        {`We'll make sure the generated content uses these resources to enhance
        the results.`}
      </small>
      <div className="flex max-w-md mt-1 space-x-2 md:max-w-2xl">
        <InputFieldTagsAutocomplete
          placeholder="Add resources"
          customFieldName="resource"
          suggestions={suggestions}
          onInput={debouncedOnChange}
          tags={resources}
          onAddition={async (tag) => {
            const tagName = tag.name;
            const filePath = tag.id;

            if (!tagName || resources.some((r) => r.filePath === filePath)) {
              return;
            }

            const customResource = customResources.find(
              (r) => r.file.path === filePath
            );

            if (!customResource) {
              return;
            }

            onChange([
              ...resources,
              {
                id: uuidv4(),
                name: customResource.title,
                downloadUrl: customResource.file.downloadUrl,
                filePath: customResource.file.path,
              },
            ]);
          }}
          onDelete={(tag) => {
            onChange(resources.filter((r) => r.id !== tag.id));
          }}
        />
      </div>
    </div>
  );
};

ContentManagementResponseFormResources.propTypes = {
  resources: PropTypes.array,
  onChange: PropTypes.func,
};

export default ContentManagementResponseFormResources;
