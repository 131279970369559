import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import defaultLanguages from 'constants/defaultLanguages';
import { REGEX_EMAIL, REGEX_URL } from 'constants/regex';

const UPLOAD_FOLDER = 'journalistImages';

const AdminJournalistsEditJournalistModal = ({
  open = false,
  onClose = noop,
  onSubmit = noop,
  currentJournalist = null,
}) => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: currentJournalist
      ? {
          ...currentJournalist,
          authorUrl: currentJournalist?.authorUrl || '',
          linkedInHandle: currentJournalist?.linkedInHandle || '',
          twitterHandle: currentJournalist?.twitterHandle || '',
          defaultLanguage: currentJournalist?.defaultLanguage || '',
          publicationName: currentJournalist.publication?.name,
          jobTitle: currentJournalist.jobTitle,
          image: currentJournalist.image
            ? {
                path: currentJournalist.image,
              }
            : null,
        }
      : {
          name: '',
          image: null,
        },
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const defaultLanguage = watch('defaultLanguage');

  return (
    <Modal open={open}>
      <Modal.Title>
        {currentJournalist ? 'Edit Journalist' : 'Add Journalist'}
      </Modal.Title>
      <Modal.Content>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="journalistForm"
          className="flex flex-col gap-2"
        >
          <div className="w-full flex flex-row gap-2">
            <InputFieldText
              {...register(
                'name',
                currentJournalist
                  ? {}
                  : {
                      validate: (value) =>
                        (value || '').trim().split(' ').length >= 2 ||
                        'First and Last name are required',
                    }
              )}
              errors={errors}
              disabled={!!currentJournalist}
            >
              <InputFieldText.Label>Name</InputFieldText.Label>
            </InputFieldText>

            <div className="w-full flex flex-col gap-2">
              <Label className="block text-gray-700">Language</Label>
              <InputFieldDropdown
                options={defaultLanguages}
                value={defaultLanguage}
                onChange={(value) => setValue('defaultLanguage', value)}
                buttonClassName="w-full"
                placeholder="Select a language"
              />
            </div>
          </div>

          <InputFieldText {...register('jobTitle')} errors={errors}>
            <InputFieldText.Label>Job Title</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('publicationName', {
              required: 'Publication is required',
            })}
            errors={errors}
          >
            <InputFieldText.Label>Publication</InputFieldText.Label>
          </InputFieldText>
          {!!currentJournalist && (
            <p className="text-xs text-gray-400">
              Changing the publication will create a new journalist
            </p>
          )}
          <InputFieldText
            {...register('email', {
              pattern: {
                value: REGEX_EMAIL,
                message: 'Please enter a valid email address',
              },
            })}
            errors={errors}
          >
            <InputFieldText.Label>Email</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('authorUrl', {
              pattern: {
                value: REGEX_URL,
                message: 'Please enter a valid URL',
              },
            })}
            maxLength={2000}
            errors={errors}
          >
            <InputFieldText.Label>Author website</InputFieldText.Label>
          </InputFieldText>
          <div className="flex flex-col gap-4 sm:flex-row">
            <InputFieldText
              {...register('linkedInHandle')}
              errors={errors}
              containerClassName="basis-3/5"
              placeholder="reidhoffman"
            >
              <InputFieldText.Prefix>linkedin.com/in/</InputFieldText.Prefix>
              <InputFieldText.Label>LinkedIn URL</InputFieldText.Label>
            </InputFieldText>

            <InputFieldText
              {...register('twitterHandle')}
              errors={errors}
              containerClassName="basis-2/5"
              placeholder="jackdorsey"
            >
              <InputFieldText.Prefix>@</InputFieldText.Prefix>
              <InputFieldText.Label>Twitter handle</InputFieldText.Label>
            </InputFieldText>
          </div>
          <InputFieldUpload name="image" folder={UPLOAD_FOLDER} form={form}>
            <InputFieldUpload.Label>Image</InputFieldUpload.Label>
          </InputFieldUpload>
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button submit form="journalistForm">
          Update Journalist
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminJournalistsEditJournalistModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentJournalist: PropTypes.object,
};

export default AdminJournalistsEditJournalistModal;
