import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import useCreateSetState from '../hooks/useCreateSetState';
import useQueryParams from '../hooks/useQueryParams';

import OpportunitiesListBulkActions from './OpportunitiesListBulkActions';
import OpportunitiesListEmptyState from './OpportunitiesListEmptyState';
import OpportunitiesListErrorState from './OpportunitiesListErrorState';
import OpportunitiesListOpportunity from './OpportunitiesListOpportunity/OpportunitiesListOpportunity';
import OpportunitiesListResponseModal from './OpportunitiesListResponseModal/OpportunitiesListResponseModal';

const getOpportunityIdFromThread = (thread) =>
  thread.reference ? thread.reference.split('/').reverse()[0] : null;

const OpportunitiesList = ({
  threads = [],
  opportunities = [],
  error = false,
  companyId = '',
}) => {
  const { showDeclined, id } = useQueryParams();
  const setId = useCreateSetState('id');
  const showOpportunityModal = id && opportunities.some((opp) => opp.id === id);

  const currentOpportunities = showDeclined
    ? opportunities.filter((opp) => opp.declinedCompanies.includes(companyId))
    : opportunities.filter((opp) => !opp.declinedCompanies.includes(companyId));

  const index = currentOpportunities.findIndex((opp) => opp.id === id);
  const [currentOpportunityIndex, setCurrentOpportunityIndex] = useState(index);

  const [selectedOpportunitiesIds, setSelectedOpportunitiesIds] = useState([]);
  const selectedOpportunities = useMemo(
    () =>
      currentOpportunities.filter((o) =>
        selectedOpportunitiesIds.includes(o.id)
      ),
    [currentOpportunities, selectedOpportunitiesIds]
  );

  return (
    <>
      <div className="mt-6 relative grow" data-test-id="opportunitiesList">
        {!error && currentOpportunities.length === 0 && (
          <OpportunitiesListEmptyState showDeclined={showDeclined} />
        )}

        {error && <OpportunitiesListErrorState />}

        {currentOpportunities.map((opportunity, idx) => (
          <OpportunitiesListOpportunity
            thread={(threads.threads || []).find(
              (thread) => getOpportunityIdFromThread(thread) === opportunity.id
            )}
            key={opportunity.id}
            opportunity={opportunity}
            onExpand={() => {
              setId(opportunity.id);
              setCurrentOpportunityIndex(idx);
            }}
            onSelect={() =>
              setSelectedOpportunitiesIds((arr) =>
                selectedOpportunitiesIds.includes(opportunity.id)
                  ? arr.filter((o) => o !== opportunity.id)
                  : [...arr, opportunity.id]
              )
            }
            isSelected={selectedOpportunitiesIds.includes(opportunity.id)}
          />
        ))}
      </div>
      {selectedOpportunitiesIds.length ? (
        <OpportunitiesListBulkActions
          selectedOpportunities={selectedOpportunities}
          setSelectedOpportunities={setSelectedOpportunitiesIds}
        />
      ) : null}

      {showOpportunityModal ? (
        <OpportunitiesListResponseModal
          open={showOpportunityModal}
          thread={(threads.threads || []).find(
            (thread) =>
              thread.reference ===
              currentOpportunities[currentOpportunityIndex]?.id
          )}
          opportunity={currentOpportunities[currentOpportunityIndex]}
          next={() =>
            setCurrentOpportunityIndex((prev) => {
              const next = (prev + 1) % opportunities.length;
              setId(opportunities[next].id);
              return next;
            })
          }
          prev={() =>
            setCurrentOpportunityIndex((prev) => {
              const next = (prev - 1) % opportunities.length;
              setId(opportunities[next].id);
              return next;
            })
          }
          currentCount={currentOpportunityIndex}
          totalCount={currentOpportunities.length}
          onClose={() => setId('')}
        />
      ) : null}
    </>
  );
};

OpportunitiesList.propTypes = {
  opportunities: PropTypes.object.isRequired,
  error: PropTypes.bool,
  threads: PropTypes.array,
  companyId: PropTypes.string,
};

export default OpportunitiesList;
