import React from 'react';

import SwiperCore, { Navigation } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import ContentManagementNavbarTabs from './ContentManagementNavbarTabs';

SwiperCore.use([Navigation]);

const ContentManagementNavbar = () => (
  <div className="sticky top-0 z-50 -mx-6 -mt-6 mb-8">
    <div className="w-full flex bg-white shadow-sm text-gray-300 border-b border-gray-200">
      <ContentManagementNavbarTabs />
    </div>
  </div>
);

export default ContentManagementNavbar;
