/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';
import { Link, generatePath } from 'react-router-dom';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useJournalistThreadActivity from 'hooks/useJournalistThreadActivity';

import SweetheartListJournalistAdd from './SweetheartListJournalistAdd';
import SweetheartListJournalistContacted from './SweetheartListJournalistContacted';
import SweetheartListJournalistDelete from './SweetheartListJournalistDelete';
import SweetheartListJournalistProfile from './SweetheartListJournalistProfile';
import SweetheartListJournalistReachOut from './SweetheartListJournalistReachOut';

const SweetheartListSearchJournalist = ({
  journalist = {},
  onClick = noop,
  hideColumns = [],
  isSelected = false,
  handleSelected = noop,
}) => {
  const { companyData } = useCompanyData();
  const currentJournalistThreads = useJournalistThreadActivity(journalist.id);

  const mostRecentActiveOrDraftThread =
    useMemo(
      () => currentJournalistThreads.find((thread) => !thread.isArchived),
      [currentJournalistThreads]
    ) ?? {};

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const tier = journalist.publication?.tier
    ? journalist.publication?.domainAuthority > 90
      ? 'National'
      : `Tier ${journalist.publication?.tier}`
    : 'n/a';

  return (
    <tr
      className={classNames(' cursor-pointer', {
        'bg-teal-50': isSelected,
        'group odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50 even:hover:bg-gray-100':
          !isSelected,
      })}
      data-test-id="sweetheartList-table-row"
      onClick={onClick}
    >
      <td
        className={classNames(
          'z-10 sticky left-0  px-4 py-2 whitespace-nowrap',
          {
            'bg-teal-50': isSelected,
            'group-odd:bg-white group-even:bg-gray-50 group-odd:group-hover:bg-gray-50 group-even:group-hover:bg-gray-100':
              !isSelected,
          }
        )}
      >
        <input
          checked={isSelected}
          onClick={(e) => e.stopPropagation()}
          onChange={handleSelected}
          type="checkbox"
          className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded cursor-pointer"
        />
      </td>
      <td className="px-4 py-2">
        <SweetheartListJournalistProfile
          name={journalist?.name}
          url={journalist?.image}
          hasStats={
            journalist.hasStats || !!journalist?.journalistAnalyticsIngest
          }
          ImageComponent={JournalistImage}
          testId="sweetheartList-table-row-journalist"
        />
      </td>
      <td className="px-4 py-2">{journalist?.jobTitle || 'n/a'}</td>
      <td className="px-4 py-2">
        <SweetheartListJournalistProfile
          name={journalist?.publication?.name}
          url={journalist?.publication?.image}
          ImageComponent={PublicationImage}
          testId="sweetheartList-table-row-publication"
        />
      </td>
      <td className="px-4 py-2 text-center">
        <div className="bg-white px-4 py-2 rounded-full border border-gray-200 flex items-center justify-center whitespace-nowrap text-teal-500">
          {tier}
        </div>
      </td>
      <td className="px-4 py-2 text-center">
        <DomainAuthorityPill
          domainAuthority={journalist?.publication?.domainAuthority}
        />
      </td>

      {!hideColumns.includes('activity') ? (
        <td className="px-4 py-2 text-left">
          {mostRecentActiveOrDraftThread.id ? (
            <Link
              key={mostRecentActiveOrDraftThread.id}
              onClick={handleClick}
              to={generatePath(MESSAGES_PATH_FULL, {
                id: companyData.slug,
                threadId: mostRecentActiveOrDraftThread.id,
              })}
              className="cursor-pointer block"
            >
              <SweetheartListJournalistContacted
                journalist={journalist}
                isActiveConversation={
                  mostRecentActiveOrDraftThread.isActiveConversation
                }
                date={
                  new Date(mostRecentActiveOrDraftThread.dateCreated)
                    .toISOString()
                    .split('T')[0]
                }
              />
            </Link>
          ) : null}
        </td>
      ) : null}

      {!hideColumns.includes('reachout') ? (
        <td className="px-4 py-2 text-center">
          <SweetheartListJournalistReachOut journalist={journalist} />
        </td>
      ) : null}

      {!hideColumns.includes('delete') ? (
        <td className="px-4 py-2 text-center">
          <SweetheartListJournalistDelete journalistId={journalist.id} />
        </td>
      ) : null}

      {!hideColumns.includes('add') ? (
        <td className="px-4 py-2 text-center">
          <SweetheartListJournalistAdd journalistId={journalist.id} />
        </td>
      ) : null}
    </tr>
  );
};

SweetheartListSearchJournalist.propTypes = {
  isSelected: PropTypes.bool,
  handleSelected: PropTypes.func,
  journalist: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  hideColumns: PropTypes.array,
};

export default SweetheartListSearchJournalist;
