import PropTypes from 'prop-types';
import React from 'react';

import { noop } from 'lodash';

import Button from 'components/buttons/Button';
import { getAnnouncementType } from 'constants/announcementTypes';

import useGetAnnouncement from './hooks/useGetAnnouncement';

const AnnouncementOverview = ({ setIsAnnouncementModalOpen = noop }) => {
  const { data: announcement } = useGetAnnouncement();

  const announcementType = getAnnouncementType(
    announcement?.document?.type
  )?.name;

  return (
    <div className="bg-white shadow overflow-hidden rounded-md ">
      <div className="p-4 border-b">
        <h2 className="text-xl font-medium">Overview</h2>
      </div>
      <div className="p-4">
        <div className="flex items-center justify-between border rounded-md p-2">
          <div className="flex-shrink-0">
            <span className="font-medium whitespace-nowrap">Announcement:</span>
          </div>
          <div className="ml-2 flex-grow truncate">
            {new Date(announcement.document.lastEdited).toLocaleDateString(
              'en-GB',
              {
                timeZone: 'CET',
                day: '2-digit',
                month: 'long',
                year: 'numeric',
              }
            )}
          </div>
          <Button
            onClick={() => setIsAnnouncementModalOpen(true)}
            className="ml-4 flex-shrink-0"
          >
            Edit
          </Button>
        </div>
        <div className="flex items-center justify-between border rounded-md p-2">
          <div className="flex-shrink-0">
            <span className="font-medium whitespace-nowrap">News Type:</span>
          </div>
          <div className="ml-2 flex-grow truncate">{announcementType}</div>
          <Button
            onClick={() => setIsAnnouncementModalOpen(true)}
            className="ml-4 flex-shrink-0"
          >
            Edit
          </Button>
        </div>
      </div>
    </div>
  );
};

AnnouncementOverview.propTypes = {
  setIsAnnouncementModalOpen: PropTypes.func,
};

export default AnnouncementOverview;
