import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Button from 'components/buttons/Button';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useJournalistLists from 'contexts/SweetheartListContext/hooks/useJournalistLists';

import AnnouncementAttachListModal from './AnnouncementAttachListModal';
import useUpsertAnnouncement from './hooks/useUpsertAnnouncement';

const AnnouncementAttachJournalistList = ({ announcement = {} }) => {
  const { companyData } = useCompanyData();
  const { mutateAsync: upsertAnnouncement } = useUpsertAnnouncement();
  const [isAttachListModalOpen, setIsAttachListModalOpen] = useState(false);
  const { journalistLists, isLoadingJournalistLists } = useJournalistLists(
    companyData?.uid
  );

  const handleSelectAttachList = async (list) => {
    const updatedList = [...(announcement.journalistLists || []), { ...list }];
    await upsertAnnouncement({
      ...announcement,
      journalistLists: updatedList.map((item) => item.id),
    });

    setIsAttachListModalOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => {
          setIsAttachListModalOpen(true);
        }}
        className="w-full"
      >
        + Attach List
      </Button>
      {isAttachListModalOpen && (
        <AnnouncementAttachListModal
          open={isAttachListModalOpen}
          onClose={() => setIsAttachListModalOpen(false)}
          onSelect={handleSelectAttachList}
          journalistLists={journalistLists.map((list) => ({
            ...list,
            isListAlreadySelected: announcement.journalistLists.some(
              (selectedList) => selectedList.id === list.id
            ),
          }))}
          isJournalistListsLoading={isLoadingJournalistLists}
        />
      )}
    </>
  );
};

AnnouncementAttachJournalistList.propTypes = {
  announcement: PropTypes.object,
};

export default AnnouncementAttachJournalistList;
