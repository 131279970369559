import React from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import PageWrapper from 'components/PageWrapper';
import settingsTabs from 'constants/settingsTabs';
import getPageTitle from 'utils/getPageTitle';

import BillingSettings from './BillingSettings/BillingSettings';
import CompanySettings from './CompanySettings/CompanySettings';
import SettingsNavbar from './SettingsNavbar/SettingsNavbar';
import UserSettings from './UserSettings/UserSettings';

const SettingsPage = () => {
  const { tab } = useParams();
  const { title } = settingsTabs.find((t) => t.field === tab);

  return (
    <PageWrapper>
      <Helmet>
        <title>{getPageTitle(`${title} settings`)}</title>
      </Helmet>
      <SettingsNavbar />

      <UserSettings />
      <CompanySettings />
      <BillingSettings />
    </PageWrapper>
  );
};

export default SettingsPage;
