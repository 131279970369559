import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const MOD_URL = process.env.REACT_APP_MOD_URL;

const useAdminOpportunities = () => {
  const auth = useAuth();
  return useQuery(['opportunities', 'admin'], async () => {
    const jwt = await auth.currentUser?.getIdToken();
    const response = await fetch(`${MOD_URL}/opportunities/admin`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (!response?.ok) {
      throw new Error('Failed fetching opportunities');
    }
    return response.json();
  });
};

export default useAdminOpportunities;
