import { useEffect } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from 'reactfire';

import useThreadsSearchParams from './useThreadsSearchParams';

const sendScheduledDraft = async ({ threadId = '', dateSendAfter, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/threads/${threadId}/draft/schedule`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      ...(dateSendAfter ? { body: JSON.stringify({ dateSendAfter }) } : {}),
    });

    const responseData = await response.json();

    if (responseData.errors || responseData.errors?.length) {
      return responseData;
    }

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to schedule message: ${error.message}`);
  }
};

const useScheduleDraft = (companyId, setSendMessageErrors) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { threadId } = useParams();
  const [searchParams] = useThreadsSearchParams();
  const { mutateAsync } = useMutation({
    mutationFn: (dateSendAfter) =>
      sendScheduledDraft({ threadId, dateSendAfter, auth }),
    onSettled: (data) => {
      if (data?.errors || data?.errors?.length) {
        setSendMessageErrors(data.errors);
        return;
      }

      setSendMessageErrors(null);
      queryClient.invalidateQueries(['draft', threadId]);
      queryClient.invalidateQueries(['threadDetails', threadId]);
      queryClient.invalidateQueries([
        'companyThreads',
        companyId,
        searchParams.toString(),
      ]);
    },
  });

  useEffect(() => setSendMessageErrors(null), [setSendMessageErrors, threadId]);

  return mutateAsync;
};

export default useScheduleDraft;
