import { ANNOUNCEMENT_PATH } from 'constants/paths';

import AnnouncementPage from './AnnouncementPage';

const AnnouncementRoute = {
  path: ANNOUNCEMENT_PATH,
  authRequired: true,
  component: AnnouncementPage,
};

export default AnnouncementRoute;
