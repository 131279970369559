/* eslint-disable max-params */
import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const fetchCompanyTasks = async ({ uid, auth }) => {
  const BASE_URL = process.env.REACT_APP_UCD_URL;
  const endpoint = `/companies/${uid}/tasks`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch tasks: ${error.message}`);
  }
};

const useTasks = (uid = '') => {
  const auth = useAuth();

  return useQuery({
    queryKey: ['companyTasks', uid],
    queryFn: () => fetchCompanyTasks({ uid, auth }),
    enabled: !!uid,
  });
};

export default useTasks;
