import { useMutation } from 'react-query';
import { useAuth } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';

const updateDocument = async ({ companyId, document, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/documents/${companyId}`;

  const jwt = await auth.currentUser?.getIdToken();

  const response = await fetch(`${BASE_URL}${endpoint}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(document),
  });

  if (!response.ok) {
    throw new Error('API call failed');
  }
};

const useUpdateDocument = (companyId) => {
  const auth = useAuth();
  const { showError } = useNotifications();
  return useMutation({
    mutationFn: (document) => updateDocument({ companyId, document, auth }),
    onError: () =>
      showError({
        message: 'There was an error',
      }),
  });
};

export default useUpdateDocument;
