import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import noop from 'lodash/noop';
import { Controller } from 'react-hook-form';

import Label from 'components/form/Label';
import InputFieldTextDocumentEditor from 'components/form/inputFields/InputFieldTextDocumentEditor';
import useAddSavedDraftNotification from 'hooks/useAddSavedDraftNotification';

import ContentManagementDocumentFormTitle from './ContentManagementDocuments/ContentManagementDocumentFormTitle';
import DocumentTag from './ContentManagementDocuments/DocumentTag';
import useCurrentDocumentContext from './hooks/useCurrentDocumentContext';

const ContentDocumentDefault = ({
  control,
  reset = noop,
  messageError = [],
  saveDocumentDebounced = noop,
}) => {
  const containerRef = useRef();
  const { currentDocument } = useCurrentDocumentContext();

  const editorRef = useRef(null);
  const addSavedDraftNotification = useAddSavedDraftNotification(containerRef);
  const [editorContent] = useState(currentDocument?.draft?.content || '');

  useEffect(() => {
    if (currentDocument) {
      reset({
        document: {
          title: currentDocument?.title || '',
          type: currentDocument?.type || '',
          draft: currentDocument?.draft || {
            content: '',
            plainText: '',
          },
        },
      });
    }
  }, [currentDocument, reset]);

  return (
    <div className="relative">
      <div
        data-test-id="contentManagement-responseEditor"
        className="relative flex flex-col gap-6"
        ref={containerRef}
      >
        <div>
          <DocumentTag type={currentDocument.type} />
        </div>
        <Controller
          name="document"
          control={control}
          defaultValue={{
            title: currentDocument?.title || '',
            type: currentDocument?.type || '',
            draft: currentDocument?.draft?.content || {
              content: '',
              plainText: '',
            },
          }}
          rules={{
            validate: {
              requiredTitle: (value) =>
                value.title !== '' || 'Please enter a title',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <ContentManagementDocumentFormTitle
                value={value.title}
                control={control}
                messageError={{ title: messageError.document }}
                onChange={(e) => {
                  onChange({ ...value, title: e.target.value });
                  saveDocumentDebounced(
                    {
                      title: e.target.value,
                      type: currentDocument?.type,
                      draft: currentDocument?.draft,
                    },
                    addSavedDraftNotification
                  );
                }}
              />

              <div>
                <Label htmlFor="draft" className="mb-2">
                  Content
                </Label>

                <InputFieldTextDocumentEditor
                  name="draft"
                  editorRef={editorRef}
                  initialValue={editorContent}
                  onChange={(content, plainText) => {
                    saveDocumentDebounced(
                      {
                        draft: { content, plainText },
                      },
                      addSavedDraftNotification
                    );
                  }}
                  testId="contentManagement-responseFormTextInput"
                  id="contentManagement-responseFormTextInput"
                />
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};

ContentDocumentDefault.propTypes = {
  control: PropTypes.object,
  messageError: PropTypes.object,
  saveDocumentDebounced: PropTypes.func,
  reset: PropTypes.func,
};

export default ContentDocumentDefault;
