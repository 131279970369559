import PropTypes from 'prop-types';
import React from 'react';

import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import ReactDOMServer from 'react-dom/server';
import { Controller, useForm } from 'react-hook-form';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldFounders from 'components/form/inputFields/InputFieldFounders/InputFieldFounders';
import InputFieldTagsList from 'components/form/inputFields/InputFieldTagsList/InputFieldTagsList';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import { PAGE_TITLE } from 'constants/meta';
import { tagsInsights } from 'constants/tags';

const CompanyInsightEditModal = ({
  open,
  insight = null,
  founders = [],
  onClose,
  onSubmit,
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      question: insight?.question || '',
      answer: insight?.answer || '',
      tag: insight?.tag || tagsInsights[0],
      founder: insight?.founder || {},
    },
  });

  const formName = 'company-insights-editModal';

  return (
    <Modal open={open}>
      <Modal.Close srMessage="Close Insight edit modal" onClose={onClose} />
      <Modal.Icon Icon={QuestionMarkCircleIcon} />
      <Modal.Title>{insight ? 'Edit' : 'Add a'} company insight</Modal.Title>
      <Modal.Description>
        These help journalists understand where your company fits into the
        startup ecosystem.
      </Modal.Description>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} id={formName}>
          <InputFieldText
            {...register('question', {
              required: 'Please put in a question',
            })}
            errors={errors}
            placeholder="Please type a question here..."
            testId="company-insights-editModal-questionInput"
          >
            <InputFieldText.Label>Question*</InputFieldText.Label>
          </InputFieldText>

          <div className="flex items-center mt-3">
            <Label htmlFor="date">Answer*</Label>

            <Tooltip
              dataHTML
              id="tooltip-insights-answer"
              html={ReactDOMServer.renderToString(
                <p className="pointer-events-auto">
                  Answer for insights should ideally be 4-6 sentences long. If
                  you&apos;d like to see an example, you can view{' '}
                  <a
                    className="underline"
                    href="https://app.mvpr.io/company/mvpr#insights"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {PAGE_TITLE}&apos;s Insight answers here
                  </a>
                </p>
              )}
            />
          </div>

          <InputfieldTextArea
            {...register('answer', {
              required: 'Please put in an answer',
            })}
            placeholder="Please type your answer here..."
            data-test-id="company-insights-editModal-answerInput"
          />

          {insight?.question?.includes('competitor') && (
            <p className="mt-2 text-xs text-gray-400">
              E.g.: Acme Studios (acme-studios.com), Alpha Studios
              (alphastudios.com),...
            </p>
          )}

          {errors.answer && (
            <ErrorMessage testId="company-insights-editModal-answerInput-error">
              {errors.answer.message}
            </ErrorMessage>
          )}

          <div className="flex justify-between items-baseline mt-3">
            <Label htmlFor="date">Tag</Label>
          </div>

          <Controller
            name="tag"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputFieldTagsList
                tags={tagsInsights}
                onChange={onChange}
                value={value}
                testId="company-insights-editModal-tagsInput"
              />
            )}
          />

          <div className="mt-3">
            <Label htmlFor="users">Related team members</Label>
          </div>

          <div className="mt-2">
            <Controller
              name="founder"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputFieldFounders
                  founders={founders}
                  onChange={onChange}
                  value={value}
                  testId="company-insights-editModal-founderInput"
                />
              )}
            />
          </div>

          {errors.founder && (
            <ErrorMessage>{errors.founder.message}</ErrorMessage>
          )}
        </form>
        <ReactTooltip
          id="tooltip-insights-answer"
          className="max-w-xs text-sm"
          delayHide={1500}
        />
      </Modal.Content>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-insights-editModal-cancel"
        >
          Cancel
        </Button>

        <Button
          submit
          form={formName}
          disabled={!isValid}
          eventType="pressPage_update"
          eventProperties={{ action: 'insight_add' }}
          data-test-id="company-insights-editModal-submit"
        >
          {insight?.answer ? 'Update' : 'Add'} answer
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyInsightEditModal.propTypes = {
  open: PropTypes.bool,
  insight: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string,
  }),
  founders: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyInsightEditModal;
