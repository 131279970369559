import PropTypes from 'prop-types';
import React from 'react';

import { NewspaperIcon } from '@heroicons/react/20/solid';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

const JournalistDetailsModalLastStories = ({ lastStories = [] }) => (
  <div className="flex flex-col gap-2">
    {lastStories.map((story) => (
      <a
        key={story.title}
        href={story.url}
        rel="noreferrer"
        target="_blank"
        className="italic w-fit bg-gray-100 px-3 py-1.5 rounded-md shadow-sm text-sm flex gap-2 items-start"
      >
        <NewspaperIcon className="w-5 h-5 text-teal-500 flex-shrink-0" />
        <span>&quot;{story.title}&quot;</span>
        {story.url && (
          <ArrowTopRightOnSquareIcon className="w-5 h-5 flex-shrink-0" />
        )}
      </a>
    ))}
  </div>
);

JournalistDetailsModalLastStories.propTypes = {
  lastStories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
};

export default JournalistDetailsModalLastStories;
