import { useCallback } from 'react';

import { useParams } from 'react-router-dom';

import useAPIRequest from 'hooks/useAPIRequest';

const useRemoveAttachment = ({ revalidateDraft } = {}) => {
  const { threadId } = useParams();
  const { fetchData: removeAttachment } = useAPIRequest({
    service: 'MOD',
    endpoint: `/threads/${threadId}/draft/attachment`,
    method: 'DELETE',
  });

  return useCallback(
    async (path = '') => {
      await removeAttachment({
        body: { path },
      });

      await revalidateDraft();
    },
    [removeAttachment, revalidateDraft]
  );
};

export default useRemoveAttachment;
