import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

// Wrapper component that adds consistent padding around pages
const PageWrapper = ({ children = null, className = 'p-6' }) => {
  const { hasBasicCompanyAccess } = useCompanyData();

  return (
    <main
      className={classNames('bg-gray-5', className, {
        'ml-0 lg:ml-64': hasBasicCompanyAccess,
      })}
    >
      {children}
    </main>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default PageWrapper;
