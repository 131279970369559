import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { doc } from 'firebase/firestore';
import findIndex from 'lodash/findIndex';
import { useFirestore } from 'reactfire';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import SectionHeadline from 'components/company/SectionHeadline';
import TagList from 'components/tags/TagList';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';
import { coreAudiencesForUser } from 'constants/tags';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';

import CompanyMetaAudiencesModal from './CompanyMetaAudiencesModal';

const CompanyMetaAudiences = ({ showOwnerView }) => {
  const firestore = useFirestore();
  const { companyData } = useCompanyData();
  const companyId = companyData?.uid;
  const companyRef = companyId
    ? doc(firestore, COMPANIES_COLLECTION, companyId)
    : undefined;

  const { fetchData, data: audiences } = useAPIRequest({
    endpoint: `/company/${companyData?.uid}/audiences`,
    service: 'UCD',
    initialData: [],
  });

  const { fetchData: updateAudiences } = useAPIRequest({
    endpoint: `/company/${companyData?.uid}/audiences`,
    service: 'UCD',
    method: 'PUT',
  });

  const [isEdiAudienceModalOpen, setIsEditAudienceModalOpen] = useState(false);

  const onSubmit = async (newCompanyData = {}) => {
    if (!companyRef) {
      return;
    }

    await updateAudiences({ body: newCompanyData });
    await fetchData();

    setIsEditAudienceModalOpen(false);
  };

  return (
    <div className="pt-5 pb-5 mx-6 border-t border-b border-gray-100">
      <div className="flex justify-between items-center">
        <SectionHeadline>Audiences</SectionHeadline>
        {showOwnerView && (
          <DotsMenu
            options={[
              {
                label: 'Edit audiences',
                onClick: () => setIsEditAudienceModalOpen(true),
                testId: 'company-meta-topics-menu-edit',
              },
            ]}
            theme="medium"
            testId="company-meta-topics-menu"
          />
        )}
      </div>
      {audiences.length ? (
        <TagList
          className="mt-5"
          tags={(audiences || []).map((audience) => ({
            text: audience.name,
            colorIndex: findIndex(
              coreAudiencesForUser,
              (t) => t.id === audience.id
            ),
            id: audience.id,
          }))}
        />
      ) : (
        <p
          className="mt-3 text-gray-300 text-sm"
          data-test-id="company-meta-founders-text"
        >
          No audiences added yet.
        </p>
      )}

      {isEdiAudienceModalOpen && (
        <CompanyMetaAudiencesModal
          audiences={audiences}
          open={isEdiAudienceModalOpen}
          onClose={() => setIsEditAudienceModalOpen(false)}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

CompanyMetaAudiences.propTypes = {
  showOwnerView: PropTypes.bool,
};

export default CompanyMetaAudiences;
