import React from 'react';

const JournalistPlaceholder = () => (
  <div className="flex flex-row items-center text-sm animate-pulse">
    <div className="relative">
      <div className="w-[48px] h-[48px] rounded-full bg-gray-200" />
    </div>
    <div className="ml-3 flex flex-col min-w-0">
      <div className="block">
        <div className="bg-gray-200 w-40 h-4 rounded mb-2" />
        <div className="bg-gray-200 w-32 h-4 rounded" />
      </div>
    </div>
  </div>
);

export default JournalistPlaceholder;
