import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

const InboxMessageResponseFormEditorAddEditTemplateModal = ({
  onCancel = noop,
  initialValues = {},
}) => {
  const { showError, showSuccess } = useNotifications();
  const { upsertTemplate } = useInboxContext();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      text: '',
      ...initialValues,
    },
    mode: 'onSubmit',
  });

  return (
    <Modal open widthClass="max-w-xl">
      <Modal.Close srMessage="Close edit Modal" onClose={onCancel} />
      <Modal.Title>
        {initialValues.id ? 'Update' : 'Create'} new Template
      </Modal.Title>
      <Modal.Description>
        Create a new template to use in your response.
      </Modal.Description>
      <Modal.Content>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Label htmlFor="press-release-edit-rte">Template name*</Label>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Please provide a template name',
                },
              }}
              render={({ field }) => <InputFieldText {...field} />}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="press-release-edit-rte">Template content*</Label>
            <Controller
              name="text"
              control={control}
              defaultValue={initialValues.text}
              rules={{
                required: {
                  value: true,
                  message: 'Please provide the template content',
                },
              }}
              render={({ field }) => (
                <InputFieldTextEditor
                  initialValue={initialValues.text}
                  onChange={field.onChange}
                  height="320px"
                />
              )}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" className="w-fit" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          className="w-fit relative"
          onClick={handleSubmit((data) => {
            upsertTemplate(data, {
              onSuccess: () => {
                showSuccess({
                  title: `${initialValues.id ? 'Updated' : 'Created'} template`,
                  message: `"${data.name}" template has been ${
                    initialValues.id ? 'updated' : 'created'
                  }.`,
                });
              },
              onError: () => {
                showError({
                  title: `Failed to ${
                    initialValues.id ? 'update' : 'create'
                  } template`,
                  message: 'Please try again later.',
                });
              },
            });
            onCancel();
          })}
        >
          {initialValues.id ? 'Update' : 'Create'} Template
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

InboxMessageResponseFormEditorAddEditTemplateModal.propTypes = {
  submit: PropTypes.func,
  onCancel: PropTypes.func,
  initialValues: PropTypes.object,
};

export default InboxMessageResponseFormEditorAddEditTemplateModal;
