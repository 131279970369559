import PropTypes from 'prop-types';
import React from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { noop } from 'lodash';

const createHandleSort =
  ({ sort, setSort, by }) =>
  () => {
    let direction = 'asc';

    if (sort.by === by) {
      direction = sort.direction === 'asc' ? 'desc' : 'asc';
    }

    setSort({
      by,
      direction,
    });
  };

const AdminCoveragesTableSortableHeader = ({
  children = null,
  by = '',
  sort = {},
  setSort = noop,
}) => (
  <th
    scope="col"
    className={classNames(
      'group px-4 py-2 max-w-sm cursor-pointer text-center',
      {
        'bg-gray-100': sort.by === by,
      }
    )}
    role="button"
    onClick={createHandleSort({ sort, setSort, by })}
  >
    <div className="flex items-center gap-2">
      <span>{children}</span>
      <div className="group-hover:bg-gray-200 rounded-md bg-gray-100">
        {sort.direction === 'asc' ? (
          <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
        ) : (
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </div>
    </div>
  </th>
);

AdminCoveragesTableSortableHeader.propTypes = {
  children: PropTypes.node,
  sort: PropTypes.shape({
    by: PropTypes.string,
    direction: PropTypes.string,
  }),
  setSort: PropTypes.func,
  by: PropTypes.string,
};

export default AdminCoveragesTableSortableHeader;
