import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import noop from 'lodash/noop';
import { Controller } from 'react-hook-form';

import ErrorMessage from 'components/form/ErrorMessage';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';

const ContentQnAResponseFormEditor = ({
  savedAnswer = {},
  control,
  messageError = {},
  saveAnswer = noop,
}) => {
  const { id, title } = savedAnswer;

  const handleValueChange = useCallback(
    (onChange) => (event) => {
      const newValue = event.target.value || '';
      onChange(newValue);
      saveAnswer();
    },
    [saveAnswer]
  );

  return (
    <div className="col-span-full mt-4 bg-white p-10 rounded-md max-w-[780px] mx-auto">
      <label
        htmlFor={`questions.${id}.answer`}
        className="text-md font-bold block font-medium leading-6 text-gray-900"
      >
        {title} *
      </label>
      <div className="mt-2 ml-0">
        <Controller
          name={`questions.${id}.answer`}
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Please put in an answer',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <InputfieldTextArea
              onChange={handleValueChange(onChange)}
              value={value || ''}
              rows="8"
              name={`questions.${id}.answer`}
              placeholder="Please type your answer here..."
              data-test-id="contentManagement-form-answer"
              className="pl-0 border-transparent focus:border-transparent shadow-transparent focus:shadow-none resize-none"
            />
          )}
        />
        {messageError[id] && (
          <ErrorMessage testId="contentManagement-resourceTab-answerInput-error">
            {messageError[id]?.answer?.message}
          </ErrorMessage>
        )}
      </div>
    </div>
  );
};

ContentQnAResponseFormEditor.propTypes = {
  savedAnswer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    draftAnswer: PropTypes.string,
    answer: PropTypes.string,
  }),
  control: PropTypes.object.isRequired,
  messageError: PropTypes.object,
  saveAnswer: PropTypes.func,
};

export default ContentQnAResponseFormEditor;
