import React from 'react';

import classNames from 'classnames';
import {
  useSearchParams,
  Link,
  useNavigate,
  generatePath,
  useParams,
} from 'react-router-dom';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import { MESSAGES_PATH_FULL } from 'constants/paths';

import { useInboxContext } from '../context/InboxContext';

const statuses = [
  { label: 'All', value: 'all' },
  { label: 'Unread', value: 'unread' },
  { label: 'Awaiting action / Drafting', value: 'pending' },
  { label: 'Sent (waiting for response)', value: 'sent' },
  { label: 'Closed', value: 'closed', right: true },
];

const getSearchParams = (searchParams, status) => {
  const newSearchParams = new URLSearchParams(searchParams);
  newSearchParams.set('status', status);
  return newSearchParams.toString();
};

const InboxNavbarThreadStatus = () => {
  const { id } = useParams();
  const { data } = useInboxContext().threads;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const generateLink = (status) => ({
    pathname: generatePath(MESSAGES_PATH_FULL, {
      id,
    }),
    search: getSearchParams(searchParams, status),
  });
  return (
    <nav className="w-full min-[390px]:max-w-xs xl:max-w-full min-w-0 xl:w-full shrink">
      <div className="hidden xl:flex w-full gap-6" aria-label="Tabs">
        {statuses.map((status) => {
          const isActive =
            searchParams.get('status') === status.value ||
            (!searchParams.get('status') && status.value === 'all');

          return (
            <Link
              key={status.value}
              to={generateLink(status.value)}
              type="button"
              className={classNames(
                'flex gap-2 whitespace-nowrap text-sm font-medium',
                {
                  'text-teal-500 ': isActive,
                  'text-gray-500 hover:text-gray-700': !isActive,
                  'ml-auto': status.right,
                }
              )}
            >
              {status.label}
              <span
                className={classNames(
                  'rounded-full px-2.5 py-0.5 text-xs font-medium',
                  {
                    'bg-gray-100 text-gray-900': !isActive,
                    'text-teal-600 bg-teal-100': isActive,
                  }
                )}
              >
                {data?.statusCounts?.[status.value] || 0}
              </span>
            </Link>
          );
        })}
      </div>
      <InputFieldDropdown
        onChange={(newType) => {
          navigate(generateLink(newType));
        }}
        options={statuses}
        value={searchParams.get('status') || 'all'}
        wrapperClassName="w-full xl:hidden"
        buttonClassName="w-full"
        optionsClassName="z-20"
        sameWidthOption
      />
    </nav>
  );
};

export default InboxNavbarThreadStatus;
