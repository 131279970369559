import React from 'react';

import { useParams } from 'react-router-dom';

import { AGENCY_DASHBOARD_PATH } from 'constants/paths';

import AgencyWrapperBreadcrumb from './AgencyWrapperBreadcrumb';
import useAgencyCompany from './hooks/useAgencyCompany';

const AgencyWrapperBreadcrumbs = () => {
  const { companyId, agencyId } = useParams();

  const { data } = useAgencyCompany(agencyId, companyId);

  const pages = [
    {
      name: 'Companies',
      pattern: AGENCY_DASHBOARD_PATH,
    },
    {
      name: data?.company?.name,
      pattern: `${AGENCY_DASHBOARD_PATH}/:companyId`,
    },
  ];

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {pages.map((page, index) => (
          <AgencyWrapperBreadcrumb
            key={page?.name}
            {...page}
            isFirst={index === 0}
          />
        ))}
      </ol>
    </nav>
  );
};

export default AgencyWrapperBreadcrumbs;
