import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { generatePath, Link } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';

import AssistantResourcesQnA from '../ContentManagementQnA/AssistantResourcesQnA';
import ContentQnAResponseFormEditor from '../ContentManagementQnA/ContentQnAResponseFormEditor';
import AutoSaveIndicator from '../helpers/AutoSaveIndicator';
import useCurrentResourceContext from '../hooks/useCurrentResourceContext';
import useSendContentFormResource from '../hooks/useSendContentFormResource';

import useQnAResource from './hooks/useQnAResource';

const ContentManagementResourcesTemplateContent = ({ companyData }) => {
  const { currentResource: resource } = useCurrentResourceContext();
  const { showSuccess, showError } = useNotifications();
  const { data, isLoading } = useQnAResource(companyData.uid, resource?.type);

  const currentResource = useMemo(() => data ?? resource, [data, resource]);

  const formId = currentResource
    ? `contentManagement-qna-${currentResource.type}`
    : 'contentManagement-qna';

  const {
    handleSubmit,
    control,
    register,
    messageError,
    onSubmit,
    reset,
    handleAutoSave,
    autoSaveStatus,
    isSubmitDisabled,
  } = useSendContentFormResource({
    resource: data,
  });

  useEffect(() => {
    if (data?.questions) {
      const resetData = {
        status: data.status,
        questions: data.questions.reduce((acc, question) => {
          const answerKey =
            data.status === 'completed' ? 'answer' : 'draftAnswer';
          acc[question.id] = {
            answer: question[answerKey] ?? '',
          };
          return acc;
        }, {}),
      };
      reset(resetData);
    }
  }, [data, reset]);

  const handleCopy = async () => {
    try {
      const contentToCopy = data.questions
        .map((question) => {
          const answerKey =
            data.status === 'completed' ? 'answer' : 'draftAnswer';
          const answerText = question[answerKey]?.trim() || '';
          return `${question.title.trim()}\n${answerText}`;
        })
        .join('\n\n');

      await navigator.clipboard.writeText(contentToCopy);

      showSuccess({
        message: 'Content copied successfully.',
      });
    } catch (error) {
      showError({
        message: 'Failed to copy content.',
      });
    }
  };

  if (isLoading || !currentResource) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between py-3 px-2 bg-white shadow sticky top-0 z-50 -mx-6 -mt-6">
        <div className="flex flex-row items-center">
          <Link
            to={generatePath(CONTENT_MANAGEMENT_PATH, {
              id: companyData.slug,
              tab: 'qna',
            })}
            className="inline-flex items-center justify-center h-10 w-10 bg-teal-500 text-white rounded hover:bg-teal-600"
            aria-label="Back to overview"
          >
            <ArrowLeftIcon className="text-white w-4 h-4" />
          </Link>
          <div className="ml-3 text-xl">{currentResource?.title}</div>
        </div>

        <div className="flex items-center gap-2">
          <Button
            type="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitDisabled}
            data-test-id="contentManagement-responseFormSubmitButton"
          >
            Submit For Review
          </Button>

          <Button
            type="secondary"
            onClick={handleCopy}
            data-test-id="contentManagement-responseFormCopyButton"
          >
            Copy Content
          </Button>
        </div>
      </div>

      <div className="flex-1 -mx-6 -mb-6 mt-0 flex flex-row overflow-hidden">
        <div className="overflow-auto bg-gray-100 flex-1 h-[calc(100vh-129px)]">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            {data.questions.map((question) => (
              <ContentQnAResponseFormEditor
                key={question.id}
                savedAnswer={question}
                control={control}
                messageError={messageError.questions}
                reset={reset}
                register={register}
                saveAnswer={handleAutoSave}
              />
            ))}
          </form>
          <AutoSaveIndicator status={autoSaveStatus} />
        </div>
        <AssistantResourcesQnA
          reference={`questionnaire/${currentResource.type}/${companyData.uid}`}
        />
      </div>
    </div>
  );
};

ContentManagementResourcesTemplateContent.propTypes = {
  companyData: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContentManagementResourcesTemplateContent;
