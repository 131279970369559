import PropTypes from 'prop-types';
import React from 'react';

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { noop } from 'lodash';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

import AgencyDashboardJournalistsEditModalButton from './AgencyDashboardJournalistsEditModalButton';

const AgencyDashboardJournalistsSearch = ({ query = '', setQuery = noop }) => (
  <div className="flex justify-between">
    <InputFieldText
      type="text"
      autoComplete="company"
      containerClassName="w-full mr-2"
      name="search"
      placeholder="Search by journalist, publication or email"
      value={query}
      onChange={(e) => setQuery(e.target.value)}
    >
      <InputFieldText.Prefix>
        <MagnifyingGlassIcon className="w-4 h-4" />
      </InputFieldText.Prefix>
    </InputFieldText>
    <AgencyDashboardJournalistsEditModalButton />
  </div>
);

AgencyDashboardJournalistsSearch.propTypes = {
  query: PropTypes.string,
  setQuery: PropTypes.func,
};

export default AgencyDashboardJournalistsSearch;
