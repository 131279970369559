import PropTypes from 'prop-types';
import React from 'react';

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/lodash';
import parseUrl from 'tld-extract';

import stripProtocol from 'utils/data/stripProtocol';

// Displays a parsed URL without the protocol prefix
const UrlDomain = ({
  url = '',
  testId = '',
  onClick = noop,
  externalLinkIcon = false,
}) => {
  if (!url) {
    return null;
  }

  const externalUrl = `https://${stripProtocol(url)}`;

  let urlParsed = null;
  try {
    urlParsed = url
      ? parseUrl(externalUrl, {
          allowUnknownTLD: true,
        })
      : '';
  } catch (e) {
    // TODO-LOG: send this to logger
  }

  if (!urlParsed?.domain) {
    return null;
  }

  return (
    <a
      href={externalUrl}
      target="_blank"
      className="flex items-center text-sm"
      onClick={onClick}
      data-test-id={testId}
      rel="noreferrer"
    >
      {externalLinkIcon && (
        <ArrowTopRightOnSquareIcon className="w-4 h-4 shrink-0 ml-2 mr-1 text-gray-500" />
      )}
      <p className="text-teal-500 hover:underline truncate">
        {urlParsed.domain}
      </p>
    </a>
  );
};

UrlDomain.propTypes = {
  url: PropTypes.string,
  testId: PropTypes.string,
  onClick: PropTypes.func,
  externalLinkIcon: PropTypes.bool,
};

export default UrlDomain;
