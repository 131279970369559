import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

const ContentManagementDocumentResponseFormResetQnAModal = ({
  open,
  title,
  content,
  onConfirm,
  onCancel,
}) => (
  <Modal open={open} onClose={onCancel}>
    <Modal.Title>{title}</Modal.Title>
    <Modal.Content>
      <p>{content}</p>
      <div className="flex justify-end space-x-2">
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </div>
    </Modal.Content>
  </Modal>
);

ContentManagementDocumentResponseFormResetQnAModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ContentManagementDocumentResponseFormResetQnAModal;
