import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth, useUser } from 'reactfire';

const fetchAgencyMembers = async ({ agencyId, auth }) => {
  const BASE_URL = process.env.REACT_APP_UCD_URL;
  const endpoint = `/agencies/${agencyId}/members`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch agency members: ${error.message}`);
  }
};

const inviteMember = async ({ email, agencyId, auth }) => {
  const BASE_URL = process.env.REACT_APP_UCD_URL;
  const endpoint = `/agencies/${agencyId}/invites`;

  const jwt = await auth.currentUser?.getIdToken();
  let response;
  try {
    response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error?.msg || 'API call failed');
    }
  } catch (error) {
    throw new Error(`Failed to invite member to agency: ${error.message}`);
  }
};

const useAgencyMembers = (agencyId) => {
  const auth = useAuth();
  const { data: userDataAuth } = useUser();
  const queryClient = useQueryClient();

  const { data, error, isFetching } = useQuery(
    ['agencyMembers', agencyId],
    () => fetchAgencyMembers({ agencyId, auth }),
    {
      enabled: !!userDataAuth?.uid && !!agencyId,
      initialData: { members: [], invites: [] },
    }
  );

  const inviteMemberMutation = useMutation({
    mutationFn: async (email) =>
      inviteMember({
        email,
        agencyId,
        auth,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries('agencyMembers');
    },
  });

  return {
    members: data.members,
    invites: data.invites.filter((i) => i.status === 'pending'),
    inviteMemberMutation,
    isLoading: isFetching,
    error,
  };
};

export default useAgencyMembers;
