import PropTypes from 'prop-types';
import React, { useState } from 'react';

import classNames from 'classnames';
import { getDownloadURL, ref } from 'firebase/storage';
import { noop } from 'lodash';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useStorage } from 'reactfire';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import countries from 'constants/countries';
import defaultLanguages from 'constants/defaultLanguages';
import { REGEX_URL } from 'constants/regex';
import useAPIRequest from 'hooks/useAPIRequest';

import useAgencyPublications from './hooks/useAgencyPublications';

const UPLOAD_FOLDER = 'publicationImages';

const AgencyDashboardPublicationsEditModal = ({
  defaultValues = {},
  onClose = noop,
}) => {
  const storage = useStorage();
  const { agencyId } = useParams();
  const isEditing = defaultValues.id;
  const { showSuccess, showError } = useNotifications();
  const { upsertPublicationMutation } = useAgencyPublications(agencyId);
  const { fetchData: getPrefillData } = useAPIRequest({
    method: 'POST',
    endpoint: '/publications/prefill',
    service: 'JPD',
  });

  const [prefillLoading, setPrefillLoading] = useState(false);

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      ...defaultValues,
      image: defaultValues.image
        ? {
            path: defaultValues.image,
          }
        : null,
    },
  });
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = form;

  const defaultLanguage = watch('defaultLanguage');
  const country = watch('country');

  return (
    <Modal open>
      <Modal.Title>Add publication</Modal.Title>
      <Modal.Content>
        <form
          onSubmit={handleSubmit(async (p) => {
            try {
              const image = p?.image?.localName
                ? await getDownloadURL(ref(storage, p?.image?.path))
                : p?.image?.path || null;

              await upsertPublicationMutation.mutateAsync({
                ...p,
                image,
              });
              showSuccess({
                title: `Publication ${isEditing ? 'updated' : 'created'}`,
                message: (
                  <span>
                    Publication <b>{p.name}</b> has been{' '}
                    {isEditing ? 'updated' : 'created'}
                  </span>
                ),
              });
              onClose();
            } catch (error) {
              showError({
                title: `Failed to ${
                  isEditing ? 'update' : 'create'
                } publication`,
                message: error.message,
              });
            }
          })}
          id="publicationForm"
          className="flex flex-col gap-2"
        >
          <div className="w-full flex flex-row gap-2">
            <InputFieldText
              {...register('name', {
                required: 'Name is required',
              })}
              errors={errors}
              disabled={!!isEditing}
            >
              <InputFieldText.Label>Name</InputFieldText.Label>
            </InputFieldText>

            <div className="w-full flex flex-col gap-2">
              <Label className="block text-gray-700">Language</Label>
              <InputFieldDropdown
                options={defaultLanguages}
                value={defaultLanguage}
                onChange={(value) => setValue('defaultLanguage', value)}
                buttonClassName="w-full"
                placeholder="Select a language"
              />
            </div>
          </div>

          <div className="w-full flex flex-row gap-2">
            <div className="w-full flex flex-col gap-2">
              <Label className="block text-gray-700">Country</Label>
              <InputFieldDropdown
                options={countries}
                value={country}
                onChange={(value) => setValue('country', value)}
                buttonClassName="w-full"
                placeholder="Select a country"
              />
            </div>

            <InputFieldText {...register('city')} errors={errors}>
              <InputFieldText.Label>City</InputFieldText.Label>
            </InputFieldText>
          </div>

          <InputFieldText
            {...register('phoneNumber')}
            errors={errors}
            type="tel"
          >
            <InputFieldText.Label>Phone number</InputFieldText.Label>
          </InputFieldText>

          <div className="w-full flex flex-col gap-2">
            <Label className="block text-gray-700">Description</Label>
            <InputfieldTextArea
              {...register('description')}
              errors={errors}
              className="!mt-0"
            />
          </div>

          <div className="flex flex-col gap-4 sm:flex-row">
            <InputFieldText
              {...register('linkedInHandle')}
              errors={errors}
              containerClassName="basis-3/5"
              placeholder="reidhoffman"
            >
              <InputFieldText.Prefix>linkedin.com/in/</InputFieldText.Prefix>
              <InputFieldText.Label>LinkedIn URL</InputFieldText.Label>
            </InputFieldText>

            <InputFieldText
              {...register('twitterHandle')}
              errors={errors}
              containerClassName="basis-2/5"
              placeholder="jackdorsey"
            >
              <InputFieldText.Prefix>@</InputFieldText.Prefix>
              <InputFieldText.Label>Twitter handle</InputFieldText.Label>
            </InputFieldText>
          </div>

          <InputFieldText
            {...register('website', {
              pattern: {
                value: REGEX_URL,
                message: 'Please type in a valid URL',
              },
            })}
            errors={errors}
          >
            <InputFieldText.Label>Website</InputFieldText.Label>
          </InputFieldText>

          <div className="flex gap-4">
            <InputFieldText
              {...register('domainAuthority')}
              errors={errors}
              type="number"
              step={1}
              min={0}
              max={100}
            >
              <InputFieldText.Label>Domain authority</InputFieldText.Label>
            </InputFieldText>
            <Button
              onClick={async () => {
                try {
                  setPrefillLoading(true);
                  const formData = form.getValues();
                  const data = await getPrefillData({
                    body: {
                      url: formData.website,
                    },
                  });
                  if (data.image) {
                    form.setValue('image', {
                      path: data.image,
                      type: 'image',
                    });
                  }
                  if (data.domainAuthority) {
                    form.setValue('domainAuthority', data.domainAuthority);
                  }
                  if (data.name && !formData.name) {
                    form.setValue('name', data.name);
                  }
                } catch (error) {
                  showError({
                    message: `Something went wrong`,
                  });
                } finally {
                  setPrefillLoading(false);
                }
              }}
              className="h-fit self-end"
            >
              {prefillLoading ? 'Loading...' : 'Prefill from website'}
            </Button>
          </div>

          <InputFieldUpload name="image" folder={UPLOAD_FOLDER} form={form}>
            <InputFieldUpload.Label>Image</InputFieldUpload.Label>
          </InputFieldUpload>
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          className="relative"
          submit
          form="publicationForm"
        >
          <span
            className={classNames({
              'opacity-50': upsertPublicationMutation.isLoading,
            })}
          >
            {isEditing ? 'Update' : 'Create'} Publication
          </span>
          {upsertPublicationMutation.isLoading && (
            <Spinner
              color="text-white"
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          )}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AgencyDashboardPublicationsEditModal.propTypes = {
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
};

export default AgencyDashboardPublicationsEditModal;
