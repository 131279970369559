import React from 'react';

import classNames from 'classnames';

import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

import InboxMessageResponseFormEditorResponse from './InboxMessageResponseFormEditorResponse';
import InboxMessageResponseFormEditorTemplateDropdown from './InboxMessageResponseFormEditorTemplateDropdown';

const InboxMessageResponseFormEditor = () => {
  const { currentThread, threadDetails } = useInboxContext();
  const { isInitialDraft } = threadDetails.data;

  return (
    <div className="relative">
      <div
        data-test-id="inboxMessage-responseEditor"
        className={classNames('relative flex flex-col gap-2', {})}
      >
        <div className="flex justify-between items-center flex-wrap">
          <h3 className="text-lg font-medium">
            Respond to {currentThread?.journalist?.name || 'Journalist'}
          </h3>

          {isInitialDraft ? (
            <InboxMessageResponseFormEditorTemplateDropdown />
          ) : null}
        </div>

        <InboxMessageResponseFormEditorResponse />
      </div>
    </div>
  );
};

export default InboxMessageResponseFormEditor;
