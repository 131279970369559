import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { updateProfile } from 'firebase/auth';
import { getDownloadURL, ref } from 'firebase/storage';
import { useStateMachine } from 'little-state-machine';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useStorage } from 'reactfire';

import Card from 'components/Card';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import UserAvatar from 'components/UserAvatar';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import { useTracking } from 'modules/segment/TrackingProvider';

import RemovePhotoModal from './RemovePhotoModal';
import UpdatePhotoModal from './UpdatePhotoModal';

const updatePhotoURL = (state, payload) => ({
  ...state,
  user: { ...state.user, photoURL: payload },
});

const ProfileForm = ({ user = {}, userRef = {}, userData = {} }) => {
  const { setDocument } = useFirestoreWrapper();
  const { showSuccess, showError } = useNotifications();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    nativeValidation: false,
    defaultValues: userData,
  });

  const { actions } = useStateMachine({ updatePhotoURL });
  const storage = useStorage();
  const trackingService = useTracking();

  const [isUpdatePhotoModalOpen, setUpdatePhotoModalOpen] = useState(false);
  const [removePhotoModalOpen, setRemovePhotoModalOpen] = useState(false);

  const onSubmit = async (newUserData = {}) => {
    try {
      // Log changed events
      if (userData.firstName !== newUserData.firstName) {
        trackingService.track('userData_update', {
          action: 'firstName_change',
        });
      }
      if (userData.lastName !== newUserData.lastName) {
        trackingService.track('userData_update', {
          action: 'lastName_change',
        });
      }

      // Update properties
      await updateProfile(user, {
        displayName: `${userData.firstName} ${userData.lastName}`,
      });
      await setDocument(userRef, newUserData, { merge: true });
      showSuccess({ message: 'User settings updated successfully' });
    } catch (err) {
      console.error('Error updating profile', err); // eslint-disable-line no-console
      showError({ tile: 'Error updating profile', message: err.message });
    }
  };

  const updatePhoto = async ({ photo }) => {
    try {
      let photoURL = '';
      if (photo && photo.name) {
        photoURL = await getDownloadURL(
          ref(storage, `users/${user.uid}/${photo.name}`)
        );
      }

      await updateProfile(user, {
        photoURL,
      });
      await setDocument(userRef, { photoUrl: photoURL }, { merge: true });

      actions.updatePhotoURL(photoURL);
      setUpdatePhotoModalOpen(false);
      setRemovePhotoModalOpen(false);
      trackingService.track('userData_update', { action: 'photo_change' });
    } catch (e) {
      // TODO-LOG: send this to logger
    }
  };

  return (
    <>
      <Card
        title="Profile"
        subtitle="This information will be displayed publicly so be careful what you
      share."
      >
        <div className="w-full">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <Label htmlFor="photo">Photo</Label>
              <div className="mt-2 flex items-center">
                <UserAvatar className="w-16 h-16" />
                <div className="ml-4 flex">
                  <Button
                    type="secondary"
                    className="mr-2"
                    onClick={() => setUpdatePhotoModalOpen(true)}
                  >
                    <span>Change</span>
                    <span className="sr-only"> user photo</span>
                  </Button>

                  {user.photoURL && (
                    <Link
                      to="#"
                      className="text-sm font-medium text-gray-700 py-2 px-3"
                      onClick={() => setRemovePhotoModalOpen(true)}
                    >
                      Remove
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-6 mt-6">
              <InputFieldText
                {...register('firstName')}
                containerClassName="col-span-12 sm:col-span-6"
                defaultValue={userData.firstName}
                placeholder="First name"
              >
                <InputFieldText.Label>First name</InputFieldText.Label>
              </InputFieldText>

              <InputFieldText
                {...register('lastName')}
                containerClassName="col-span-12 sm:col-span-6"
                defaultValue={userData.lastName}
                placeholder="Last name"
              >
                <InputFieldText.Label>Last name</InputFieldText.Label>
              </InputFieldText>
            </div>
            <div className="mt-4 flex flex-row justify-end">
              <Button
                type="secondary"
                submit
                disabled={isSubmitting || !isValid}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Card>

      {isUpdatePhotoModalOpen ? (
        <UpdatePhotoModal
          open={isUpdatePhotoModalOpen}
          uploadFolder={`users/${user.uid}`}
          onClose={() => setUpdatePhotoModalOpen(false)}
          onSubmit={updatePhoto}
        />
      ) : null}

      <RemovePhotoModal
        open={removePhotoModalOpen}
        onClose={() => setRemovePhotoModalOpen(false)}
        onSubmit={() => updatePhoto({ photo: null })}
      />
    </>
  );
};

ProfileForm.propTypes = {
  user: PropTypes.object.isRequired,
  userRef: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
};

export default ProfileForm;
