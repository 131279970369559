import React from 'react';

import { tabs } from 'constants/planningTabs';

import PlanningNavbarTabsItem from './PlanningNavbarTabsItem';

const PlanningNavbarTabs = () => (
  <div className="flex pt-2" data-intercom-id="planning-tabs">
    {tabs.map((tab) => (
      <PlanningNavbarTabsItem key={tab.field} tab={tab} />
    ))}
  </div>
);

export default PlanningNavbarTabs;
