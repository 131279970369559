import PropTypes from 'prop-types';
import React from 'react';

import padStart from 'lodash/padStart';
import { Tooltip } from 'react-tooltip';

import StripeRedirectModal from 'components/StripeRedirectModal';
import Button from 'components/buttons/Button';
import cardIcons from 'constants/cardIcons';
import stripePortalConfigNames from 'constants/stripePortalConfigNames';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useRedirectToStripePortal from 'hooks/useRedirectToStripePortal';
import { useTracking } from 'modules/segment/TrackingProvider';
import GenericCardIcon from 'static/cardBrands/GenericCardIcon';

import useCompanySubscription from '../hooks/useCompanySubscription';

const BillingSettingsPaymentDetails = ({
  expiryMonth = 0,
  expiryYear = 0,
  lastDigits = '',
  brand = '',
}) => {
  const { companyData } = useCompanyData();
  const { uid } = useUserData();
  const { data: subscription } = useCompanySubscription(companyData?.uid);
  const trackingService = useTracking();
  const CardIcon = cardIcons[brand] || GenericCardIcon;
  const { isRedirecting, redirectToStripePortal } = useRedirectToStripePortal(
    stripePortalConfigNames.PAYMENT_UPDATE
  );

  const updatePaymentDetails = async (event) => {
    await trackingService.track('subscription_update', {
      action: 'paymentDetails_update',
    });
    redirectToStripePortal(event);
  };

  if (!subscription) {
    return null;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row gap-2 mb-4">
        <CardIcon className="w-[1.95rem] h-5 shrink-0 mt-1" />
        <div>
          <p>Ending with {lastDigits}</p>
          <p className="text-gray-500">
            Expires {padStart(expiryMonth, 2, '0')} / {expiryYear}
          </p>
        </div>
      </div>
      <Button
        disabled={subscription.subscriptionOwner.id !== uid}
        type="secondary"
        className="w-fit mt-auto"
        onClick={updatePaymentDetails}
        data-tooltip-id={
          subscription.subscriptionOwner.id !== uid
            ? 'payment-button-explanation'
            : null
        }
        data-tooltip-html={`<span className="text-center">Only <b>${subscription.subscriptionOwner.email}</b> <br/> can update the payment details</span>`}
      >
        Update payment details
      </Button>
      <StripeRedirectModal isRedirecting={isRedirecting} />

      <Tooltip id="payment-button-explanation" className="text-sm" />
    </div>
  );
};

BillingSettingsPaymentDetails.propTypes = {
  expiryMonth: PropTypes.number,
  expiryYear: PropTypes.number,
  lastDigits: PropTypes.string,
  brand: PropTypes.string,
};

export default BillingSettingsPaymentDetails;
