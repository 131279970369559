import PropTypes from 'prop-types';
import React from 'react';

import { BuildingOfficeIcon } from '@heroicons/react/20/solid';

const CompanyImageV2 = ({
  url = '',
  size = 12,
  alt = 'Logo of company',
  children = null,
  className = '',
}) => {
  const baseClassName = `${className} max-h-${size} w-${size} rounded-lg self-center`;

  return (
    <div
      className={`h-${size} w-${size} text-gray-200 ring-1 ring-gray-900/10 rounded-lg bg-white flex relative`}
    >
      {url ? (
        <img alt={alt} src={url} className={baseClassName} />
      ) : (
        <BuildingOfficeIcon className={baseClassName} />
      )}
      {children}
    </div>
  );
};

CompanyImageV2.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.number,
  children: PropTypes.node,
};
export default CompanyImageV2;
