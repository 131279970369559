import React, { useState } from 'react';

import AgencyNavbarContent from './AgencyNavbarContent';
import AgencyNavbarMobileHeader from './AgencyNavbarMobileHeader';
import AgencyNavbarMobileTransition from './AgencyNavbarMobileTransition';

const AgencyNavbarMobile = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <>
      <AgencyNavbarMobileHeader
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      <AgencyNavbarMobileTransition
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      >
        <div className="flex grow flex-col gap-y-2 overflow-y-auto bg-white px-6 pb-4">
          <AgencyNavbarContent />
        </div>
      </AgencyNavbarMobileTransition>
    </>
  );
};

export default AgencyNavbarMobile;
