import PropTypes from 'prop-types';
import React from 'react';

import Avatar from 'components/Avatar';

const InputFieldTagsAutocompleteSuggestion = ({ item = {} }) => (
  <div id={item.id} className="h-fit flex gap-2 items-center">
    {item.showAvatar ? (
      <Avatar
        image={item?.data?.photoUrl}
        className="w-5 h-5"
        alt={item.name}
      />
    ) : null}
    <span>{item.name}</span>
  </div>
);

InputFieldTagsAutocompleteSuggestion.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
};

export default InputFieldTagsAutocompleteSuggestion;
