import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CompanyArticles from './CompanyArticles/CompanyArticles';
import CompanyDownloads from './CompanyDownloads/CompanyDownloads';
import CompanyInsights from './CompanyInsights/CompanyInsights';
import CompanyQuotes from './CompanyQuotes/CompanyQuotes';
import CompanySectionsHowTo from './CompanySectionsHowTo';
import CompanySectionsHowToModal from './CompanySectionsHowToModal';
import CompanySnapshots from './CompanySnapshots/CompanySnapshots';
import CompanyVideos from './CompanyVideos/CompanyVideos';

const CompanySections = ({
  companyId = '',
  cruds = {},
  sectionRefs = {},
  founders = [],
  showOwnerView = false,
  isPreview = false,
}) => {
  const [showHowToModal, setShowHowToModal] = useState(false);

  return (
    <>
      <div className="min-h-screen h-full p-6 pt-0 md:pl-6 border-l bg-gray-50 border-gray-200 relative">
        <div
          data-intercom-id="pressPage-content-overlay"
          className="absolute w-full h-[calc(100vh-336px)] -ml-6 -mt-2 -z-10 pointer-events-none"
        />
        {!isPreview && showOwnerView && (
          <CompanySectionsHowTo onClick={() => setShowHowToModal(true)} />
        )}
        <CompanySnapshots
          crud={cruds.achievements}
          sectionRef={sectionRefs.achievements}
          showOwnerView={showOwnerView}
        />
        <CompanyVideos
          crud={cruds.videos}
          sectionRef={sectionRefs.videos}
          showOwnerView={showOwnerView}
          sectionRefs={sectionRefs}
        />

        <CompanyArticles
          crud={cruds.articles}
          sectionRef={sectionRefs.articles}
          showOwnerView={showOwnerView}
          companyId={companyId}
        />

        <CompanyInsights
          crud={cruds.insights}
          founders={founders}
          sectionRef={sectionRefs.insights}
          showOwnerView={showOwnerView}
        />

        <CompanyQuotes
          crud={cruds.quotes}
          founders={founders}
          sectionRef={sectionRefs.quotes}
          showOwnerView={showOwnerView}
        />
        <CompanyDownloads
          crud={cruds.downloads}
          sectionRef={sectionRefs.downloads}
          showOwnerView={showOwnerView}
          companyId={companyId}
        />
      </div>

      <CompanySectionsHowToModal
        open={showHowToModal}
        onClose={() => setShowHowToModal(false)}
      />
    </>
  );
};

CompanySections.propTypes = {
  companyId: PropTypes.string.isRequired,
  cruds: PropTypes.object.isRequired,
  sectionRefs: PropTypes.object.isRequired,
  founders: PropTypes.array,
  showOwnerView: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool,
};

export default React.memo(CompanySections);
