import PropTypes from 'prop-types';
import React from 'react';

import { PhotoIcon } from '@heroicons/react/20/solid';
import { StorageImage } from 'reactfire';

// Grabs an image from the Firebase storage, renders a placeholder while loading
const FirebaseImage = ({ path = '', alt = '', ...rest }) => (
  <StorageImage
    alt={alt}
    storagePath={path}
    suspense={false}
    placeHolder={
      <div className="bg-gray-50 absolute inset-0 flex justify-center items-center text-gray-200">
        <PhotoIcon className="h-full" />
      </div>
    }
    {...rest}
  />
);

FirebaseImage.propTypes = {
  path: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default FirebaseImage;
