import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TrashIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

const SweetheartListJournalistDelete = ({ journalistId = '' }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { showError } = useNotifications();
  const { listId } = useParams();
  const { deleteJournalist } = useSweetheartListContext();

  const handleDeleteJournalist = async (e) => {
    e.stopPropagation();
    setIsDeleting(true);
    try {
      await deleteJournalist({ listId, journalistId });
    } catch (error) {
      showError({
        title: 'Unable to delete journalist',
        message: error.message,
      });
    }
    setIsDeleting(false);
  };

  return (
    <Button type="danger" onClick={handleDeleteJournalist} className="relative">
      <TrashIcon
        className={classNames('w-4 h-4', {
          invisible: isDeleting,
        })}
      />
      {isDeleting && (
        <Spinner
          color="text-white"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      )}
    </Button>
  );
};

SweetheartListJournalistDelete.propTypes = {
  journalistId: PropTypes.string,
};

export default SweetheartListJournalistDelete;
