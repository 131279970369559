import React from 'react';

import { SparklesIcon, TrashIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

import useCreateSetState from '../hooks/useCreateSetState';
import useQueryParams from '../hooks/useQueryParams';

const OpportunitiesMetaToggle = () => {
  const { showDeclined } = useQueryParams();
  const setShowDeclined = useCreateSetState('showDeclined');

  return (
    <button
      type="button"
      data-test-id="opportunities-showDeclined"
      onClick={() => setShowDeclined(!showDeclined)}
      title={`Show ${showDeclined ? 'upcoming' : 'declined'} opportunities`}
      className={classNames(
        'rounded-md pointer-events-auto w-fit py-1 px-1.5 text-sm flex gap-1 items-center',
        {
          'text-gray-500 hover:text-gray-400': !showDeclined,
          'text-teal-500 hover:text-teal-400': showDeclined,
        }
      )}
    >
      {showDeclined ? (
        <SparklesIcon className="w-4 h-4" aria-hidden="true" />
      ) : (
        <TrashIcon className="w-4 h-4" aria-hidden="true" />
      )}
      <span className="inline lg:hidden 2xl:inline text-xs">
        Show {showDeclined ? 'upcoming' : 'declined'}
      </span>
    </button>
  );
};

OpportunitiesMetaToggle.propTypes = {};

export default OpportunitiesMetaToggle;
