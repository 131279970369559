import { generatePath, redirect } from 'react-router-dom';

import contentTabs, { tabNames } from 'constants/contentTabs';
import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';

import ContentManagementPage from './ContentManagementPage';

const ContentManagementRoute = {
  path: CONTENT_MANAGEMENT_PATH,
  authRequired: true,
  component: ContentManagementPage,
  loader: ({ params }) => {
    const { id, tab } = params;

    if (!tab || !contentTabs.find((t) => t.field === tab)) {
      return redirect(
        generatePath(CONTENT_MANAGEMENT_PATH, {
          id,
          tab: tabNames.DOCUMENTS,
        })
      );
    }

    return null;
  },
};

export default ContentManagementRoute;
