import PropTypes from 'prop-types';
import React, { createContext, useMemo } from 'react';

import noop from 'lodash/noop';
import { generatePath, useParams } from 'react-router-dom';

import { COMPANY_PATH } from 'constants/paths';
import useUserData from 'contexts/UserContext/hooks/useUserData';

const defaultImpersonationData = {
  impersonatedCompanySlug: null,
  setImpersonatedCompanySlug: noop,
};
export const ImpersonationContext = createContext(defaultImpersonationData);

const { Provider } = ImpersonationContext;

const ImpersonationProvider = ({ children = null }) => {
  const { id: impersonatedCompanySlug = null } = useParams();
  const user = useUserData();

  const value = useMemo(
    () => ({
      impersonatedCompanySlug,
      setImpersonatedCompanySlug: (slug) => {
        if (!slug) {
          return;
        }

        if (
          window.location.pathname.startsWith('/admin') ||
          window.location.pathname.startsWith('/agencies')
        ) {
          window.location.replace(generatePath(COMPANY_PATH, { id: slug }));
          return;
        }

        const pathNameParts = window.location.pathname.split('/');
        const slugIndex = pathNameParts.findIndex(
          (part) => part === impersonatedCompanySlug
        );

        pathNameParts[slugIndex] = slug;

        window.location.replace(
          pathNameParts.slice(0, slugIndex + 1).join('/')
        );
      },
    }),
    [impersonatedCompanySlug]
  );

  if (!user.claimsAdmin && !user.claimsAgencyId) {
    return children;
  }

  return <Provider value={value}>{children}</Provider>;
};

ImpersonationProvider.propTypes = {
  children: PropTypes.node,
};

export default ImpersonationProvider;
