import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { EnvelopeIcon, HandThumbDownIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

import Button from 'components/buttons/Button';
import useCompaniesOpportunitiesOps from 'hooks/useCompaniesOpportunitiesOps';
import useQueryParams from 'routes/Opportunities/hooks/useQueryParams';
import SweetheartListJournalistAdd from 'routes/SweetheartList/SweetheartListJournalist/SweetheartListJournalistAdd';

import OpportunitiesListShareModal from './OpportunitiesListShareModal';

const OpportunitiesListResponseModalLeftActions = ({
  onClose = noop,
  opportunity = {},
}) => {
  const { showDeclined } = useQueryParams();
  const companiesOpportunitiesOps = useCompaniesOpportunitiesOps();
  const [showShareModal, setShowShareModal] = useState(false);

  const isShareable = useMemo(() => {
    if (opportunity.deadline) {
      const deadlineDate = new Date(opportunity.deadline);
      const currentDate = new Date();
      const timeDifference = deadlineDate - currentDate;

      return timeDifference > 24 * 60 * 60 * 1000 || timeDifference <= 0;
    }
    return false;
  }, [opportunity.deadline]);

  const handleOpenShareModal = () => {
    setShowShareModal(true);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  return (
    <>
      {!showDeclined && (
        <Button
          type="secondary"
          className="space-x-1"
          onClick={() => {
            companiesOpportunitiesOps.decline(opportunity);
            onClose();
          }}
        >
          <HandThumbDownIcon className="w-4" /> <span>Decline</span>
        </Button>
      )}

      <SweetheartListJournalistAdd
        placeholder="Add journalist to list"
        journalistId={opportunity?.journalist?.id}
      />

      <div
        className={`space-x-1 ${
          !isShareable ? 'bg-pink-400' : 'bg-pink-400 hover:bg-pink-300'
        } rounded-md inline-block`}
      >
        <Button
          type="primary"
          className="w-full h-full bg-transparent hover:bg-transparent bg-pink-400 hover:bg-pink-300"
          onClick={handleOpenShareModal}
          disabled={!isShareable}
        >
          <EnvelopeIcon className="w-4 mr-1" /> <span>Refer Opportunity</span>
        </Button>
      </div>

      {showShareModal ? (
        <OpportunitiesListShareModal
          open={showShareModal}
          onClose={handleCloseShareModal}
          opportunityId={opportunity.id}
        />
      ) : null}
    </>
  );
};

OpportunitiesListResponseModalLeftActions.propTypes = {
  onClose: PropTypes.func.isRequired,
  opportunity: PropTypes.object.isRequired,
};

export default OpportunitiesListResponseModalLeftActions;
