/* eslint-disable max-params */
import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'reactfire';

const updateCompanySettings = async ({ uid, data, auth }) => {
  const BASE_URL = process.env.REACT_APP_UCD_URL;
  const endpoint = `/companies/${uid}/settings`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to update company settings: ${error.message}`);
  }
};

const useUpdateCompanySettings = (uid = '') => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => updateCompanySettings({ data, uid, auth }),
    onMutate: async (newData) => {
      await queryClient.cancelQueries(['companySettings', uid]);
      const previousData = queryClient.getQueryData(['companySettings', uid]);
      queryClient.setQueryData(['companySettings', uid], (old) => ({
        ...old,
        ...newData,
      }));
      return { previousData };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(['companySettings', uid], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['companySettings', uid]);
    },
  });
};

export default useUpdateCompanySettings;
