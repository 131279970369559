import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { EyeIcon, PencilIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import CompanyImage from 'components/company/CompanyImage';
import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import UpdateLogoModal from 'components/company/UpdateLogoModal';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import CompanyHeaderImage from 'static/company-header.jpg';

import CompanyHeaderDeleteModal from './CompanyHeaderDeleteModal';
import CompanyHeaderModal from './CompanyHeaderModal';

const CompanyHeader = ({
  companyRef = {},
  companyData = {},
  showOwnerView = false,
  isPreview = false,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditLogoModalOpen, setIsEditLogoModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { updateDocument } = useFirestoreWrapper();

  const { hasBasicCompanyAccess } = useCompanyData();

  const previewCtaVisible = hasBasicCompanyAccess;
  const isOwnerNotPreview = hasBasicCompanyAccess && !isPreview;

  const onUpdate = async (obj) => {
    await updateDocument(companyRef, {
      headerImage: obj.image,
    });
    setIsEditModalOpen(false);
  };

  const onDelete = async () => {
    await updateDocument(companyRef, {
      headerImage: null,
    });
    setIsDeleteModalOpen(false);
  };

  const updateLogo = async ({ logo }) => {
    await updateDocument(companyRef, { logo });
    setIsEditLogoModalOpen(false);
  };

  return (
    <>
      <div className="relative w-full">
        <div className="relative h-52 overflow-hidden">
          <UniversalImage
            image={companyData.headerImage || { path: CompanyHeaderImage }}
            alt={`Company header of ${companyData.name}`}
            testId="company-header-image"
          />
          <div
            className="absolute inset-0 bg-gray-500 bg-opacity-50"
            aria-hidden="true"
          />
        </div>

        {previewCtaVisible && (
          <div
            data-intercom-id="pressPage-preview"
            className="absolute top-4 left-4"
          >
            <Link
              to={{ search: isPreview ? null : 'preview=true' }}
              className="inline-flex justify-center items-center py-2 px-4 font-bold text-sm whitespace-nowrap text-gray-100 bg-transparent hover:opacity-80 focus:border-gray-100 focus:outline-none rounded"
              data-test-id="company-header-preview-toggle"
            >
              {isPreview ? (
                <PencilIcon className="w-6 h-6 mr-2" />
              ) : (
                <EyeIcon className="w-6 h-6 mr-2" />
              )}
              {isPreview ? 'Back to edit mode' : 'Go to preview mode'}
            </Link>
          </div>
        )}

        {isOwnerNotPreview && (
          <div className="absolute bottom-3 right-4">
            <DotsMenu
              options={[
                {
                  label: 'Update header',
                  onClick: () => setIsEditModalOpen(true),
                  testId: 'company-header-menu-update',
                },
              ].concat(
                companyData.headerImage
                  ? {
                      label: 'Delete header',
                      onClick: () => setIsDeleteModalOpen(true),
                      icon: <XMarkIcon className="w-4 h-4" />,
                      testId: 'company-header-menu-delete',
                    }
                  : []
              )}
              testId="company-header-menu"
            />
          </div>
        )}

        <div className="overflow-hidden flex flex-none justify-center items-center absolute z-10 group shadow rounded h-28 w-28 -bottom-8 left-6 md:-bottom-14 md:left-8">
          <div
            className={classNames('w-full h-full relative', {
              'cursor-pointer': isOwnerNotPreview,
            })}
            onClick={() => isOwnerNotPreview && setIsEditLogoModalOpen(true)}
            data-test-id="company-header-logo"
          >
            {isOwnerNotPreview && (
              <>
                <div className="hidden group-hover:block z-10 absolute h-full w-full box-border border-4 border-teal-500 pointer-events-none" />
                <div className="hidden group-hover:flex absolute z-20 bottom-0 right-0 w-8 justify-center rounded-tl rounded-br bg-teal-500">
                  <span className="sr-only">Edit Logo</span>
                  <EllipsisHorizontalIcon className="text-gray-100 h-5" />
                </div>
              </>
            )}
            <CompanyImage
              image={companyData?.logo}
              alt={`${companyData.name} logo`}
              className="w-full h-full"
              isResponsive
            />
          </div>
        </div>
      </div>

      {isEditLogoModalOpen ? (
        <UpdateLogoModal
          open={isEditLogoModalOpen}
          uploadFolder={`companies/${companyData.uid}/logo`}
          onSubmit={updateLogo}
          onClose={() => setIsEditLogoModalOpen(false)}
        />
      ) : null}

      {showOwnerView && isEditModalOpen ? (
        <CompanyHeaderModal
          open={isEditModalOpen}
          uploadFolder={`companies/${companyData.uid}/header`}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onUpdate}
        />
      ) : null}

      <CompanyHeaderDeleteModal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={onDelete}
      />
    </>
  );
};

CompanyHeader.propTypes = {
  companyRef: PropTypes.object.isRequired,
  companyData: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    logo: PropTypes.object,
    headerImage: PropTypes.object,
    isOwner: PropTypes.bool,
    owner: PropTypes.string,
  }).isRequired,
  showOwnerView: PropTypes.bool,
  isPreview: PropTypes.bool.isRequired,
};

export default CompanyHeader;
