import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import Button from 'components/buttons/Button';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import SweetheartListAddJournalistModal from './SweetheartListAddJournalistModal';

const SweetheartListAddJournalistModalOpenButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { listId } = useParams();
  const { claimsAdmin: isAdmin, claimsAgencyId: isAgency } = useUserData();

  if ((!isAdmin && !isAgency) || !listId) {
    return null;
  }

  return (
    <>
      <Button className="h-fit" onClick={() => setIsModalOpen(true)}>
        Add Journalist
      </Button>
      {isModalOpen && (
        <SweetheartListAddJournalistModal
          onAbort={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default SweetheartListAddJournalistModalOpenButton;
