import React from 'react';

import { tabs } from 'constants/contentTabs';

import ContentManagementNavbarTabsItem from './ContentManagementNavbarTabsItem';

const ContentManagementNavbarTabs = () => (
  <div className="flex pt-2" data-intercom-id="content-tabs">
    {tabs.map((tab) => (
      <ContentManagementNavbarTabsItem key={tab.field} tab={tab} />
    ))}
  </div>
);

export default ContentManagementNavbarTabs;
