import React from 'react';

import { CogIcon } from '@heroicons/react/24/outline';

import EmptyState from 'components/EmptyState';

const ReportsPageBeingGenerated = () => (
  <EmptyState
    helmetTitle="Reports"
    testId="analyticsDashboard-BeingGenerated"
    title="We are currently generating your reports!"
    subtitle="Please come back later."
    Icon={CogIcon}
  />
);

export default ReportsPageBeingGenerated;
