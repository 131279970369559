import React from 'react';

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import moment from 'moment';

import Card from 'components/Card';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import useCompanyInvoices from '../hooks/useCompanyInvoices';

const BillingSettingsInvoices = () => {
  const { companyData } = useCompanyData();

  const { data: invoices } = useCompanyInvoices(companyData?.uid);

  if (!invoices || invoices.length === 0) {
    return null;
  }

  return (
    <Card title="Invoices">
      <div className="w-full items-center gap-5">
        <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 text-gray-500">
            <thead className="bg-gray-50 text-xs font-medium uppercase">
              <tr className="text-left tracking-wider">
                <th scope="col" className="px-4 py-2">
                  Invoice
                </th>
                <th scope="col" className="px-4 py-2 max-w-sm">
                  Date
                </th>
                <th scope="col" colSpan="2" className="px-4 py-2">
                  Receipt
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 text-sm">
              {invoices.map((invoice) => (
                <tr key={invoice.id}>
                  <td className="px-4 py-2 whitespace-nowrap">
                    {invoice.number || 'n/a'}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    {moment.unix(invoice.created).format('LL') || 'n/a'}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    <a
                      href={invoice?.hosted_invoice_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex gap-1 items-center text-teal-500 hover:text-teal-600"
                    >
                      Invoice
                      <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
};

export default BillingSettingsInvoices;
