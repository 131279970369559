import React from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import PageWrapper from 'components/PageWrapper';
import TiersGuard from 'components/TiersGuard';
import { featureNames } from 'constants/featureMap';
import { PLANNING_PATH } from 'constants/paths';
import { tabNames, tabs } from 'constants/planningTabs';
import useRedirectUsersToOwnCompany from 'hooks/useRedirectUsersToOwnCompany';
import getPageTitle from 'utils/getPageTitle';

import Planning from './Planning';
import PlanningNavbar from './PlanningNavbar';

const PlanningPage = () => {
  const { tab, documentId } = useParams();

  const tabNotFound = !tab || !tabs.find((t) => t.field === tab);
  const tabName = tabNotFound ? tabNames.TASKS : tab;

  useRedirectUsersToOwnCompany(PLANNING_PATH, {
    tab: tabName,
    documentId,
  });

  return (
    <PageWrapper className="flex flex-col p-6 min-h-[calc(100vh-64px)]">
      <Helmet>
        <title>{getPageTitle('Planning Page')}</title>
      </Helmet>
      <TiersGuard featureId={featureNames.CONTENT_MANAGEMENT}>
        <PlanningNavbar />
        <Planning />
      </TiersGuard>
    </PageWrapper>
  );
};

export default PlanningPage;
