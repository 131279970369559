import PropTypes from 'prop-types';
import React from 'react';

import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import noop from 'lodash/noop';

import Label from 'components/form/Label';

const JournalistFiltersCheckboxes = ({
  name = '',
  options = [],
  optionNameMapping = {},
  selectedOptions = [],
  onChange = noop,
}) => (
  <Disclosure defaultOpen as="div" className="border-b border-gray-200 py-6">
    {({ open }) => (
      <>
        <h3 className="-my-3 flow-root">
          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
            <span className="font-medium text-gray-900">{name}</span>
            <span className="ml-6 flex items-center">
              {open ? (
                <MinusIcon className="h-5 w-5" aria-hidden="true" />
              ) : (
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
              )}
            </span>
          </Disclosure.Button>
        </h3>
        <Disclosure.Panel className="pt-6">
          <div className="space-y-4">
            {options.map((option) => (
              <div key={option.value} className="flex items-center">
                <input
                  id={`filter-${option.value}`}
                  name={option.value}
                  type="checkbox"
                  checked={selectedOptions.includes(option.value)}
                  className="h-4 w-4 mr-2 text-teal-500 focus:ring-teal-500 border-gray-300 rounded cursor-pointer"
                  onChange={() => onChange(option.value)}
                />
                <Label
                  htmlFor={`filter-${option.value}`}
                  className="ml-3 text-sm text-gray-600"
                >
                  {optionNameMapping[option.value] || option.value}
                </Label>
              </div>
            ))}
          </div>
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

JournalistFiltersCheckboxes.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  optionNameMapping: PropTypes.object,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default JournalistFiltersCheckboxes;
