import PropTypes from 'prop-types';
import React, { useState } from 'react';

import noop from 'lodash/noop';
import { useParams } from 'react-router-dom';

import HtmlContainer from 'components/HtmlContainer';
import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';
import useAPIRequest from 'hooks/useAPIRequest';

import AgencyDashboardCompaniesCompanyMessagesMessage from './AgencyDashboardCompaniesCompanyMessagesMessage';

const AgencyDashboardCompaniesCompanyMessages = ({
  messages = [],
  refetch = noop,
}) => {
  const [content, setContent] = useState(null);
  const [editingMode, setEditingMode] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [editedContentHtml, setEditedContentHtml] = useState('');
  const { companyId } = useParams();

  const { fetchData: editMessage } = useAPIRequest({
    method: 'PUT',
    service: 'MOD',
  });

  const handleEdit = (message) => {
    setSelectedMessage(message);
    setEditingMode(true);
  };

  const handleSave = async (editedMessage) => {
    await editMessage({
      endpoint: `/companies/${companyId}/messages/${editedMessage.id}`,
      body: {
        text: editedMessage.text,
        html: editedMessage.html,
      },
    });

    await refetch();

    setEditingMode(false);
    setSelectedMessage(null);
  };

  return (
    <div className="shadow border-b border-gray-200 rounded-lg overflow-hidden">
      <div className="sm:flex sm:items-center px-4 pt-3.5">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Messages
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all unapproved messages
          </p>
        </div>
      </div>
      <div className="overflow-x-auto w-full">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="block py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Journalist
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Publication
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Content
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Actions</span>
              </th>

              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Edit
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            {messages?.length === 0 && (
              <tr>
                <td
                  className="text-center px-3 py-4 text-sm font-medium text-gray-500"
                  colSpan="6"
                >
                  No messages to approve
                </td>
              </tr>
            )}
            {messages?.map((message) => (
              <AgencyDashboardCompaniesCompanyMessagesMessage
                fetchData={refetch}
                onExpand={() => setContent(message)}
                onEdit={() => handleEdit(message)}
                key={message.id}
                message={message}
              />
            ))}
          </tbody>
        </table>
        {editingMode && (
          <Modal open={editingMode}>
            <Modal.Close onClose={() => setEditingMode(false)} />
            <Modal.Content className="flex flex-col space-y-4 pt-4">
              <InputFieldTextEditor
                height={500}
                initialValue={selectedMessage?.html ?? selectedMessage?.text}
                onChange={(htmlContent, plainTextContent) => {
                  setEditedContent(plainTextContent);
                  setEditedContentHtml(htmlContent);
                }}
              />
              <div className="flex justify-end space-x-4">
                <Button
                  onClick={() =>
                    handleSave({
                      ...selectedMessage,
                      text: editedContent,
                      html: editedContentHtml,
                    })
                  }
                >
                  Save
                </Button>
                <Button onClick={() => setEditingMode(false)}>Cancel</Button>
              </div>
            </Modal.Content>
          </Modal>
        )}
        <Modal open={Boolean(content)}>
          <Modal.Close onClose={() => setContent(null)} />
          <Modal.Content>
            <>
              <p className="text-lg font-semibold text-gray-900">
                {content?.subject}
              </p>
              <HtmlContainer htmlString={content?.html} />
            </>
          </Modal.Content>
        </Modal>
      </div>
    </div>
  );
};

AgencyDashboardCompaniesCompanyMessages.propTypes = {
  messages: PropTypes.array,
  refetch: PropTypes.func,
};

export default AgencyDashboardCompaniesCompanyMessages;
