import PropTypes from 'prop-types';
import React from 'react';

const NavbarInboxUnreadIcon = ({ unreadMessagesCount = 0 }) => {
  if (unreadMessagesCount === 0) {
    return null;
  }

  return (
    <div
      className="rounded-full bg-red-500 border-white text-white w-fit min-w-[20px] h-5 p-1 font-bold flex text-xs items-center justify-center"
      data-test-id="navbar-inbox-unreadIcon"
    >
      {unreadMessagesCount}
    </div>
  );
};

NavbarInboxUnreadIcon.propTypes = {
  unreadMessagesCount: PropTypes.number,
};
export default NavbarInboxUnreadIcon;
