import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

const CreateDocumentModal = ({ isOpen, onAbort, children }) => (
  <Modal open={isOpen} widthClass="max-w-4xl">
    <Modal.Close srMessage="Close create document modal" onClose={onAbort} />
    <Modal.Content>
      <div className="mb-4">{children}</div>
    </Modal.Content>
    <Modal.RightButtons>
      <Button type="secondary" className="w-fit" onClick={onAbort}>
        Cancel
      </Button>
    </Modal.RightButtons>
  </Modal>
);

CreateDocumentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAbort: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default CreateDocumentModal;
