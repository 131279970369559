import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { generatePath, Link } from 'react-router-dom';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import { SWEETHEART_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

const AnnouncementAttachListModal = ({
  open = false,
  onClose,
  onSelect,
  journalistLists = [],
  isJournalistListsLoading,
}) => {
  const { companyData } = useCompanyData();
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Close srMessage="Close journalist list modal" onClose={onClose} />
      <Modal.Title>Attach Journalist List</Modal.Title>
      <Modal.Content>
        {journalistLists.length === 0 ? (
          <div className="text-gray-600 text-center">
            No journalist lists available. You can create a new one in your{' '}
            <Link
              to={generatePath(SWEETHEART_PATH, {
                id: companyData.slug,
                tab: 'lists',
              })}
              className="inline-block"
            >
              <span className="bg-gray-100 text-teal-600 font-semibold px-2 py-1 rounded-md shadow-sm hover:bg-gray-200">
                Journalists
              </span>
            </Link>{' '}
            page.
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {isJournalistListsLoading && <Spinner />}
            {!isJournalistListsLoading &&
              journalistLists.map((list) => (
                <div
                  key={list.id}
                  className={classNames(
                    'p-4 rounded-md shadow',
                    list.isListAlreadySelected
                      ? 'bg-gray-200 cursor-not-allowed'
                      : 'bg-white cursor-pointer hover:bg-gray-100'
                  )}
                  onClick={() => {
                    if (!list.isListAlreadySelected) {
                      onSelect(list);
                    }
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="font-semibold text-lg line-clamp-1">
                        {list.name}
                      </h3>
                      <p className="text-sm text-gray-600 line-clamp-1">
                        {list.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};

AnnouncementAttachListModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  journalistLists: PropTypes.array.isRequired,
  isJournalistListsLoading: PropTypes.bool.isRequired,
};

export default AnnouncementAttachListModal;
