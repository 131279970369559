import { generatePath, redirect } from 'react-router-dom';

import journalistsTabs, { tabNames } from 'constants/journalistsTabs';
import { SWEETHEART_PATH } from 'constants/paths';

import SweetheartListPage from './SweetheartListPage';

const SweetheartListRoute = {
  path: SWEETHEART_PATH,
  authRequired: true,
  component: SweetheartListPage,
  loader: ({ params }) => {
    const { id, tab } = params;

    if (!tab || !journalistsTabs.find((t) => t.field === tab)) {
      return redirect(
        generatePath(SWEETHEART_PATH, {
          id,
          tab: tabNames.LISTS,
        })
      );
    }

    return null;
  },
};

export default SweetheartListRoute;
