import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { noop } from 'lodash';

import Button from 'components/buttons/Button';
import Label from 'components/form/Label';

import ContentManagementDocumentResponseFormEditIdeaModal from '../../ArticleIdea/ContentManagementDocumentResponseFormEditIdeaModal';

const ContentManagementResponseFormArticleIdea = ({
  idea = {},
  onEdit = noop,
}) => {
  const [isEditIdeaModalOpen, setIsEditIdeaModalOpen] = useState(false);

  const handleEditIdea = async (newIdea) => {
    onEdit(newIdea, setIsEditIdeaModalOpen);
  };

  return (
    <div>
      <Label htmlFor="article-idea">Article Idea</Label>
      <small className="mt-2 text-gray-400 text-xs">
        {`We'll make sure that the Generated Content is aligned with
                        the article idea`}
      </small>
      {Object.keys(idea).length !== 0 ? (
        <div key={idea.headline} className="space-y-4 mt-2">
          <div className="max-w-md md:max-w-2xl relative block rounded-lg border bg-white px-4 py-4 shadow-none">
            <div className="flex justify-between">
              <div className="flex flex-col space-y-1">
                {idea.headline && (
                  <span
                    id="server-size-0-label"
                    className="font-medium text-sm text-gray-900 line-clamp-1"
                  >
                    {idea.headline}
                  </span>
                )}
                {idea.description ? (
                  <span
                    id="server-size-0-description-0"
                    className="text-gray-400 text-sm line-clamp-2"
                  >
                    {idea.description}
                  </span>
                ) : (
                  <span
                    id="server-size-0-description-0"
                    className="text-gray-400"
                  >
                    <p>No description yet</p>
                  </span>
                )}
              </div>
              <Button
                type="secondary"
                className="px-2.5 py-1.5 bg-gray-50 border-gray-300 border rounded-md text-sm self-end"
                onClick={() => {
                  setIsEditIdeaModalOpen(true);
                }}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p className="mt-2 text-gray-400 text-sm">
            Creating an article idea allows us to help you create a great
            article outline from scratch using AI. We can also help you create
            article ideas here.
          </p>
          <Button
            type="secondary"
            className="mt-2"
            onClick={() => {
              setIsEditIdeaModalOpen(true);
            }}
          >
            Create article idea
          </Button>
        </div>
      )}

      {isEditIdeaModalOpen && (
        <ContentManagementDocumentResponseFormEditIdeaModal
          open={isEditIdeaModalOpen}
          idea={idea}
          onSubmit={handleEditIdea}
          onClose={() => {
            setIsEditIdeaModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

ContentManagementResponseFormArticleIdea.propTypes = {
  idea: PropTypes.object,
  onEdit: PropTypes.func,
};

export default ContentManagementResponseFormArticleIdea;
