/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Listbox, Portal } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import noop from 'lodash/lodash';
import { usePopper } from 'react-popper';

const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
};

const InputFieldMultiLevelDropdown = ({
  sameWidthOption = true,
  options = {},
  onChange = noop,
  value = '',
  wrapperClassName = '',
  buttonClassName = 'w-24',
  optionClassName = '',
  optionsClassName = '',
  testId = '',
  intercomId = '',
  name = '',
  label = '',
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [selectedOption, setSelectedOption] = useState(value);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const dropdownRef = useRef(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      sameWidthOption ? sameWidth : {},
    ],
  });

  const handleSelect = (option) => {
    setSelectedOption(option);
    onChange(option);
    setOpenSubMenu(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenSubMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={dropdownRef}>
      <Listbox
        as="div"
        value={selectedOption}
        onChange={handleSelect}
        className={wrapperClassName}
      >
        {({ open }) => {
          const buttonLabel =
            options.others.find((option) => option?.value === value)?.label ||
            value;
          const buttonChildren = (
            <span className="block truncate" data-test-id={`${testId}-value`}>
              {(typeof buttonLabel === 'function'
                ? buttonLabel()
                : buttonLabel) || <>&nbsp;</>}
            </span>
          );

          return (
            <div className="relative flex flex-col">
              {label && (
                <Listbox.Label className="block text-sm font-medium text-gray-700 cursor-pointer mb-2">
                  {label}
                </Listbox.Label>
              )}
              <span className="block w-full rounded-md">
                <Listbox.Button
                  id={name}
                  onMouseDown={() => setOpenSubMenu(null)}
                  className={classNames(
                    'focus:border-teal-500 flex gap-2 items-center cursor-default relative rounded-md border border-gray-300 bg-white text-left focus:outline-none text-sm sm:leading-5',
                    'pl-3 pr-9 py-2 shadow-sm',
                    buttonClassName
                  )}
                  onBlur={() => setOpenSubMenu(null)}
                  ref={setReferenceElement}
                  data-test-id={testId}
                  data-intercom-id={intercomId}
                >
                  {buttonChildren}
                  <ChevronDownIcon className="w-4 h-4 text-gray-400 absolute right-3" />
                </Listbox.Button>
              </span>

              {open ? (
                <Portal>
                  <Listbox.Options
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    static
                    className={classNames(
                      'bg-white max-h-60 rounded-md py-1 text-sm leading-6 overflow-auto focus:outline-none sm:leading-5 z-10 border-gray-300 border shadow-sm',
                      optionsClassName
                    )}
                  >
                    {options.others.map((option) => {
                      const { value: optionValue = option } =
                        typeof option === 'string' ? {} : option;

                      return (
                        <Listbox.Option
                          key={optionValue}
                          value={optionValue}
                          disabled={option.disabled}
                          onClick={(e) => {
                            if (option.label === 'Press Release') {
                              e.preventDefault();

                              if (openSubMenu === option.value) {
                                setOpenSubMenu(null);
                              } else {
                                setOpenSubMenu(option.value);
                              }
                            } else {
                              handleSelect(option.value);
                            }
                          }}
                          className={optionClassName}
                        >
                          <div
                            key={option.value}
                            className="flex justify-between overflow-hidden px-4 py-2 cursor-pointer hover:bg-gray-100"
                            title={option.label}
                          >
                            <span className="block text-left truncate">
                              {option.label}
                            </span>

                            {option.subOptions && (
                              <ChevronRightIcon
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Portal>
              ) : null}

              {openSubMenu && (
                <ul
                  className="absolute left-0 ml-64 mt-2 w-56 bg-white max-h-60 rounded-md py-1 text-sm leading-6 overflow-auto focus:outline-none sm:leading-5 z-10 border-gray-300 border shadow-sm"
                  onMouseLeave={() => setOpenSubMenu(null)}
                >
                  {options.announcements.map((subOption) => (
                    <div
                      key={subOption.value}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={(e) => {
                        e.stopPropagation();

                        onChange(subOption.value);
                        setOpenSubMenu(null);
                      }}
                    >
                      {subOption.label}
                    </div>
                  ))}
                </ul>
              )}
            </div>
          );
        }}
      </Listbox>
    </div>
  );
};

InputFieldMultiLevelDropdown.propTypes = {
  sameWidthOption: PropTypes.bool,
  optionWithCheckbox: PropTypes.bool,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  wrapperClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  optionsClassName: PropTypes.string,
  testId: PropTypes.string,
  intercomId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default InputFieldMultiLevelDropdown;
