import {
  AdjustmentsVerticalIcon,
  StarIcon,
  NewspaperIcon,
  UsersIcon,
  CurrencyPoundIcon,
} from '@heroicons/react/20/solid';

import {
  ADMIN_OPPORTUNITIES_PATH,
  ADMIN_CREATE_OPPORTUNITY_PATH,
  ADMIN_PUBLICATIONS_PATH,
  ADMIN_JOURNALISTS_PATH,
  ADMIN_COVERAGES_PATH,
} from 'constants/paths';

const adminNavigation = [
  {
    title: 'Opportunities',
    description: 'Assign/Unassign companies to and from media opportunities',
    href: ADMIN_OPPORTUNITIES_PATH,
    icon: AdjustmentsVerticalIcon,
  },
  {
    title: 'Custom opportunities',
    description: 'Create custom opportunities for specific companies',
    href: ADMIN_CREATE_OPPORTUNITY_PATH,
    icon: StarIcon,
  },
  {
    title: 'Publications',
    description:
      'A list of publications, to manage images and domain authority',
    href: ADMIN_PUBLICATIONS_PATH,
    icon: NewspaperIcon,
  },
  {
    title: 'Journalists',
    description:
      'A list of journalists, to manage images, email and phone number',
    href: ADMIN_JOURNALISTS_PATH,
    icon: UsersIcon,
  },
  {
    title: 'Coverage',
    description: 'A list of the coverage in the media',
    href: `${ADMIN_COVERAGES_PATH}`,
    icon: CurrencyPoundIcon,
  },
];

export default adminNavigation;
