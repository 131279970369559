import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'reactfire';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useQueryParams from 'routes/Opportunities/hooks/useQueryParams';
import useSearchTerms from 'routes/Opportunities/hooks/useSearchTerms';

import useOpportunitiesBySearch from './useOpportunitiesBySearch';

const declineOpportunity = async ({ opportunityId, companyId, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/companies/${companyId}/opportunities/${opportunityId}/decline`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to decline opportunity: ${error.message}`);
  }
};

const useDeclineOpportunity = () => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { companyData } = useCompanyData();

  const { terms } = useSearchTerms();
  const queryParams = useQueryParams();
  const { queryKey } = useOpportunitiesBySearch({
    searchTerms: terms,
    queryParams,
    companyId: companyData?.uid,
  });

  return useMutation({
    mutationFn: ({ companyId, opportunityId }) =>
      declineOpportunity({ companyId, opportunityId, auth }),
    onMutate: async ({ companyId, opportunityId }) => {
      await queryClient.cancelQueries(queryKey);
      const previousOppData = queryClient.getQueryData(queryKey);
      const newOppData = queryClient.setQueryData(queryKey, (old) => ({
        ...old,
        items: old.items.map((item) => {
          if (item.id === opportunityId) {
            return {
              ...item,
              declinedCompanies: [...item.declinedCompanies, companyId],
            };
          }
          return item;
        }),
      }));

      return { previousOppData, newOppData };
    },

    // eslint-disable-next-line max-params
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context.previousOppData);
    },
  });
};

export default useDeclineOpportunity;
