import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

const useGetCurrentThread = (threads = []) => {
  const { threadId } = useParams();

  return useMemo(() => {
    if (!threads || !threads.length || !threadId) {
      return null;
    }

    return threads.find((t) => t.id === threadId) || null;
  }, [threadId, threads]);
};

export default useGetCurrentThread;
