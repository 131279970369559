const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const isPresentYear = (selectedYear) =>
  new Date().getFullYear() === selectedYear;

export const isPresentMonth = (selectedMonth) =>
  new Date().getMonth() === months.indexOf(selectedMonth);

export const isPresentDay = (selectedDay) =>
  new Date().getDate() === selectedDay;

// eslint-disable-next-line max-params
const prepareDataSetForHour = (max, min, increment = 60) =>
  Array.from({ length: max - min + 1 }, (_, i) => {
    const hour = `${min + i}`.padStart(2, '0');
    return Array.from({ length: 60 / increment }, (__, j) => {
      const minutes = `${j * increment}`.padStart(2, '0');
      return `${hour}:${minutes}`;
    });
  }).flat();

const prepareDataSet = (max, min) =>
  Array.from({ length: max - min + 1 }, (_, i) => `${min + i}`);

export const getNumDaysInMonth = (year, month) =>
  new Date(year, months.indexOf(month) + 1, 0).getDate();

export const createDate = ({ day, month, year, hour }) => {
  const newDate = new Date();

  if (year) {
    newDate.setFullYear(year);
  }

  if (month) {
    newDate.setMonth(months.indexOf(month));
  }

  if (day) {
    newDate.setDate(day);
  }

  if (hour) {
    const [hours, minutes] = hour.split(':');
    newDate.setHours(hours, minutes, 0, 0);
  }

  return newDate;
};

export const getNumPastYearsSince = (year) => new Date().getFullYear() - year;

export const updateInvalidSelections = ({
  selection,
  updateSelections,
  validOptionRanges,
}) => {
  const { selectedHour, selectedDay, selectedMonth, selectedYear } = selection;
  const isSelectedDayValid = validOptionRanges.days.includes(selectedDay);
  const isSelectedMonthValid = validOptionRanges.months.includes(selectedMonth);
  const isSelectedYearValid = validOptionRanges.years.includes(selectedYear);
  const isSelectedHourValid = validOptionRanges.hours.includes(selectedHour);

  if (
    (selectedDay !== undefined && !isSelectedDayValid) ||
    (selectedMonth !== undefined && !isSelectedMonthValid) ||
    (selectedYear !== undefined && !isSelectedYearValid) ||
    (selectedHour !== undefined && !isSelectedHourValid)
  ) {
    updateSelections({
      day: isSelectedDayValid ? selectedDay : validOptionRanges.days[0],
      month: isSelectedMonthValid ? selectedMonth : validOptionRanges.months[0],
      year: isSelectedYearValid ? selectedYear : validOptionRanges.years[0],
      hour: isSelectedHourValid ? selectedHour : validOptionRanges.hours[0],
    });
  }
};

export const generateAnyRanges = ({
  numDaysInMonth,
  numPastYears,
  numFutureYears,
  increment,
}) => {
  const generateDays = () =>
    prepareDataSet(numDaysInMonth, 1).map((day) => Number.parseInt(day, 10));

  const generateYears = () =>
    prepareDataSet(
      new Date().getFullYear() + numFutureYears,
      new Date().getFullYear() - numPastYears
    ).map((year) => Number.parseInt(year, 10));

  return {
    days: generateDays(),
    years: generateYears(),
    months,
    hours: prepareDataSetForHour(23, 0, increment),
  };
};

export const generateFutureRanges = ({
  isPresentDayBool,
  isPresentMonthBool,
  isPresentYearBool,
  numDaysInMonth,
  numFutureYears,
  increment,
}) => {
  const generateFutureYears = () =>
    prepareDataSet(
      new Date().getFullYear() + numFutureYears,
      new Date().getFullYear()
    ).map((year) => Number.parseInt(year, 10));

  const generateFutureMonths = () => {
    if (isPresentYearBool) {
      return months.slice(new Date().getMonth());
    }
    return months;
  };

  const generateFutureDays = () => {
    if (isPresentYearBool && isPresentMonthBool) {
      return prepareDataSet(numDaysInMonth, new Date().getDate()).map((day) =>
        Number.parseInt(day, 10)
      );
    }

    return prepareDataSet(numDaysInMonth, 1).map((day) =>
      Number.parseInt(day, 10)
    );
  };

  const generateFutureHours = () => {
    if (isPresentYearBool && isPresentMonthBool && isPresentDayBool) {
      return prepareDataSetForHour(23, new Date().getHours(), increment).filter(
        (hour) => {
          const [hours, minutes] = hour
            .split(':')
            .map((v) => Number.parseInt(v, 10));

          if (hours === new Date().getHours()) {
            return minutes > new Date().getMinutes();
          }

          return true;
        }
      );
    }

    return prepareDataSetForHour(23, 0, increment);
  };

  return {
    days: generateFutureDays(),
    years: generateFutureYears(),
    months: generateFutureMonths(),
    hours: generateFutureHours(),
  };
};

export const generatePastRanges = ({
  isPresentDayBool,
  isPresentMonthBool,
  isPresentYearBool,
  numDaysInMonth,
  numPastYears,
  increment,
}) => {
  const generatePastYears = () =>
    prepareDataSet(
      new Date().getFullYear(),
      new Date().getFullYear() - numPastYears
    ).map((year) => Number.parseInt(year, 10));

  const generatePastMonths = () => {
    if (isPresentYearBool) {
      return months.slice(0, new Date().getMonth() + 1);
    }
    return months;
  };

  const generatePastDays = () => {
    if (isPresentYearBool && isPresentMonthBool) {
      return prepareDataSet(new Date().getDate(), 1).map((day) =>
        Number.parseInt(day, 10)
      );
    }

    return prepareDataSet(numDaysInMonth, 1).map((day) =>
      Number.parseInt(day, 10)
    );
  };

  const generatePastHours = () => {
    if (isPresentYearBool && isPresentMonthBool && isPresentDayBool) {
      return prepareDataSetForHour(new Date().getHours(), 0, increment).filter(
        (hour) => {
          const [hours, minutes] = hour
            .split(':')
            .map((v) => Number.parseInt(v, 10));
          if (hours === new Date().getHours()) {
            return minutes < new Date().getMinutes();
          }

          return true;
        }
      );
    }

    return prepareDataSetForHour(23, 0, increment);
  };

  return {
    days: generatePastDays(),
    years: generatePastYears(),
    months: generatePastMonths(),
    hours: generatePastHours(),
  };
};
