import React, { useState } from 'react';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import { parseReference } from './Tasks/TasksTab';
import useTasks from './Tasks/hooks/useTasks';

const generateLines = (tasks, slug) =>
  tasks
    .map((task) => {
      const { reference } = parseReference(task.reference, slug);
      return `- ${task.title} ${reference || task.externalLink} `;
    })
    .join('\n');

// eslint-disable-next-line max-params
const generateSummary = (tasks, companyName, slug) => {
  const agencyTasks = tasks.filter((task) => task.withAgency);
  const clientTasks = tasks.filter((task) => !task.withAgency);
  const agencyTasksSummary = generateLines(agencyTasks, slug);
  const clientTasksSummary = generateLines(clientTasks, slug);

  return `*${companyName}*

${clientTasksSummary}


*MVPR*

${agencyTasksSummary}`;
};

const PlanningNavbarGenerateSummary = () => {
  const { companyData: { uid, slug, name } = {} } = useCompanyData();
  const { claimsAdmin: isAdmin } = useUserData();
  const [showGenerateSummaryModal, setShowGenerateSummaryModal] =
    useState(false);
  const { data: tasks } = useTasks(uid);
  const [summary, setSummary] = useState('');

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => {
          setSummary(generateSummary(tasks, name, slug));
          setShowGenerateSummaryModal(true);
        }}
      >
        Generate Summary
      </Button>
      {showGenerateSummaryModal ? (
        <Modal open>
          <Modal.Title>Summary</Modal.Title>
          <Modal.Content className="flex flex-col gap-2">
            <InputfieldTextArea rows={10} value={summary} />
          </Modal.Content>
          <Modal.RightButtons>
            <Button onClick={() => setShowGenerateSummaryModal(false)}>
              Close
            </Button>
          </Modal.RightButtons>
        </Modal>
      ) : null}
    </>
  );
};

export default PlanningNavbarGenerateSummary;
