import { useMemo, useEffect } from 'react';

import { useQuery, useQueryClient } from 'react-query';
import { useAuth } from 'reactfire';

const fetchJournalistData = async ({ id, auth }) => {
  const BASE_URL = process.env.REACT_APP_JPD_URL;
  const endpoint = `/journalists/${id}`;
  const jwt = await auth.currentUser?.getIdToken();

  const response = await fetch(`${BASE_URL}${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData?.msg || 'API call failed');
  }

  return response.json();
};

const useJournalistsFromStories = (stories) => {
  const queryClient = useQueryClient();
  const auth = useAuth();

  const uniqueJournalistIds = useMemo(
    () => [...new Set(stories?.map((s) => s.journalist.id))],
    [stories]
  );

  const fetchAllJournalistsData = () =>
    Promise.all(
      uniqueJournalistIds.map((id) => fetchJournalistData({ id, auth }))
    );

  const {
    data: journalistsData,
    error,
    isLoading,
    refetch,
  } = useQuery(
    ['journalistsData', uniqueJournalistIds],
    fetchAllJournalistsData,
    {
      enabled: uniqueJournalistIds.length > 0,
      initialData: [],
      refetchOnWindowFocus: false,
      staleTime: 60000,
    }
  );

  useEffect(() => {
    if (uniqueJournalistIds.length) {
      queryClient.invalidateQueries(['journalistsData']);
      refetch();
    }
  }, [stories, uniqueJournalistIds, refetch, queryClient]);

  return {
    journalistsData,
    isLoadingJournalistsData: isLoading,
    journalistsDataError: error,
    getJournalistsData: refetch,
  };
};

export default useJournalistsFromStories;
