import PropTypes from 'prop-types';
import React from 'react';

import PageWrapper from 'components/PageWrapper';

import AgencyNavbar from './AgencyNavbar/AgencyNavbar';
import AgencyWrapperBreadcrumbs from './AgencyWrapperBreadcrumbs';

const AgencyWrapper = ({ children, title, showBreadCrumbs = true }) => (
  <>
    <AgencyNavbar />
    <PageWrapper>
      <div className="flex flex-col gap-2 mb-4">
        <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
        {showBreadCrumbs ? <AgencyWrapperBreadcrumbs /> : null}
      </div>
      <div>{children}</div>
    </PageWrapper>
  </>
);

AgencyWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  showBreadCrumbs: PropTypes.bool,
};

export default AgencyWrapper;
