import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  verifyBeforeUpdateEmail,
  updatePassword,
} from 'firebase/auth';
import { Link } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

import AccountFormPasswordProviderCreate from './AccountFormPasswordProviderCreate';
import UpdateEmailModal from './UpdateEmailModal';
import UpdatePasswordModal from './UpdatePasswordModal';

const AccountFormPasswordProvider = ({
  user = {},
  passwordProvider = null,
}) => {
  const { showSuccess, showError } = useNotifications();
  const [updateEmailModalOpen, setUpdateEmailModalOpen] = useState(false);
  const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState(false);

  const onSubmitEmail = async ({ password, newEmail }) => {
    try {
      const credentials = EmailAuthProvider.credential(
        passwordProvider.email,
        password
      );

      await reauthenticateWithCredential(user, credentials);
      await verifyBeforeUpdateEmail(user, newEmail);

      setUpdateEmailModalOpen(false);
      showSuccess({
        title: 'Email address updated',
        message: 'Please check your inbox',
      });
    } catch (e) {
      setUpdateEmailModalOpen(false);
      showError({
        title: 'Something went wrong',
        message: 'Please try again!',
      });
    }
  };

  const onSubmitPassword = async ({ password, newPassword }) => {
    try {
      const credentials = EmailAuthProvider.credential(
        passwordProvider.email,
        password
      );

      await reauthenticateWithCredential(user, credentials);
      await updatePassword(user, newPassword);

      setUpdatePasswordModalOpen(false);
      showSuccess({ message: 'Your password has been updated' });
    } catch (e) {
      setUpdatePasswordModalOpen(false);
      showError({
        title: 'Something went wrong',
        message: 'Please try again!',
      });
    }
  };

  if (!passwordProvider) {
    return <AccountFormPasswordProviderCreate />;
  }

  return (
    <div className="flex flex-col gap-2 w-full">
      <InputFieldText
        name="email"
        defaultValue={passwordProvider.email}
        disabled
      >
        <InputFieldText.Label>Email</InputFieldText.Label>
      </InputFieldText>

      <div className="flex gap-2">
        <Button
          href="#"
          type="secondary"
          onClick={(e) => {
            e.preventDefault();
            setUpdateEmailModalOpen(true);
          }}
        >
          Change email
        </Button>
        <Link
          to="#"
          className="font-medium text-sm text-gray-700 hover:text-gray-500 py-2 px-3"
          onClick={(e) => {
            e.preventDefault();
            setUpdatePasswordModalOpen(true);
          }}
        >
          Reset password
        </Link>
      </div>

      <UpdateEmailModal
        open={updateEmailModalOpen}
        onClose={() => setUpdateEmailModalOpen(false)}
        onSubmit={onSubmitEmail}
      />

      <UpdatePasswordModal
        open={updatePasswordModalOpen}
        onClose={() => setUpdatePasswordModalOpen(false)}
        onSubmit={onSubmitPassword}
      />
    </div>
  );
};

AccountFormPasswordProvider.propTypes = {
  user: PropTypes.object,
  passwordProvider: PropTypes.object,
};

export default AccountFormPasswordProvider;
