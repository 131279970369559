import React from 'react';

import { useParams } from 'react-router-dom';

import Card from 'components/Card';
import UserAvatar from 'components/UserAvatar';
import Tag from 'components/tags/Tag';

import AgencyDashboardSettingsMembersAdd from './AgencyDashboardSettingsMembersAdd';
import useAgencyMembers from './hooks/useAgencyMembers';

const AgencyDashboardSettingsMembers = () => {
  const { agencyId } = useParams();
  const { invites, members } = useAgencyMembers(agencyId);

  return (
    <Card title="Members">
      <div>
        <ul>
          {members.map((m) => (
            <li className="flex justify-between mb-2" key={m.id}>
              <div className="flex min-w-0 gap-x-4">
                <UserAvatar
                  showUserPhoto={false}
                  image={{ path: m.photoUrl }}
                  className="w-10 h-10"
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-5 text-gray-900">
                    {m.firstName} {m.lastName}
                  </p>
                  <p className="text-sm leading-4 text-gray-500">{m.email}</p>
                </div>
              </div>
            </li>
          ))}
          {invites.map((i) => (
            <li className="flex justify-between mb-2" key={i.token}>
              <div className="flex min-w-0 flex-auto max-w-sm gap-x-4">
                <UserAvatar showUserPhoto={false} className="w-10 h-10" />
                <div className="flex min-w-0 flex-auto">
                  <p className="text-sm text-gray-500 self-center whitespace-normal break-all max-w-32">
                    {i.email}
                  </p>
                </div>
                <div className="self-center">
                  <Tag bold bgColor="teal-500" textColor="white">
                    Pending
                  </Tag>
                </div>
              </div>
            </li>
          ))}
        </ul>

        <AgencyDashboardSettingsMembersAdd />
      </div>
    </Card>
  );
};

export default AgencyDashboardSettingsMembers;
