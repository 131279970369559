import React from 'react';

const DashLine = () => (
  <svg
    className="h-1 w-full"
    viewBox="0 0 100 1"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H100"
      stroke="#d1d5db"
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray="4 8"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

export default DashLine;
