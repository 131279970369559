import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import AvatarListAvatar from './AvatarListAvatar';

const sizes = {
  sm: 'h-6 w-6',
  md: 'h-8 w-8',
  lg: 'h-10 w-10',
};

const AvatarList = ({ users = [], size = 'sm' }) => {
  const avatarContainerRef = useRef(null);
  const [avatarsInView, setAvatarsInView] = useState(users.map(() => true));
  const indexOfFirstAvatarNotInView = avatarsInView.findIndex(
    (avatarInView) => !avatarInView
  );

  return (
    <div className="relative overflow-hidden">
      <div
        ref={avatarContainerRef}
        className="relative items-center flex p-0.5 -space-x-1 "
      >
        {users.map((user, index) => {
          const isLast = index === users.length - 1;
          const shouldObserve = isLast || !avatarsInView[user.length - 1];

          return (
            <AvatarListAvatar
              containerRef={avatarContainerRef}
              user={user}
              key={user.id}
              size={sizes[size]}
              index={index}
              setAvatarsInView={setAvatarsInView}
              isLast={isLast}
              {...(indexOfFirstAvatarNotInView > -1
                ? {
                    hiddenUsersCount:
                      users.length + 1 - indexOfFirstAvatarNotInView,
                    shouldShowPlaceholder:
                      index === indexOfFirstAvatarNotInView - 1,
                    shouldHide: index >= indexOfFirstAvatarNotInView,
                  }
                : {})}
              shouldObserve={shouldObserve}
            />
          );
        })}
      </div>
    </div>
  );
};

AvatarList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      photoUrl: PropTypes.string,
    })
  ),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default AvatarList;
