import PropTypes from 'prop-types';
import React from 'react';

import { noop } from 'lodash';
import findIndex from 'lodash/findIndex';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import intents from 'constants/intents';
import { tagsIntent } from 'constants/tags';
import getTagColors from 'utils/color/getTagColors';

const createClassName = (tag) => {
  const tagIndex = findIndex(tagsIntent, (t) => t.slug === tag.slug);
  const [bgColor, textColor] = getTagColors(tagIndex, tag.name?.length);

  return `bg-${bgColor} text-${textColor} rounded-md w-fit px-2 py-0.5 items-center flex`;
};

const types = [
  intents.opportunities.OPPORTUNITY,
  intents.opportunities.CUSTOM_OPPORTUNITY,
  intents.sweetheartList.OP_ED,
  intents.sweetheartList.OUTREACH,
  intents.sweetheartList.PRESS_RELEASE,
].map((type) => ({
  label: () => {
    const tag = tagsIntent.find((t) => t.slug === type);
    return <div className={createClassName(tag)}>{tag.name}</div>;
  },
  value: type,
}));

const IntentsDropdown = ({
  allOption = { label: 'All types', value: 'all' },
  onChange = noop,
  value = 'all',
}) => (
  <InputFieldDropdown
    onChange={onChange}
    placeholder="Select type"
    options={[allOption, ...types]}
    value={value}
    wrapperClassName="md:w-52 w-full min-[390px]:w-fit md:shrink-0"
    buttonClassName="w-full h-[38px] "
    optionsClassName="z-20"
    sameWidthOption
  />
);

IntentsDropdown.propTypes = {
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default IntentsDropdown;
