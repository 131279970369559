import React from 'react';

import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import IntentsDropdown from 'components/IntentsDropdown';
import { MESSAGES_PATH_FULL } from 'constants/paths';

const InboxNavbarThreadTypes = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <IntentsDropdown
      onChange={(newType) => {
        searchParams.set('intent', newType);
        navigate({
          pathname: generatePath(MESSAGES_PATH_FULL, {
            id,
          }),
          search: searchParams.toString(),
        });
      }}
      value={searchParams.get('intent') || 'all'}
    />
  );
};

export default InboxNavbarThreadTypes;
