import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { uniqBy } from 'lodash';
import noop from 'lodash/noop';
import { useParams } from 'react-router-dom';

import SearchJournalistModal from 'components/SearchJournalistModal';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

const SweetheartListAddJournalistModal = ({ onAbort = noop }) => {
  const [selectedJournalists, setSelectedJournalists] = useState([]);
  const { listId } = useParams();
  const {
    journalistList: { journalists = [] },
    insertJournalist,
  } = useSweetheartListContext();

  const handleAddJournalist = (journalist) => {
    insertJournalist({ listId, journalistId: journalist.id });
    setSelectedJournalists((prevJournalists) =>
      uniqBy([...prevJournalists, journalist], 'id')
    );
  };

  useEffect(() => {
    setSelectedJournalists((prevJournalists) =>
      uniqBy([...prevJournalists, ...journalists], 'id')
    );
  }, [journalists]);

  return (
    <SearchJournalistModal
      selected={selectedJournalists}
      onCancel={onAbort}
      onSelect={handleAddJournalist}
    />
  );
};

SweetheartListAddJournalistModal.propTypes = {
  onAbort: PropTypes.func,
};

export default SweetheartListAddJournalistModal;
