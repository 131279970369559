import { useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { useInboxContext } from '../context/InboxContext';

import useScrollIntoView from './useScrollIntoView';

const useManageScrollIntoView = () => {
  const { messageId, threadId } = useParams();
  const { threads, threadDetails } = useInboxContext();

  const getMessagesElement = useCallback(() => {
    if (threadDetails.loading) {
      return null;
    }
    if (messageId) {
      return document.getElementById(`inbox-messages-message-${messageId}`);
    }
    return document.getElementById(`inbox-messages-context-${threadId}`);
  }, [messageId, threadId, threadDetails.loading]);

  const getThreadsElement = useCallback(() => {
    if (threads.loading) {
      return null;
    }
    return document.getElementById(`inbox-threads-thread-${threadId}`);
  }, [threadId, threads.loading]);

  useScrollIntoView(getMessagesElement, true);
  useScrollIntoView(getThreadsElement);
};

export default useManageScrollIntoView;
