import React from 'react';

import AgencyNavbarContent from './AgencyNavbarContent';

const AgencyNavbarDesktop = () => (
  <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
    <div className="flex grow flex-col gap-y-2 overflow-y-auto border-r border-gray-200 bg-white px-4 pb-4">
      <AgencyNavbarContent />
    </div>
  </div>
);

export default AgencyNavbarDesktop;
