import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'reactfire';

import useThreadsSearchParams from './useThreadsSearchParams';

const upsertCoverage = async ({ companyId, coverage, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/companies/${companyId}/coverages`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(coverage),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to record coverage: ${error.message}`);
  }
};

const useRecordCoverage = (companyId, customKey) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const [searchParams] = useThreadsSearchParams();
  const threadsKey = ['companyThreads', companyId, searchParams.toString()];

  const { mutateAsync } = useMutation({
    mutationFn: (coverage) => upsertCoverage({ companyId, coverage, auth }),
    onSettled: () => {
      queryClient.invalidateQueries(customKey || threadsKey);
    },
  });

  return mutateAsync;
};

export default useRecordCoverage;
