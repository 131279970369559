import React from 'react';

import SwiperCore, { Navigation } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import PlanningNavbarGenerateSummary from './PlanningNavbarGenerateSummary';
import PlanningNavbarTabs from './PlanningNavbarTabs';

SwiperCore.use([Navigation]);

const PlanningNavbar = () => (
  <div className="sticky top-0 z-50 -mx-6 -mt-6 mb-8">
    <div className="w-full pr-2 flex justify-between items-center bg-white shadow-sm text-gray-300 border-b border-gray-200">
      <PlanningNavbarTabs />
      <PlanningNavbarGenerateSummary />
    </div>
  </div>
);

export default PlanningNavbar;
