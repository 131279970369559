import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from 'react-tooltip';

import StripeRedirectModal from 'components/StripeRedirectModal';
import Button from 'components/buttons/Button';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useRedirectToStripePortal from 'hooks/useRedirectToStripePortal';
import { useTracking } from 'modules/segment/TrackingProvider';

import useCompanySubscription from '../../hooks/useCompanySubscription';

const BillingSettingsSubscriptionUpdateButton = ({
  portalConfigName = '',
  action = 'subscription_update',
  subscriptionName = '',
}) => {
  const trackingService = useTracking();
  const { companyData } = useCompanyData();
  const { uid } = useUserData();
  const { data: subscription } = useCompanySubscription(companyData?.uid);

  const { redirectToStripePortal, isRedirecting } =
    useRedirectToStripePortal(portalConfigName);

  const onClick = async (event) => {
    await trackingService.track('subscription_update', {
      action,
      stripe_subscriptionName: subscriptionName,
    });
    redirectToStripePortal(event);
  };

  return (
    <>
      <Button
        disabled={subscription.subscriptionOwner.id !== uid}
        className="col-span-2 w-fit"
        type="secondary"
        onClick={onClick}
        data-tooltip-id={
          subscription.subscriptionOwner.id !== uid
            ? 'subscription-button-explanation'
            : null
        }
        data-tooltip-html={`<span className="text-center">Only <b>${subscription.subscriptionOwner.email}</b> <br/> can change this subscription</span>`}
      >
        {action === 'reactivate'
          ? 'Reactivate your subscription'
          : 'Update your subscription'}
      </Button>
      <StripeRedirectModal isRedirecting={isRedirecting} />
      <Tooltip id="subscription-button-explanation" className="text-sm" />
    </>
  );
};

BillingSettingsSubscriptionUpdateButton.propTypes = {
  portalConfigName: PropTypes.string,
  action: PropTypes.oneOf(['update', 'reactivate']),
  subscriptionName: PropTypes.string,
};

export default BillingSettingsSubscriptionUpdateButton;
