import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const UCD_URL = process.env.REACT_APP_UCD_URL;

const useCompanyTier = (companyId = '') => {
  const auth = useAuth();

  return useQuery(
    ['companies', companyId, 'tier'],
    async () => {
      if (!companyId) {
        throw new Error('Company ID is required');
      }

      const { currentUser } = auth;
      if (!currentUser) {
        throw new Error('User is not authenticated');
      }

      const jwt = await currentUser.getIdToken();
      const response = await fetch(`${UCD_URL}/companies/${companyId}/tier`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Failed fetching tier: ${errorMessage}`);
      }

      return response.json();
    },
    {
      enabled: !!companyId,
      retry: false,
    }
  );
};

export default useCompanyTier;
