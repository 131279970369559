import React from 'react';

const NavbarLinksNewBadge = () => (
  <div
    className="rounded-full bg-teal-500 text-white w-fit h-4 py-3 px-2 flex text-xs items-center justify-center -mr-2"
    data-test-id="navbar-inbox-unreadIcon"
  >
    New
  </div>
);

export default NavbarLinksNewBadge;
