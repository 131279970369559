import PropTypes from 'prop-types';
import React from 'react';

import { Link, generatePath, useParams } from 'react-router-dom';

import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';
import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';

import DocumentTag from './ContentManagementDocuments/DocumentTag';

const ContentManagementDocumentList = ({
  documents,
  currentDocument,
  slug,
}) => {
  const { tab } = useParams();

  return (
    <ul className="grid grid-cols-1 gap-6 border-b border-gray-200 py-6 pt-0 sm:grid-cols-2">
      {documents?.map((document) => {
        const formattedDate = getDateFromTimestamp(document.draft?.lastEdited);

        return (
          <li
            key={document.id}
            className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
          >
            <Link
              to={generatePath(CONTENT_MANAGEMENT_PATH, {
                id: slug,
                documentId: document.id,
                tab,
              })}
              className={`flex w-full h-full rounded-lg items-center justify-between space-x-6 p-6 ${
                currentDocument?.id === document.id
                  ? 'bg-gray-100'
                  : 'hover:bg-gray-50'
              }`}
            >
              <div className="flex-1 truncate">
                <div>
                  <DocumentTag type={document.type} />
                </div>
                <div className="flex items-center mt-2 space-x-3">
                  <h3 className="truncate text-sm font-medium text-gray-900">
                    {document.title || 'Blank document'}
                  </h3>
                </div>
                <span className="mt-2 truncate text-xs font-light text-gray-500">
                  {document.draft?.lastEdited
                    ? `Last edited ${formattedDate}`
                    : 'Not edited yet'}
                </span>
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

ContentManagementDocumentList.propTypes = {
  documents: PropTypes.array,
  currentDocument: PropTypes.object,
  slug: PropTypes.string,
};

export default ContentManagementDocumentList;
