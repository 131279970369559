import PropTypes from 'prop-types';
import React from 'react';

const ReportsCard = ({ title = '', subTitle = '', value = '' }) => (
  <dl className="mt-4">
    <dt className="truncate text-sm font-medium text-gray-500">{title}</dt>
    <dt className="text-xxs font-medium text-gray-400 leading-tight mt-1">
      {subTitle}
    </dt>

    <dd className="mt-1 text-5xl font-semibold tracking-tight text-gray-900">
      {value}
    </dd>
  </dl>
);

ReportsCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ReportsCard;
