import React, { useMemo } from 'react';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';

import ReportsCoverage from './ReportsCoverage/ReportsCoverage';
import useCompanyCoverages from './ReportsCoverage/useCompanyCoverages';
import ReportsJournalist from './ReportsJournalist';
import ReportsPageBeingGenerated from './ReportsPageBeingGenerated';

const Reports = () => {
  const { companyData } = useCompanyData();

  const { data: stats, loading } = useAPIRequest({
    service: 'UCD',
    endpoint: `/company/${companyData.uid}/stats`,
    method: 'GET',
    initialData: [],
  });
  const coverages = useCompanyCoverages(companyData.uid);

  const hasCompanyStats = Boolean(Object.keys(stats).length);

  const isEmpty = useMemo(() => {
    const received = stats?.allTime?.messagesReceived ?? 0;
    const sent = stats?.allTime?.messagesSent ?? 0;
    const pressCoveragesNumber = coverages.data.items.length ?? 0;
    return received === 0 && sent === 0 && pressCoveragesNumber === 0;
  }, [
    stats?.allTime?.messagesReceived,
    stats?.allTime?.messagesSent,
    coverages.data.items.length,
  ]);

  return (
    <div>
      <div className="flex flex-col gap-6">
        {hasCompanyStats && (
          <>
            <h1 className="flex items-center text-3xl font-semibold text-gray-800">
              Reports
            </h1>

            <ReportsCoverage
              coverages={coverages.data.items}
              companyData={companyData}
            />
            <ReportsJournalist stats={stats} />
          </>
        )}

        {!loading && !hasCompanyStats && isEmpty && (
          <ReportsPageBeingGenerated />
        )}
      </div>
    </div>
  );
};

Reports.propTypes = {};

export default Reports;
