// import tailwindColors from 'tailwindcss/colors';

// const allowedColors = [
//   'red',
//   'orange',
//   'amber',
//   'yellow',
//   'lime',
//   'green',
//   'emerald',
//   'teal',
//   'cyan',
//   'sky',
//   'blue',
//   'indigo',
//   'violet',
//   'purple',
//   'fuchsia',
//   'pink',
//   'rose',
//   'lightBlue',
// ];

export const colors = [
  '#14b8a6', // Largest portion
  '#0d2752', // Second largest portion
  '#fee183', // Third largest portion
  '#a8557f', // Fourth largest portion
  '#2dd4bf', // Seventh largest portion
  '#5eead4', // Eighth largest portion
  '#99f6e4', // Ninth largest portion
  '#ccfbf1', // Tenth largest portion
  '#D1D5DB', // Sixth largest portion
];

export const adaptColorsToDataLength = (data) => {
  const colorsLength = colors.length;
  const dataLength = data.length;
  const isLastItemOther = data[dataLength - 1][0] === 'Other';

  if (isLastItemOther) {
    return [...colors.slice(0, dataLength - 1), colors[colorsLength - 1]];
  }

  return colors;
};

// // Generate TW colors for production
// console.log(Object.entries(tailwindColors)
// .filter(([color]) => allowedColors.includes(color))
// .map(([, colorsObject]) => colorsObject[300])
// .filter(Boolean)
// );

export default colors;
