import { doc } from 'firebase/firestore';
import { useUser, useFirestore, useFirestoreDocData } from 'reactfire';

import { USERS_COLLECTION } from 'constants/firebasePaths';

// provides the read, create, update and delete operations for a firestore ref
const useUserByUID = (userId = '') => {
  const firestore = useFirestore();
  const { data: user } = useUser();

  const userRef = doc(firestore, USERS_COLLECTION, userId || user?.uid);
  const { data: userDoc, status } = useFirestoreDocData(userRef);

  return { userDoc, status, userRef };
};

export default useUserByUID;
