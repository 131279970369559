import React from 'react';

import AgencyDashboardSettingsMembers from './AgencyDashboardSettingsMembers';

const AgencyDashboardSettings = () => (
  <div className="flex flex-col gap-2">
    <AgencyDashboardSettingsMembers />
  </div>
);

export default AgencyDashboardSettings;
