import React, { useState } from 'react';

import { PencilIcon } from '@heroicons/react/20/solid';

import Button from 'components/buttons/Button';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

import SweetheartListJournalistListsAddEditModal from './SweetheartListJournalistListsAddEditModal';

const SweetheartListJournalistListsListHeader = () => {
  const {
    journalistList: { name, description, id, journalists },
  } = useSweetheartListContext();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  return (
    <div className="flex items-center justify-between mt-4 mb-4">
      <div>
        <h2 className="text-2xl font-bold">{name}</h2>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
      <Button
        type="secondary"
        className="w-[38px] h-[38px] !p-0"
        onClick={() => {
          setIsEditModalOpen(true);
        }}
      >
        <PencilIcon className="w-4 h-4" />
      </Button>
      {isEditModalOpen && (
        <SweetheartListJournalistListsAddEditModal
          onCancel={() => {
            setIsEditModalOpen(false);
          }}
          showDelete
          initialValues={{
            id,
            name,
            description,
            journalists: journalists.map((journalist) => journalist.id),
          }}
        />
      )}
    </div>
  );
};

export default SweetheartListJournalistListsListHeader;
