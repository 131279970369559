import PropTypes from 'prop-types';
import React from 'react';

import EmptyState from 'components/EmptyState';
import PageWrapper from 'components/PageWrapper';

const ErrorPage = ({ instructions = null }) => (
  <PageWrapper className="p-0">
    <EmptyState
      helmetTitle="Error"
      testId="page-error"
      title="Error"
      subtitle="An unexpected error occurred. Please try again later."
      fullPage
      instructions={instructions}
    />
  </PageWrapper>
);

ErrorPage.propTypes = {
  instructions: PropTypes.node,
};

export default ErrorPage;
