import PropTypes from 'prop-types';
import React from 'react';

import Callout from 'components/Callout/Callout';
import stripePortalConfigNames from 'constants/stripePortalConfigNames';

import BillingSettingsSubscriptionUpdateButton from './BillingSettingsSubscriptionStatusUpdateButton';

const BillingSettingsSubscriptionStatus = ({
  willCancel = false,
  subscriptionName = '',
}) => (
  <div className="col-span-3 flex flex-col gap-2">
    {willCancel && (
      <Callout wrapperClassName="mt-0">
        Your subscription has been cancelled. You will not be charged again. You
        can still use the features you have access to until your subscription expires.
      </Callout>
    )}
    <BillingSettingsSubscriptionUpdateButton
      portalConfigName={
        willCancel
          ? stripePortalConfigNames.SUBSCRIPTION_REACTIVATION
          : stripePortalConfigNames.SUBSCRIPTION_UPDATE
      }
      action={willCancel ? 'subscription_reactivate' : 'subscription_update'}
      subscriptionName={subscriptionName}
    />
  </div>
);

BillingSettingsSubscriptionStatus.propTypes = {
  willCancel: PropTypes.bool,
  subscriptionName: PropTypes.string
};

export default BillingSettingsSubscriptionStatus;
