import PropTypes from 'prop-types';
import React from 'react';

const StoryTitle = ({ title }) => (
  <h2 className="text-xl font-bold">{title}</h2>
);

StoryTitle.propTypes = {
  title: PropTypes.string,
};

export default StoryTitle;
