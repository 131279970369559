import PropTypes from 'prop-types';
import React from 'react';

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import moment from 'moment';

const BillingSettingsSuccessFeesTable = ({ successFees = [] }) => (
  <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
    <table className="min-w-full divide-y divide-gray-200 text-gray-500">
      <thead className="bg-gray-50 text-xs font-medium uppercase">
        <tr className="text-left tracking-wider">
          <th scope="col" className="px-4 py-2">
            Title
          </th>
          <th scope="col" className="px-4 py-2 max-w-sm">
            Link
          </th>
          <th scope="col" className="px-4 py-2 max-w-sm">
            Date Published
          </th>
          <th scope="col" colSpan="2" className="px-4 py-2">
            Receipt
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 text-sm">
        {successFees.map(({ date, title, link, invoice }) => (
          <tr key={title}>
            <td className="px-4 py-2 whitespace-nowrap">{title || 'n/a'}</td>
            <td className="px-4 py-2 whitespace-nowrap">
              {link ? (
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex gap-1 items-center text-teal-500 hover:text-teal-600"
                >
                  <span className="max-w-sm truncate">{link}</span>
                  <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                </a>
              ) : (
                'n/a'
              )}
            </td>
            <td className="px-4 py-2 whitespace-nowrap">
              {date ? moment(date).format('LL') : 'n/a'}
            </td>
            <td className="px-4 py-2 whitespace-nowrap max-w-sm">
              <a
                href={invoice?.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex gap-1 items-center text-teal-500 hover:text-teal-600"
              >
                Invoice
                <ArrowTopRightOnSquareIcon className="w-4 h-4" />
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

BillingSettingsSuccessFeesTable.propTypes = {
  successFees: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.shape({
        unit_amount: PropTypes.number,
      }),
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

export default BillingSettingsSuccessFeesTable;
