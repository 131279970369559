import PropTypes from 'prop-types';
import React from 'react';

import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

const CompanyDownloadWrapper = ({
  url,
  showOwnerView = false,
  onClick,
  children,
}) => {
  const className =
    'relative z-0 flex flex-1 flex-col justify-start items-start overflow-hidden rounded-lg text-sm border-gray-200 bg-white border';

  if (showOwnerView) {
    return <li className={className}>{children}</li>;
  }

  return (
    <Link
      to={url}
      rel="noreferrer"
      onClick={onClick}
      className="flex group relative"
    >
      <li
        className={`${className} hover:border-teal-500 hover:shadow-md overflow-hidden`}
      >
        <div className="absolute top-4 right-4 z-50 flex justify-center space-x-1">
          <ArrowDownTrayIcon className="w-4 h-4 text-white" />
          <span className="text-white text-xs ">click to download</span>
        </div>
        {children}
      </li>
    </Link>
  );
};

CompanyDownloadWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  showOwnerView: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CompanyDownloadWrapper;
