import PropTypes from 'prop-types';
import React from 'react';

import {
  linkWithPopup,
  GoogleAuthProvider,
  unlink,
  reload,
} from 'firebase/auth';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { ReactComponent as IconGoogle } from 'static/icon-google.svg';

const AccountFormGoogleProvider = ({
  user,
  googleProvider = null,
  hasPasswordProvider = false,
}) => {
  const { showSuccess, showError } = useNotifications();

  const handleLinkGoogle = async () => {
    if (googleProvider && hasPasswordProvider) {
      try {
        await unlink(user, 'google.com');
        await reload(user);
        showSuccess({
          title: 'Google unlinked',
          message: 'You can no longer sign in with Google',
        });
      } catch (e) {
        showError({
          title: 'Something went wrong',
          message: 'Please try again!',
        });
      }
      return;
    }

    if (googleProvider && !hasPasswordProvider) {
      showError({
        title: 'At least one authentication method is required',
        message: 'You must create a password and email before unliking Google',
      });
      return;
    }

    try {
      await linkWithPopup(user, new GoogleAuthProvider());
      showSuccess({
        title: 'Google linked',
        message: 'You can now sign in with Google',
      });
    } catch (e) {
      showError({
        title: 'Something went wrong',
        message: 'Please try again!',
      });
    }
  };

  return (
    <div>
      <Label>Google</Label>
      <div className="flex items-end gap-2 mt-2">
        {googleProvider?.email ? (
          <InputFieldText
            name="email"
            defaultValue={googleProvider.email}
            disabled
          />
        ) : null}
        <Button
          type="secondary"
          className="h-fit flex gap-2 items-center justify-center"
          onClick={handleLinkGoogle}
        >
          <div className="w-5 h-5">
            <IconGoogle />
          </div>
          <span>{googleProvider ? 'Unlink' : 'Link'} Google</span>
        </Button>
      </div>
    </div>
  );
};

AccountFormGoogleProvider.propTypes = {
  user: PropTypes.object.isRequired,
  googleProvider: PropTypes.object,
  hasPasswordProvider: PropTypes.bool,
};

export default AccountFormGoogleProvider;
