import PropTypes from 'prop-types';
import React from 'react';

import AvatarList from 'components/AvatarList/AvatarList';

const MvprDeeplinkJournalistList = ({
  data: { name = '', description = '', journalists = [] } = {},
}) => (
  <div className="flex flex-col gap-2 text-gray-700">
    <h2 className="font-bold">{name}</h2>
    <p className="line-clamp-3 text-sm mb-4">{description}</p>
    {journalists.length ? (
      <div className="w-1/2">
        <AvatarList
          users={journalists.map((journalist) => ({
            id: journalist.id,
            name: journalist.name,
            photoUrl: journalist.image,
          }))}
        />
      </div>
    ) : null}
  </div>
);

MvprDeeplinkJournalistList.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    journalist: PropTypes.object,
  }),
};

export default MvprDeeplinkJournalistList;
