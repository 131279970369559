import React, { useState } from 'react';

import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

import InboxMessageResponseFormEditorManageTemplatesModal from './InboxMessageResponseFormEditorManageTemplatesModal';
import { useResponseFormEditorContext } from './InboxMessageResponseFormEditorProvider';
import createTemplateHtml from './helpers/createTemplateHtml';
import createTemplateOptions from './helpers/createTemplateOptions';
import useSignature from './hooks/useSignature';

const InboxMessageResponseFormEditorTemplateDropdown = () => {
  const { draft, saveDraft } = useResponseFormEditorContext();
  const {
    templates: { data: templates },
  } = useInboxContext();
  const signature = useSignature();
  const [isManageTemplatesModalOpen, setIsManageTemplatesModalOpen] =
    useState(false);

  return (
    <div className="flex gap-2 items-baseline flex-wrap">
      <Label htmlFor="template">Choose a template</Label>

      <InputFieldDropdown
        name="template"
        value={draft.template}
        testId="inboxMessage-responseFormDropdown"
        intercomId="inbox-messages-templates"
        options={createTemplateOptions(templates)}
        placeholder="No template selected"
        onChange={(newTemplate) => {
          if (newTemplate === 'manage_templates') {
            setIsManageTemplatesModalOpen(true);
            return;
          }

          saveDraft({
            template: newTemplate,
            html: createTemplateHtml({ templates, newTemplate, signature }),
          });
        }}
        wrapperClassName="w-fit"
        buttonClassName="w-60"
      />
      {isManageTemplatesModalOpen ? (
        <InboxMessageResponseFormEditorManageTemplatesModal
          onCancel={() => {
            setIsManageTemplatesModalOpen(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default InboxMessageResponseFormEditorTemplateDropdown;
