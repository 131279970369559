import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';

import { VideoCameraIcon } from '@heroicons/react/20/solid';
import ReactPlayer from 'react-player';

import usePrevious from 'hooks/usePrevious';

const PLAYER_TIMEOUT = 3000;

const CompanyVideoEditModalPreview = ({
  url,
  defaultUrl,
  error,
  onDuration,
}) => {
  const [hasError, setHasError] = useState(null);
  const playerRef = useRef(null);

  const isValidUrl = (url || defaultUrl) && !error;
  const previousUrl = usePrevious(url);

  // timeout resets react-player when new URL is passed in
  // it also sets to error state if URL can't be played after PLAYER_TIMEOUT
  useEffect(() => {
    let validationTimeout;

    // reset if url has changed
    if (previousUrl !== url) {
      setHasError(null);
      clearTimeout(validationTimeout);
    }

    // when no error, clear timeout, e.g. when video has started playing
    if (hasError === false) {
      clearTimeout(validationTimeout);
    }

    // is valid URL and no error state has been set, start validation timeout
    if (isValidUrl && hasError === null) {
      validationTimeout = setTimeout(() => {
        if (!playerRef.current) {
          return;
        }
        const player = playerRef.current.getInternalPlayer();
        // if player has not been set, this means there is an error loading the video
        setHasError(!player);
      }, PLAYER_TIMEOUT);
    }

    return () => clearTimeout(validationTimeout);
  }, [hasError, isValidUrl, previousUrl, url]);

  if (!isValidUrl) {
    return (
      <div className="h-64 flex justify-center items-center bg-gray-50">
        <VideoCameraIcon className="text-gray-300 w-12 h-12" />
      </div>
    );
  }

  if (hasError) {
    return (
      <div className="h-64 flex justify-center items-center bg-gray-50">
        Invalid URL
      </div>
    );
  }

  return (
    <ReactPlayer
      ref={playerRef}
      url={url || defaultUrl}
      height="100%"
      width="100%"
      playing
      onReady={async () => {
        // getting the duration via current.getDuration doesn't work with Vimeo
        const duration = await playerRef.current
          .getInternalPlayer()
          .getDuration();

        if (duration) {
          onDuration(duration);
        }
        // if video has a duration, unset error
        setHasError(!duration);
      }}
      onError={() => setHasError(true)}
    />
  );
};

CompanyVideoEditModalPreview.propTypes = {
  url: PropTypes.string,
  defaultUrl: PropTypes.string,
  error: PropTypes.string,
  onDuration: PropTypes.func.isRequired,
};

export default CompanyVideoEditModalPreview;
