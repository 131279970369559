import PropTypes from 'prop-types';
import React from 'react';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const ContentManagementDocumentFormTitle = ({
  value,
  control,
  messageError,
  onChange,
}) => (
  <div className="flex max-w-md space-x-2 md:max-w-2xl">
    <InputFieldText
      id="contentManagement-titleFormTextInput"
      name="title"
      placeholder="The title of the document"
      control={control}
      errors={messageError}
      value={value}
      onChange={onChange}
    >
      <InputFieldText.Label>Title</InputFieldText.Label>
    </InputFieldText>
  </div>
);

ContentManagementDocumentFormTitle.propTypes = {
  value: PropTypes.string,
  control: PropTypes.object,
  messageError: PropTypes.object,
  onChange: PropTypes.func,
};

export default ContentManagementDocumentFormTitle;
