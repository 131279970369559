import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ArrowDownTrayIcon, TrashIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';
import downloadFile from 'utils/file/downloadFile';

const InboxMessageResponseFormEditorAttachmentsFile = ({ file = {} }) => {
  const [isRemovingAttachment, setIsRemovingAttachment] = useState(false);
  const { removeAttachment } = useInboxContext();

  return (
    <li
      key={file.path}
      className={classNames('col-span-1 rounded-md shadow-sm group', {
        'opacity-50': isRemovingAttachment || file.pending,
      })}
    >
      <div className="p-3 flex w-full items-center justify-between rounded-md border border-gray-200 bg-white">
        <div className="flex-1 truncate text-sm">
          <span className="font-medium text-gray-900 group-hover:text-gray-600">
            {file.fileName}
          </span>
        </div>
        <div className="flex relative w-7">
          <DotsMenu
            theme="dark"
            options={[
              {
                label: `Download`,
                icon: <ArrowDownTrayIcon className="w-4 h-4" />,
                onClick: () => downloadFile(file.downloadUrl, file.fileName),
              },
              {
                label: `Delete`,
                icon: <TrashIcon className="w-4 h-4" />,
                onClick: async () => {
                  setIsRemovingAttachment(true);
                  await removeAttachment(file.path);
                  setIsRemovingAttachment(false);
                },
              },
            ]}
          />
        </div>
      </div>
    </li>
  );
};

InboxMessageResponseFormEditorAttachmentsFile.propTypes = {
  file: PropTypes.object,
};

export default InboxMessageResponseFormEditorAttachmentsFile;
