import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { noop } from 'lodash';

import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import ThreadTag from 'components/tags/ThreadTag';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';

import ContentManagementDocumentResponseFormThreadsModal from './ContentManagementDocumentResponseFormThreadsModal';

const ContentManagementResponseFormThread = ({
  thread = {},
  onSelectThread = noop,
  onRemove = noop,
}) => {
  const { companyData } = useCompanyData();
  const [isThreadsModalOpen, setIsThreadsModalOpen] = useState(false);

  const { data, loading: areThreadsLoading } = useAPIRequest({
    service: 'MOD',
    endpoint: `/companies/${companyData?.uid}/threads`,
    initialData: { threads: [] },
  });
  const { threads } = data;

  const activeThreads = threads.filter((t) => t.status === 'sent');

  const handleOnSelect = (threadData) => {
    onSelectThread(threadData);
    setIsThreadsModalOpen(false);
  };

  return (
    <div>
      <Label htmlFor="thread">Thread</Label>
      <small className="mt-2 text-gray-400 text-xs">
        {`We'll make sure that the Generated Content is aligned with
                        the selected thread’s message`}
      </small>
      {!thread || Object.keys(thread).length === 0 ? (
        <div className="max-w-md bg-white rounded-xl mt-2 border overflow-hidden md:max-w-2xl">
          <div className="flex justify-between items-center p-4">
            <div>
              <div className="flex items-center gap-x-3">
                <span
                  id="server-size-0-label"
                  className="text-sm font-medium leading-6 text-gray-900 line-clamp-1"
                >
                  Thread subject
                </span>
              </div>

              <div className="flex items-start gap-x-3">
                <span
                  id="server-size-0-description-0"
                  className="text-sm leading-6 text-gray-400 line-clamp-2"
                >
                  Thread description
                </span>
              </div>
            </div>

            <Button
              type="secondary"
              className="text-[#374151] hover:bg-gray-50"
              onClick={() => setIsThreadsModalOpen(true)}
            >
              Choose a thread...
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="max-w-md bg-white rounded-xl mt-2 border overflow-hidden md:max-w-2xl">
            <div className="p-4">
              <div className="mb-0.5">
                <ThreadTag threadIntent={thread?.intent} />
              </div>
              <div className="flex items-center gap-x-3">
                <p
                  className={
                    thread?.subject
                      ? 'text-sm font-semibold leading-6 text-gray-900 mr-[85px] line-clamp-2'
                      : 'text-sm font-semibold italic leading-6 text-gray-400 mr-[85px] line-clamp-2'
                  }
                >
                  {thread?.subject ?? 'No subject'}
                </p>
              </div>

              {thread?.opportunity?.description ? (
                <div className="flex items-start gap-x-3">
                  <p className="text-sm leading-6 text-gray-900 line-clamp-2">
                    {thread?.opportunity?.description}
                  </p>
                </div>
              ) : null}
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">{thread?.author}</p>
                <span className="mx-1">&middot;</span>
                <p className="truncate">
                  {thread?.journalistData?.publication?.name}
                </p>
              </div>
            </div>
          </div>

          <button
            type="button"
            onClick={onRemove}
            className="mt-2 text-red-500 underline hover:no-underline text-xs"
          >
            Remove thread
          </button>
        </div>
      )}

      {isThreadsModalOpen && !areThreadsLoading && (
        <ContentManagementDocumentResponseFormThreadsModal
          open={isThreadsModalOpen}
          threads={activeThreads}
          onSelect={handleOnSelect}
          onClose={() => setIsThreadsModalOpen(false)}
        />
      )}
    </div>
  );
};

ContentManagementResponseFormThread.propTypes = {
  thread: PropTypes.object,
  onRemove: PropTypes.func,
  onSelectThread: PropTypes.func,
};

export default ContentManagementResponseFormThread;
