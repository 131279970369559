/* eslint-disable max-params */
import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'reactfire';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

const createThread = async ({ uid = '', auth = {}, threadData = {} }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/companies/${uid}/threads`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(threadData),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch journalist lists: ${error.message}`);
  }
};

const useCreateThread = () => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const { companyData } = useCompanyData();
  const mutationKey = ['companyThreads', companyData.uid, ''];
  const { data: newThread, mutateAsync } = useMutation({
    mutationFn: (threadData) =>
      createThread({ threadData, uid: companyData.uid, auth }),
    onMutate: async (data) => {
      await queryClient.cancelQueries(mutationKey);
      const previousCompanyThreads = queryClient.getQueryData(mutationKey);
      queryClient.setQueryData(mutationKey, (old) => ({
        ...old,
        statusCounts: {
          ...old.statusCounts,
          all: old.statusCounts.all + 1,
          pending: old.statusCounts.pending + 1,
        },
        threads: [
          ...old.threads,
          {
            ...data,
            id: new Date().getTime().toString(),
            dateCreated: new Date().toISOString(),
            isPending: true,
          },
        ],
      }));

      return { previousCompanyThreads };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(mutationKey, context.previousCompanyThreads);
    },
    onSettled: () => {
      queryClient.invalidateQueries(mutationKey);
    },
  });

  return {
    createThread: mutateAsync,
    newThread,
  };
};

export default useCreateThread;
