import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

const NavbarLinksLink = ({
  path = '',
  label = '',
  children = null,
  testId = '',
  intercomId = '',
  Badge = null,
  className = '',
  end = false,
}) => (
  <NavLink
    to={path}
    data-test-id={testId}
    data-intercom-id={intercomId}
    target={path?.startsWith('https://') ? '__blank' : null}
    end={end}
  >
    {({ isActive }) => (
      <div
        className={classNames(
          'hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal items-center',
          className,
          isActive ? 'bg-gray-50' : ''
        )}
      >
        {children}
        <p
          className={classNames('', {
            'text-gray-900': isActive,
            'text-gray-500 group-hover:text-gray-500': !isActive,
          })}
        >
          {label}
        </p>
        {Badge && <div className="ml-auto mr-2">{Badge}</div>}
      </div>
    )}
  </NavLink>
);
NavbarLinksLink.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.element,
  testId: PropTypes.string,
  intercomId: PropTypes.string,
  Badge: PropTypes.node,
  className: PropTypes.string,
  end: PropTypes.bool,
};

export default NavbarLinksLink;
