import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const MOD_URL = process.env.REACT_APP_MOD_URL;

const useQnAResource = (companyId, resourceType) => {
  const auth = useAuth();
  return useQuery(
    ['questionnaire', resourceType],
    async () => {
      if (!companyId || !resourceType) {
        throw new Error('companyId and resourceType are required');
      }
      const jwt = await auth.currentUser?.getIdToken();
      const response = await fetch(
        `${MOD_URL}/resources/${companyId}/${resourceType}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch questionnaire data');
      }
      return response.json();
    },
    {
      enabled: !!companyId && !!resourceType,
    }
  );
};

export default useQnAResource;
