import React from 'react';

import AgencyNavbarDesktop from './AgencyNavbarDesktop';
import AgencyNavbarMobile from './AgencyNavbarMobile';

const AgencyNavbar = () => (
  <>
    <AgencyNavbarDesktop />
    <AgencyNavbarMobile />
  </>
);

export default AgencyNavbar;
