/* eslint-disable max-params */
import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'reactfire';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

const upsertAnnouncement = async ({ data, uid, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/milestones/${uid}/announcements`;
  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(
      `Failed to ${data.id ? 'update' : 'create'} announcement: ${
        error.message
      }`
    );
  }
};

const useUpsertAnnouncement = () => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { uid } = useCompanyData()?.companyData || {};

  return useMutation({
    mutationFn: (data) => upsertAnnouncement({ data, uid, auth }),
    onSettled: () => {
      queryClient.invalidateQueries('announcements');
    },
  });
};

export default useUpsertAnnouncement;
