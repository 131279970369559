import { useEffect } from 'react';

import noop from 'lodash/noop';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  ADMIN_PATH,
  COMPANIES_JOURNALIST_PATH,
  AGENCY_DASHBOARD_PATH,
  OPPORTUNITIES_PATH,
  CREATE_NEW_COMPANY,
} from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

// redirect hook for auth pages (login), redirecting the user to the correct path on load
const useRedirectAuth = (skipCompanyRedirect = false) => {
  const {
    isAnonymous,
    isAuthenticating,
    uid,
    claimsAgencyId,
    claimsAdmin,
    claimsJournalist,
  } = useUserData();
  const { companyData } = useCompanyData();
  const slug = companyData?.slug;
  const hasCompany = companyData && slug;

  const navigate = useNavigate();

  const isLoggedIn = !isAnonymous && uid;
  const showAuth = !isLoggedIn;

  useEffect(() => {
    if (isAuthenticating) {
      return noop;
    }

    if (claimsAgencyId) {
      navigate(
        generatePath(AGENCY_DASHBOARD_PATH, { agencyId: claimsAgencyId })
      );
      return noop;
    }

    if (claimsJournalist) {
      navigate(COMPANIES_JOURNALIST_PATH);
      return noop;
    }

    if (claimsAdmin) {
      navigate(ADMIN_PATH);
      return noop;
    }

    if (skipCompanyRedirect) {
      return noop;
    }

    // if user has company, redirect there
    if (isLoggedIn && slug) {
      navigate(generatePath(OPPORTUNITIES_PATH, { id: slug }));
      return noop;
    }

    if (isLoggedIn && !hasCompany) {
      navigate(CREATE_NEW_COMPANY);
      return noop;
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showAuth;
};

export default useRedirectAuth;
