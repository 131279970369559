import React, { useState } from 'react';

import { FunnelIcon } from '@heroicons/react/20/solid';

import OpportunitiesFilterSortModal from 'components/opportunities/OpportunitiesFilterSortModal';

import OpportunitiesControlsOrganizeFilter from './OpportunitiesControlsOrganizeFilter';
import OpportunitiesControlsOrganizeSort from './OpportunitiesControlsOrganizeSort';

const OpportunitiesControlsOrganize = () => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  return (
    <div className="flex gap-2 md:pt-2">
      <OpportunitiesControlsOrganizeFilter />
      <OpportunitiesControlsOrganizeSort />
      <button
        type="button"
        className="lg:hidden bg-gray-100 text-gray-500 px-3 py-3 rounded-md text-sm font-medium hover:bg-gray-50 cursor-pointer"
        onClick={() => setIsFilterModalOpen(true)}
        data-test-id="opportunities-navBar-filterIcon"
      >
        <FunnelIcon className="w-4" />
      </button>
      <OpportunitiesFilterSortModal
        open={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
      >
        <div className="flex flex-col gap-2">
          <h2 className="text-sm text-gray-700 font-semibold">Filter by</h2>
          <OpportunitiesControlsOrganizeFilter fromModal />

          <h2 className="text-sm text-gray-700 font-semibold">Sort by</h2>
          <OpportunitiesControlsOrganizeSort fromModal />
        </div>
      </OpportunitiesFilterSortModal>
    </div>
  );
};

export default OpportunitiesControlsOrganize;
