import { REPORTS_PATH } from 'constants/paths';

import ReportsPage from './ReportsPage';

const ReportsRoute = {
  path: REPORTS_PATH,
  authRequired: true,
  component: ReportsPage,
};

export default ReportsRoute;
