/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React from 'react';

import { Editor } from '@tinymce/tinymce-react';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import noop from 'lodash/noop';
import { useStorage } from 'reactfire';
import { v4 as uuidv4 } from 'uuid';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import usePhotoUrl from 'hooks/usePhotoUrl';
import useUserName from 'hooks/useUserName';

const TextEditor = ({
  onChange = noop,
  value = '',
  id = 'c-document__editor',
  height = 'calc(100vh - 129px)',
  editorRef = {},
}) => {
  const userPhotoUrl = usePhotoUrl();
  const userName = useUserName();
  const storage = useStorage();
  const { companyData } = useCompanyData();
  const uploadFolder = `inboxes/${companyData?.uid}`;

  const getBaseLanguage = (locale) => locale.split('-')[0] || 'en';
  const spellCheckerLanguage = getBaseLanguage(
    companyData?.defaultLanguage || 'en-US'
  );

  const uploadFile = async (file) => {
    const uploadRef = ref(storage, `${uploadFolder}/${uuidv4()}-${file.name}`);
    await uploadBytes(uploadRef, file, {
      contentDisposition: `attachment; filename="${file.name}"`,
      customMetadata: {
        companyId: companyData?.uid,
      },
    });
    const url = await getDownloadURL(uploadRef);
    return url;
  };

  const EDITOR_SETTINGS = {
    branding: false,
    elementpath: false,
    height,
    menubar: false,
    convert_urls: false,
    plugins: [
      'advlist',
      'autolink',
      'lists',
      'link',
      'image',
      'charmap',
      'anchor',
      'media',
      'wordcount',
      'tinymcespellchecker',
      'code',
      'tinycomments',
      'quickbars',
    ],
    tinycomments_mode: 'embedded',
    tinycomments_author: userName || 'Unknwon',
    tinycomments_author_avatar: userPhotoUrl,
    tinycomments_can_resolve(req, done) {
      const allowed = req.comments.length > 0;
      done({
        canResolve: allowed,
      });
    },
    toolbar:
      'blocks | image |' +
      'addcomment showcomments |' +
      'bold italic forecolor removeformat | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | ' +
      'removeformat' +
      'spellchecker language spellcheckdialog | undo redo',
    spellchecker_language: spellCheckerLanguage,
    spellchecker_active: true,
    extended_valid_elements: 'span[class]',
    content_style: `
  html {
     background-color: #f3f4f6;
  }
  body {
    background-color: white;
    max-width: 90ch;
    min-height: 120vh;
    margin: 20px auto;
    padding: 40px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 1.5;
  }

  p, h1, h2, h3, h4, h5, h6 {
     margin-top: 0;
  }

  a {
    color: #38b2ac;
  }

  .highlight {
    cursor: pointer;
    background-color: #fdf6b2;
    display: inline-block;
    color: #633112;
  }

  .journalistPlaceholder {
    color: #B91C1C;
    display: inline-block;
    background-color: #FECACA;
    cursor: not-allowed;
  }
  `,
    file_picker_callback: (cb) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      input.addEventListener('change', async (e) => {
        const file = e.target.files[0];
        const url = await uploadFile(file);
        cb(url);
      });

      input.click();
    },
  };

  return (
    <div className="h-full">
      <Editor
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        onEditorChange={(v, editor) => {
          onChange({
            html: v,
            text: editor.getContent({ format: 'text' }),
          });
        }}
        onInit={(_, editor) => {
          editorRef.current = editor;
        }}
        value={value}
        init={EDITOR_SETTINGS}
        id={id}
      />
    </div>
  );
};

TextEditor.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string,
  value: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editorRef: PropTypes.object,
};

export default TextEditor;
