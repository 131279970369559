import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ChevronDownIcon, InboxIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Callout from 'components/Callout/Callout';
import JournalistTeaser from 'components/JournalistTeaser';
import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';
import intents from 'constants/intents';
import threadSource from 'constants/threadSource';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

const intentOptions = [
  { value: intents.sweetheartList.OP_ED, label: 'Thought Leadership' },
  { value: intents.sweetheartList.OUTREACH, label: 'Cold Outreach' },
  {
    value: intents.sweetheartList.PRESS_RELEASE,
    label: 'Pitch Release',
  },
];

const SweetheartListJournalistListsListBulkConversationsModal = ({
  onCancel = noop,
  selected = [],
  draftContent = undefined,
}) => {
  const { announcementId } = useParams();
  const [isExpanded, setIsExpanded] = useState(false);
  const { showSuccess, showError } = useNotifications();
  const { startConversations, isStartingConversations, journalistList } =
    useSweetheartListContext();
  const { register, formState, control, handleSubmit } = useForm({
    defaultValues: {
      subject: '',
      journalistIds: selected.map((journalist) => journalist.id),
      draft: {
        html: draftContent || '',
        text: '',
      },
      intent: intents.sweetheartList.OP_ED,
      source: threadSource.SWEETHEART_LIST,
      reference: announcementId ? `announcements/${announcementId}` : null,
    },
  });
  const { errors } = formState;

  return (
    <Modal open widthClass="max-w-xl lg:max-w-2xl xl:max-w-3xl">
      <Modal.Close
        srMessage="Close Opportunity Response modal"
        testId="opportunity-responseModal-close"
        onClose={onCancel}
      />
      <Modal.Title>Create Drafts</Modal.Title>
      <Modal.Content>
        <div className="flex flex-col gap-2 relative">
          {selected.length === 0 ? (
            <div className="flex z-10 bg-white/80 items-center justify-center absolute inset-0">
              <Callout type="warning">
                {journalistList.journalists.length
                  ? 'No journalists selected to create drafts for.'
                  : 'Please add journalists to the list first before creating drafts.'}
              </Callout>
            </div>
          ) : null}
          <div className="flex gap-2">
            <InputFieldText
              errors={errors}
              {...register('subject', {
                required: 'Please put in a subject',
              })}
            >
              <InputFieldText.Label>Subject*</InputFieldText.Label>
            </InputFieldText>
            <Controller
              name="intent"
              control={control}
              render={({ field }) => (
                <InputFieldDropdown
                  label="Intent"
                  sameWidthOption
                  value={field.value}
                  buttonClassName="w-fit"
                  onChange={(intent) => field.onChange(intent)}
                  options={intentOptions}
                />
              )}
            />
          </div>
          <div>
            <Label>Draft*</Label>
            <Controller
              name="draft"
              control={control}
              render={({ field }) => (
                <InputFieldTextEditor
                  value={field.value.html}
                  onChange={(html, text) => {
                    field.onChange({
                      html,
                      text,
                    });
                  }}
                />
              )}
            />
            {errors.draft?.message ? (
              <ErrorMessage>{errors.draft.message}</ErrorMessage>
            ) : null}
          </div>

          <div className="mt-2 w-full bg-gray-50 flex flex-col gap-2 rounded-md">
            <button
              type="button"
              onClick={() => setIsExpanded((prev) => !prev)}
              className="text-sm text-gray-500 w-full flex gap-1 items-center justify-center px-2 py-1 rounded-md hover:bg-gray-100"
            >
              <p>Creating drafts for {selected.length} journalists</p>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {isExpanded ? (
              <div className="flex flex-col gap-2 px-2 py-1">
                {selected.map((journalist) => (
                  <JournalistTeaser
                    key={journalist.id}
                    journalist={journalist}
                  />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </Modal.Content>

      <Modal.RightButtons>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          onClick={handleSubmit(async (values) => {
            await startConversations(values, {
              onSuccess: () => {
                showSuccess({
                  title: 'Conversations started',
                  Icon: InboxIcon,
                  message: 'Conversations have been started',
                });
              },
              onError: () => {
                showError({
                  message: 'Error starting conversations',
                });
              },
            });
            onCancel();
          })}
          disabled={selected.length === 0}
          type="primary"
          className="relative"
        >
          <span
            className={classNames({
              'animate-pulse': isStartingConversations,
            })}
          >
            {isStartingConversations ? 'Creating' : 'Create'} Drafts
          </span>
          {isStartingConversations ? <Spinner color="text-white" /> : null}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

SweetheartListJournalistListsListBulkConversationsModal.propTypes = {
  onCancel: PropTypes.func,
  selected: PropTypes.array,
  draftContent: PropTypes.string,
};

export default SweetheartListJournalistListsListBulkConversationsModal;
