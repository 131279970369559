import { MESSAGES_PATH_FULL } from 'constants/paths';

import InboxPage from './InboxPage';

const InboxRoute = {
  path: MESSAGES_PATH_FULL,
  component: InboxPage,
  authRequired: true,
};

export default InboxRoute;
