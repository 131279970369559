import PropTypes from 'prop-types';
import React from 'react';

import JournalistDetailsModalInteractionsIcon from './JournalistDetailsModalInteractionsIcon';

export const formatDate = (date) => {
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return `${day} ${monthNames[monthIndex]}`;
};

const JournalistDetailsModalInteractionsType = ({ threadData, isLast }) => {
  const date = formatDate(new Date(threadData.dateCreated));

  return (
    <li key={threadData.id}>
      <div className="relative pb-8">
        {isLast ? null : (
          <span
            className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        )}
        <div className="relative flex space-x-3">
          <JournalistDetailsModalInteractionsIcon status={threadData.status} />

          <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
            <div>
              {threadData.status === 'draft' ? (
                <p className="text-sm text-gray-500">Draft created</p>
              ) : (
                <p className="text-sm text-gray-500">Contacted </p>
              )}
            </div>
            <div className="whitespace-nowrap text-right text-sm text-gray-500">
              <time>{date}</time>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

JournalistDetailsModalInteractionsType.propTypes = {
  threadData: PropTypes.object,
  isLast: PropTypes.bool,
};

export default JournalistDetailsModalInteractionsType;
