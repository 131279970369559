import React, { useState } from 'react';

import { EmailAuthProvider, getAuth, linkWithCredential } from 'firebase/auth';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const AccountFormPasswordProviderCreate = () => {
  const { showSuccess, showError } = useNotifications();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const linkUserWithEmailAndPassword = async () => {
    const credential = EmailAuthProvider.credential(email, password);
    const auth = getAuth();

    try {
      await linkWithCredential(auth.currentUser, credential);
      showSuccess({
        title: 'Email authentication created',
        message: 'You can now sign in with your email and password',
      });
    } catch (error) {
      showError({
        title: 'Something went wrong',
        message: 'Please try to logout and login again!',
      });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <InputFieldText
        name="email"
        defaultValue=""
        onChange={(e) => setEmail(e.currentTarget.value)}
      >
        <InputFieldText.Label>Email</InputFieldText.Label>
      </InputFieldText>
      <InputFieldText
        type="password"
        name="password"
        defaultValue=""
        onChange={(e) => setPassword(e.currentTarget.value)}
      >
        <InputFieldText.Label>Password</InputFieldText.Label>
      </InputFieldText>
      <Button onClick={linkUserWithEmailAndPassword}>
        Create Email authentication
      </Button>
    </div>
  );
};

export default AccountFormPasswordProviderCreate;
