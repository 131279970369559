import { useEffect, useState } from 'react';

import noop from 'lodash/noop';
import { useAuth } from 'reactfire';

import getSearchSuggestions from '../helpers/getSearchSuggestions';

const useAudienceSearchSuggestions = (searchQuery = '') => {
  const auth = useAuth();
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!searchQuery) {
      setIsLoading(false);
      setSuggestions([]);
      return noop;
    }

    const controller = new AbortController();

    (async () => {
      setIsLoading(true);

      try {
        const idToken = await auth.currentUser?.getIdToken();
        setSuggestions(
          await getSearchSuggestions({
            searchQuery,
            idToken,
            controller,
          })
        );
        setIsLoading(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          setIsLoading(false);
        }
      }
    })();

    return () => controller.abort();
  }, [searchQuery, auth]);

  return [suggestions, isLoading];
};

export default useAudienceSearchSuggestions;
