import PropTypes from 'prop-types';
import React from 'react';

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { noop } from 'lodash';

import Tag from 'components/tags/Tag';
import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';

import AdminCoveragesTableRowCompany from './AdminCoveragesTableRowCompany';
import AdminCoveragesTableRowPublication from './AdminCoveragesTableRowPublication';

const intentOptions = [
  { label: 'Thought Leadership', value: 'opEd' },
  { label: 'Press Release', value: 'pressRelease' },
  { label: 'Cold outreach', value: 'outreach' },
  { label: 'Opportunity', value: 'opportunity' },
  { label: 'Custom opportunity', value: 'customOpportunity' },
];

const getIntentLabel = (intent) => {
  if (!intent) {
    return 'n/a';
  }
  return (
    intentOptions.find((option) => option.value === intent)?.label || 'n/a'
  );
};

// TODO: remove highlight of success fees without thread or company when migration is done
const AdminCoveragesTableRow = ({
  coverage = {},
  isSelected = false,
  handleSelected = noop,
}) => (
  <tr
    className={classNames({
      'bg-teal-50': isSelected,
    })}
  >
    <td
      className={classNames('z-10 sticky left-0  px-4 py-2 whitespace-nowrap', {
        'bg-teal-50': isSelected,
        'bg-white': !isSelected,
      })}
    >
      <input
        checked={isSelected}
        onChange={handleSelected}
        type="checkbox"
        className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded cursor-pointer"
      />
    </td>
    <td className="z-10 px-4 py-2 whitespace-nowrap">
      <div className="flex gap-2 items-center">
        <AdminCoveragesTableRowCompany
          name={coverage.company?.name}
          logo={coverage.company?.logo}
        />
      </div>
    </td>
    <td className="z-10 px-4 py-2 whitespace-nowrap">
      <div className="flex gap-2 items-center">
        <AdminCoveragesTableRowPublication
          name={coverage.journalist?.publication?.name}
          logo={coverage.journalist?.publication?.image}
        />
      </div>
    </td>
    <td className="px-4 py-2 max-w-[20ch] whitespace-nowrap">
      {coverage.link ? (
        <a
          href={coverage.link}
          target="_blank"
          rel="noreferrer"
          title={coverage.title}
          className="flex items-center gap-1 text-teal-500 underline underline-offset-2"
        >
          <span className="truncate">{coverage.title}</span>
          <ArrowTopRightOnSquareIcon className="h-4 w-4 shrink-0" />
        </a>
      ) : (
        coverage.title
      )}
    </td>
    <td className="px-4 py-2 whitespace-nowrap">
      {getIntentLabel(coverage.thread?.intent)}
    </td>
    <td className="px-4 py-2 whitespace-nowrap">
      {coverage.invoice?.id ? (
        <Tag uppercase bold textColor="green-500" bgColor="green-100">
          INVOICED
        </Tag>
      ) : null}
    </td>
    <td className="px-4 py-2 whitespace-nowrap">
      {getDateFromTimestamp(coverage.createdAt)}
    </td>
    <td className="px-4 py-2 whitespace-nowrap">
      {getDateFromTimestamp(coverage.publishedAt)}
    </td>
    <td className="px-4 py-2 whitespace-nowrap">
      {coverage.invoice?.createdAt
        ? getDateFromTimestamp(coverage.invoice?.createdAt)
        : 'n/a'}
    </td>
  </tr>
);

AdminCoveragesTableRow.propTypes = {
  coverage: PropTypes.object,
  isSelected: PropTypes.bool,
  handleSelected: PropTypes.func,
};

export default AdminCoveragesTableRow;
