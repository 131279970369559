import PropTypes from 'prop-types';
import React, { useState } from 'react';

import classNames from 'classnames';

import JournalistDetailsModal from './JournalistDetailsModal/JournalistDetailsModal';
import JournalistTeaser from './JournalistTeaser';

const JournalistTeaserWithModal = ({
  journalist = {},
  journalistTeaserClassName = '',
}) => {
  const [isJournalistModalOpen, setIsJournalistModalOpen] = useState(false);
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => setIsJournalistModalOpen(true)}
      className={classNames(
        'hover:bg-gray-100 cursor-pointer rounded-md px-2 py-1',
        { 'pb-3': journalist?.publication?.image }
      )}
    >
      <JournalistTeaser
        journalist={journalist}
        className={journalistTeaserClassName}
      />

      {isJournalistModalOpen ? (
        <JournalistDetailsModal
          journalist={journalist}
          onClose={() => setIsJournalistModalOpen(false)}
        />
      ) : null}
    </div>
  );
};

JournalistTeaserWithModal.propTypes = {
  journalist: PropTypes.object,
  journalistTeaserClassName: PropTypes.string,
};

export default JournalistTeaserWithModal;
