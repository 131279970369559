/* eslint-disable max-params */
import React, { useCallback, useEffect, useState } from 'react';

import { ControlledBoard, moveCard } from '@caldwell619/react-kanban';
import { useParams } from 'react-router-dom';

import tasksColumns from 'constants/tasks';
import { parseDeeplink } from 'hooks/useGetResource';
import TasksAddEditModal from 'routes/Planning/Tasks/TasksAddEditModal';
import TasksCard from 'routes/Planning/Tasks/TasksCard';
import TasksColumnHeader from 'routes/Planning/Tasks/TasksColumnHeader';
import 'routes/Planning/Tasks/kanban.css';
import { createInitialBoard, orderTasks } from 'routes/Planning/Tasks/TasksTab';
import useUpdateTaskStatus from 'routes/Planning/Tasks/hooks/useUpdateTaskStatus';
import useUpsertTask from 'routes/Planning/Tasks/hooks/useUpsertTask';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';

import useAgencyCompanies from '../hooks/useAgencyCompanies';

import useAgencyTasks from './hooks/useAgencyTasks';
import useAgencyTasksCollaborators from './hooks/useAgencyTasksCollaborators';

const mergeBoards = (boards, setShowAddEditTaskModal) => {
  const allCardsByColumnId = boards.reduce((acc, board) => {
    board.columns.forEach((column) => {
      acc[column.id] = acc[column.id] || [];
      acc[column.id].push(...column.cards);
    });
    return acc;
  }, {});

  return {
    columns: Object.entries(allCardsByColumnId).map(([id, cards]) => ({
      id,
      cards: orderTasks(cards),
      onClick: () => setShowAddEditTaskModal({ status: id }),
      title: tasksColumns.find((column) => column.id === id).title,
    })),
  };
};

const createInitialBoardFromTasks = ({
  companies,
  tasks,
  setShowAddEditTaskModal,
  collaboratorsData,
}) => {
  const tasksByCompany = tasks.reduce((acc, task) => {
    if (!acc[task.companyId]) {
      acc[task.companyId] = [];
    }
    acc[task.companyId].push(task);
    return acc;
  }, {});

  return mergeBoards(
    companies.map((company) => {
      const companyTasks = tasksByCompany[company.id] || [];
      const collaborators =
        collaboratorsData?.collaboratorsByCompanyId?.[company.id] || [];
      const otherCollaborators = Object.entries(
        collaboratorsData?.otherCollaboratorsByTaskId || {}
      )
        .filter(([taskId]) => companyTasks.some((task) => task.id === taskId))
        .map(([, users]) => users)
        .flat();

      return createInitialBoard({
        tasks: companyTasks,
        companyLogo: { path: company.logo },
        companyName: company.name,
        companySlug: company.slug,
        onClick: setShowAddEditTaskModal,
        collaborators: [...collaborators, ...otherCollaborators],
      });
    }),
    setShowAddEditTaskModal
  );
};

const AgencyDashboardPlanning = () => {
  const { agencyId } = useParams();
  const { mutateAsync: upsertTask } = useUpsertTask(null, 'agencyTasks');
  const { mutateAsync: updateTaskStatus } = useUpdateTaskStatus(
    null,
    'agencyTasks'
  );
  const { data: tasks = [], dataUpdatedAt: tasksUpdatedAt } =
    useAgencyTasks(agencyId);
  const {
    data: collaboratorsData = {},
    dataUpdatedAt: collaboratorsUpdatedAt,
  } = useAgencyTasksCollaborators(agencyId);
  const {
    data: { items: companies },
    dataUpdatedAt: companiesUpdatedAt,
  } = useAgencyCompanies(agencyId);

  const [showAddEditTaskModal, setShowAddEditTaskModal] = useState(false);
  const [controlledBoard, setBoard] = useState(
    createInitialBoardFromTasks({
      companies,
      tasks,
      setShowAddEditTaskModal,
      collaboratorsData,
    })
  );

  const handleCardMove = useCallback(
    (card, source, destination) => {
      setBoard((currentBoard) => moveCard(currentBoard, source, destination));
      updateTaskStatus({
        taskId: card.id,
        companyId: card.companyId,
        status: destination.toColumnId,
      });
    },
    [updateTaskStatus]
  );

  const handleSubmit = useCallback(
    async (newTask) =>
      upsertTask({
        ...newTask,
        deadline: newTask.isDeadlineEnabled
          ? createTimestampFromDateObj(newTask.deadlineObject)
          : null,
        withAgency: !newTask.withAgency,
        reference:
          parseDeeplink(newTask.reference, newTask.companyId)?.reference || '',
        collaborators: newTask.collaborators.map(
          (collaborator) => collaborator.id
        ),
        externalCollaborators: newTask.externalCollaborators.map(
          (collaborator) => collaborator.name
        ),
      }),
    [upsertTask]
  );

  useEffect(() => {
    setBoard(
      createInitialBoardFromTasks({
        companies,
        tasks,
        setShowAddEditTaskModal,
        collaboratorsData,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesUpdatedAt, collaboratorsUpdatedAt, tasksUpdatedAt]);

  return (
    <div className="grow -mx-6">
      <ControlledBoard
        disableColumnDrag
        allowRemoveColumn={false}
        allowRenameColumn={false}
        renderCard={TasksCard}
        onCardDragEnd={handleCardMove}
        renderColumnHeader={TasksColumnHeader}
        allowAddCard={false}
      >
        {controlledBoard}
      </ControlledBoard>
      {showAddEditTaskModal && (
        <TasksAddEditModal
          showCompanySelector
          companies={companies}
          onCompanyChange={(companyId) => {
            setShowAddEditTaskModal((prev) => ({
              ...prev,
              companyId,
            }));
          }}
          task={showAddEditTaskModal}
          collaborators={
            collaboratorsData?.collaboratorsByCompanyId?.[
              showAddEditTaskModal?.companyId
            ] || []
          }
          onCancel={() => {
            setShowAddEditTaskModal(null);
          }}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default AgencyDashboardPlanning;
