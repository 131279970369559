import PropTypes from 'prop-types';
import React from 'react';

import { EyeSlashIcon } from '@heroicons/react/20/solid';
import findIndex from 'lodash/findIndex';

import TagList from 'components/tags/TagList';
import { coreTopicsForUser } from 'constants/tags';

const CompanyQuoteTags = ({ tags = [], isPrivate = false, testId = '' }) => (
  <div className="pr-4 flex flex-wrap items-center">
    <TagList
      tags={tags.map((tag) => ({
        id: tag.name,
        colorIndex: findIndex(coreTopicsForUser, (t) => t.id === tag.id),
        text: tag.name,
        testId: `${testId}-${tag.name}`,
      }))}
    />

    {isPrivate && (
      <span className="p-0 py-1.5 mr-2 mb-2" data-test-id={`${testId}-private`}>
        <EyeSlashIcon className="w-4 h-4 text-gray-500" />
      </span>
    )}
  </div>
);

CompanyQuoteTags.propTypes = {
  tags: PropTypes.array,
  isPrivate: PropTypes.bool,
  testId: PropTypes.string,
};

export default CompanyQuoteTags;
