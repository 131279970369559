import PropTypes from 'prop-types';
import React from 'react';

import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import get from 'lodash/get';
import { Controller } from 'react-hook-form';

import ErrorMessage from 'components/form/ErrorMessage';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';

const InputFieldUploadFile = ({
  id = '',
  name = '',
  form = {},
  children = null,
  testId = '',
  showError = true,
  rules = {},
  multiple = false,
  accept = '',
}) => {
  const { companyData } = useCompanyData();

  const {
    watch,
    getValues,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    control,
  } = form;
  const error = get(errors, name, false);
  const inputId = id || name;

  const { fetchData: getUploadUrl } = useAPIRequest({
    service: 'MOD',
    endpoint: `/resources/${companyData.uid}/resource/uploadUrl`,
    method: 'POST',
  });

  const uploadFile = async (file) => {
    const {
      url,
      file: uploadedFile,
      headers,
    } = await getUploadUrl({
      body: {
        name: file.name,
        type: file.type,
      },
    });
    await fetch(url, {
      method: 'PUT',
      body: file,
      headers,
    });
    return uploadedFile;
  };

  const removeFile = (index) => {
    const currentFiles = getValues(name) || [];
    const updatedFiles = currentFiles.filter((_, i) => i !== index);
    setValue(name, updatedFiles);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      rules={rules}
      render={() => {
        const formFiles = watch(name) || [];

        return (
          <div className="w-full flex flex-col gap-2">
            {children}

            <div>
              <label
                htmlFor={inputId}
                className="relative py-2 px-3 w-fit flex items-center truncate rounded-md border border-gray-200 bg-white cursor-pointer"
              >
                <input
                  id={inputId}
                  type="file"
                  multiple={multiple}
                  accept={accept}
                  onChange={async (e) => {
                    const selectedFiles = e.target.files;
                    const existingFiles = getValues(name) || [];

                    const alreadyAddedFiles = Array.from(selectedFiles).filter(
                      (newFile) =>
                        existingFiles.some(
                          (existingFile) =>
                            existingFile.uploadedFile.fileName === newFile.name
                        )
                    );

                    if (alreadyAddedFiles.length > 0) {
                      setError(name, {
                        type: 'manual',
                        message: `The following files have already been added: ${alreadyAddedFiles
                          .map((file) => file.name)
                          .join(', ')}`,
                      });
                      e.target.value = '';
                      return;
                    }

                    const uploadedFilesArray = await Promise.all(
                      Array.from(selectedFiles).map(async (fileBody) => {
                        const uploadedFile = await uploadFile(fileBody);
                        return {
                          uploadedFile,
                          fileBody,
                        };
                      })
                    );

                    const allFiles = [...existingFiles, ...uploadedFilesArray];

                    setValue(name, allFiles);
                    clearErrors(name);
                    e.target.value = '';
                  }}
                  className="hidden"
                />

                <div className="mr-1 text-teal-500 ">
                  <PlusIcon className="w-6 h-6 -my-1" />
                </div>
                <div className="w-fit truncate text-sm">
                  <span className="font-medium text-gray-500">
                    {!formFiles || formFiles.length === 0
                      ? 'Add files...'
                      : 'Add another file...'}
                  </span>
                </div>
              </label>
            </div>

            {formFiles.length > 0 && (
              <ul className="mt-2 text-gray-600 space-y-2">
                {formFiles.map(({ uploadedFile }, index) => (
                  <li key={uploadedFile?.fileName ?? index}>
                    <div className="relative py-2 px-3 pr-8 w-fit flex items-center truncate rounded-md border border-gray-200 bg-white">
                      <span className="mr-3 text-xl">📄</span>
                      <div className="w-fit truncate text-sm">
                        <span className="font-medium text-gray-500">
                          {uploadedFile?.fileName ?? index}
                        </span>
                      </div>
                      <button
                        type="button"
                        onClick={() => removeFile(index)}
                        className="absolute top-0 right-0 mt-1 mr-1 p-1 text-gray-400 hover:text-gray-600 focus:outline-none"
                        aria-label="Remove file"
                      >
                        <XMarkIcon className="h-3 w-3" />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}

            {error && showError ? (
              <ErrorMessage testId={`${testId}-error`}>
                {error.message}
              </ErrorMessage>
            ) : null}
          </div>
        );
      }}
    />
  );
};

InputFieldUploadFile.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  children: PropTypes.node,
  testId: PropTypes.string,
  rules: PropTypes.object,
  showError: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
};

export default InputFieldUploadFile;
